import { faCalendarStar, faSpinner } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { t } from 'i18next';

export function Loading() {
  return (
    <div className="flex gap-2 items-center bg-neutrals-N300 p-2 rounded-md">
      <FontAwesomeIcon className="text-neutrals-N700" icon={faCalendarStar} />
      <span className="font-semibold text-sm">{t('entities.event_other')}</span>
      <FontAwesomeIcon
        className="text-neutrals-N700"
        icon={faSpinner}
        spin
        size="xs"
      />
    </div>
  );
}
