import { faFileContract } from '@fortawesome/pro-duotone-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Vertrag } from '../../../../gql/graphql';
import { Badge } from '@/components/ui/badge';

export function VertragsInfo({ vertrag }: { vertrag: Vertrag }) {
  return (
    <div className="flex gap-4 my-3 px-10">
      <FontAwesomeIcon
        fixedWidth
        swapOpacity
        size={'4x'}
        className="text-neutrals-N700/80 dark:text-neutrals-N400"
        icon={faFileContract}
      />
      <div>
        <h5 className="pb-2 text-lg font-bold">W-{vertrag.id}</h5>
        <div className="flex flex-row gap-2">
          <Badge
            variant="secondary"
            className="font-semibold text-neutrals-N800 bg-[#E5E7EB]">
            {'K-' + vertrag.kundennummer.toString()}
          </Badge>
          <span className="align-baseline">{vertrag.unternehmensname}</span>
        </div>
      </div>
    </div>
  );
}
