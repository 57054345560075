/* eslint-disable max-lines-per-function */
import { E2NTableCell } from '../../../../components/Table';
import { Dienstplanuebernahme } from '../../entities';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBan, faCheck, faSpinner } from '@fortawesome/pro-regular-svg-icons';
import { useState } from 'react';
import { Button } from '@/components/ui/button';
import {
  useDienstplansuebernahmeIgnorieren,
  useDienstplanuebernahmeUebernehmen,
} from '../../hooks/useDienstplanuebernahme';
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '@/components/ui/tooltip';
import { useTranslation } from 'react-i18next';

// eslint-disable-next-line complexity
export const DienstplanuebernahmeEdit = function EditCell({
  dienstplanuebernahme,
  disabled = false,
}: {
  dienstplanuebernahme: Dienstplanuebernahme;
  disabled?: boolean;
}) {
  const [ignorieren] = useDienstplansuebernahmeIgnorieren();
  const [uebernehmen] = useDienstplanuebernahmeUebernehmen();
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();

  function updateStates(state: boolean) {
    setLoading(state);
  }

  return (
    <E2NTableCell className="rounded-r-lg w-40 p-4">
      <div className="flex gap-1">
        <TooltipProvider>
          <Tooltip>
            <TooltipTrigger>
              <Button
                disabled={loading || disabled}
                onClick={() => {
                  updateStates(true);
                  uebernehmen([dienstplanuebernahme]).finally(() => {
                    updateStates(false);
                  });
                }}
                className="bg-primary-blue-B300 hover:bg-primary-blue-B500 size-7">
                {(loading || disabled) && (
                  <FontAwesomeIcon icon={faSpinner} fixedWidth spin />
                )}
                {!loading && !disabled && (
                  <FontAwesomeIcon icon={faCheck} fixedWidth />
                )}
              </Button>
            </TooltipTrigger>
            <TooltipContent>
              {t(
                'domain.arbeitszeiten.dienstplanuebernahme.buttons.uebernehmen_one',
              )}
            </TooltipContent>
          </Tooltip>
        </TooltipProvider>
        <TooltipProvider>
          <Tooltip>
            <TooltipTrigger>
              <Button
                disabled={loading || disabled}
                onClick={() => {
                  updateStates(true);
                  ignorieren([dienstplanuebernahme]).finally(() => {
                    setLoading(false);
                  });
                }}
                className="bg-primary-red-R200 text-primary-red-R400 hover:bg-primary-red-R300 size-7">
                {(loading || disabled) && (
                  <FontAwesomeIcon icon={faSpinner} fixedWidth spin />
                )}
                {!loading && !disabled && (
                  <FontAwesomeIcon icon={faBan} fixedWidth />
                )}
              </Button>
            </TooltipTrigger>
            <TooltipContent>
              {t(
                'domain.arbeitszeiten.dienstplanuebernahme.buttons.ignorieren_one',
              )}
            </TooltipContent>
          </Tooltip>
        </TooltipProvider>
      </div>
    </E2NTableCell>
  );
};
