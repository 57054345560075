import { faCircle } from '@fortawesome/pro-regular-svg-icons';
import { faCheck } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
export function CheckedIcon() {
  return (
    <span className="fa-stack fa-xs text-neutrals-N700">
      <FontAwesomeIcon
        icon={faCheck}
        fixedWidth
        className="fa-stack-1x text-primary-blue-B600 bg-white rounded-full py-[0.08rem]"
      />
      <FontAwesomeIcon icon={faCircle} fixedWidth className="fa-stack-2x" />
    </span>
  );
}
