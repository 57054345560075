import { Routes, Route } from 'react-router-dom';
import { Umsatzplanung, UmsatzplanungV2 } from './pages';
import { EditPlanwerteDialogForm } from './pages/UmsatzplanungV2/Planung';

export function Controlling() {
  return (
    <Routes>
      <Route path="/umsatzplanung/:year/:month" element={<Umsatzplanung />} />
      <Route path="/umsatzplanung" element={<Umsatzplanung />} />
      <Route path="/umsatzplanung-v2" element={<UmsatzplanungV2 />} />
      <Route
        path="/umsatzplanung-v2/:year/:month"
        element={<UmsatzplanungV2 />}>
        <Route
          path="/umsatzplanung-v2/:year/:month/planwerte/edit"
          element={<EditPlanwerteDialogForm />}
        />
      </Route>
    </Routes>
  );
}
