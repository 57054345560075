/* eslint-disable max-lines-per-function */
import { MoneyInput } from '@/components';
import { FormControl, FormField, FormItem } from '@/components/ui/form';
import { TableCell, TableRow } from '@/components/ui/table';
import { UmsatzPlan } from '@/domain/controlling/entities';
import { Control } from 'react-hook-form';
import {
  MoneyField,
  ZielstundenField,
} from '../../DataTable/Cells/GenericFields';
import { useTranslation } from 'react-i18next';

export function UmsatzPlanWerteTableRow({
  control,
  umsatzPlanwert,
}: {
  control: Control<{
    absatzPlan?: string;
    absatzProduktivitaet?: string;
    umsatzPlan?: string;
    umsatzProduktivitaet?: string;
  }>;
  umsatzPlanwert: UmsatzPlan;
}) {
  const { t } = useTranslation();

  return (
    <TableRow className="hover:bg-transparent">
      <TableCell className="font-medium py-4 ps-0 w-1/4">
        <FormField
          control={control}
          name="umsatzPlan"
          render={({ field }) => (
            <FormItem>
              <FormControl>
                <MoneyInput
                  value={field.value}
                  isIconVisible={false}
                  onBlur={(e) => {
                    field.onChange(e.target.value);
                  }}
                  onInput={(e) => {
                    field.onChange(e.currentTarget.value);
                  }}
                  unit="€"
                />
              </FormControl>
            </FormItem>
          )}
        />
      </TableCell>
      <TableCell className="font-medium p-4 w-1/4">
        <MoneyField
          value={umsatzPlanwert ? umsatzPlanwert.prognose : undefined}
          unit={t(`general.currency.EUR`)}
        />
      </TableCell>
      <TableCell className="font-medium p-4 w-1/4">
        <FormField
          control={control}
          name="umsatzProduktivitaet"
          render={({ field }) => (
            <FormItem>
              <FormControl>
                <MoneyInput
                  value={field.value}
                  isIconVisible={false}
                  onBlur={(e) => {
                    field.onChange(e.target.value);
                  }}
                  onInput={(e) => {
                    field.onChange(e.currentTarget.value);
                  }}
                  unit={t('domain.controlling.umsatzplanung.ziele.€/Std.')}
                />
              </FormControl>
            </FormItem>
          )}
        />
      </TableCell>
      <TableCell className="font-medium p-4 w-1/4">
        <ZielstundenField
          value={
            umsatzPlanwert && umsatzPlanwert.umsatz
              ? umsatzPlanwert.umsatz.stunden
              : undefined
          }
        />
      </TableCell>
    </TableRow>
  );
}
