import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMessageLines } from '@fortawesome/pro-regular-svg-icons';
import { Badge } from '@/components/ui/badge';

export function BemerkungCell({ bemerkung }: { bemerkung: string }) {
  return (
    <Badge className="px-1 py-1.5 bg-neutrals-N200" variant="secondary">
      <FontAwesomeIcon
        icon={faMessageLines}
        size="xl"
        className={bemerkung ? 'text-primary-blue-B600' : 'text-neutrals-N600'}
        fixedWidth
      />
    </Badge>
  );
}
