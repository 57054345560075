import {
  formatDateTime,
  formatCurrency,
  formatDuration,
  formatDate,
  formatBezahlStatus,
} from './attributFormatters';

// eslint-disable-next-line complexity
export function formatAttribut(attribut: string, value: string | null): string {
  if (value === null || value === 'null' || value === '') {
    return '';
  }

  switch (attribut) {
    case 'DATUM':
      return formatDate(value);
    case 'BEGINN':
    case 'ENDE':
    case 'KOMMEN':
    case 'GEHEN':
    case 'ERSTELLUNGSDATUM':
      return formatDateTime(value);
    case 'BETRAG':
    case 'STUNDENLOHN':
      return formatCurrency(value);
    case 'DAUER':
    case 'PAUSE':
      return formatDuration(value);
    case 'BEZAHLT':
      return formatBezahlStatus(value);
    case 'ARBEITSBEREICH':
    case 'ART':
    case 'BEMERKUNG':
    case 'MITARBEITER':
      return value ?? '';
    default:
      return value;
  }
}
