import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react';
import { Abteilung } from '../../../entities';
import { markierungMapper } from '../../../../../utils';
import { faExclamationCircle } from '@fortawesome/pro-light-svg-icons';
import { useStandort } from '../../../../../hooks';
import { useTranslation } from 'react-i18next';
import { CheckedIcon } from '../CheckedIcon';
import { UncheckedIcon } from '../UncheckedIcon';
import { Markierung } from '../../Markierung';
import { faTimes, faAnglesUpDown } from '@fortawesome/pro-regular-svg-icons';
import { useAbteilungen } from '../../../hooks/useAbteilungen';
import { Button } from '@/components/ui/button';
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from '@/components/ui/command';
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@/components/ui/popover';

// eslint-disable-next-line max-lines-per-function
export function AbteilungsFilter({
  onSelect,
}: {
  onSelect: (abteilungen: Abteilung[]) => void;
}) {
  const { standortId } = useStandort();
  const { abteilungen, error } = useAbteilungen(standortId);
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);

  const [selectedAbteilungen, setSelectedAbteilungen] = useState<Abteilung[]>(
    [],
  );

  function getColorByOption(abteilungId: string) {
    const abteilung = abteilungen.find(
      (a) => a.id.toLowerCase() === abteilungId,
    );
    const color = markierungMapper[abteilung.markierung].base;
    return color;
  }
  function handleSelect(currentValue: string) {
    const selectedIds = selectedAbteilungen.map((abteilung) => abteilung.id);

    const id = currentValue.split('% %')[1];

    if (selectedIds.includes(id)) {
      setSelectedAbteilungen((selectedAbteilungen) => {
        onSelect(
          selectedAbteilungen.filter(
            (selectedAbteilungen) => selectedAbteilungen.id !== id,
          ),
        );
        return selectedAbteilungen.filter(
          (selectedAbteilungen) => selectedAbteilungen.id !== id,
        );
      });
    } else {
      setSelectedAbteilungen((selectedAbteilungen) => {
        const abteilung = abteilungen.find((abteilung) => abteilung.id === id);
        onSelect([...selectedAbteilungen, abteilung]);
        return [...selectedAbteilungen, abteilung];
      });
    }
  }

  if (error) {
    return (
      <Button
        variant="ghost"
        role="combobox"
        aria-expanded={open}
        className="w-[240px] justify-between bg-white border border-neutrals-N300">
        {selectedAbteilungen.length > 0 ? (
          <div className="flex gap-2 items-center">
            <Markierung
              color={getColorByOption(
                selectedAbteilungen[selectedAbteilungen.length - 1].id,
              )}
            />
            {selectedAbteilungen[selectedAbteilungen.length - 1].name}
          </div>
        ) : (
          t('domain.arbeitszeiten.uebersicht.abteilungfilter.placeholder')
        )}
        <FontAwesomeIcon
          icon={faExclamationCircle}
          className="text-primary-red-R500"
        />
      </Button>
    );
  }

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger asChild>
        <Button
          variant="ghost"
          role="combobox"
          aria-expanded={open}
          className="w-[280px] h-[42px] bg-white border border-neutrals-N300 flex justify-between items-baseline">
          {selectedAbteilungen.length > 0 ? (
            <div className="flex gap-1 items-baseline">
              <Markierung
                color={getColorByOption(
                  selectedAbteilungen[selectedAbteilungen.length - 1].id,
                )}
              />
              <span className="truncate w-[110px] text-start text-base ">
                {selectedAbteilungen[selectedAbteilungen.length - 1].name}
              </span>
              {selectedAbteilungen.length > 1 && (
                <span className="text-neutrals-N700">
                  {'+' + (selectedAbteilungen.length - 1) + ' weitere'}
                </span>
              )}
            </div>
          ) : (
            <span className="text-neutrals-N700 w-20 font-normal text-base">
              {t('domain.arbeitszeiten.uebersicht.abteilungfilter.placeholder')}
            </span>
          )}
          <div className="flex items-baseline ps-2">
            {selectedAbteilungen.length > 0 && (
              <div
                className="group"
                onClick={(event) => {
                  event.preventDefault();
                  setSelectedAbteilungen([]);
                  onSelect([]);
                }}>
                <FontAwesomeIcon
                  className="group-hover:text-primary-blue-B600 text-neutrals-N700"
                  fixedWidth
                  size="lg"
                  icon={faTimes}
                />
              </div>
            )}
            <FontAwesomeIcon fixedWidth icon={faAnglesUpDown} />
          </div>
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-[280px] p-0">
        <Command
          filter={(value, search) => {
            if (
              value
                .split('% %')[0]
                .toLocaleLowerCase()
                .includes(search.toLocaleLowerCase())
            ) {
              return 1;
            }
            return 0;
          }}>
          <CommandInput
            placeholder={t(
              'domain.arbeitszeiten.uebersicht.abteilungfilter.search',
            )}
            className="h-9"
          />
          <CommandList>
            <CommandEmpty className="p-4 text-xs text-center text-neutrals-N900">
              {t('domain.arbeitszeiten.uebersicht.abteilungfilter.notFound')}
            </CommandEmpty>
            <CommandGroup>
              {abteilungen.map((abteilung) => (
                <CommandItem
                  key={abteilung.id}
                  //Wird benötigt um eindeutige Arbeitsbereiche zu haben, die den gleichen namen haben.
                  //Wird in der Filter funktion ausgewertet
                  //Und ja ich schäme mich dafüer aber andere Lösungen finde ich nicht :'(
                  value={`${abteilung.name}% %${abteilung.id}`}
                  onSelect={(currentValue) => {
                    handleSelect(currentValue);
                  }}>
                  <div className="flex justify-between items-center w-full">
                    <div className="flex gap-2 items-center truncate max-w-36">
                      <Markierung color={getColorByOption(abteilung.id)} />
                      <span className="truncate">{abteilung.name}</span>
                    </div>
                    {selectedAbteilungen.find(
                      (selectedAbteilungen) =>
                        selectedAbteilungen.id === abteilung.id,
                    ) ? (
                      <CheckedIcon />
                    ) : (
                      <UncheckedIcon />
                    )}
                  </div>
                </CommandItem>
              ))}
            </CommandGroup>
          </CommandList>
        </Command>
      </PopoverContent>
    </Popover>
  );
}
