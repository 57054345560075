import { useTranslation } from 'react-i18next';
import { E2NTableCell } from '../../../../components/Table';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getTimeDisplayFromDate } from '../../../../utils';
import { Badge } from '@/components/ui/badge';
import { faQuestionCircle } from '@fortawesome/pro-regular-svg-icons';

export function DienstplanuebernahmeArbeitszeit({
  beginn,
  ende,
  dauer,
}: {
  beginn: Date;
  ende: Date;
  dauer: number;
}) {
  const { t } = useTranslation();

  return (
    <E2NTableCell
      className="font-semibold w-[30%]"
      isBorderVisible
      isBorderDashed>
      <div className="flex items-center justify-between gap-4">
        <Badge className="flex flex-col gap-1 items-start basis-[65px] bg-primary-blue-B100 hover:bg-primary-blue-B100 text-primary-blue-B600 text-sm font-extrabold">
          {beginn !== null ? (
            <span>{getTimeDisplayFromDate(beginn)}</span>
          ) : (
            <FontAwesomeIcon icon={faQuestionCircle} size="lg" />
          )}
        </Badge>
        {dauer !== 0 ? (
          <>
            <div className="h-[1px]  bg-neutrals-N500 grow" />
            <span className="text-xs  text-neutrals-N700">
              {dauer} {t('general.date.minutes')}
            </span>
            <div className="h-[1px] bg-neutrals-N500 grow" />
          </>
        ) : (
          <div className="h-[1px] bg-neutrals-N500 grow" />
        )}

        <Badge
          className={`flex flex-col gap-1 items-start justify-center  ${
            ende !== null ? 'basis-[65px]' : 'basis-[30px] h-[26px]'
          } bg-primary-blue-B100 hover:bg-primary-blue-B100 text-primary-blue-B600 text-sm font-extrabold`}>
          {ende !== null ? (
            <span>{getTimeDisplayFromDate(ende)}</span>
          ) : (
            <FontAwesomeIcon icon={faQuestionCircle} size="lg" />
          )}
        </Badge>
      </div>
    </E2NTableCell>
  );
}
