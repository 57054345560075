/* eslint-disable complexity */
/* eslint-disable max-lines-per-function */
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '@/components/ui/dialog';
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '@/components/ui/tooltip';
import { faSpinner, faBan } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import { Dienstplanuebernahme } from '../../entities';
import { useState } from 'react';
import { useDienstplansuebernahmeIgnorieren } from '../../hooks/useDienstplanuebernahme';
import { Button } from '@/components/ui/button';

export function AlleIgnorierenDialog({
  dienstplanuebernahmen,
}: {
  dienstplanuebernahmen: Dienstplanuebernahme[];
}) {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [ignorieren] = useDienstplansuebernahmeIgnorieren();

  const [isDialogOpen, setIsDialogOpen] = useState(false);

  return (
    <Dialog open={isDialogOpen} onOpenChange={setIsDialogOpen}>
      <DialogTrigger disabled={isLoading || dienstplanuebernahmen.length === 0}>
        <TooltipProvider>
          <Tooltip>
            <TooltipContent>
              {t(
                'domain.arbeitszeiten.dienstplanuebernahme.buttons.ignorieren',
                { count: dienstplanuebernahmen.length },
              )}
            </TooltipContent>
            <TooltipTrigger>
              <Button
                disabled={isLoading || dienstplanuebernahmen.length === 0}
                className="bg-primary-red-R400 hover:bg-primary-red-R600 text-white size-7">
                {isLoading && (
                  <FontAwesomeIcon icon={faSpinner} fixedWidth spin />
                )}
                {!isLoading && <FontAwesomeIcon icon={faBan} fixedWidth />}
              </Button>
            </TooltipTrigger>
          </Tooltip>
        </TooltipProvider>
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>
            {t('domain.arbeitszeiten.dienstplanuebernahme.dialog.title')}
          </DialogTitle>
        </DialogHeader>
        {dienstplanuebernahmen.length < 2 && (
          <p>
            {t(
              'domain.arbeitszeiten.dienstplanuebernahme.dialog.descriptionIgnornieren_one',
              {
                count: dienstplanuebernahmen.length,
              },
            )}
          </p>
        )}
        {dienstplanuebernahmen.length > 1 && (
          <p>
            {t(
              'domain.arbeitszeiten.dienstplanuebernahme.dialog.descriptionIgnornieren_many',
              {
                count: dienstplanuebernahmen.length,
              },
            )}
          </p>
        )}
        <DialogFooter className="flex gap-4">
          <Button
            disabled={isLoading}
            className="bg-primary-red-R400 hover:bg-primary-red-R600 text-white"
            onClick={() => {
              setIsLoading(true);
              ignorieren(dienstplanuebernahmen).finally(() => {
                setIsLoading(false);
                setIsDialogOpen(false);
              });
            }}>
            {t(
              'domain.arbeitszeiten.dienstplanuebernahme.dialog.buttonIgnorieren',
            )}

            {isLoading && <FontAwesomeIcon icon={faSpinner} fixedWidth spin />}
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}
