import { faCaretDown, faCaretUp } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';

export function Abweichung({ abweichung }: { abweichung: number }) {
  const { t } = useTranslation();

  return (
    <span
      className={`text-xs w-fit whitespace-nowrap rounded-md py-[1px] px-2 flex gap-1 items-center ${
        Math.abs(abweichung) > 10
          ? 'text-primary-red-R500 bg-primary-red-R100'
          : ' bg-neutrals-N300'
      }`}>
      {abweichung < 0 && <FontAwesomeIcon icon={faCaretDown} />}
      {abweichung > 0 && <FontAwesomeIcon icon={faCaretUp} />}
      {Math.abs(abweichung)} {t('general.date.minutes')}
    </span>
  );
}
