import { faUsers, faLocationDot } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Vertrag, VertragTarif } from '../../../../gql/graphql';

// Functional Component, daher gilt diese Regel hier nicht
// eslint-disable-next-line max-lines-per-function
export function ProgressCircle({
  percentage,
  vertrag,
}: {
  percentage: number;
  vertrag: Vertrag;
}) {
  let percentages = 280 - (280 * percentage) / 100;

  if (percentages < 0) {
    percentages = 0;
  }

  return (
    <div className="relative w-20 h-20 rounded-full bg-neutrals-N400 bg-opacity-20 ">
      <FontAwesomeIcon
        className="absolute top-[1.5rem] ms-[1.25rem] text-neutrals-N700 dark:text-neutrals-N400"
        size="2xl"
        icon={
          vertrag.vertragTarif === VertragTarif.Mitarbeiter
            ? faUsers
            : faLocationDot
        }
        fixedWidth
      />
      <div className="absolute"></div>
      <svg className="w-full h-full" viewBox="0 0 100 100">
        {/* Background circle */}
        <circle
          className="text-neutrals-N300 stroke-current"
          strokeWidth="10"
          cx="50"
          cy="50"
          r="45"
          fill="transparent"
        />
        {/* Progress circle */}
        <circle
          className={
            percentages === 0
              ? 'text-primary-red-R400 progress-ring__circle stroke-current'
              : 'text-primary-green-G600 progress-ring__circle stroke-current'
          }
          strokeWidth="10"
          strokeLinecap="round"
          cx="50"
          cy="50"
          r="45"
          fill="transparent"
          strokeDashoffset={percentages}
        />
      </svg>
    </div>
  );
}
