import { useTranslation } from 'react-i18next';
import { useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { changePasswort } from '../../../services';
import { z } from 'zod';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleNotch } from '@fortawesome/pro-solid-svg-icons';
import { Button } from '@/components/ui/button';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form';
import { Input } from '@/components/ui/input';

export function PasswordReset() {
  const { t } = useTranslation();
  return (
    <div>
      <div className="grid md:grid-cols-6 grid-cols-1 gap-6 py-12">
        <div className="flex flex-col gap-2 md:col-span-2 col-span-full">
          <div className="flex gap-2">
            <h2 className="text-lg font-semibold leading-7 text-neutrals-N900">
              {t('pages.profile.sections.changePassword.heading')}
            </h2>
          </div>
          <p className="text-sm leading-6 text-neutrals-N900">
            <p>
              {t('pages.profile.sections.changePassword.description.general')}
            </p>
            <ul className="ml-5 list-disc">
              <li>
                {t('pages.profile.sections.changePassword.description.1')}
              </li>
              <li>
                {t('pages.profile.sections.changePassword.description.2')}
              </li>
              <li>
                {t('pages.profile.sections.changePassword.description.3')}
              </li>
            </ul>
          </p>
        </div>
        <div className="md:col-start-3 col-span-full 2xl:col-span-2 xl:col-span-3 content-center ml-8">
          <ChangePassword />
        </div>
      </div>
    </div>
  );
}

// eslint-disable-next-line max-lines-per-function
function ChangePassword() {
  const { t } = useTranslation();
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const schema = useMemo(() => {
    return z
      .object({
        oldPassword: z.string({
          message: t('pages.profile.sections.changePassword.old.invalid'),
        }),
        newPassword: z
          .string({
            message: t('pages.profile.sections.changePassword.new.empty'),
          })
          .min(12, {
            message: t('pages.profile.sections.changePassword.new.unsecure'),
          }),
        newPasswordRepeat: z.string({
          message: t('pages.profile.sections.changePassword.repeat.empty'),
        }),
      })
      .superRefine((val, ctx) => {
        if (val.newPasswordRepeat.length < 1) {
          return;
        }
        if (val.newPassword !== val.newPasswordRepeat) {
          ctx.addIssue({
            code: 'custom',
            message: t('pages.profile.sections.changePassword.repeat.invalid'),
            path: ['newPasswordRepeat'],
          });
        }
      });
  }, [t]);

  const form = useForm<z.infer<typeof schema>>({
    resolver: zodResolver(schema),
  });

  return (
    <div className="flex w-full gap-2">
      <div></div>
      <div className="w-full">
        <Form {...form}>
          <form
            className="flex flex-col gap-4"
            onSubmit={form.handleSubmit(async (data) => {
              setIsLoading(true);
              const returnData = await changePasswort(
                data.oldPassword,
                data.newPassword,
              );
              if (returnData.status === 'error') {
                if (returnData.type === 'NotAuthorized') {
                  form.setError('oldPassword', {
                    message: t(
                      'pages.profile.sections.changePassword.old.invalid',
                    ),
                  });
                  setIsLoading(false);
                  return;
                }
                setError(true);
                setIsLoading(false);
                return;
              }
              setSuccess(true);
              setError(false);
              setIsLoading(false);
              setTimeout(() => {
                setSuccess(false);
              }, 3000);
            })}>
            <FormField
              control={form.control}
              name="oldPassword"
              render={({ field }) => (
                <FormItem className="">
                  <FormLabel>
                    {t('pages.profile.sections.changePassword.old.label')}
                  </FormLabel>
                  <FormControl>
                    <Input type="password" {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="newPassword"
              render={({ field }) => (
                <FormItem className="">
                  <FormLabel>
                    {t('pages.profile.sections.changePassword.new.label')}
                  </FormLabel>
                  <FormControl>
                    <Input type="password" {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="newPasswordRepeat"
              render={({ field }) => (
                <FormItem className="">
                  <FormLabel>
                    {t('pages.profile.sections.changePassword.repeat.label')}
                  </FormLabel>
                  <FormControl>
                    <Input type="password" {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <div className="flex flex-col gap-2 items-start">
              <Button
                className={`w-fit flex gap-2 transition-all duration-300 ${
                  success ? 'bg-primary-green-G600' : ''
                }`}
                type="submit"
                disabled={isLoading}>
                {isLoading && (
                  <FontAwesomeIcon
                    className="animate-spin"
                    icon={faCircleNotch}
                  />
                )}
                <span>
                  {success === false &&
                    t('pages.profile.sections.changePassword.confirm')}
                  {success &&
                    t('pages.profile.sections.changePassword.success')}
                </span>
              </Button>
              {error && (
                <span className="text-sm text-primary-red-R400 max-w-[50%]">
                  {t('pages.profile.sections.changePassword.error')}
                </span>
              )}
            </div>
          </form>
        </Form>
      </div>
    </div>
  );
}
