/* eslint-disable max-lines-per-function */
/* eslint-disable complexity */
import { maskitoParseNumber } from '@maskito/kit';
import { t } from 'i18next';
import { z } from 'zod';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
dayjs.extend(customParseFormat);
dayjs.extend(isSameOrAfter);

export const formSchema = z
  .object({
    dauer: z
      .string()
      .refine(
        (val) =>
          val === '' ||
          (!isNaN(maskitoParseNumber(val, '.')) &&
            maskitoParseNumber(val, '.') > 0),
        {
          message: t(
            'domain.arbeitszeiten.sheet.arbeitszeit.pausenError.dauerLaufzeit',
          ),
        },
      )
      .nullable(),
    beginn: z.string().nullable(),
    ende: z.string().nullable(),
    bezahlt: z.boolean().optional(),
    arbeitszeitStart: z.string().nullable().optional(),
    arbeitszeitEnde: z.string().nullable().optional(),
  })
  .superRefine((data, ctx) => {
    const arbeitszeitStart = dayjs(data.arbeitszeitStart, 'HH:mm');
    let arbeitszeitEnde = dayjs(data.arbeitszeitEnde, 'HH:mm');
    let beginn = dayjs(data.beginn, 'HH:mm');
    let ende = dayjs(data.ende, 'HH:mm');

    if (arbeitszeitEnde.isBefore(arbeitszeitStart)) {
      arbeitszeitEnde = arbeitszeitEnde.add(1, 'day');
    }
    if (beginn.isBefore(arbeitszeitStart)) {
      beginn = beginn.add(1, 'day');
    }
    if (ende.isBefore(arbeitszeitStart)) {
      ende = ende.add(1, 'day');
    }

    if ((data.dauer !== '') === (data.beginn !== '' && data.ende !== '')) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: t(
          'domain.arbeitszeiten.sheet.arbeitszeit.pausenError.dauerBeginnEndeNotEmpty',
        ),
        path: ['dauer'],
      });
    }

    if (beginn.isSame(ende)) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: t(
          'domain.arbeitszeiten.sheet.arbeitszeit.pausenError.beginnEndeNotEqual',
        ),
        path: ['beginn'],
      });
    }

    if (
      beginn.isBefore(arbeitszeitStart) ||
      beginn.isSameOrAfter(arbeitszeitEnde)
    ) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: t(
          'domain.arbeitszeiten.sheet.arbeitszeit.pausenError.innerhalbArbeitszeitBeginn',
        ),
        path: ['beginn'],
      });
    }

    if (isEndeValid(ende, arbeitszeitStart, arbeitszeitEnde)) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: t(
          'domain.arbeitszeiten.sheet.arbeitszeit.pausenError.innerhalbArbeitszeitEnde',
        ),
        path: ['beginn'],
      });
    }
  });

function isEndeValid(
  ende: dayjs.Dayjs,
  arbeitszeitStart: dayjs.Dayjs,
  arbeitszeitEnde: dayjs.Dayjs,
): boolean {
  if (
    !ende.isValid() ||
    !arbeitszeitEnde.isValid() ||
    !arbeitszeitStart.isValid()
  ) {
    return false;
  }

  const isArbeitszeitEndeBeforArbeitszeitStart =
    arbeitszeitEnde.isBefore(arbeitszeitStart);
  const isArbeitszeitEndeAfterArbeitszeitStart =
    arbeitszeitEnde.isAfter(arbeitszeitStart);
  const isPausenendeBeforeArbeitszeitStart = ende.isBefore(arbeitszeitStart);
  const isPausenEndeAfterArbeitszeitEnde = ende.isAfter(arbeitszeitEnde);

  if (isArbeitszeitEndeAfterArbeitszeitStart) {
    return (
      isPausenEndeAfterArbeitszeitEnde || isPausenendeBeforeArbeitszeitStart
    );
  }

  return (
    isArbeitszeitEndeBeforArbeitszeitStart &&
    isPausenEndeAfterArbeitszeitEnde &&
    isPausenendeBeforeArbeitszeitStart
  );
}

export type FormValues = z.infer<typeof formSchema>;
