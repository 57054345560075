import { useMutation } from '@apollo/client';
import { useCallback } from 'react';
import { ARBEITSZEIT_UPDATE } from './graphql/mutation';
import {
  ArbeitszeitUpdateMutation,
  ArbeitszeitUpdateMutationVariables,
} from '../../../gql/graphql';

export function useArbeitszeitUpdate() {
  const [execute, result] = useMutation<
    ArbeitszeitUpdateMutation,
    ArbeitszeitUpdateMutationVariables
  >(ARBEITSZEIT_UPDATE, {
    refetchQueries: ['getArbeitszeitenByDateAndStandortId'],
  });

  const arbeitszeitUpdate = useCallback(
    (variables: ArbeitszeitUpdateMutationVariables) => {
      return execute({
        variables,
      });
    },
    [execute],
  );

  return [arbeitszeitUpdate, result] as const;
}
