import {
  ApolloClient,
  ApolloProvider,
  createHttpLink,
  from,
  InMemoryCache,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { ReactNode } from 'react';
import { fetchAuthSession } from 'aws-amplify/auth';
import { loadErrorMessages, loadDevMessages } from '@apollo/client/dev';
import { RetryLink } from '@apollo/client/link/retry';
import { getEnvironment } from './utils';
import * as Sentry from '@sentry/react';

if (import.meta.env.DEV) {
  loadDevMessages();
  loadErrorMessages();
}

const httpLink = createHttpLink({
  uri:
    getEnvironment() === 'production'
      ? import.meta.env.VITE_GRAPHQL_API_URL_PROD
      : getEnvironment() === 'staging'
      ? import.meta.env.VITE_GRAPHQL_API_URL_STAGING
      : import.meta.env.VITE_GRAPHQL_API_URL_LOCAL,
});

const authLink = setContext(async (_, { headers }) => {
  let accessToken: string;
  try {
    const { tokens } = await fetchAuthSession();
    accessToken = tokens.accessToken.toString();
  } catch (error) {
    Sentry.captureException(error);
  }

  return {
    headers: {
      ...headers,
      authorization: accessToken ? `Bearer ${accessToken}` : '',
    },
  };
});

const retryLink = new RetryLink({
  delay: {
    initial: 100,
    max: 1000,
    jitter: false,
  },
  attempts: {
    max: 3,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    retryIf: (error, _operation) => {
      return !!error;
    },
  },
});

const client = new ApolloClient({
  link: from([authLink.concat(httpLink), retryLink]),
  cache: new InMemoryCache(),
});

export function ApolloProviderWrapper({ children }: { children: ReactNode }) {
  return <ApolloProvider client={client}>{children}</ApolloProvider>;
}
