import { useTranslation } from 'react-i18next';
import { Zuschlag } from '../../entities';
import { truncatNumberToTwoDigits } from '../../util';

export function ZuschlaegeRow({ zuschlag }: { zuschlag: Zuschlag }) {
  const { t } = useTranslation();
  const showBetrag = zuschlag.betrag !== null;
  return (
    <>
      <div className="flex border-b border-neutrals-N300 last:border-none max-w-full p-5 font-bold tracking-wide">
        <div className="w-3/4">
          <span className="truncate">{zuschlag.name}</span>
        </div>
        <div className="w-1/2 flex gap-1">
          <span>{zuschlag.dauer}</span>
          <span>{t('general.date.minutes')}</span>
        </div>
        {showBetrag && (
          <div className="w-1/6 flex gap-1">
            <span> {truncatNumberToTwoDigits(zuschlag.betrag)}</span>
            <span>{t('general.currency.EUR')}</span>
          </div>
        )}
      </div>
    </>
  );
}
