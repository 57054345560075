import React from 'react';
import ReactDOM from 'react-dom/client';
import './styles/main.scss';
import './i18n';
import './index.css';
import { ApolloProviderWrapper } from './ApolloClient';
import './styles/react-grid-styles.scss';
import { Amplify } from 'aws-amplify';
import { authConfig } from './amplifyAuthConfig';
import { cognitoUserPoolsTokenProvider } from 'aws-amplify/auth/cognito';
import { isLocalhost, isReview } from './utils';
import { CookieStorage } from 'aws-amplify/utils';
import { BrowserRouter } from 'react-router-dom';
import { AuthenticationProvider } from './app/AuthenticationContext';
import { HelmetProvider, Helmet } from 'react-helmet-async';
import { GlobalStyles } from './GlobalStyles';
import { App } from './app';

import * as Sentry from '@sentry/react';

Sentry.init({
  dsn: 'https://5166d9a1dfcdc13bbaecd7b1b972aaf2@o4508318398545920.ingest.de.sentry.io/4508675909419088',
  beforeSend(event) {
    const errorMessage = event.exception?.values?.[0]?.value || '';

    const ignoredMessages = [
      'jb',
      'db',
      'Object captured as promise rejection with keys: details, message, status',
      'CWR: Failed to retrieve Cognito OpenId token: TypeError: Load failed',
      'Incorrect username or password.',
      'User does not exist.',
      'gmo',
    ];

    if (
      errorMessage &&
      ignoredMessages.some((msg) => errorMessage.includes(msg))
    ) {
      return null;
    }

    return event;
  },
  environment: import.meta.env.VITE_SENTRY_ENVIROMENT,
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
    Sentry.feedbackIntegration({
      colorScheme: 'light',
      autoInject: false,
    }),
  ],
  // Tracing
  tracesSampleRate: 0.1, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ['localhost', /^https:\/\/yourserver\.io\/api/],
  // Session Replay
  replaysSessionSampleRate: 0,
  replaysOnErrorSampleRate: 0.1, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

Amplify.configure({ Auth: authConfig });
cognitoUserPoolsTokenProvider.setKeyValueStorage(
  new CookieStorage({
    domain: isLocalhost
      ? 'localhost'
      : isReview
      ? window.location.host
      : 'e2n.de',
    path: '/',
    expires: 365,
    secure: isLocalhost ? false : true,
    sameSite: 'strict',
  }),
);

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <React.StrictMode>
    <AuthenticationProvider>
      <ApolloProviderWrapper>
        <HelmetProvider>
          <Helmet defaultTitle="e2n Manager" titleTemplate="%s · e2n Manager" />
          <GlobalStyles />
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </HelmetProvider>
      </ApolloProviderWrapper>
    </AuthenticationProvider>
  </React.StrictMode>,
);
