import { useTranslation } from 'react-i18next';
import { HistorieTabelle } from './HistorieTabelle';
import { useAudit } from '@/domain/arbeitszeiten/hooks/useAudit';
import { useEditSheetContext } from '../../EditSheetContext';

export function Historie() {
  const { arbeitszeit } = useEditSheetContext();
  const { audit } = useAudit(arbeitszeit.id);
  const { t } = useTranslation();

  return (
    <div className="flex flex-col gap-6">
      {audit.length > 0 ? (
        audit.map((audit, index) => (
          <div
            key={index}
            className="flex flex-col justify-center bg-white rounded-md p-1">
            <HistorieTabelle historie={[audit]} />
          </div>
        ))
      ) : (
        <div>{t('domain.arbeitszeiten.sheet.historie.empty')}</div>
      )}
    </div>
  );
}
