import { ArbeitsbereichFilter } from './DataTable';
import { Abteilung } from './entities';

export function filterMitarbeiterName(
  vorname: string,
  nachname: string,
  filterValue: string,
) {
  const mitarbeiterName = vorname + ' ' + nachname;
  if (mitarbeiterName.toLowerCase().includes(filterValue.toLowerCase())) {
    return true;
  }
  return false;
}

export function filterArbeitsbereiche(
  arbeitsbereichId: string,
  abteilungen: Abteilung[],
  filterValue: ArbeitsbereichFilter,
  filterArbeitsbereiche: string[],
  filterAbteilungen: string[],
) {
  if (!filterValue) {
    return true;
  }

  if (filterArbeitsbereiche && filterArbeitsbereiche.length > 0) {
    return filterArbeitsbereiche.includes(arbeitsbereichId);
  }

  if (filterAbteilungen && filterAbteilungen.length > 0) {
    return abteilungen.some((abteilung) =>
      filterAbteilungen.includes(abteilung.id),
    );
  }

  return true;
}
