import { Routes, Route } from 'react-router-dom';
import { LoginPage } from '../../pages';

export function UnauthenticatedRoutes() {
  return (
    <Routes>
      <Route index element={<LoginPage />} />
      <Route path="/login" element={<LoginPage />} />
      <Route path="*" element={<LoginPage />} />
    </Routes>
  );
}
