import { Navigation as E2NNavigation } from '@e2n/e2n-ui';
import { useMenuItems } from './useMenuItems';
import { ProfileItem } from './ProfileItem';
import { HomeItem } from './HomeItem/HomeItem';

export const Navigation = () => {
  const menuItems = useMenuItems();

  return (
    <E2NNavigation
      menuItems={menuItems}
      label="main-navigation"
      renderProfile={() => <ProfileItem />}
      renderHome={() => <HomeItem />}
    />
  );
};
