import { Link, useParams } from 'react-router-dom';
import { E2NTableCell } from '../../../../components/Table';
import { Arbeitszeit } from '../../entities';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit } from '@fortawesome/pro-regular-svg-icons';
import { memo } from 'react';

export const EditCell = memo(function EditCell({
  arbeitszeit,
}: {
  arbeitszeit: Arbeitszeit;
}) {
  const { year, month, day } = useParams();
  return (
    <E2NTableCell className="rounded-r-lg w-40 p-4">
      <Link
        to={`/arbeitszeiten/uebersicht/${year}/${month}/${day}/sheet/edit/${arbeitszeit.id}`}>
        <FontAwesomeIcon
          icon={faEdit}
          className="text-primary-blue-B600"
          fixedWidth
        />
      </Link>
    </E2NTableCell>
  );
});
