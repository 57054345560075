export class TagebuchEinstellung {
  constructor(
    readonly absatzproduktivitaet: AbsatzproduktivitaetEinstellungen,
  ) {}
}

export class AbsatzproduktivitaetEinstellungen {
  constructor(
    readonly standortId: string,
    readonly absatzVorgabe: AbsatzVorgabe,
    readonly zielProduktivitaet: number,
  ) {}
}

export enum AbsatzVorgabe {
  Mpt = 'MPT',
  Stueck = 'STUECK',
}
