import { ChartConfig, ChartContainer } from '@/components/ui/chart';
import { Pie, PieChart as RechartPieChart } from 'recharts';

export function PieChartSkeleton({
  umsatzplanung,
}: {
  umsatzplanung: 'UMSATZ' | 'ABSATZ' | 'UMSATZ_ABSATZ';
}) {
  const chartConfig = {
    festangestellte: {
      color: '#E5E5EA',
    },
  } satisfies ChartConfig;

  const chartData = [
    {
      type: 'festangestellte',
      count: 1000,
      fill: '#E5E5EA',
    },
  ];

  return (
    <ChartContainer
      config={chartConfig}
      className={`aspect-square ${
        umsatzplanung === 'UMSATZ_ABSATZ'
          ? 'w-[100px] h-[100px]'
          : 'w-[50px] h-[50px]'
      }`}>
      <RechartPieChart margin={{ top: 0, right: 0, bottom: 0, left: 0 }}>
        <Pie
          isAnimationActive={false}
          data={chartData}
          dataKey="count"
          nameKey="type"
          innerRadius={umsatzplanung === 'UMSATZ_ABSATZ' ? 30 : 13}
        />
      </RechartPieChart>
    </ChartContainer>
  );
}
