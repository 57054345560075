import { useStandort } from '../../../../hooks';
import { Mitarbeiter } from '../../entities';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMitarbeiter } from '../../hooks/useMitarbeiter';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faAnglesUpDown,
  faExclamationCircle,
} from '@fortawesome/pro-regular-svg-icons';
import { Button } from '@/components/ui/button';
import { Avatar, AvatarFallback, AvatarImage } from '@/components/ui/avatar';
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from '@/components/ui/command';
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@/components/ui/popover';

// eslint-disable-next-line max-lines-per-function
export function MitarbeiterSelect({
  onChange,
  isUpdating,
  selectedMitarbeiter,
}: {
  onChange?: (newMitarbeiter: Mitarbeiter) => void;
  isUpdating: boolean;
  selectedMitarbeiter?: Mitarbeiter;
}) {
  const [open, setOpen] = useState(false);
  const { t } = useTranslation();
  const { standortId } = useStandort();
  const { mitarbeiter, error } = useMitarbeiter(standortId);

  if (error) {
    return (
      <Button
        variant="outline"
        role="combobox"
        aria-expanded={open}
        className="w-[275px] justify-between"
        disabled>
        {t('domain.arbeitszeiten.create.mitarbeiterError')}
        <FontAwesomeIcon
          icon={faExclamationCircle}
          className="text-primary-red-R500"
        />
      </Button>
    );
  }

  function handleSelect(newMitarbeiterId: string) {
    const selectedMitarbeiter = mitarbeiter.find(
      (m) => m.id === newMitarbeiterId,
    );
    onChange?.(selectedMitarbeiter);
    setOpen(false);
  }

  function handleFilter(id: string, search: string) {
    const searchLower = search.toLowerCase();
    const filteredMitarbeiter = mitarbeiter.filter(
      (m) =>
        m.vorname.toLowerCase().includes(searchLower) ||
        m.nachname.toLowerCase().includes(searchLower),
    );
    return filteredMitarbeiter.some((m) => m.id === id) ? 1 : 0;
  }

  return (
    <Popover open={open} onOpenChange={(open) => setOpen(open)}>
      <PopoverTrigger asChild>
        <Button
          variant="outline"
          role="combobox"
          className="w-[275px] justify-between">
          {selectedMitarbeiter
            ? `${selectedMitarbeiter?.vorname} ${selectedMitarbeiter?.nachname}`
            : t('domain.arbeitszeiten.create.mitarbeiterSelectPlaceholder')}
          <FontAwesomeIcon icon={faAnglesUpDown} />
        </Button>
      </PopoverTrigger>
      <PopoverContent className="bg-white p-0 max-w-[275px]">
        <Command filter={handleFilter}>
          <CommandInput disabled={isUpdating} placeholder="Suchen…" />
          <CommandList>
            <CommandEmpty className="p-4 text-xs text-center">
              {t('domain.arbeitszeiten.create.mitarbeiterSelectNotFound')}
            </CommandEmpty>
            <CommandGroup>
              {mitarbeiter.map((m) => (
                <CommandItem
                  className="flex items-center gap-2 p-2"
                  value={m.id}
                  key={m.id}
                  onSelect={handleSelect}>
                  <Avatar>
                    <AvatarImage
                      src={m.bildUrl}
                      alt={`${m.vorname} ${m.nachname}`}
                    />
                    <AvatarFallback className="text-slate-800">
                      {m.vorname[0] + m.nachname[0]}
                    </AvatarFallback>
                  </Avatar>
                  <div className="truncate grow">{`${m.vorname} ${m.nachname}`}</div>
                  {m.extern && (
                    <div className="py-1 px-3 bg-primary-blue-B200/25 rounded-full text-xs font-semibold text-primary-blue-B400">
                      {t(
                        'domain.arbeitszeiten.create.mitarbeiterSelectExternLabel',
                      )}
                    </div>
                  )}
                </CommandItem>
              ))}
            </CommandGroup>
          </CommandList>
        </Command>
      </PopoverContent>
    </Popover>
  );
}
