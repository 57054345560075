import { CSSProperties } from 'react';
import cx from 'classnames';
import Skeleton from './Skeleton';

type TextFieldProps = {
  className?: string;
  height?: CSSProperties['height'];
  width?: CSSProperties['width'];
};

type TextStyleProps = CSSProperties & {
  '--skeleton--field--width': CSSProperties['width'];
  '--skeleton--field--height': CSSProperties['height'];
};

export function TextField({ height, width }: TextFieldProps) {
  return (
    <div className="flex flex-col gap-2">
      <Skeleton.Text height={'16px'} width={'66px'} />
      <div
        className={cx(
          'bg-neutrals-N300 animate-pulse',
          'h-[var(--skeleton--field--height,100%)] w-[var(--skeleton--field--width,100%)] rounded-md',
        )}
        style={
          {
            '--skeleton--field--width': width,
            '--skeleton--field--height': height,
          } as TextStyleProps
        }
      />
    </div>
  );
}
