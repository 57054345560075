export type TimeChangeType = 'Sommerzeit' | 'Winterzeit' | false;

export function checkTimeChange(date: Date): TimeChangeType {
  const year = date.getFullYear();

  const getLastSundayOfMonth = (year: number, month: number): Date => {
    const lastDay = new Date(year, month + 1, 0);
    lastDay.setDate(lastDay.getDate() - lastDay.getDay());
    return lastDay;
  };

  const isSameDay = (d1: Date, d2: Date): boolean =>
    d1.getFullYear() === d2.getFullYear() &&
    d1.getMonth() === d2.getMonth() &&
    d1.getDate() === d2.getDate();

  const summerStart = getLastSundayOfMonth(year, 2); // März
  const winterStart = getLastSundayOfMonth(year, 9); // Oktober

  if (isSameDay(date, summerStart)) return 'Sommerzeit';
  if (isSameDay(date, winterStart)) return 'Winterzeit';

  return false;
}
