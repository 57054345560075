import { useSuspenseQuery } from '@apollo/client';
import { Vertrag } from '../../../../gql/graphql';
import { GET_VERTRAEGE_BY_STANDORTID } from './queries';
import { useStandortContext } from '../../../../store/standortContext';

export function useVertraege(): {
  vertraege?: Vertrag[];
  error?: string;
} {
  const idStandorte = useStandortContext((s) => s.standorte);
  const standortIds = idStandorte.map((standort) => standort.id);
  const { data } = useSuspenseQuery(GET_VERTRAEGE_BY_STANDORTID, {
    variables: {
      idStandorte: standortIds,
    },
    fetchPolicy: 'network-only',
  });

  if (data.vertraegeByIdStandort.vertrag.length < 1) {
    return {
      vertraege: [],
    };
  }

  const vertraege = data.vertraegeByIdStandort.vertrag.filter(
    (vertragsdaten) => vertragsdaten !== null && vertragsdaten.id.length > 0,
  );

  return {
    vertraege: Array.from(
      new Set(vertraege.map((vertragsdaten) => vertragsdaten)),
    ).map((vertrag) => ({
      id: vertrag.id,
      kundennummer: vertrag.kundennummer,
      unternehmensname: vertrag.unternehmensname,
      aktiveAnzahl: vertrag.aktiveAnzahl,
      vereinbarteAnzahl: vertrag.vereinbarteAnzahl,
      vertragTarif: vertrag.vertragTarif,
      vertragIntervall: vertrag.vertragIntervall,
      faelligkeitsdatum: vertrag.faelligkeitsdatum,
      summeNetto: {
        waehrung: vertrag.summeNetto.waehrung,
        wert: vertrag.summeNetto.wert,
      },
      vertragZahlungsmethode: vertrag.vertragZahlungsmethode,
    })),
  };
}
