import { Badge } from '@/components/ui/badge';
import { useTranslation } from 'react-i18next';
import { useEditSheetContext } from '../EditSheetContext';

export function ZuschlaegeTrigger() {
  const { t } = useTranslation();
  const { arbeitszeit } = useEditSheetContext();
  const count =
    arbeitszeit.zuschlaege !== null ? arbeitszeit.zuschlaege.length : 0;

  return (
    <div className="flex gap-2">
      {count > 0 && (
        <Badge className="flex items-center justify-center rounded-full text-white size-5 p-1 text-[10px] font-semibold">
          {count}
        </Badge>
      )}
      <span>{t('domain.arbeitszeiten.sheet.tabs.zuschläge')}</span>
    </div>
  );
}
