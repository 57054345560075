import { useTranslation } from 'react-i18next';
import { E2NTableCell } from '../../../../components/Table';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Antrag } from '../../entities';
import { getTimeDisplayFromDate } from '../../../../utils';
import { faPaperPlane } from '@fortawesome/pro-light-svg-icons';
import { Link, useParams } from 'react-router-dom';
import { ArbeitszeitantragStatus } from '../../../../gql/graphql';
import { faClock } from '@fortawesome/pro-light-svg-icons';

function AntragWrapper({
  status,
  time,
}: {
  status: ArbeitszeitantragStatus;
  time: string;
}) {
  const bgColor =
    status === ArbeitszeitantragStatus.Genehmigt
      ? 'bg-primary-green-G100'
      : status === ArbeitszeitantragStatus.Abgelehnt
      ? 'bg-primary-red-R100'
      : 'bg-primary-blue-B100';

  const textColor =
    status === ArbeitszeitantragStatus.Genehmigt
      ? 'text-primary-green-G600'
      : status === ArbeitszeitantragStatus.Abgelehnt
      ? 'text-primary-red-R400'
      : 'text-primary-blue-B600';

  return (
    <div className={`flex items-center gap-2 ${bgColor} p-1 rounded-md`}>
      <span className={`${textColor}`}>{time}</span>
      <FontAwesomeIcon
        size="xs"
        className={`${textColor}`}
        icon={faPaperPlane}
      />
    </div>
  );
}

// eslint-disable-next-line max-lines-per-function, complexity
export const ArbeitszeitAntragCell = function ArbeitszeitAntragCell({
  beginn,
  ende,
  dauer,
  antraege,
  arbeitszeitId,
}: {
  beginn: Date;
  ende: Date;
  dauer: number;
  antraege: Antrag[];
  arbeitszeitId: string;
}) {
  const { t } = useTranslation();
  const { year, month, day } = useParams();

  let beginnAntrag = antraege.filter((antrag) => antrag.beginn !== null);
  if (beginnAntrag.length > 1) {
    beginnAntrag = beginnAntrag.sort((a, b) => {
      return b.aenderungsdatum.getTime() - a.aenderungsdatum.getTime();
    });
    beginnAntrag = [beginnAntrag[0]];
  }
  let endeAntrag = antraege.filter((antrag) => antrag.ende !== null);
  if (endeAntrag.length > 1) {
    endeAntrag = endeAntrag.sort((a, b) => {
      return b.aenderungsdatum.getTime() - a.aenderungsdatum.getTime();
    });
    endeAntrag = [endeAntrag[0]];
  }

  return (
    <E2NTableCell
      className="font-semibold w-[30%]"
      isBorderVisible
      isBorderDashed>
      <div className="flex items-center justify-between gap-4">
        <div className="flex flex-col gap-1 items-start basis-[70px]">
          {beginn !== null ? (
            <span>{getTimeDisplayFromDate(beginn)}</span>
          ) : (
            <Link
              to={
                beginnAntrag[0]
                  ? `/arbeitszeiten/uebersicht/${year}/${month}/${day}/sheet/edit/${arbeitszeitId}/antraege`
                  : `/arbeitszeiten/uebersicht/${year}/${month}/${day}/sheet/edit/${arbeitszeitId}/arbeitszeit`
              }>
              {beginnAntrag[0] ? (
                <AntragWrapper
                  status={beginnAntrag[0].status}
                  time={getTimeDisplayFromDate(beginnAntrag[0].beginn)}
                />
              ) : (
                <FontAwesomeIcon
                  icon={faClock}
                  size="lg"
                  className="motion-safe:animate-bounce"
                  bounce
                />
              )}
            </Link>
          )}
        </div>
        {dauer !== 0 ? (
          <>
            <div className="h-[1px] bg-neutrals-N500 grow" />
            <span className="text-xs text-neutrals-N700">
              {dauer} {t('general.date.minutes')}
            </span>
            <div className="h-[1px] bg-neutrals-N500 grow" />
          </>
        ) : (
          <div className="h-[1px] bg-neutrals-N500 grow" />
        )}

        {ende !== null ? (
          <span>{getTimeDisplayFromDate(ende)}</span>
        ) : (
          <Link
            to={
              endeAntrag[0]
                ? `/arbeitszeiten/uebersicht/${year}/${month}/${day}/sheet/edit/${arbeitszeitId}/antraege`
                : `/arbeitszeiten/uebersicht/${year}/${month}/${day}/sheet/edit/${arbeitszeitId}/arbeitszeit`
            }>
            {endeAntrag[0] ? (
              <AntragWrapper
                status={endeAntrag[0].status}
                time={getTimeDisplayFromDate(endeAntrag[0].ende)}
              />
            ) : (
              <FontAwesomeIcon
                icon={faClock}
                size="lg"
                className="motion-safe:animate-bounce"
                bounce
              />
            )}
          </Link>
        )}
      </div>
    </E2NTableCell>
  );
};
