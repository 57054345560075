import { ResourcesConfig } from 'aws-amplify';
import { getEnvironment, managerV2BaseUrl } from './utils';

const OAUTH_SCOPES = [
  'openid',
  'email',
  'aws.cognito.signin.user.admin',
  'profile',
];

export const AZURE_IDP_NAME = import.meta.env.VITE_AZURE_IDP_NAME;

export const authConfig: ResourcesConfig['Auth'] = {
  Cognito: {
    userPoolId:
      getEnvironment() === 'production'
        ? import.meta.env.VITE_COGNITO_USER_POOL_ID_PROD
        : import.meta.env.VITE_COGNITO_USER_POOL_ID_DEV,
    userPoolClientId:
      getEnvironment() === 'production'
        ? import.meta.env.VITE_COGNITO_USER_POOL_CLIENT_ID_PROD
        : import.meta.env.VITE_COGNITO_USER_POOL_CLIENT_ID_DEV,
    signUpVerificationMethod: 'code',
    loginWith: {
      oauth: {
        domain:
          getEnvironment() === 'production'
            ? import.meta.env.VITE_COGNITO_OAUTH_DOMAIN_PROD
            : import.meta.env.VITE_COGNITO_OAUTH_DOMAIN_DEV,
        scopes: OAUTH_SCOPES,
        redirectSignIn: [managerV2BaseUrl],
        redirectSignOut: [`${managerV2BaseUrl}/login`],
        responseType: 'code',
      },
    },
  },
};
