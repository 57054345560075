import { useTranslation } from 'react-i18next';
import { Arbeitsbereich } from '../../entities';
import { faArrowLeft } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  getHoursAndMinutesFromMinutes,
  getMonthNameByDateObject,
  getWeekdayByDate,
} from '../../../../utils';
import { Suspense, useState } from 'react';
import { useArbeitszeitUpdate } from '../../hooks/useArbeitszeitenUpdate';

import { useParams } from 'react-router-dom';
import { handleOnCompleted } from './handleOnCompleted';
import {
  ArbeitsbereichSelect,
  ArbeitsbereichSelectLoading,
} from '../Comboboxen';
import { useEditSheetContext } from './EditSheetContext';
import { Avatar, AvatarFallback, AvatarImage } from '@/components/ui/avatar';
import { SheetClose, SheetHeader, SheetTitle } from '@/components/ui/sheet';
import { toast } from '@/hooks/use-toast';
import * as Sentry from '@sentry/react';

// eslint-disable-next-line max-lines-per-function
export function Header() {
  const { t } = useTranslation();
  const { arbeitszeit } = useEditSheetContext();
  const { year, month, day } = useParams();
  const arbeitszeitDate = new Date(`${year}-${month}-${day}`);
  const date = `${
    getWeekdayByDate(arbeitszeitDate).shortage
  }., ${arbeitszeitDate.getDate()}. ${getMonthNameByDateObject(
    arbeitszeitDate,
  )}`;
  const initials =
    arbeitszeit.mitarbeiter.vorname[0].toUpperCase() +
    arbeitszeit.mitarbeiter.nachname[0].toUpperCase();
  const { hours, minutes } = getHoursAndMinutesFromMinutes(arbeitszeit.dauer);
  const [arbeitszeitUpdate] = useArbeitszeitUpdate();
  const [isUpdating, setIsUpdating] = useState(false);

  async function onChangeArbeitsbereich(newArbeitsbereich: Arbeitsbereich) {
    setIsUpdating(true);
    arbeitszeitUpdate({
      arbeitszeitUpdateInput: {
        arbeitsbereichId: newArbeitsbereich.id,
      },
      arbeitszeitId: arbeitszeit.id,
    })
      .then((result) => {
        handleOnCompleted(result.data, t);
        setIsUpdating(false);
      })
      // Hier werden die Fehler abgefangen, die nicht von den Union Type gehandelt werden konnten
      .catch((e) => {
        toast({
          title: t(
            'domain.arbeitszeiten.sheet.header.arbeitszeitUpdateUnnownError',
          ),
        });
        Sentry.captureException(e);
        setIsUpdating(false);
      });
  }

  return (
    <SheetHeader className="bg-neutrals-N200">
      <SheetTitle>
        <div className="flex flex-col gap-2">
          <div className="flex items-center justify-between px-4 pt-2">
            <SheetClose className="flex items-center gap-2">
              <FontAwesomeIcon
                className="text-primary-blue-B500"
                icon={faArrowLeft}
                fixedWidth
              />
              <span className="text-sm text-neutrals-N800">
                {t('domain.arbeitszeiten.sheet.header.bearbeiten')}
              </span>
            </SheetClose>
            <span className="text-sm text-neutrals-N800 text-right">
              {t('domain.arbeitszeiten.sheet.header.letzteAenderungAm', {
                date: arbeitszeit.geaendertAm.toLocaleString('de', {
                  dateStyle: 'medium',
                  timeStyle: 'short',
                }),
              })}
              <br />
              <span>
                {t('domain.arbeitszeiten.sheet.header.letzteAenderungVon', {
                  user: arbeitszeit.geaendertVon,
                })}
              </span>
            </span>
          </div>
          <hr className="border-dashed border-1 border-neutrals-N400" />
          <div className="px-4 pb-2 flex gap-1 items-stretch justify-between text-sm font-normal divide-x divide-neutrals-N400">
            <div className="flex items-center px-2 grow gap-2 max-w-52">
              <Avatar>
                {arbeitszeit.mitarbeiter.bildUrl ? (
                  <AvatarImage src={arbeitszeit.mitarbeiter.bildUrl} />
                ) : (
                  <AvatarFallback className="bg-neutrals-N800 text-white">
                    {initials}
                  </AvatarFallback>
                )}
              </Avatar>
              <span className="truncate font-semibold">
                {`${arbeitszeit.mitarbeiter.vorname} ${arbeitszeit.mitarbeiter.nachname}`}
              </span>
            </div>
            <div className="px-2 flex items-center grow">{date}</div>
            <div className="w-52 px-2">
              <Suspense
                fallback={
                  <ArbeitsbereichSelectLoading
                    name={arbeitszeit.arbeitsbereich.name}
                  />
                }>
                <ArbeitsbereichSelect
                  defaultArbeitsbereich={arbeitszeit.arbeitsbereich}
                  onChange={onChangeArbeitsbereich}
                  isUpdating={isUpdating}
                />
              </Suspense>
            </div>
            <div className="flex items-center grow">
              <div className="flex gap-1 items-baseline grow px-2">
                <span className="font-semibold">{hours}</span>
                <span className=" text-neutrals-N700 text-xs">
                  {t('general.date.hours')}
                </span>
                <span className="font-semibold">{minutes}</span>
                <span className=" text-neutrals-N700 text-xs">
                  {t('general.date.minutes')}
                </span>
              </div>
            </div>
          </div>
        </div>
      </SheetTitle>
    </SheetHeader>
  );
}
