import { CSSProperties } from 'react';
import cx from 'classnames';

type TextProps = {
  className?: string;
  height?: CSSProperties['height'];
  width?: CSSProperties['width'];
};

type TextStyleProps = CSSProperties & {
  '--skeleton--badge--width': CSSProperties['width'];
  '--skeleton--badge--height': CSSProperties['height'];
};

export function Badge({ height, width }: TextProps) {
  return (
    <div
      className={cx(
        'bg-neutrals-N300 animate-pulse',
        'h-[var(--skeleton--badge--height,100%)] w-[var(--skeleton--badge--width,100%)] rounded-lg',
      )}
      style={
        {
          '--skeleton--badge--width': width,
          '--skeleton--badge--height': height,
        } as TextStyleProps
      }
    />
  );
}
