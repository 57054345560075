import { ReactNode, createContext, useContext } from 'react';
import { useGetOptInManagerV2 } from '../hooks';

type Identifier = 'profile-page' | 'mitarbeitanlage' | 'beta-design';

type Flag = {
  key: Identifier;
  value: boolean;
  attributes: NonNullable<object>;
};

type FeatureFlagContext = {
  isFlagEnabled: (key: Identifier) => boolean;
};

const FeatureFlagContext = createContext<FeatureFlagContext>(null);

export function FeatureFlagProvider({ children }: { children: ReactNode }) {
  // Umsetzen sobald Query passt
  //   const { data } = useSuspenseQuery(GET_FEATURE_FLAGS);
  const { isOptInManagerV2 } = useGetOptInManagerV2();

  const data: Flag[] = [
    { key: 'beta-design', value: isOptInManagerV2, attributes: {} },
    // Beispiel für Struktur der Query, die die FeatureFlags zurückliefert
    // { key: 'profile-page', value: false, attributes: {} },
    // {
    //   key: 'mitarbeitanlage',
    //   value: false,
    //   attributes: { mandantenkreisId: 1 },
    // },
  ];

  function isFlagEnabled(key: Identifier) {
    const flag = data.find((flag) => flag.key === key);
    if (!flag) {
      return false;
    }
    return flag.value;
  }

  const value = { isFlagEnabled };

  return (
    <FeatureFlagContext.Provider value={value}>
      {children}
    </FeatureFlagContext.Provider>
  );
}

// Würde ich hier stehen lassen damit Kontext und Hook zusammenstehen
// eslint-disable-next-line react-refresh/only-export-components
export function useFeatureFlagContext() {
  const featureFlagContext = useContext(FeatureFlagContext);
  return featureFlagContext;
}
