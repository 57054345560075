import { graphql } from '../../../../gql';

export const GET_PLANUMSAETZE_BY_STANDORTID = graphql(`
  query getPlanUmsaetzeByStandortId($filter: PlanumsatzFilter!) {
    planUmsaetzeByStandortId(filter: $filter) {
      ... on Planumsatz {
        tagebuch {
          idStandort
          datum
          planUmsatz
          planAbsatz
          istUmsatz
          istAbsatz
        }
        prognoseumsaetze {
          datum
          prognose
        }
        umsatzBudget
      }
      ... on PlanumsatzError {
        type
        message
      }
    }
  }
`);

export const GET_CONTROLLING_EINSTELLUNGEN_BY_STANDORT_ID = graphql(`
  query getControllingEinstellungenByStandortID($standortId: String!) {
    controlling(standortIds: [$standortId]) {
      einstellungen {
        absatzproduktivitaet {
          absatzVorgabe
          zielProduktivitaet
          standortId
        }
      }
    }
  }
`);

export const GET_UMSATZ_ABSATZ_ZIELE_BY_STANDORT_ID = graphql(`
  query getUmsatzAbsatzZieleByStandortId(
    $standortId: String!
    $jahr: Int!
    $monat: Int!
  ) {
    controlling(standortIds: [$standortId]) {
      umsatzZiel(jahr: $jahr, monat: $monat) {
        ... on UmsatzZiel {
          jahrMonat {
            jahr
            monat
          }
          standortId
          umsatz {
            produktivitaet {
              currency
              amount
            }
            standortId
            stunden
            wert {
              amount
              currency
            }
          }
        }
      }
      absatzZiel(jahr: $jahr, monat: $monat) {
        ... on AbsatzZiel {
          standortId
          absatz {
            produktivitaet {
              mpt
              stueck
            }
            stunden
            wert
            standortId
          }
          jahrMonat {
            jahr
            monat
          }
        }
      }
    }
  }
`);

export const GET_UMSATZPLANUNG_SOLLSTUNDEN_BY_STANDORT_ID = graphql(`
  query getUmsatzplanungSollstundenByStandortId(
    $standortId: String!
    $jahr: Int!
    $monat: Int!
  ) {
    controlling(standortIds: [$standortId]) {
      sollstunden(jahr: $jahr, monat: $monat) {
        aushilfen
        festangestellt
      }
    }
  }
`);

export const GET_PLANWERTE_BY_STANDORT_ID = graphql(`
  query getPlanwerteByStandortId(
    $standortId: String!
    $jahr: Int!
    $monat: Int!
  ) {
    controlling(standortIds: [$standortId]) {
      umsatzPlanWerte(jahr: $jahr, monat: $monat) {
        datum
        standortId
        plan {
          produktivitaet {
            amount
            currency
          }
          stunden
          wert {
            amount
            currency
          }
        }
        prognose {
          amount
          currency
        }
      }
      absatzPlanWerte(jahr: $jahr, monat: $monat) {
        datum
        standortId
        plan {
          produktivitaet {
            mpt
            stueck
          }
          stunden
          wert
        }
        prognose
      }
    }
  }
`);
