/* eslint-disable complexity */
/* eslint-disable max-lines-per-function */
import { Money } from '@/domain/controlling/entities';
import { Separator } from './Separator';
import { ContentWrapperCell } from './ContentWrapperCell';
import { useTranslation } from 'react-i18next';
import { AbsoluteNumberField, MoneyField } from './GenericFields';
import { useStandort } from '@/hooks';
import { useTagebuchEinstellungen } from '@/domain/controlling/hooks/useTagebuchEinstellungen';
import { Differenz } from './Differenz';

export function AbsatzUmsatzPlanCell({
  absatzPlanWert,
  umsatzPlanWert,
  umsatzPrognoseWert,
  absatzPrognoseWert,
}: {
  absatzPlanWert: number | undefined;
  absatzPrognoseWert: number | undefined;
  umsatzPlanWert: Money | undefined;
  umsatzPrognoseWert: Money | undefined;
}) {
  const { t } = useTranslation();
  const { standortId } = useStandort();
  const { umsatzplanung } = useTagebuchEinstellungen(standortId);

  return (
    <ContentWrapperCell>
      {umsatzplanung !== 'ABSATZ' && (
        <div className="flex gap-4 items-center">
          <div className="w-20">
            <MoneyField
              className="w-20"
              value={umsatzPlanWert}
              unit={
                umsatzPlanWert
                  ? t(`general.currency.${umsatzPlanWert.currency}`)
                  : t(`general.currency.EUR`)
              }
            />
          </div>
          {umsatzPrognoseWert?.amount === undefined ? null : (
            <Differenz
              planWert={umsatzPlanWert?.amount}
              tooltipMessage={t(
                'domain.controlling.umsatzplanung.planung.tooltip.abweichungZurPrognose',
              )}
              prognoseWert={umsatzPrognoseWert.amount}
              type="umsatz"
              unit={t('general.currency.EUR')}
            />
          )}
        </div>
      )}
      {umsatzplanung === 'UMSATZ_ABSATZ' && <Separator />}
      {umsatzplanung !== 'UMSATZ' && (
        <div className="flex gap-4 items-center">
          <AbsoluteNumberField
            className="w-20"
            value={absatzPlanWert}
            unit={t('domain.controlling.umsatzplanung.units.stueck')}
          />
          {absatzPrognoseWert === undefined ? null : (
            <Differenz
              tooltipMessage={t(
                'domain.controlling.umsatzplanung.planung.tooltip.abweichungZurPrognose',
              )}
              planWert={absatzPlanWert}
              prognoseWert={absatzPrognoseWert}
              type="absatz"
              unit={t('domain.controlling.umsatzplanung.units.stueck')}
            />
          )}
        </div>
      )}
    </ContentWrapperCell>
  );
}
