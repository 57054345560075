import { HTMLAttributes } from 'react';
import { useTranslation } from 'react-i18next';

export function LabeledTimeField({
  label,
  hours,
  minutes,
  className,
}: {
  label: string;
  hours: number;
  minutes: number;
} & HTMLAttributes<HTMLDivElement>) {
  const { t } = useTranslation();

  return (
    <div className={`flex flex-col justify-center gap-1 ${className}`}>
      <div className="text-xs text-neutrals-N700 font-bold">{label}</div>
      <div className="flex flex-row gap-1 items-baseline">
        <div className="text-xl font-extrabold">{hours}</div>
        <div className="font-semibold text-sm text-neutrals-N700">
          {t('general.date.hours')}
        </div>
        <div className="text-xl font-extrabold">{minutes}</div>
        <div className="font-semibold text-sm text-neutrals-N700">
          {t('general.date.minutes')}
        </div>
      </div>
    </div>
  );
}
