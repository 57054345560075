import { TFunction } from 'i18next';
import { handleGraphQLErrors } from '../../../../utils/errors/handleGraphQLErrors';
import {
  ArbeitsbereichNotFound,
  Arbeitszeiten,
  ArbeitszeitNotFound,
  ArbeitszeitUpdateIdArbeitsbereichNull,
  ArbeitszeitUpdateMutation,
  ArbeitszeitUpdateResult,
  ManagerHasNoAccessToArbeitsbereich,
  ManagerHasRoleArbeitszeitNurHeuteBearbeiten,
  UnknownError,
  CharacterLengthError,
} from '../../../../gql/graphql';
import { toast } from '@/hooks/use-toast';
import * as Sentry from '@sentry/react';

function getErrorMessage(updateErrorResult: ArbeitszeitUpdateResult) {
  let message = '';
  if (
    updateErrorResult.__typename === 'ArbeitsbereichNotFound' ||
    updateErrorResult.__typename === 'ArbeitszeitUpdateIdArbeitsbereichNull'
  ) {
    message =
      'domain.arbeitszeiten.sheet.header.arbeitszeitArbeitsbereichUpdateError';
  } else if (updateErrorResult.__typename === 'ArbeitszeitNotFound') {
    message =
      'domain.arbeitszeiten.sheet.header.arbeitszeitNotFoundUpdateError';
  } else if (
    updateErrorResult.__typename === 'ManagerHasNoAccessToArbeitsbereich'
  ) {
    message =
      'domain.arbeitszeiten.sheet.header.arbeitszeitManagerHasNoAccessToArbeitsbereichUpdateError';
  } else if (updateErrorResult.__typename === 'CharacterLengthError') {
    message = 'domain.arbeitszeiten.sheet.header.characterLengthError';
  } else {
    message = 'domain.arbeitszeiten.sheet.header.arbeitszeitUpdateUnknownError';
  }
  return message;
}

export function handleOnCompleted(
  updateResult: ArbeitszeitUpdateMutation,
  t: TFunction,
) {
  const updateError = handleGraphQLErrors<
    Arbeitszeiten,
    | ArbeitsbereichNotFound
    | UnknownError
    | CharacterLengthError
    | ArbeitszeitNotFound
    | ManagerHasNoAccessToArbeitsbereich
    | ArbeitszeitUpdateIdArbeitsbereichNull
    | ManagerHasRoleArbeitszeitNurHeuteBearbeiten
  >(updateResult.updateArbeitszeit);

  if (updateError) {
    Sentry.captureException(
      new Error(`${updateError.type}:${updateError.message}`),
    );
    const message = getErrorMessage(updateResult.updateArbeitszeit);
    toast({
      title: t(message),
    });
  } else {
    toast({
      title: t('domain.arbeitszeiten.sheet.arbeitszeit.edit.erfolgreich'),
    });
  }
}
