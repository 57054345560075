import { useParams } from 'react-router-dom';
import { useProfile } from '../../../hooks';

export function useManagerCanEditArbeitszeiten() {
  const { year, month, day } = useParams();
  const today = new Date();
  const { manager } = useProfile();
  if (
    manager.rechte.arbeitszeit.bearbeiten &&
    manager.rechte.arbeitszeit.alleTage
  ) {
    return true;
  }
  if (
    manager.rechte.arbeitszeit.bearbeiten &&
    manager.rechte.arbeitszeit.nurHeute &&
    `${year}-${month}-${day}` === `${today.toISOString().substring(0, 10)}`
  ) {
    return true;
  }
  return false;
}
