import { useTranslation } from 'react-i18next';
import { E2NTableCell } from '../../../../components/Table';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faArrowLeftToBracket,
  faClock,
} from '@fortawesome/pro-light-svg-icons';
import { Puenktlichkeit } from '../../entities';
import { Abweichung } from '../Abweichung';
import { getTimeDisplayFromDate } from '../../../../utils';
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '@/components/ui/tooltip';
import { Badge } from '@/components/ui/badge';

// eslint-disable-next-line max-lines-per-function, complexity
export const ArbeitszeitCell = function ArbeitszeitCell({
  beginn,
  ende,
  dauer,
  puenktlichkeit,
  kommen,
  gehen,
}: {
  beginn: Date;
  ende: Date;
  dauer: number;
  puenktlichkeit: Puenktlichkeit;
  kommen?: Date;
  gehen?: Date;
}) {
  const { t } = useTranslation();

  return (
    <E2NTableCell
      className="font-semibold w-[30%]"
      isBorderVisible
      isBorderDashed>
      <div className="flex items-center justify-between gap-4">
        <div className="flex flex-col gap-1 items-start basis-[70px]">
          {beginn !== null ? (
            <div className="flex flex-row gap-3 items-center">
              <span>{getTimeDisplayFromDate(beginn)}</span>
              {kommen !== null && kommen !== undefined && (
                <TooltipProvider delayDuration={0}>
                  <Tooltip>
                    <TooltipTrigger>
                      <div className="flex gap-1 items-center text-neutrals-N500">
                        <FontAwesomeIcon
                          icon={faArrowLeftToBracket}
                          size="sm"
                        />
                        <span className="text-xs text-neutrals-N500">
                          {getTimeDisplayFromDate(kommen)}
                        </span>
                      </div>
                    </TooltipTrigger>
                    <TooltipContent>
                      {t(`domain.arbeitszeiten.uebersicht.kommenGestempelt`)}
                    </TooltipContent>
                  </Tooltip>
                </TooltipProvider>
              )}
            </div>
          ) : (
            <Badge className="bg-primary-orange-O500 hover:bg-primary-orange-O500 w-28 flex justify-center">
              {t('domain.arbeitszeiten.uebersicht.keineBuchung')}
            </Badge>
          )}
          {puenktlichkeit.abweichungBeginn !== undefined && (
            <Abweichung abweichung={puenktlichkeit.abweichungBeginn} />
          )}
        </div>
        {dauer !== 0 ? (
          <>
            <div className="h-[1px] bg-neutrals-N300 grow" />
            <span className="text-xs text-neutrals-N500">
              {dauer} {t('general.date.minutes')}
            </span>
            <div className="h-[1px] bg-neutrals-N300 grow" />
          </>
        ) : (
          <div className="h-[1px] bg-neutrals-N300 grow" />
        )}

        <div className="flex flex-col gap-1 items-start basis-[70px]">
          {ende !== null ? (
            <div className="flex flex-row gap-3 items-center">
              <span>{getTimeDisplayFromDate(ende)}</span>
              {gehen !== null && gehen !== undefined && (
                <TooltipProvider>
                  <Tooltip delayDuration={0}>
                    <TooltipTrigger>
                      <div className="flex gap-1 items-center text-neutrals-N500">
                        <FontAwesomeIcon
                          icon={faArrowLeftToBracket}
                          size="sm"
                        />
                        <span className="text-xs">
                          {getTimeDisplayFromDate(gehen)}
                        </span>
                      </div>
                    </TooltipTrigger>
                    <TooltipContent>
                      {t(`domain.arbeitszeiten.uebersicht.gehenGestempelt`)}
                    </TooltipContent>
                  </Tooltip>
                </TooltipProvider>
              )}
            </div>
          ) : (
            <Ende datum={beginn} />
          )}

          {puenktlichkeit.abweichungEnde !== undefined &&
            puenktlichkeit.abweichungEnde !== null && (
              <Abweichung abweichung={puenktlichkeit.abweichungEnde} />
            )}
        </div>
      </div>
    </E2NTableCell>
  );
};

function Ende({ datum }: { datum: Date }) {
  const { t } = useTranslation();

  const today = new Date();
  if (
    datum < today &&
    today.getTime() - datum.getTime() > 24 * 60 * 60 * 1000
  ) {
    return (
      <Badge className="bg-primary-orange-O500 hover:bg-primary-orange-O500 w-28 flex justify-center">
        {t('domain.arbeitszeiten.uebersicht.keineBuchung')}
      </Badge>
    );
  }
  return <FontAwesomeIcon icon={faClock} size="lg" />;
}
