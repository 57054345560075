import { t } from 'i18next';
import { Dispatch, SetStateAction, useState } from 'react';
import {
  AbsoluteNumberInput,
  TimeInput,
} from '../../../../../../../../components';
import { useForm } from 'react-hook-form';
import { useArbeitszeitUpdate } from '../../../../../../hooks';
import { handleOnCompleted } from '../../../../handleOnCompleted';
import { Arbeitszeit } from '../../../../../../entities';
import { Card } from './Card';
import { Separator } from './Separator';
import { mapPauseToInput } from './mapPauseToInputType';
import { Pausenart } from '../../../../../../../../gql/graphql';
import { maskitoParseNumber } from '@maskito/kit';
import { formSchema, FormValues } from './formSchema';
import { zodResolver } from '@hookform/resolvers/zod';
import { Label } from '@/components/ui/label';
import { Button } from '@/components/ui/button';
import { Switch } from '@/components/ui/switch';
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
} from '@/components/ui/form';
import { toast } from '@/hooks/use-toast';
import * as Sentry from '@sentry/react';
import { getTimeDisplayFromDate } from '@/utils';

// eslint-disable-next-line max-lines-per-function
export function CreateForm({
  setIsCreateFormVisible,
  arbeitszeit,
}: {
  setIsCreateFormVisible: Dispatch<SetStateAction<boolean>>;
  arbeitszeit: Arbeitszeit;
}) {
  const { id: arbeitszeitId, pausen } = arbeitszeit;
  const [arbeitszeitUpdate] = useArbeitszeitUpdate();
  const [isSaving, setIsSaving] = useState(false);

  const form = useForm<FormValues>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      beginn: '',
      ende: '',
      dauer: '',
      bezahlt: false,
      arbeitszeitStart: arbeitszeit.start
        ? getTimeDisplayFromDate(arbeitszeit.start)
        : '',
      arbeitszeitEnde: arbeitszeit.ende
        ? getTimeDisplayFromDate(arbeitszeit.ende)
        : '',
    },
  });

  async function onSubmit(data: FormValues) {
    const newPausen = [
      ...pausen.map((pause) => mapPauseToInput(pause)),
      {
        pausenart: Pausenart.Manuell,
        beginn: data.beginn === '' ? undefined : data.beginn,
        ende: data.ende === '' ? undefined : data.ende,
        bezahlt: data.bezahlt,
        dauer: data.dauer ? maskitoParseNumber(data.dauer, '.') : null,
        idPause: null,
      },
    ];
    setIsSaving(true);
    arbeitszeitUpdate({
      arbeitszeitUpdateInput: {
        pausen: newPausen,
      },
      arbeitszeitId: arbeitszeitId,
    })
      .then((result) => {
        handleOnCompleted(result.data, t);
        setIsSaving(false);
        setIsCreateFormVisible(false);
      })
      .catch((e) => {
        toast({
          title: t(
            'domain.arbeitszeiten.sheet.header.arbeitszeitUpdateUnknownError',
          ),
        });
        Sentry.captureException(e);
        setIsSaving(false);
      });
  }

  return (
    <Form {...form}>
      <form
        className="flex flex-col gap-4"
        onSubmit={form.handleSubmit(onSubmit)}>
        <div className="flex gap-2 justify-between items-center">
          <Card>
            <FormField
              control={form.control}
              name="dauer"
              render={({ field }) => (
                <FormItem className="w-24">
                  <FormLabel>
                    {t(
                      'domain.arbeitszeiten.sheet.arbeitszeit.pausenDauerLabel',
                    )}
                  </FormLabel>
                  <FormControl>
                    <AbsoluteNumberInput
                      defaultValue={field.value}
                      placeholder="--"
                      unit={t('general.date.minutes')}
                      {...field}
                      onBlur={(e) => {
                        field.onBlur();
                        field.onChange(e);
                      }}
                    />
                  </FormControl>
                  <FormDescription />
                </FormItem>
              )}
            />
          </Card>
          <Separator />
          <Card>
            <FormField
              control={form.control}
              name="beginn"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>
                    {t('domain.arbeitszeiten.sheet.arbeitszeit.beginnLabel')}
                  </FormLabel>
                  <FormControl>
                    <TimeInput
                      defaultValue={field.value}
                      placeholder="--:--"
                      {...field}
                      onBlur={(e) => {
                        field.onBlur();
                        field.onChange(e);
                      }}
                    />
                  </FormControl>
                  <FormDescription />
                </FormItem>
              )}
            />
            {/* Hier musste ich leider mit einem Margin Top arbeiten um die Trennlinie zwischen dem Beginn- und Ende Inputfield mittig zu setzen.
              align-items: center funktioniert hier leider nicht, da die Höhe des Labels auch mit berücksichtigt wird und es dann komisch aussieht
            */}
            <div className="h-[1px] bg-neutrals-N500 w-32 mt-6" />
            <FormField
              control={form.control}
              name="ende"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>
                    {t('domain.arbeitszeiten.sheet.arbeitszeit.endeLabel')}
                  </FormLabel>
                  <FormControl>
                    <TimeInput
                      defaultValue={field.value}
                      placeholder="--:--"
                      {...field}
                      onBlur={(e) => {
                        field.onBlur();
                        field.onChange(e);
                      }}
                    />
                  </FormControl>
                  <FormDescription />
                </FormItem>
              )}
            />
          </Card>
        </div>
        {form.formState.errors.beginn && (
          <div className="text-sm text-primary-red-R400 font-semibold bg-primary-red-R100 p-4 rounded-md">
            {form.formState.errors.beginn.message}
          </div>
        )}
        {form.formState.errors.dauer && (
          <div className="text-sm text-primary-red-R400 font-semibold bg-primary-red-R100 p-4 rounded-md">
            {form.formState.errors.dauer.message}
          </div>
        )}
        <div className="flex justify-end gap-4 items-center">
          <FormField
            control={form.control}
            name="bezahlt"
            render={({ field }) => (
              <FormItem className="flex items-center space-x-2">
                <Switch
                  size="sm"
                  id="createPauseBezahlt"
                  checked={field.value}
                  onCheckedChange={(checked) => {
                    field.onChange(checked);
                  }}
                />
                <Label htmlFor="createPauseBezahlt">
                  {t(
                    'domain.arbeitszeiten.sheet.arbeitszeit.pausenBezahltLabel',
                  )}
                </Label>
              </FormItem>
            )}
          />

          <Button
            onClick={() => setIsCreateFormVisible(false)}
            variant="outline">
            {t('actions.abbrechen')}
          </Button>
          <Button disabled={isSaving}>
            {!isSaving ? t('actions.speichern') : t('actions.wirdGespeichert')}
          </Button>
        </div>
      </form>
    </Form>
  );
}
