/* eslint-disable max-lines-per-function */
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@/components/ui/popover.tsx';
import { useForm } from 'react-hook-form';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form.tsx';
import { zodResolver } from '@hookform/resolvers/zod';
import * as Sentry from '@sentry/react';
import { Textarea } from '@/components/ui/textarea.tsx';
import { faPenField } from '@fortawesome/pro-regular-svg-icons';
import { z } from 'zod';
import { useState } from 'react';
import { useProfile } from '@/hooks';
import { Button } from '../ui/button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import { toast } from '@/hooks/use-toast';

export function ToggleFeedback() {
  const { t } = useTranslation();

  const formSchema = z.object({
    support: z
      .string()
      .max(1000, {
        message: t('domain.arbeitszeiten.feedback.error'),
      })
      .optional(),
    good: z
      .string()
      .max(1000, {
        message: t('domain.arbeitszeiten.feedback.error'),
      })
      .optional(),
  });
  const form = useForm({
    resolver: zodResolver(formSchema),
  });
  const [open, setOpen] = useState(false);

  function onSubmit(values: z.infer<typeof formSchema>) {
    Sentry.captureFeedback(
      {
        name: `${profile.manager.vorname} ${profile.manager.nachname}`, // optional
        email: profile.manager.email, // optional
        message: `${t('domain.arbeitszeiten.feedback.ansicht')}:
        ${values.good}
        -------------------
        ${t('domain.arbeitszeiten.feedback.support')}:
        ${values.support}`, // required
      },
      {
        includeReplay: false, // optional
        attachments: [], // optional
      },
    );
    setOpen(false);
    toast({
      description: t('domain.arbeitszeiten.feedback.success.description'),
    });
  }

  const profile = useProfile();

  return (
    <Popover open={open} onOpenChange={(e) => setOpen(e)}>
      <PopoverTrigger asChild>
        <div className="absolute right-8 z-[49] bottom-4">
          <Button
            variant="default"
            className="rounded-full size-16 drop-shadow-xl"
            onClick={() => setOpen(true)}>
            <FontAwesomeIcon icon={faPenField} size="2xl" />
          </Button>
        </div>
      </PopoverTrigger>
      <PopoverContent className="bg-white mr-8 w-[21rem]">
        <Form {...form}>
          <h1 className="font-bold text-xl">
            {t('domain.arbeitszeiten.feedback.title')}
          </h1>
          <p className="text-sm mb-5">
            {t('domain.arbeitszeiten.feedback.description')}
          </p>
          <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-8">
            <FormField
              control={form.control}
              name="good"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>
                    <span className="text-sm">
                      {t('domain.arbeitszeiten.feedback.ansicht')}
                    </span>
                  </FormLabel>
                  <FormControl>
                    <Textarea {...field} maxLength={1000} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="support"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>
                    <span className="text-sm">
                      {t('domain.arbeitszeiten.feedback.support')}
                    </span>
                  </FormLabel>
                  <FormControl>
                    <Textarea {...field} maxLength={1000} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <Button type="submit" className="w-full font-semibold">
              {t('domain.arbeitszeiten.feedback.submit')}
            </Button>
          </form>
        </Form>
      </PopoverContent>
    </Popover>
  );
}
