import { HTMLAttributes } from 'react';
import { TableHeader } from '../ui/table';

export function E2NtableHeader({
  children,
  ...other
}: HTMLAttributes<HTMLTableSectionElement>) {
  const className = 'text-xs text-neutrals-N700 text px-4 ' + other.className;
  return (
    <TableHeader {...other} className={className}>
      {children}
    </TableHeader>
  );
}
