import { AbsatzVorgabe, Money } from '@/domain/controlling/entities';
import { ContentWrapperCell } from './ContentWrapperCell';
import { AbsoluteNumberField, MoneyField } from './GenericFields';
import { useTranslation } from 'react-i18next';
import { Separator } from './Separator';
import { useStandort } from '@/hooks';
import { useTagebuchEinstellungen } from '@/domain/controlling/hooks/useTagebuchEinstellungen';

export function ProduktivitaetCell({
  umsatzZielproduktivitaet,
  absatzZielproduktivitaet,
}: {
  umsatzZielproduktivitaet: Money | undefined;
  absatzZielproduktivitaet: number | undefined;
}) {
  const { t } = useTranslation();
  const { standortId } = useStandort();
  const { umsatzplanung, tagebuchEinstellungen } =
    useTagebuchEinstellungen(standortId);

  return (
    <ContentWrapperCell>
      {umsatzplanung !== 'ABSATZ' && (
        <MoneyField
          value={umsatzZielproduktivitaet}
          unit={t('domain.controlling.umsatzplanung.units.€/Std.')}
        />
      )}
      {umsatzplanung === 'UMSATZ_ABSATZ' && <Separator />}
      {umsatzplanung !== 'UMSATZ' && (
        <AbsoluteNumberField
          value={absatzZielproduktivitaet}
          unit={
            tagebuchEinstellungen.absatzproduktivitaet.absatzVorgabe ===
            AbsatzVorgabe.Stueck
              ? t('domain.controlling.umsatzplanung.units.Stk./Std.')
              : t('domain.controlling.umsatzplanung.units.mpt')
          }
        />
      )}
    </ContentWrapperCell>
  );
}
