import { CSSProperties } from 'react';
import cx from 'classnames';

type TextProps = {
  className?: string;
  height?: CSSProperties['height'];
  width?: CSSProperties['width'];
};

type TextStyleProps = CSSProperties & {
  '--skeleton--text--width': CSSProperties['width'];
  '--skeleton--text--height': CSSProperties['height'];
};

export function Text({ height, width, className }: TextProps) {
  return (
    <div
      className={cx(
        'bg-neutrals-N300 animate-pulse',
        'h-[var(--skeleton--text--height,100%)] w-[var(--skeleton--text--width,100%)] rounded-full',
        className,
      )}
      style={
        {
          '--skeleton--text--width': width,
          '--skeleton--text--height': height,
        } as TextStyleProps
      }
    />
  );
}
