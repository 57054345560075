import { Dialog, DialogContent } from '@/components/ui/dialog';
import { EditForm } from './EditForm';
import { EditDialogHeader } from './EditDialogHeader';
import { useEditDialogContext } from './EditDialogContext';
import { SkeletonForm } from './SkeletonForm';
import { Suspense, useState } from 'react';
import { UnsavedChangesDialog } from './UnsavedChangesDialog';
import { Button } from '@/components/ui/button';
import { useTranslation } from 'react-i18next';
import { ErrorBoundary } from '@/components';
import { ErrorFallbackDialog } from './ErrorFallbackDialog';
import { useParams } from 'react-router-dom';
import { MonthParams } from '../../types';
import { DialogFooterMessage } from '../../components';

// eslint-disable-next-line max-lines-per-function
export function EditDialog({
  onOpenChange,
  open,
}: {
  open: boolean;
  onOpenChange: (open: boolean) => void;
}) {
  const {
    hasUnsavedChanges,
    setDialogFooterMessage,
    dialogFooterMessage,
    setJahrMonat,
    isUpdating,
  } = useEditDialogContext();
  const [alertOpen, setAlertOpen] = useState(false);
  const { year, month } = useParams<MonthParams>();
  const { t } = useTranslation();

  return (
    <>
      <UnsavedChangesDialog
        open={alertOpen}
        onOpenChange={setAlertOpen}
        onEditDialogOpenChange={onOpenChange}
      />
      <Dialog
        open={open}
        onOpenChange={(open) => {
          if (!hasUnsavedChanges) {
            setDialogFooterMessage(undefined);
            onOpenChange(open);
            setJahrMonat({ jahr: parseInt(year), monat: parseInt(month) });
          } else {
            setAlertOpen(true);
          }
        }}>
        <ErrorBoundary FallbackComponent={() => <ErrorFallbackDialog />}>
          <DialogContent className="sm:max-w-[680px] px-8 py-0">
            <div className="flex flex-col gap-6 pt-8 pb-4">
              <EditDialogHeader />
              <Suspense fallback={<SkeletonForm />}>
                <EditForm />
              </Suspense>
            </div>
            <hr className="h-px bg-neutrals-N300 border-0 -mx-8"></hr>
            <div className="flex items-center justify-between pb-4">
              <DialogFooterMessage
                dialogFooterMessage={dialogFooterMessage}
                isUpdating={isUpdating}
              />
              <div className="flex items-center justify-end gap-4">
                <Button form="editUmsatzzieleForm">
                  {t('domain.controlling.umsatzplanung.ziele.fertig')}
                </Button>
              </div>
            </div>
          </DialogContent>
        </ErrorBoundary>
      </Dialog>
    </>
  );
}
