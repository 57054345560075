import {
  CreateOrUpdateUmsatzPlanWertMutation,
  CreateOrUpdateUmsatzPlanWertMutationVariables,
} from '@/gql/graphql';
import { useMutation } from '@apollo/client';
import { useCallback } from 'react';
import { CREATE_OR_UPDATE_UMSATZ_PLANWERT } from './graphql/mutations';

export function useUmsatzPlanWertCreateOrUpdate() {
  const [execute, result] = useMutation<
    CreateOrUpdateUmsatzPlanWertMutation,
    CreateOrUpdateUmsatzPlanWertMutationVariables
  >(CREATE_OR_UPDATE_UMSATZ_PLANWERT, {
    refetchQueries: ['getPlanwerteByStandortId'],
  });

  const createOrUpdateUmsatzPlanWert = useCallback(
    (variables: CreateOrUpdateUmsatzPlanWertMutationVariables) => {
      return execute({ variables });
    },
    [execute],
  );

  return [createOrUpdateUmsatzPlanWert, result] as const;
}
