import { useProfile } from '../../../hooks';
import { useTranslation } from 'react-i18next';
export function UserInfo() {
  const profile = useProfile();
  const { t } = useTranslation();
  return (
    <div>
      <div className="flex flex-col gap-2">
        <div className="flex gap-4">
          <span className="min-w-20 font-semibold">
            {t('pages.profile.sections.userInfo.name')}:
          </span>
          <span>{`${profile.manager.vorname} ${profile.manager.nachname}`}</span>
        </div>
        <div className="flex gap-4">
          <span className="min-w-20 font-semibold">
            {t('pages.profile.sections.userInfo.email')}:
          </span>
          <span>{profile.manager.email}</span>
        </div>
      </div>
    </div>
  );
}
