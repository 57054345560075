import {
  fetchAuthSession,
  fetchUserAttributes,
  getCurrentUser,
  signOut,
  updatePassword,
} from 'aws-amplify/auth';
import { authConfig } from '../../amplifyAuthConfig';
import { UserInfo } from './types';
import * as Sentry from '@sentry/react';

export function getAuthenticationCookies() {
  const cookies = document.cookie.split(';');

  const username = cookies.reduce((username, currentCookie) => {
    if (currentCookie.trim().includes('LastAuthUser')) {
      username = currentCookie.trim().split('=')[1];
    }
    return username;
  }, '');

  const refreshToken = cookies.reduce((refreshToken, currentCookie) => {
    if (currentCookie.trim().includes('refreshToken')) {
      refreshToken = currentCookie.trim().split('=')[1];
    }
    return refreshToken;
  }, '');

  return {
    username,
    refreshToken,
  };
}

export function setCognitoIdTokenCookie(value: string, username: string) {
  document.cookie = `CognitoIdentityServiceProvider.${
    authConfig.Cognito.userPoolClientId
  }.${encodeURIComponent(username)}.idToken=${value}`;
}

export async function handleSignOut({
  callbackAction,
}: {
  callbackAction: () => void;
}) {
  try {
    const tokens = await getSessionTokens();
    const idTokenPayload = tokens.idToken.payload;
    if (
      idTokenPayload.identities &&
      isUserLoggedInWithMicrosoft(idTokenPayload.identities)
    ) {
      await signOut();
    } else {
      await signOut().then(callbackAction);
    }
  } catch (error) {
    Sentry.captureException(error);
    alert(error);
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function isUserLoggedInWithMicrosoft(identities: any) {
  const microsoftIdentity = identities.filter(
    (item) => item.providerName === 'MicrosoftAzureAD',
  );
  return microsoftIdentity.length === 1;
}

export async function getSessionTokens() {
  const { tokens } = await fetchAuthSession();
  return tokens;
}

export async function getCurrentUserInfo(): Promise<UserInfo> {
  const { username, userId: id } = await getCurrentUser();

  const attributes = await fetchUserAttributes();

  return {
    id,
    username,
    attributes,
  };
}

export function getUserDisplayName(userInfo: UserInfo) {
  let displayName = '';
  if (!userInfo.attributes && !userInfo.username) {
    return displayName;
  }

  if (userInfo.attributes.given_name) {
    displayName = userInfo.attributes.given_name;
  } else if (userInfo.attributes.email) {
    displayName = userInfo.attributes.email;
  } else {
    displayName = userInfo.username;
  }

  return displayName;
}

export async function changePasswort(
  oldPassword: string,
  newPassword: string,
): Promise<
  | { status: 'success' }
  | {
      status: 'error';
      type: 'NotAuthorized' | 'InvalidPasswort' | 'other';
      message: string;
    }
> {
  try {
    await updatePassword({ oldPassword, newPassword });
    return { status: 'success' };
  } catch (error) {
    if (error.name === 'NotAuthorizedException') {
      return {
        status: 'error',
        type: 'NotAuthorized',
        message: 'Falsches Password angegeben',
      };
    }
    if (error.name === 'InvalidPasswordException') {
      return {
        status: 'error',
        type: 'InvalidPasswort',
        message: 'Ihr Passwort erfüllt nicht die Anforderungen',
      };
    }
    Sentry.captureException(error);
    return {
      status: 'error',
      type: 'other',
      message: error.message,
    };
  }
}
