import { E2NTableCell } from '../../../../components/Table';
import { memo } from 'react';
import { Mitarbeiter } from '../../entities';
import { Avatar, AvatarFallback, AvatarImage } from '@/components/ui/avatar';

export const MitarbeiterCell = memo(function MitarbeiterCell({
  mitarbeiter,
}: {
  mitarbeiter: Mitarbeiter;
}) {
  const initials =
    mitarbeiter.vorname[0].toUpperCase() +
    mitarbeiter.nachname[0].toUpperCase();

  return (
    <E2NTableCell className="rounded-l-lg w-40 " isBorderVisible>
      <div className="flex gap-4 items-center font-semibold ">
        <Avatar>
          {mitarbeiter.bildUrl ? (
            <AvatarImage src={mitarbeiter.bildUrl} />
          ) : (
            <AvatarFallback>{initials}</AvatarFallback>
          )}
        </Avatar>
        <span className="truncate max-w-40">
          {`${mitarbeiter.vorname} ${mitarbeiter.nachname}`}
        </span>
      </div>
    </E2NTableCell>
  );
});
