import { t } from 'i18next';
import { Arbeitszeit } from '../../../../../entities';
import { useArbeitszeitUpdate } from '../../../../../hooks';
import { useState } from 'react';
import { handleOnCompleted } from '../../../handleOnCompleted';
import { ArbeitszeitPauseneinstellung } from '../../../../../../../gql/graphql';
import { useProfile } from '../../../../../../../hooks';
import { Label } from '@/components/ui/label';
import { Switch } from '@/components/ui/switch';
import { toast } from '@/hooks/use-toast';
import * as Sentry from '@sentry/react';

// eslint-disable-next-line max-lines-per-function
export function PausenEinstellungen({
  arbeitszeit,
}: {
  arbeitszeit: Arbeitszeit;
}) {
  const { pauseneinstellung, id: arbeitszeitId } = arbeitszeit;
  const { manager } = useProfile();
  const [arbeitszeitUpdate] = useArbeitszeitUpdate();
  const [isUpdating, setIsUpdating] = useState(false);
  const [isGesetzlich, setIsGesetzlich] = useState(
    pauseneinstellung === ArbeitszeitPauseneinstellung.Gesetzlich,
  );
  const [isAutomatisch, setIsAutomatisch] = useState(
    pauseneinstellung === ArbeitszeitPauseneinstellung.Automatisch,
  );

  async function onChangePauseneinstellung(
    isAutomatischChecked: boolean,
    isGesetzlichChecked: boolean,
  ) {
    let pauseneinstellung = ArbeitszeitPauseneinstellung.Ohne;
    if (isAutomatischChecked && !isGesetzlichChecked) {
      pauseneinstellung = ArbeitszeitPauseneinstellung.Automatisch;
    }
    if (isGesetzlichChecked && !isAutomatischChecked) {
      pauseneinstellung = ArbeitszeitPauseneinstellung.Gesetzlich;
    }
    setIsUpdating(true);
    arbeitszeitUpdate({
      arbeitszeitUpdateInput: {
        pauseneinstellung,
      },
      arbeitszeitId,
    })
      .then((result) => {
        handleOnCompleted(result.data, t);
        setIsUpdating(false);
      })
      .catch((e) => {
        toast({
          title: t(
            'domain.arbeitszeiten.sheet.header.arbeitszeitUpdateUnknownError',
          ),
        });
        Sentry.captureException(e);
        setIsUpdating(false);
      });
  }

  return (
    <div className="flex gap-4 items-center">
      {isUpdating && (
        <div className="text-xs">{t('actions.wirdGespeichert')}</div>
      )}
      <div className="flex items-center space-x-2">
        <Switch
          id="pauseneinstellung-gesetzlich"
          size="sm"
          checked={isGesetzlich}
          disabled={isUpdating || !manager.rechte.arbeitszeit.bearbeiten}
          onCheckedChange={(isGesetzlich) => {
            setIsGesetzlich(isGesetzlich);
            if (isGesetzlich && isAutomatisch) {
              setIsAutomatisch(false);
              onChangePauseneinstellung(false, true);
            } else if (!isGesetzlich && !isAutomatisch) {
              onChangePauseneinstellung(false, false);
            } else {
              onChangePauseneinstellung(false, true);
            }
          }}
        />
        <Label htmlFor="pauseneinstellung-gesetzlich">
          {t(
            'domain.arbeitszeiten.sheet.arbeitszeit.pauseneinstellungenGesetzlich',
          )}
        </Label>
      </div>
      <div className="flex items-center space-x-2">
        <Switch
          id="pauseneinstellung-automatisch"
          size="sm"
          disabled={isUpdating || !manager.rechte.arbeitszeit.bearbeiten}
          checked={isAutomatisch}
          onCheckedChange={(isAutomatisch) => {
            setIsAutomatisch(isAutomatisch);
            if (isAutomatisch && isGesetzlich) {
              setIsGesetzlich(false);
              onChangePauseneinstellung(true, false);
            } else if (!isAutomatisch && !isAutomatisch) {
              onChangePauseneinstellung(false, false);
            } else {
              onChangePauseneinstellung(true, false);
            }
          }}
        />
        <Label htmlFor="pauseneinstellung-automatisch">
          {t(
            'domain.arbeitszeiten.sheet.arbeitszeit.pauseneinstellungenAutomatisch',
          )}
        </Label>
      </div>
    </div>
  );
}
