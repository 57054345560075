import { useTranslation } from 'react-i18next';
import { PieChartSkeleton } from './PieChart';
import Skeleton from '@/components/Skeleton/Skeleton';

export function SollstundenWidgetSkeleton({
  umsatzplanung,
}: {
  umsatzplanung: 'UMSATZ' | 'ABSATZ' | 'UMSATZ_ABSATZ';
}) {
  const { t } = useTranslation();

  return (
    <div className="bg-white p-4 rounded-md flex justify-between items-center h-full">
      <div className="flex flex-col gap-1">
        <div className="text-neutrals-N700 font-semibold text-xs">
          {t('domain.controlling.umsatzplanung.sollstunden.label')}
        </div>
        <Skeleton.Field height="24px" width="50px" />
      </div>
      <PieChartSkeleton umsatzplanung={umsatzplanung} />
    </div>
  );
}
