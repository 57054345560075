import { Button } from '@/components/ui/button';
import { faEdit } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react';
import { EditDialog } from './EditDialog';
import { Suspense } from 'react';
import { SollstundenWidget } from './SollstundenWidget';
import { SollstundenWidgetSkeleton } from './SollstundenWidget/SollstundenWidgetSkeleton';
import { useTagebuchEinstellungen } from '@/domain/controlling/hooks/useTagebuchEinstellungen';
import { useStandort } from '@/hooks';
import { t } from 'i18next';
import { AbsatzZiele, UmsatzZiele } from './UmsatzAbsatzWidget';
import { ZieleSkeleton } from './UmsatzAbsatzWidget/ZieleSkeleton';

// eslint-disable-next-line max-lines-per-function
export function ZieleWrapper() {
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const { standortId } = useStandort();
  const { umsatzplanung } = useTagebuchEinstellungen(standortId);

  return (
    <>
      <Suspense fallback={<></>}>
        <EditDialog open={isDialogOpen} onOpenChange={setIsDialogOpen} />
      </Suspense>
      <div className="flex flex-col gap-4">
        <div className="flex items-center gap-1">
          <h4 className="text-xl font-bold text-neutrals-N900">
            {t('domain.controlling.umsatzplanung.ziele.header')}
          </h4>
          <Button
            size="icon"
            variant="ghost"
            onClick={() => setIsDialogOpen(true)}>
            <FontAwesomeIcon className="text-primary-blue-B600" icon={faEdit} />
          </Button>
        </div>
        <div className="flex justify-between items-stretch gap-4">
          <Suspense fallback={<ZieleSkeleton umsatzplanung={umsatzplanung} />}>
            {umsatzplanung === 'UMSATZ_ABSATZ' && (
              <div className="flex flex-col gap-4 grow justify-between">
                <div className="bg-white rounded-lg">
                  <UmsatzZiele />
                </div>
                <div className="bg-white rounded-lg">
                  <AbsatzZiele />
                </div>
              </div>
            )}
            {umsatzplanung === 'UMSATZ' && (
              <div className="bg-white rounded-lg grow">
                <UmsatzZiele />
              </div>
            )}
            {umsatzplanung === 'ABSATZ' && (
              <div className="bg-white rounded-lg grow">
                <AbsatzZiele />
              </div>
            )}
          </Suspense>
          <div className="w-[300px]">
            <Suspense
              fallback={
                <SollstundenWidgetSkeleton umsatzplanung={umsatzplanung} />
              }>
              <SollstundenWidget />
            </Suspense>
          </div>
        </div>
      </div>
    </>
  );
}
