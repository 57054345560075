import { ArbeitsbereichFilter } from '../Comboboxen/ArbeitsbereichCombobox';
import { faPlus, faSearch } from '@fortawesome/pro-light-svg-icons';
import { buildLink } from '../../../../utils';
import { Input } from '@/components/ui/input';
import { Button } from '@/components/ui/button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export function DataTableHeader() {
  return (
    <div>
      <div className="flex items-center py-4 justify-end gap-4">
        <Input
          startIcon={faSearch}
          placeholder="Namen suchen"
          value={''}
          className="max-w-sm"
        />
        <ArbeitsbereichFilter
          onSelect={(arbeitsbereich) => {
            return arbeitsbereich[0];
          }}
        />
        <Button variant="default" size="icon">
          <FontAwesomeIcon icon={faPlus} />
        </Button>
        <a
          href={buildLink('arbeitszeiten')}
          className="bg-[#545e83] py-3 px-2 rounded-lg text-white w-auto text-sm h-5 inline-flex items-center justify-center">
          Classic Design
        </a>
      </div>
    </div>
  );
}
