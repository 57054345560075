import { TagebuchUpdateInput } from '../../../../../gql/graphql';

export function calculatePlanumsatz(tagebuch: TagebuchUpdateInput[]): number {
  const istUmsatz = tagebuch.reduce((currentSum, tagebuch) => {
    if (!tagebuch || !tagebuch.planUmsatz) {
      return currentSum;
    } else {
      currentSum += tagebuch.planUmsatz;
      return currentSum;
    }
  }, 0);

  return istUmsatz;
}
