import { graphql } from '../../../../gql';

export const GET_ARBEITSZEITEN_BY_DATE_AND_STANDORT_ID = graphql(`
  query getArbeitszeitenByDateAndStandortId(
    $datum: DateTime!
    $standortId: String!
  ) {
    dienstplanuebernahmen(datum: $datum, standortId: $standortId) {
      arbeitsbereich {
        id
        markierung
        name
      }
      mitarbeiter {
        id
        vorname
        nachname
        bildUrl
      }
      beginn
      dauer
      ende
      id
      pause
      schichtId
    }
    arbeitszeiten(datum: $datum, standortId: $standortId) {
      ... on ArbeitszeitenByDatum {
        __typename
        datum
        arbeitszeiten {
          __typename
          geaendertVon
          aenderungsdatum
          datum
          ende
          dauer
          id
          konflikte {
            konflikt
            id
          }
          beginn
          kommen
          gehen
          mitarbeiter {
            vorname
            nachname
            bildUrl
            id
          }
          pausen {
            id
            pausenart
            ende
            bezahlt
            dauer
            beginn
          }
          pauseneinstellung
          dienstgaenge {
            id
            ende
            dauer
            beginn
          }
          puenktlichkeit {
            abweichungEnde
            abweichungBeginn
          }
          arbeitsbereich {
            __typename
            markierung
            id
            name
            abteilungen {
              __typename
              id
              name
              markierung
            }
          }
          abwesenheit {
            id
            grund
            dauer
          }
          termin {
            beschreibung
            bildName
            bildUrl
            id
            titel
            wiederholung
            markierung
          }
          rechtshinweise {
            __typename
            name
            art
            backgroundcolor
          }
          zuschlaege {
            name
            prozent
            betrag {
              ... on ArbeitszeitZuschlaegeBetrag {
                currency
                betrag
              }
              ... on NoAccess {
                message
              }
            }
            dauer
          }
          antraege {
            id
            status
            offen
            mitarbeiterId
            standortId
            bemerkungAntragsteller
            bemerkungVorgesetzter
            datum
            beginn
            ende
            aenderungsdatum
            erstellungsdatum
            arbeitsbereichId
          }
          bemerkung
        }
      }
      ... on ArbeitszeitError {
        type
        message
      }
    }
  }
`);

export const GET_ARBEITSBEREICHE_BY_STANDORTID = graphql(`
  query getArbeitsbereicheByStandortId($standortId: String!) {
    arbeitsbereicheByIdStandort(standortId: $standortId) {
      __typename
      ... on ArbeitsbereichByStandortId {
        __typename
        standortId
        arbeitsbereiche {
          __typename
          id
          idStandort
          markierung
          name
          abteilungen {
            id
            name
            markierung
          }
        }
      }
      ... on ArbeitsbereichError {
        type
        message
      }
    }
  }
`);

export const GET_ABTEILUNGEN_BY_STANDORTID = graphql(`
  query getAbteilungenByStandortId($standortId: String!) {
    abteilungenByStandortId(standortId: $standortId) {
      __typename
      ... on AbteilungByStandortId {
        __typename
        standortId
        abteilung {
          id
          markierung
          name
        }
      }
      ... on AbteilungError {
        type
        message
      }
    }
  }
`);

export const GET_TERMINE_BY_DATE_AND_STANDORT_ID = graphql(`
  query getTermineByDateAndStandortId($standortId: String!, $datum: DateTime!) {
    termine(standortId: $standortId, datum: $datum) {
      __typename
      ... on TermineByStandortId {
        standortId
        termine {
          wiederholung
          beginn
          ende
          beschreibung
          titel
          markierung
          id
          bildName
          bildUrl
        }
      }
      ... on TermineError {
        type
        message
      }
    }
  }
`);

export const GET_MITARBEITER_BY_STANDORT_ID = graphql(`
  query getMitarbeiterByStandortId($standortId: String!) {
    mitarbeiter(standortId: $standortId) {
      ... on MitarbeiterByStandortId {
        standortId
        mitarbeiter {
          id
          vorname
          nachname
          bildUrl
          extern
          arbeitsbereich {
            markierung
            id
            name
          }
        }
      }
      ... on MitarbeiterError {
        message
        type
      }
    }
  }
`);
export const GET_AUDIT_BY_ARBEITSZEIT_ID = graphql(`
  query getAuditByArbeitszeitId($idArbeitszeit: String!) {
    arbeitszeitById(idArbeitszeit: $idArbeitszeit) {
      id
      audit {
        __typename
        ... on Audit {
          __typename
          id
          typ
          aenderungen {
            attribut
            nachher
            vorher
          }
          aenderungsTyp
          aenderungsdatum
          geaendertVon
          geaendertVonEmail
          manager {
            email
            vorname
            nachname
          }
        }
        ... on AuditError {
          __typename
          message
          type
        }
      }
    }
  }
`);
