import { useTranslation } from 'react-i18next';
import { ZuschlagsTabelle } from './ZuschlagsTabelle';
import { useEditSheetContext } from '../../EditSheetContext';
import { truncatNumberToTwoDigits } from '../../../../util';

export function Zuschlaege() {
  const { arbeitszeit } = useEditSheetContext();
  const { t } = useTranslation();

  const gesamtDauer = arbeitszeit.zuschlaege.reduce(
    (acc, curr) => acc + curr.dauer,
    0,
  );
  const gesamtBetrag = arbeitszeit.zuschlaege.reduce(
    (acc, curr) => acc + curr.betrag,
    0,
  );

  return (
    <div className="flex flex-col justify-center gap-6 shadow-card bg-white rounded-md p-2">
      <div className="bg-neutrals-N200 min-h-10 rounded-lg py-2 px-4 text-sm flex justify-between items-center">
        <div className="w-[200px]">
          {t('domain.arbeitszeiten.sheet.zuschlaege.gesamt')}
        </div>
        <div className="flex gap-2 items-center">
          <div className="flex gap-1 items-baseline">
            <span className="font-semibold">{gesamtDauer}</span>
            <span className="text-neutrals-N700 text-xs">
              {t('general.date.minutes')}
            </span>
          </div>

          {arbeitszeit.zuschlaege.some(
            (zuschlag) => zuschlag.betrag !== null,
          ) && (
            <>
              <div className="h-6 bg-neutrals-N700 w-[1px]" />
              <div className="flex gap-1 items-baseline">
                <span className="font-semibold">
                  {truncatNumberToTwoDigits(gesamtBetrag)}
                </span>
                <span className="text-neutrals-N700 text-xs">
                  {t('general.currency.EUR')}
                </span>
              </div>
            </>
          )}
        </div>
      </div>
      <ZuschlagsTabelle zuschlaege={arbeitszeit.zuschlaege} />

      {arbeitszeit.zuschlaege.length === 0 && (
        <div className="flex justify-center text-sm text-neutrals-N700">
          {t('domain.arbeitszeiten.sheet.zuschlaege.empty')}
        </div>
      )}
    </div>
  );
}
