import { faShield } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Dispatch, SetStateAction, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DisableMFA } from './DisableMFA';
import { setUpTOTP } from 'aws-amplify/auth';
import { TOTPSetupForm } from './TOTPSetupForm';
import { Button } from '@/components/ui/button';
import * as Sentry from '@sentry/react';

// eslint-disable-next-line max-lines-per-function
export function TOTPSetup({
  isMFAEnabled,
  setIsMFAEnabled,
  isFormOpen,
  handleOpenFormChange,
}: {
  isMFAEnabled: boolean;
  setIsMFAEnabled: Dispatch<SetStateAction<boolean>>;
  isFormOpen: boolean;
  handleOpenFormChange: (value: boolean) => void;
}) {
  const [totpSetup, setTOTPSetup] = useState<{
    sharedSecret: string;
    setUpURI: URL;
  }>(undefined);

  const { t } = useTranslation();

  async function getCode() {
    try {
      const output = await setUpTOTP();

      setTOTPSetup({
        sharedSecret: output.sharedSecret,
        setUpURI: output.getSetupUri('E2N'),
      });
      handleOpenFormChange(true);
    } catch (error) {
      Sentry.captureException(error);
    }
  }

  return (
    <div className="flex w-full gap-2">
      <div className="leading-7 text-lg">
        <FontAwesomeIcon fixedWidth icon={faShield} size="lg" />
      </div>
      <div className="flex flex-col gap-4 grow">
        <div className="flex justify-between items-start">
          <h2 className="text-base font-semibold leading-7 text-neutrals-N900">
            {t('pages.profile.sections.security.setupTOTPForm.title')}
          </h2>
          <div className="flex gap-2 items-center">
            {isMFAEnabled && (
              <DisableMFA
                handleOpenFormChange={handleOpenFormChange}
                setIsMFAEnabled={setIsMFAEnabled}
              />
            )}
            <Button
              data-testid="pages-profile-security-enable-mfa"
              size="sm"
              variant="outline"
              disabled={isFormOpen}
              onClick={() => getCode()}>
              {isMFAEnabled
                ? t('pages.profile.sections.security.headerButtonLabelActive')
                : t(
                    'pages.profile.sections.security.headerButtonLabelInactive',
                  )}
            </Button>
          </div>
        </div>
        {!isFormOpen ? (
          <p className="text-sm leading-6 text-neutrals-N900">
            {t('pages.profile.sections.security.setupTOTPForm.description')}
          </p>
        ) : (
          <TOTPSetupForm
            setIsMFAEnabled={setIsMFAEnabled}
            totpSetup={totpSetup}
          />
        )}
      </div>
    </div>
  );
}
