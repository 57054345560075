import { EditDialog } from './EditDialog';
import { EditDialogContextProvider } from './EditDialogContext';

export function DialogWrapper({
  open,
  onOpenChange,
}: {
  open: boolean;
  onOpenChange: (open: boolean) => void;
}) {
  return (
    <EditDialogContextProvider onOpenChange={onOpenChange}>
      <EditDialog open={open} onOpenChange={onOpenChange} />
    </EditDialogContextProvider>
  );
}
