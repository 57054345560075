import dayjs from 'dayjs';
import { TagebuchUpdateInput } from '../../../../../gql/graphql';

export function buildChartData(
  dates: {
    date: string;
    weekday: string;
  }[],
  planumsaetze: TagebuchUpdateInput[],
): { day: string; umsatz: number }[] {
  const chartData = [];

  dates.forEach((date) => {
    const planumsatz = planumsaetze.find((p) => p.datum === date.date);
    const day = dayjs(date.date).format('DD');
    const dataSet =
      planumsatz && planumsatz.planUmsatz
        ? {
            umsatz: planumsatz.planUmsatz,
            day,
          }
        : { umsatz: 0, day };
    chartData.push(dataSet);
  });

  return chartData;
}
