export function getRandomNumberInRange(min: number, max: number): number {
  if (min > max) {
    throw new Error('Max value needs to be greater than min');
  }
  return Math.floor(Math.random() * (max - min)) + min;
}

export function calculateAbweichung(beginn: number, ende: number): number {
  const deviation = ende - beginn;

  return deviation;
}
