import { Button } from '@/components/ui/button';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const KuendigungButton = () => {
  const { t } = useTranslation();
  return (
    <Link
      className="ml-3"
      to={'https://e2n.de/backoffice/#k%C3%BCndigung'}
      target="_blank">
     <Button className="text-xs" variant="destructive" size="sm">
        {t('domain.vertraege.kuendigung.button')}
      </Button>
    </Link>
  );
};

export default KuendigungButton;
