import {
  E2Ntable,
  E2NTableCell,
  E2NtableHeader,
  E2NtableRow,
} from '@/components/Table';
import { TableBody, TableHead, TableRow } from '@/components/ui/table';
import Skeleton from '@/components/Skeleton/Skeleton';
import { useTranslation } from 'react-i18next';

// eslint-disable-next-line max-lines-per-function
export function DataTableSkeleton() {
  const { t } = useTranslation();

  return (
    <E2Ntable>
      <E2NtableHeader>
        <TableRow>
          <TableHead
            className={'ps-4 text-xs normal-case font-semibold h-0 w-[72px]'}>
            {t('domain.controlling.umsatzplanung.table.tag')}
          </TableHead>
          <TableHead className={'ps-4 text-xs normal-case font-semibold h-0 '}>
            {t('domain.controlling.umsatzplanung.table.plan')}
          </TableHead>
          <TableHead className={'ps-4 text-xs normal-case font-semibold h-0 '}>
            {t('domain.controlling.umsatzplanung.table.prognose2')}
          </TableHead>
          <TableHead className={'ps-4 text-xs normal-case font-semibold h-0 '}>
            {t('domain.controlling.umsatzplanung.table.produktivitaet')}
          </TableHead>
          <TableHead className={'ps-4 text-xs normal-case font-semibold h-0 '}>
            {t('domain.controlling.umsatzplanung.table.zielstunden')}
          </TableHead>
        </TableRow>
      </E2NtableHeader>
      <TableBody>
        <E2NtableRow className="hover:bg-transparent [&_td]:bg-white">
          <E2NTableCell colSpan={5} className="rounded-l-lg rounded-r-lg p-0">
            <Skeleton.Field height="44px" />
          </E2NTableCell>
        </E2NtableRow>
        <E2NtableRow className="hover:bg-transparent [&_td]:bg-white">
          <E2NTableCell colSpan={5} className="rounded-l-lg rounded-r-lg p-0">
            <Skeleton.Field height="44px" />
          </E2NTableCell>
        </E2NtableRow>
        <E2NtableRow className="hover:bg-transparent [&_td]:bg-white">
          <E2NTableCell colSpan={5} className="rounded-l-lg rounded-r-lg p-0">
            <Skeleton.Field height="44px" />
          </E2NTableCell>
        </E2NtableRow>
        <E2NtableRow className="hover:bg-transparent [&_td]:bg-white">
          <E2NTableCell colSpan={5} className="rounded-l-lg rounded-r-lg p-0">
            <Skeleton.Field height="44px" />
          </E2NTableCell>
        </E2NtableRow>
        <E2NtableRow className="hover:bg-transparent [&_td]:bg-white">
          <E2NTableCell colSpan={5} className="rounded-l-lg rounded-r-lg p-0">
            <Skeleton.Field height="44px" />
          </E2NTableCell>
        </E2NtableRow>
      </TableBody>
    </E2Ntable>
  );
}
