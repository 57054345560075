import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import {
  usePlanumsatz,
  usePlanumsatzCreate,
  usePlanumsatzUpdate,
} from '../../hooks';
import { getAllDatesFromCurrentMonth } from '../../../../utils/dateFunctions/getDatesOfMonth';
import { Header, TableRow } from './Table';
import { useStandort } from '../../../../hooks';
import dayjs from 'dayjs';
import { MonthPicker } from './MonthPicker';
import { ChartWrapper } from './Chart/ChartWrapper';
import { Chart } from './Chart';
import { KPI } from './KPI';
import { MonthParams, PlanumsatzEntry } from './types';
import { redirect, useParams } from 'react-router-dom';
import { E2Ntable } from '../../../../components/Table';
import { useState } from 'react';
import { handleOnCompleted } from './handleOnCompleted';
import { Button } from '@/components/ui/button';
import { TableBody } from '@/components/ui/table';
import { toast } from '@/hooks/use-toast';
import * as Sentry from '@sentry/react';

function filterAndTransformEntries(
  planumsatzEntries: PlanumsatzEntry[],
  type: 'created' | 'updated',
) {
  return planumsatzEntries
    .filter((p) => p.type === type)
    .map((t) => {
      return {
        datum: t.datum,
        planAbsatz: t.planAbsatz,
        planUmsatz: t.planUmsatz,
      };
    });
}

// Functional Component, daher brauchen wir max lines hier nicht
// Complexity würde ich mal deaktivieren, da dafür ein größeres Refactoring der Umsatzplanung notwenig wäre
// eslint-disable-next-line max-lines-per-function, complexity
export function Page() {
  const { t } = useTranslation();
  const { standortId } = useStandort();
  const [update] = usePlanumsatzUpdate();
  const [create] = usePlanumsatzCreate();
  const [isFormLoading, setIsFormLoading] = useState(false);

  const { year, month } = useParams<MonthParams>();

  const isValidYear = year && !Number.isNaN(year) && year.length === 4;
  const isValidMonth =
    month && !Number.isNaN(month) && /^[1-9]$|^1[0-2]$/.test(month);

  if (!isValidYear || !isValidMonth) {
    redirect('/controlling/umsatzplanung');
  }

  const startDate =
    !isValidYear && !isValidMonth
      ? dayjs().startOf('month').format('YYYY-MM-DD')
      : dayjs(`${year}-${month}-01`).startOf('month').format('YYYY-MM-DD');

  const endDate =
    !isValidYear && !isValidMonth
      ? dayjs().endOf('month').format('YYYY-MM-DD')
      : dayjs(`${year}-${month}-01`).endOf('month').format('YYYY-MM-DD');

  const {
    tagebuch: tagebuchData,
    error,
    umsatzBudget: umsatzBudgetData,
    prognoseumsaetze,
  } = usePlanumsatz({ von: startDate, bis: endDate }, standortId);
  const dates = getAllDatesFromCurrentMonth(startDate, endDate);

  const [planumsatzEntries, setPlanumsatzEntries] = useState(
    tagebuchData.map((t) => {
      return {
        type: 'updated',
        datum: t.datum,
        planAbsatz: t.planAbsatz,
        planUmsatz: t.planUmsatz,
      } as PlanumsatzEntry;
    }),
  );

  const [umsatzBudget, setUmsatzBudget] = useState(umsatzBudgetData);

  const onSubmit = async function () {
    setIsFormLoading(true);
    const month = dayjs(endDate).get('month') + 1;
    const year = dayjs(startDate).get('year');
    const planumsatzEntriesCreated = filterAndTransformEntries(
      planumsatzEntries,
      'created',
    );
    const planumsatzEntriesUpdated = filterAndTransformEntries(
      planumsatzEntries,
      'updated',
    );

    const umsatzBudgetInput = {
      zielumsatz: umsatzBudget,
      jahr: year,
      monat: month,
    };

    Promise.all([
      create({
        planumsatzCreateInput: {
          tagebuch: planumsatzEntriesCreated,
          umsatzBudget: umsatzBudgetInput,
        },
        standortId,
      }),
      update({
        planumsatzUpdateInput: {
          tagebuch: planumsatzEntriesUpdated,
          umsatzBudget: umsatzBudgetInput,
        },
        standortId,
      }),
    ])
      .then((values) => {
        handleOnCompleted(values[0].data, values[1].data, t);
        setIsFormLoading(false);
      })
      .catch((e) => {
        toast({
          title:
            'Es ist ein Fehler passiert und die Werte konnten nicht gespeichert werden.',
        });
        Sentry.captureException(e);
        setIsFormLoading(false);
      });
  };

  return (
    <>
      <div className="flex flex-col gap-4 p-10 w-screen">
        <Helmet>
          <title>{t('domain.controlling.umsatzplanung.header')}</title>
        </Helmet>
        <div className="flex gap-12 items-baseline justify-between w-3/4">
          <div className="flex gap-12 items-baseline">
            <h2
              data-testid="pages-profile-header"
              className="mb-4 text-2xl font-bold">
              {t('domain.controlling.umsatzplanung.header')}
            </h2>
            <MonthPicker startDate={startDate} />
          </div>
        </div>
        <ChartWrapper>
          <KPI
            zielumsatz={umsatzBudget}
            planumsatzEntries={planumsatzEntries}
            setUmsatzBudget={setUmsatzBudget}
          />
          <Chart dates={dates} planumsaetze={planumsatzEntries} />
        </ChartWrapper>
        <div className="mt-8">
          {error ? (
            <>{error}</>
          ) : (
            <div className="w-[75%] flex-col">
              <div className="flex w-full justify-end">
                <Button onClick={onSubmit} disabled={isFormLoading}>
                  {isFormLoading
                    ? t('actions.wirdGespeichert')
                    : t('actions.speichern')}
                </Button>
              </div>
              <E2Ntable>
                <Header />
                <TableBody>
                  {dates.map(({ date, weekday }) => {
                    return (
                      <TableRow
                        key={date}
                        currentDatum={date}
                        weekday={weekday}
                        planumsatzEntries={planumsatzEntries}
                        setPlanumsatzEntries={setPlanumsatzEntries}
                        prognoseumsatz={prognoseumsaetze}
                      />
                    );
                  })}
                </TableBody>
              </E2Ntable>
            </div>
          )}
        </div>
      </div>
    </>
  );
}
