/* eslint-disable complexity */
/* eslint-disable max-lines-per-function */
import {
  AbsoluteNumberField,
  MoneyField,
  ZielstundenField,
} from '../DataTable/Cells/GenericFields';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle, faCircleDashed } from '@fortawesome/pro-solid-svg-icons';
import { getWeekdayByDate } from '@/utils';
import { t } from 'i18next';
import { PayloadEntry } from './types';
import { Currency, Money } from '@/domain/controlling/entities';

export function CustomChartTooltip({
  active,
  payload,
  label,
  type,
}: {
  active: boolean;
  payload: PayloadEntry[];
  label: string;
  type: 'umsatz' | 'absatz';
}) {
  const zielstunden = payload?.find((entry) => entry.name === 'zielstunden');
  const planwert = payload?.find((entry) => entry.name === 'planwert');
  const prognose = payload?.find((entry) => entry.name === 'prognose');

  const date = getWeekdayByDate(new Date(label));

  if (active && payload && payload.length) {
    return (
      <div className="py-2 px-3 flex flex-col gap-2 w-full bg-white shadow-lg rounded-md">
        <span className="text-sm text-neutrals-N900 font-semibold">
          {date.shortage + '.' + ' ' + new Date(label).getDate()}
        </span>
        <div className="flex items-baseline gap-2 justify-between w-full">
          <div className="flex items-center gap-1">
            <FontAwesomeIcon
              icon={faCircleDashed}
              className="text-secondary-teal-T500 h-3 w-3"
            />
            <label className="text-sm">
              {t('domain.controlling.umsatzplanung.kpi.prognose.label')}
            </label>
          </div>
          {type === 'umsatz' ? (
            <MoneyField
              className="[&>div]:text-neutrals-N900 [&>div]:text-sm"
              value={
                planwert.payload.prognose
                  ? new Money(planwert.payload.prognose, Currency.EUR)
                  : undefined
              }
              unit={t('general.currency.EUR')}
            />
          ) : (
            <AbsoluteNumberField
              className="[&>div]:text-neutrals-N900 [&>div]:text-sm"
              value={prognose?.payload?.prognose}
              unit={t('domain.controlling.umsatzplanung.units.stueck')}
            />
          )}
        </div>
        <div className="flex items-baseline gap-2 justify-between w-full">
          <div className="flex items-center gap-1">
            <FontAwesomeIcon
              icon={faCircle}
              className="text-secondary-teal-T500 h-3 w-3 items-baseline"
            />
            <label className="text-sm">
              {type === 'umsatz'
                ? t('domain.controlling.umsatzplanung.kpi.planumsatz.label')
                : t('domain.controlling.umsatzplanung.kpi.planabsatz.label')}
            </label>
          </div>
          {type === 'umsatz' ? (
            <MoneyField
              className="[&>div]:text-neutrals-N900 [&>div]:text-sm"
              value={
                planwert.payload.planwert
                  ? new Money(planwert.payload.planwert, Currency.EUR)
                  : undefined
              }
              unit={t('general.currency.EUR')}
            />
          ) : (
            <AbsoluteNumberField
              className="[&>div]:text-neutrals-N900 [&>div]:text-sm"
              value={planwert?.payload?.planwert}
              unit={t('domain.controlling.umsatzplanung.units.stueck')}
            />
          )}
        </div>
        <div className="flex items-baseline gap-2 justify-between w-full">
          <div className="flex items-center gap-1">
            <FontAwesomeIcon
              icon={faCircle}
              className="w-3 h-3 text-primary-blue-B500"
            />
            <label className="text-sm">
              {t('domain.controlling.umsatzplanung.ziele.zielstunden')}
            </label>
          </div>
          <ZielstundenField
            value={zielstunden?.payload?.zielstunden}
            className="[&>div]:text-neutrals-N900 [&>div]:text-sm"
          />
        </div>
      </div>
    );
  }

  return null;
}
