import { ReactNode } from 'react';
import cx from 'classnames';

export function Container({
  children,
  className,
}: {
  children: ReactNode;
  className?: string;
}) {
  return (
    <div
      className={cx(
        'rounded-2xl p-2 overflow-y-hidden w-full',
        'flex flex-col justify-start gap-3',
        'bg-neutrals-N200',
        className,
      )}>
      {children}
    </div>
  );
}
