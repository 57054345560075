import { TableRow as E2NTableRow } from '@/components/ui/table';
import dayjs from 'dayjs';
import { Dispatch, SetStateAction, useState } from 'react';
import { PlanUmsatz, PlanAbsatz, PrognoseumsatzCell } from './TableCells';
import { E2NTableCell } from '../../../../../components/Table';
import {
  TagebuchCreateInput,
  TagebuchUpdateInput,
} from '../../../../../gql/graphql';
import { PlanumsatzEntry } from '../types';
import { Prognoseumsatz } from '../../../entities/prognoseumsatz';

type TableRow = {
  currentDatum: string;
  weekday: string;
  planumsatzEntries: PlanumsatzEntry[];
  setPlanumsatzEntries: Dispatch<SetStateAction<PlanumsatzEntry[]>>;
  prognoseumsatz: Prognoseumsatz[];
};

function getDefaultDurchschnitt(
  planumsatz: TagebuchUpdateInput | TagebuchCreateInput | undefined,
) {
  if (!planumsatz) {
    return 0;
  }
  if (planumsatz.planUmsatz <= 0 || planumsatz.planAbsatz <= 0) {
    return 0;
  }
  return planumsatz.planUmsatz / planumsatz.planAbsatz;
}

// eslint-disable-next-line max-lines-per-function
export function TableRow({
  currentDatum,
  weekday,
  planumsatzEntries,
  setPlanumsatzEntries,
  prognoseumsatz,
}: TableRow) {
  const planumsatz = planumsatzEntries.find((p) => p.datum === currentDatum);
  const isWeekend = weekday === 'Sa.' || weekday === 'So.';
  const defaultDurchschnitt = getDefaultDurchschnitt(planumsatz);

  const [durchschnitt, setDurchschnitt] = useState(defaultDurchschnitt);
  return (
    <E2NTableRow
      key={currentDatum}
      className="hover:bg-transparent [&_td]:bg-white">
      {isWeekend ? (
        <E2NTableCell className="border-r border-neutrals-N300 rounded-l-lg w-16 pe-4 h-16">
          <div className="text-left text-neutrals-N700 font-semibold">
            <div>{weekday}</div>
            <div>{dayjs(currentDatum).format('DD.MM')}</div>
          </div>
        </E2NTableCell>
      ) : (
        <E2NTableCell className="border-r border-neutrals-N300 rounded-l-lg w-16 h-16">
          <div className="p-1 text-neutrals-N700 text-left">
            <div>{weekday}</div>
            <div>{dayjs(currentDatum).format('DD.MM')}</div>
          </div>
        </E2NTableCell>
      )}
      <E2NTableCell className="w-[25%] font-semibold h-16" isBorderVisible>
        <PlanUmsatz
          currentDatum={currentDatum}
          setDurchschnitt={setDurchschnitt}
          planumsatzEntries={planumsatzEntries}
          setPlanumsatzEntries={setPlanumsatzEntries}
        />
      </E2NTableCell>
      <E2NTableCell className="w-[25%] font-semibold h-16" isBorderVisible>
        <PrognoseumsatzCell
          currentDatum={currentDatum}
          setDurchschnitt={setDurchschnitt}
          planumsatzEntries={planumsatzEntries}
          setPlanumsatzEntries={setPlanumsatzEntries}
          prognoseumsaetze={prognoseumsatz}
        />
      </E2NTableCell>
      <E2NTableCell className="w-[25%] font-semibold h-16" isBorderVisible>
        <PlanAbsatz
          currentDatum={currentDatum}
          setDurchschnitt={setDurchschnitt}
          planumsatzEntries={planumsatzEntries}
          setPlanumsatzEntries={setPlanumsatzEntries}
        />
      </E2NTableCell>
      <E2NTableCell className="w-[25%] font-semibold h-16">
        {Intl.NumberFormat('de-De', {
          currency: 'EUR',
          maximumFractionDigits: 2,
        }).format(durchschnitt)}
      </E2NTableCell>
      <E2NTableCell className="w-[25%] font-semibold rounded-r-lg h-16"></E2NTableCell>
    </E2NTableRow>
  );
}
