import { useTranslation } from 'react-i18next';
import { BemerkungCell } from '../Cells/BemerkungCell';
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '@/components/ui/tooltip';

export function BemerkungTooltip({ bemerkung }: { bemerkung: string }) {
  const { t } = useTranslation();

  return (
    <TooltipProvider>
      <Tooltip delayDuration={0}>
        <TooltipTrigger
          className={!bemerkung ? 'cursor-auto' : 'cursor-pointer'}>
          <div className="flex">
            <BemerkungCell bemerkung={bemerkung} />
          </div>
        </TooltipTrigger>
        {bemerkung && (
          <TooltipContent
            side="bottom"
            className="bg-white text-xl items-start w-[400px] px-3 pt-2">
            {!bemerkung ? (
              <div>Error</div>
            ) : (
              <div className="flex items-start gap-6 font-semibold p-1">
                <div className="text-sm text- space-y-2">
                  <div className="font-bold text-neutrals-N700 uppercase tracking-wide">
                    {t('domain.arbeitszeiten.uebersicht.tooltips.bemerkung')}
                  </div>
                  <p className="text-neutrals-N900">{bemerkung}</p>
                </div>
              </div>
            )}
          </TooltipContent>
        )}
      </Tooltip>
    </TooltipProvider>
  );
}
