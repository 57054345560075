import { Routes, Route } from 'react-router-dom';
import {
  DefaultPage,
  LoadingPage,
  LoginPage,
  LogoutPage,
  ProfilePage,
} from '../../../pages';
import { Vertraege, Controlling } from '../../../domain';
import { UnauthorizedPage } from './UnauthorizedPage.tsx';
import { useEffect } from 'react';
import { managerV1BaseUrl } from '../../../utils/util.ts';
import { Arbeitszeiten } from '../../../domain/arbeitszeiten/Arbeitszeiten.tsx';

export const NavigateToV1 = () => {
  useEffect(() => {
    window.location.replace(managerV1BaseUrl);
  }, []);
  return <LoadingPage />;
};

export function AuthenticatedRoutes() {
  return (
    <Routes>
      <Route path="/login" element={<LoginPage />} />
      <Route path="/*" element={<NavigateToV1 />} />
      <Route
        path="/profile"
        element={
          <UnauthorizedPage>
            <DefaultPage>
              <ProfilePage />
            </DefaultPage>
          </UnauthorizedPage>
        }
      />
      <Route
        path="/controlling/*"
        element={
          <UnauthorizedPage>
            <DefaultPage>
              <Controlling />
            </DefaultPage>
          </UnauthorizedPage>
        }
      />
      <Route
        path="/vertraege/*"
        element={
          <UnauthorizedPage>
            <Vertraege />
          </UnauthorizedPage>
        }
      />
      <Route
        path="/arbeitszeiten/*"
        element={
          <UnauthorizedPage>
            <DefaultPage>
              <Arbeitszeiten />
            </DefaultPage>
          </UnauthorizedPage>
        }
      />
      <Route path="/logout" element={<LogoutPage />} />
    </Routes>
  );
}
