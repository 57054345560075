import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { cn } from '../../lib/utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Dispatch,
  forwardRef,
  HTMLInputTypeAttribute,
  SetStateAction,
  useState,
} from 'react';
import { faEye, faEyeSlash } from '@fortawesome/pro-light-svg-icons';

export type InputProps = React.InputHTMLAttributes<HTMLInputElement> & {
  startIcon?: IconDefinition;
  endIcon?: IconDefinition;
  showPassword?: boolean;
};

function PasswordIcon({
  showPassword,
  type,
  isPasswordVisible,
  setIsPasswordVisible,
}: {
  showPassword: boolean;
  type: HTMLInputTypeAttribute;
  isPasswordVisible: boolean;
  setIsPasswordVisible: Dispatch<SetStateAction<boolean>>;
}) {
  return showPassword && type === 'password' ? (
    <FontAwesomeIcon
      className={cn(
        'text-neutrals-N700 group-focus-within:text-primary-blue-B600 transform transition-colors',
        'absolute right-3 top-1/2 -translate-y-1/2',
      )}
      icon={isPasswordVisible ? faEyeSlash : faEye}
      onClick={() => setIsPasswordVisible(!isPasswordVisible)}
    />
  ) : (
    <></>
  );
}

const Input = forwardRef<HTMLInputElement, InputProps>(
  // eslint-disable-next-line max-lines-per-function
  ({ className, type, startIcon, endIcon, showPassword, ...props }, ref) => {
    const [isPasswordVisible, setIsPasswordVisible] = useState(false);

    function getInputType() {
      return type === 'password'
        ? isPasswordVisible
          ? 'text'
          : 'password'
        : type;
    }

    return (
      <div className="relative group rounded-md bg-transparent">
        {startIcon && (
          <FontAwesomeIcon
            icon={startIcon}
            className={cn(
              'text-neutrals-N700 group-focus-within:text-primary-blue-B600 transform transition-colors',
              'absolute left-2.5 top-1/2  -translate-y-1/2',
            )}
          />
        )}
        <input
          type={getInputType()}
          className={cn(
            'peer',
            'flex w-full py-2',
            `${startIcon ? 'ps-8' : 'ps-4'}`,
            `${endIcon ? 'pe-9' : 'pe-4'}`,
            'rounded-md border border-neutrals-N300',
            'bg-white text-sm transition-colors leading-6',
            'file:border-0 file:bg-transparent file:text-md file:font-medium',
            'focus-visible:outline-none focus-visible:border-neutrals-N800',
            'disabled:cursor-not-allowed disabled:opacity-70',
            className,
          )}
          ref={ref}
          {...props}
        />
        {endIcon && (
          <FontAwesomeIcon
            className={cn(
              'text-neutrals-N700 group-focus-within:text-primary-blue-B600 transform transition-colors',
              'absolute right-3 top-1/2 -translate-y-1/2',
            )}
            icon={endIcon}
          />
        )}
        <PasswordIcon
          isPasswordVisible={isPasswordVisible}
          setIsPasswordVisible={setIsPasswordVisible}
          type={type}
          showPassword={showPassword}
        />
      </div>
    );
  },
);
Input.displayName = 'Input';

export { Input };
