import { Routes, Route } from 'react-router-dom';
import { NavigateToV1 } from '../../app/Routes/AuthenticatedRoutes/AuthenticatedRoutes';
import { EditSheet, SheetWrapper } from './components/EditSheet';
import { UebersichtWrapper } from './pages/uebersicht/UebersichtWrapper';
import { CreateSheet } from './components/CreateSheet';
import { useProfile } from '../../hooks';
import {
  Antrag,
  Arbeitszeit,
  Dienstgang,
  Rechtshinweise,
  Zuschlaege,
} from './components/EditSheet/content';
import { HistorieWrapper } from './components/EditSheet/content/Historie';

// eslint-disable-next-line max-lines-per-function
export function Arbeitszeiten() {
  const profile = useProfile();
  const rechte = profile.manager.rechte;

  if (rechte.arbeitszeit.erlaubt === false) {
    return <NavigateToV1 />;
  }

  return (
    <Routes>
      <Route
        path="/uebersicht/:year/:month/:day"
        element={<UebersichtWrapper />}>
        <Route
          path="/uebersicht/:year/:month/:day/sheet"
          element={<SheetWrapper />}>
          <Route
            path="/uebersicht/:year/:month/:day/sheet/edit/:arbeitszeitId"
            element={<EditSheet />}>
            <Route
              path="/uebersicht/:year/:month/:day/sheet/edit/:arbeitszeitId"
              index
              element={<Arbeitszeit />}
            />
            <Route
              path="/uebersicht/:year/:month/:day/sheet/edit/:arbeitszeitId/arbeitszeit"
              element={<Arbeitszeit />}
            />
            <Route
              path="/uebersicht/:year/:month/:day/sheet/edit/:arbeitszeitId/hinweise"
              element={<Rechtshinweise />}
            />
            <Route
              path="/uebersicht/:year/:month/:day/sheet/edit/:arbeitszeitId/antraege"
              element={<Antrag />}
            />
            <Route
              path="/uebersicht/:year/:month/:day/sheet/edit/:arbeitszeitId/dienstgaenge"
              element={<Dienstgang />}
            />
            <Route
              path="/uebersicht/:year/:month/:day/sheet/edit/:arbeitszeitId/zuschlaege"
              element={<Zuschlaege />}
            />
            <Route
              path="/uebersicht/:year/:month/:day/sheet/edit/:arbeitszeitId/historie"
              element={<HistorieWrapper />}
            />
          </Route>
          <Route
            path="/uebersicht/:year/:month/:day/sheet/create"
            element={<CreateSheet />}
          />
        </Route>
      </Route>
      <Route path="*" element={<NavigateToV1 />} />
    </Routes>
  );
}
