import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { MonthParams } from './types';
import { Navigate, Outlet, useParams, useSearchParams } from 'react-router-dom';
import {
  getIsValidYear,
  getIsValidMonth,
  getStartDate,
} from '../../helpers/dateValidations';
import { MonthPicker } from './MonthPicker';
import { getMonthNameByDate } from '@/utils';
import { ZieleWrapper } from './Ziele';
import { PlanungWrapper, TableWrapper } from './Planung';
import { useEffect, useMemo } from 'react';
import { toast } from '@/hooks/use-toast';

export function Page() {
  const { t } = useTranslation();
  const { year, month } = useParams<MonthParams>();
  const [searchParams] = useSearchParams();
  const isValidYear = useMemo(() => getIsValidYear(year), [year]);
  const isValidMonth = useMemo(() => getIsValidMonth(month), [month]);

  const startDate = useMemo(
    () => getStartDate(isValidYear, isValidMonth, year, month),
    [isValidMonth, isValidYear, month, year],
  );

  useEffect(() => {
    if (searchParams.get('success')) {
      toast({
        description: t('domain.controlling.umsatzplanung.planung.success'),
      });
    }
  }, [searchParams, t]);

  if (!isValidYear || !isValidMonth) {
    const defaultYear = new Date().getFullYear().toString();
    const defaultMonth = (new Date().getMonth() + 1).toString();
    return (
      <Navigate
        to={`/controlling/umsatzplanung-v2/${defaultYear}/${defaultMonth}`}
        replace
      />
    );
  }

  return (
    <>
      <Helmet>
        <title>{t('domain.controlling.umsatzplanung.header')}</title>
      </Helmet>
      <div className="flex gap-4 items-center">
        <MonthPicker startDate={startDate} />
        <h2 className="font-extrabold text-2xl">
          {getMonthNameByDate(startDate)}
        </h2>
      </div>
      <ZieleWrapper />
      <PlanungWrapper />
      <Outlet />
      <TableWrapper year={parseInt(year)} month={parseInt(month)} />
    </>
  );
}
