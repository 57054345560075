import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { t } from 'i18next';
import { getPausenartIndicator } from '../../getPausenartIndicator';
import { Pausenart } from '../../../../../../../../../gql/graphql';
import { TableCell } from '@/components/ui/table';

export function PausenartCell({ pausenart }: { pausenart: Pausenart }) {
  return (
    <TableCell className="font-medium p-4">
      <div className="flex gap-1 items-baseline">
        <FontAwesomeIcon
          className="text-primary-blue-B400"
          icon={getPausenartIndicator(pausenart)}
        />
        <span className="font-semibold">
          {t(`entities.pausenart.${pausenart}`)}
        </span>
      </div>
    </TableCell>
  );
}
