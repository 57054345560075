import { useSuspenseQuery } from '@apollo/client';
import { GET_ARBEITSBEREICHE_BY_STANDORTID } from './graphql/queries';
import {
  ArbeitsbereichByStandortId,
  ArbeitsbereichError,
} from '../../../gql/graphql';
import { handleGraphQLErrors } from '../../../utils/errors/handleGraphQLErrors';
import { Arbeitsbereich } from '../entities';
import * as Sentry from '@sentry/react';

export function useArbeitsbereiche(standortId: string): {
  arbeitsbereiche?: Arbeitsbereich[];
  error?: string;
} {
  const { data } = useSuspenseQuery(GET_ARBEITSBEREICHE_BY_STANDORTID, {
    variables: { standortId },
    fetchPolicy: 'cache-first',
  });

  const error = handleGraphQLErrors<
    ArbeitsbereichByStandortId,
    ArbeitsbereichError
  >(data.arbeitsbereicheByIdStandort);
  if (error) {
    Sentry.captureException(error);
    return { error: error.message };
  }

  const currentArbeitsbereiche =
    data.arbeitsbereicheByIdStandort as ArbeitsbereichByStandortId;

  const arbeitsbereiche = [...currentArbeitsbereiche.arbeitsbereiche];

  return {
    arbeitsbereiche: arbeitsbereiche.map((arbeitsbereich) => {
      return new Arbeitsbereich(
        arbeitsbereich.name,
        arbeitsbereich.id,
        arbeitsbereich.markierung,
        arbeitsbereich.abteilungen,
      );
    }),
  };
}
