import { faEdit, faSpinner } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Arbeitsbereich, Arbeitszeit } from '../../../entities';
import { useState } from 'react';
import { markierungMapper } from '../../../../../utils';
import { useTranslation } from 'react-i18next';
import { useArbeitsbereiche } from '../../../hooks/useArbeitsbereiche';
import { faExclamationCircle } from '@fortawesome/pro-light-svg-icons';
import { CheckedIcon } from '../CheckedIcon';
import { UncheckedIcon } from '../UncheckedIcon';
import { useStandort } from '../../../../../hooks';
import { useManagerCanEditArbeitszeiten } from '../../../hooks/useManagerCanEditArbeitszeit';
import { Markierung } from '../../Markierung';
import { Button } from '@/components/ui/button';
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from '@/components/ui/command';
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@/components/ui/popover';

// eslint-disable-next-line max-lines-per-function
export function ArbeitsbereichSelect({
  defaultArbeitsbereich,
  onChange,
  isUpdating,
}: {
  defaultArbeitsbereich: Arbeitszeit['arbeitsbereich'];
  onChange?: (newArbeitsbereich: Arbeitsbereich) => void;
  isUpdating: boolean;
}) {
  const [open, setOpen] = useState(false);
  const canEditArbeitszeit = useManagerCanEditArbeitszeiten();
  const { t } = useTranslation();
  const { standortId } = useStandort();
  const { arbeitsbereiche, error } = useArbeitsbereiche(standortId);
  const [selectedArbeitsbereich, setSelectedArbeitsbereich] = useState(
    defaultArbeitsbereich,
  );

  if (error) {
    return (
      <div className="flex justify-between items-center h-full">
        <div>{defaultArbeitsbereich.name}</div>
        <FontAwesomeIcon
          icon={faExclamationCircle}
          className="text-primary-red-R500"
        />
      </div>
    );
  }

  function getColorByOption(arbeitsbereichId: string) {
    const arbeitsbereich = arbeitsbereiche.find(
      (a) => a.id.toLowerCase() === arbeitsbereichId,
    );
    const color = markierungMapper[arbeitsbereich.markierung].base;
    return color;
  }

  function handleSelect(currentValue: string) {
    const id = currentValue.split('% %')[1];
    const newArbeitsbereich = arbeitsbereiche.find((a) => a.id === id);
    setSelectedArbeitsbereich(newArbeitsbereich);
    if (onChange) {
      onChange(newArbeitsbereich);
    }
  }

  function handlePopoverTriggerClick(open: boolean) {
    if (canEditArbeitszeit) {
      setOpen(open);
    }
  }

  return (
    <Popover open={open} onOpenChange={handlePopoverTriggerClick}>
      <PopoverTrigger asChild>
        <div className="flex items-center justify-between gap-2 w-full h-full">
          <Markierung color={getColorByOption(selectedArbeitsbereich.id)} />
          <span className="truncate grow">
            {selectedArbeitsbereich
              ? arbeitsbereiche.find(
                  (arbeitsbereich) =>
                    arbeitsbereich.name === selectedArbeitsbereich.name,
                ).name
              : ''}
          </span>
          {canEditArbeitszeit &&
            (!isUpdating ? (
              <Button
                variant="outline"
                size="icon"
                disabled={isUpdating}
                className="shrink-0 border-none hover:bg-neutrals-N300 hover:text-primary-blue-B600">
                <FontAwesomeIcon icon={faEdit} />
              </Button>
            ) : (
              <Button variant="ghost" disabled>
                <FontAwesomeIcon icon={faSpinner} spin />
              </Button>
            ))}
        </div>
      </PopoverTrigger>
      <PopoverContent className="bg-white p-0 max-w-[200px]">
        <Command>
          <CommandInput disabled={isUpdating} placeholder="Suchen…" />
          <CommandList>
            <CommandEmpty className="p-4 text-xs text-center">
              {t('domain.arbeitszeiten.sheet.header.arbeitsbereichNotFound')}
            </CommandEmpty>
            <CommandGroup>
              {arbeitsbereiche.map((arbeitsbereich) => {
                return (
                  <CommandItem
                    disabled={isUpdating}
                    className="hover:rounded-md transition-colors"
                    key={arbeitsbereich.id}
                    value={`${arbeitsbereich.name}% %${arbeitsbereich.id}`}
                    onSelect={handleSelect}>
                    <div className="flex justify-between items-center w-full">
                      <div className="flex gap-2 items-center truncate max-w-28">
                        <Markierung
                          color={getColorByOption(arbeitsbereich.id)}
                        />
                        <span className="truncate">{arbeitsbereich.name}</span>
                      </div>
                      {selectedArbeitsbereich.id === arbeitsbereich.id ? (
                        <CheckedIcon />
                      ) : (
                        <UncheckedIcon />
                      )}
                    </div>
                  </CommandItem>
                );
              })}
            </CommandGroup>
          </CommandList>
        </Command>
      </PopoverContent>
    </Popover>
  );
}
