import { Table, TableRow, TableBody, TableCell } from '@/components/ui/table';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faArrowRight,
  faClock,
  faMugHot,
} from '@fortawesome/pro-light-svg-icons';
import { Audit } from '@/domain/arbeitszeiten/entities/Arbeitszeit';
import { formatDateTime } from './attributFormatters';

// eslint-disable-next-line max-lines-per-function
export function HistorieTabelle({
  historie: historie,
}: {
  historie?: Audit[];
}) {
  return (
    <div className="flex">
      <FontAwesomeIcon
        icon={historie[0].typ === 'ArbeitszeitAudit' ? faClock : faMugHot}
        className="px-2 fa-fw"
        size="lg"
        fixedWidth
      />
      <div className="flex flex-col items-start pb-2">
        <div className="flex w-full justify-between">
          <div className="flex gap-4 normal-case text-sm font-semibold items-center pl-2">
            {formatDateTime(historie[0].aenderungsdatum.toISOString())}
            <FontAwesomeIcon
              icon={faArrowRight}
              className="px-1"
              size="sm"
              fixedWidth
            />
            geändert von {historie[0].geaendertVon}{' '}
          </div>
        </div>
        <div className="w-full border-neutrals-N300 border-t-2 ">
          <Table className="w-full mt-2">
            <TableBody className="p-2">
              {historie.map((historie, id) =>
                historie.aenderungen.map((aenderung, index) => (
                  <TableRow
                    key={`${id}-${index}`}
                    className="hover:bg-transparent gap-1 items-baseline space-y-4 font-bold">
                    <TableCell className="font-semibold w-[150px] border-r border-neutrals-N300">
                      <div>{aenderung.attribut}</div>
                    </TableCell>
                    <TableCell className="w-[250px] text-neutrals-N700 border-r border-neutrals-N300">
                      <div>{aenderung.vorher}</div>
                    </TableCell>
                    <TableCell className="w-[250px]">
                      <div className="text-black">{aenderung.nachher}</div>
                    </TableCell>
                  </TableRow>
                )),
              )}
            </TableBody>
          </Table>
        </div>
      </div>
    </div>
  );
}
