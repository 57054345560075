import { useAuthenticationContext } from './AuthenticationContext';
import { UserInfo, getCurrentUserInfo } from '../services';
import { Dispatch, SetStateAction, useEffect } from 'react';
import { Hub } from 'aws-amplify/utils';
import { getCookieDomain } from '../utils';
import { LoadingPage } from '../pages';
import { fetchAuthSession } from 'aws-amplify/auth';
import { AuthenticatedRoutes, UnauthenticatedRoutes } from './Routes';
import Cookies from 'js-cookie';
import { FeatureFlagProvider } from '../store/FeatureFlagContext';
import * as Sentry from '@sentry/react';

async function checkUser() {
  let userInfo: UserInfo;
  try {
    const { tokens } = await fetchAuthSession({ forceRefresh: true });
    if (tokens) {
      userInfo = await getCurrentUserInfo();
    }
  } catch (error) {
    Sentry.captureException(error);
  }
  return userInfo;
}

function useHandleAuthEvents({
  setIsUserLoading,
  setUserInfo,
}: {
  setIsUserLoading: (value: boolean) => void;
  setUserInfo: Dispatch<SetStateAction<UserInfo>>;
}) {
  useEffect(() => {
    checkUser().then((userInfo) => {
      setUserInfo(userInfo);
      Sentry.setUser({
        id: userInfo?.attributes.email,
        email: userInfo?.attributes.email,
      });
      setIsUserLoading(false);
    });

    Hub.listen('auth', (data) => {
      if (data.payload.event !== 'tokenRefresh') {
        Cookies.remove('standort', {
          path: '',
          domain: getCookieDomain(),
        });
      }
      switch (data.payload.event) {
        case 'signedIn':
          checkUser().then((userInfo) => {
            setUserInfo(userInfo);
            Sentry.setUser({
              id: userInfo?.attributes.email,
              email: userInfo?.attributes.email,
            });
            setIsUserLoading(false);
          });
          break;
      }
    });
  }, [setIsUserLoading, setUserInfo]);
}

export function App() {
  const { setUserInfo, userInfo, isUserLoading, setIsUserLoading } =
    useAuthenticationContext();

  useHandleAuthEvents({ setUserInfo, setIsUserLoading });

  return isUserLoading ? (
    <LoadingPage />
  ) : userInfo ? (
    <FeatureFlagProvider>
      <AuthenticatedRoutes />
    </FeatureFlagProvider>
  ) : (
    <UnauthenticatedRoutes />
  );
}
