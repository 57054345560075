import { Arbeitszeit } from '../../../../../../entities';
import { Pausenart } from '../../../../../../../../gql/graphql';
import { useState } from 'react';
import {
  BeginnEndeCell,
  BezahltCell,
  DauerCell,
  PausenartCell,
  EditCell,
} from './Cells';
import { EditForm } from '../Forms';
import { TableHeader } from './TableHeader';
import { useManagerCanEditArbeitszeiten } from '../../../../../../hooks/useManagerCanEditArbeitszeit';
import {
  Table as E2nTable,
  TableBody,
  TableCell,
  TableRow,
} from '@/components/ui/table';

export function Table({ arbeitszeit }: { arbeitszeit: Arbeitszeit }) {
  const { pausen } = arbeitszeit;
  const managerCanEditArbeitszeit = useManagerCanEditArbeitszeiten();
  const [editablePause, setEditablePause] = useState<string | undefined>(
    undefined,
  );

  return (
    <E2nTable>
      <TableHeader />
      <TableBody>
        {pausen
          .filter((pause) => pause.pausenart !== Pausenart.Dienstgang)
          .map((pause, id) => {
            return editablePause === pause.id ? (
              <TableRow className="hover:bg-white">
                <TableCell colSpan={5}>
                  <EditForm
                    arbeitszeit={arbeitszeit}
                    setIsCreateFormVisible={() => setEditablePause(undefined)}
                    pauseId={pause.id}
                  />
                </TableCell>
              </TableRow>
            ) : (
              <TableRow key={id} className="hover:bg-transparent">
                <PausenartCell pausenart={pause.pausenart} />
                <BeginnEndeCell beginn={pause.beginn} ende={pause.ende} />
                <DauerCell dauer={pause.dauer} />
                <BezahltCell pause={pause} arbeitszeit={arbeitszeit} />
                {pause.pausenart !== Pausenart.Automatisch &&
                  pause.pausenart !== Pausenart.Gesetzlich &&
                  managerCanEditArbeitszeit && (
                    <EditCell
                      pauseId={pause.id}
                      setEditablePause={setEditablePause}
                    />
                  )}
              </TableRow>
            );
          })}
      </TableBody>
    </E2nTable>
  );
}
