import {
  CreateOrUpdateAbsatzPlanWertMutation,
  CreateOrUpdateAbsatzPlanWertMutationVariables,
} from '@/gql/graphql';
import { useMutation } from '@apollo/client';
import { useCallback } from 'react';
import { CREATE_OR_UPDATE_ABSATZ_PLANWERT } from './graphql/mutations';

export function useAbsatzPlanWertCreateOrUpdate() {
  const [execute, result] = useMutation<
    CreateOrUpdateAbsatzPlanWertMutation,
    CreateOrUpdateAbsatzPlanWertMutationVariables
  >(CREATE_OR_UPDATE_ABSATZ_PLANWERT, {
    refetchQueries: ['getPlanwerteByStandortId'],
  });

  const createOrUpdateAbsatzPlanWert = useCallback(
    (variables: CreateOrUpdateAbsatzPlanWertMutationVariables) => {
      return execute({ variables });
    },
    [execute],
  );

  return [createOrUpdateAbsatzPlanWert, result] as const;
}
