import { toast } from '@/hooks/use-toast';
import {
  PlanumsatzCreateMutation,
  PlanumsatzUpdateMutation,
  PlanumsatzError,
  PlanumsatzCreateResult,
  PlanumsatzUpdateResult,
} from '../../../../gql/graphql';
import { handleGraphQLErrors } from '../../../../utils/errors/handleGraphQLErrors';
import { TFunction } from 'i18next';

export function handleOnCompleted(
  createResult: PlanumsatzCreateMutation,
  updateResult: PlanumsatzUpdateMutation,
  t: TFunction,
) {
  const updateError = handleGraphQLErrors<
    PlanumsatzUpdateResult,
    PlanumsatzError
  >(updateResult.updatePlanumsatz);

  const createError = handleGraphQLErrors<
    PlanumsatzCreateResult,
    PlanumsatzError
  >(createResult.createPlanumsatz);

  if (updateError || createError) {
    toast({
      title: t('domain.controlling.umsatzplanung.onError', {
        createError: createError.message,
        updateError: updateError.message,
      }),
    });
  } else {
    toast({
      title: t('domain.controlling.umsatzplanung.onSuccess'),
    });
  }
}
