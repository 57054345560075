import {
  AbsatzProduktivitaet,
  AbsatzVorgabe,
} from '@/domain/controlling/entities';
import { formatNumber } from '../formatNumber';

export function getAbsatzProduktivitaetByMptOrStueck(
  absatzproduktivitaet: AbsatzProduktivitaet,
  absatzVorgabe: AbsatzVorgabe,
) {
  if (
    (absatzproduktivitaet.stueck === undefined ||
      absatzproduktivitaet.stueck === null) &&
    (absatzproduktivitaet.mpt === undefined ||
      absatzproduktivitaet.mpt === null)
  ) {
    return '';
  }
  if (absatzVorgabe === AbsatzVorgabe.Stueck) {
    return formatNumber(absatzproduktivitaet.stueck.toString(), true);
  } else {
    return formatNumber(absatzproduktivitaet.mpt.toString(), true);
  }
}
