import { CSSProperties } from 'react';
import cx from 'classnames';

type SizeVariants = 'xs' | 'sm' | 'md' | 'lg' | 'xl';
const sizeMap: Record<SizeVariants, string> = {
  xs: '1.5rem',
  sm: '2rem',
  md: '2.5rem',
  lg: '3rem',
  xl: '4rem',
};

type StyleProps = CSSProperties & {
  '--avatar-skeleton-width': CSSProperties['width'];
  '--avatar-skeleton-height': CSSProperties['height'];
};

export function Avatar({
  size = `sm`,
  className,
}: {
  size?: SizeVariants;
  className?: string;
}) {
  return (
    <div
      className={cx(
        'animate-pulse rounded-full bg-neutrals-N300 w-[var(--avatar-skeleton-width)] h-[var(--avatar-skeleton-height)] ',
        className,
      )}
      style={
        {
          '--avatar-skeleton-height': sizeMap[size],
          '--avatar-skeleton-width': sizeMap[size],
        } as StyleProps
      }
    />
  );
}
