import { TFunction } from 'i18next';
import { handleGraphQLErrors } from '../../../../utils/errors/handleGraphQLErrors';
import {
  Arbeitszeiten,
  ArbeitszeitDeleteMutation,
  ArbeitszeitDeleteResult,
  ManagerHasRoleArbeitszeitNurHeuteBearbeiten,
  UnknownError,
  ArbeitszeitManagerNotFound,
  ArbeitszeitMonatAbgeschlossen,
  ArbeitszeitNotFound,
  ArbeitszeitDeleteManagerHasNoAccessToArbeitsbereich,
} from '../../../../gql/graphql';
import { toast } from '@/hooks/use-toast';
import * as Sentry from '@sentry/react';

function getDeleteErrorMessage(deleteErrorResult: ArbeitszeitDeleteResult) {
  let message = '';
  if (deleteErrorResult.__typename === 'ArbeitszeitNotFound') {
    message =
      'domain.arbeitszeiten.sheet.header.arbeitszeitNotFoundDeleteError';
  } else if (deleteErrorResult.__typename === 'ArbeitszeitManagerNotFound') {
    message =
      'domain.arbeitszeiten.sheet.header.arbeitszeitManagerNotFoundDeleteError';
  } else if (deleteErrorResult.__typename === 'ArbeitszeitMonatAbgeschlossen') {
    message =
      'domain.arbeitszeiten.sheet.header.arbeitszeitMonatAbgeschlossenDeleteError';
  } else if (
    deleteErrorResult.__typename ===
    'ManagerHasRoleArbeitszeitNurHeuteBearbeiten'
  ) {
    message =
      'domain.arbeitszeiten.sheet.header.arbeitszeitNurHeuteBearbeitenDeleteError';
  } else {
    message = 'domain.arbeitszeiten.sheet.header.arbeitszeitDeleteUnknownError';
  }
  return message;
}

export function handleOnCompletedDelete(
  deleteResult: ArbeitszeitDeleteMutation,
  t: TFunction,
) {
  const deleteError = handleGraphQLErrors<
    Arbeitszeiten,
    | ArbeitszeitNotFound
    | ArbeitszeitManagerNotFound
    | ArbeitszeitMonatAbgeschlossen
    | ManagerHasRoleArbeitszeitNurHeuteBearbeiten
    | ArbeitszeitDeleteManagerHasNoAccessToArbeitsbereich
    | UnknownError
  >(deleteResult.deleteArbeitszeit as ArbeitszeitDeleteResult);

  if (deleteError) {
    Sentry.captureException(
      new Error(`${deleteError.type}:${deleteError.message}`),
    );
    const message = getDeleteErrorMessage(
      deleteResult.deleteArbeitszeit as ArbeitszeitDeleteResult,
    );
    toast({
      title: t(message),
    });
  } else {
    toast({
      title: t('domain.arbeitszeiten.sheet.arbeitszeit.delete.erfolgreich'),
    });
  }
}
