export class AbsatzProduktivitaet {
  constructor(readonly stueck?: number, readonly mpt?: number) {}
}

export class Absatz {
  constructor(
    readonly standortId: string,
    readonly produktivitaet: AbsatzProduktivitaet,
    readonly wert: number,
    readonly stunden: number,
  ) {}
}

export class AbsatzPlan {
  constructor(
    readonly datum: string,
    readonly absatz: Absatz,
    readonly prognose: number,
  ) {}
}
