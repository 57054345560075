import { useTranslation } from 'react-i18next';
import { Badge } from '@/components/ui/badge';
import { useEditSheetContext } from '../EditSheetContext';

export function RechtshinweiseTrigger() {
  const { t } = useTranslation();
  const { arbeitszeit } = useEditSheetContext();

  return (
    <div className="flex gap-1 items-center">
      <Badge className="flex bg-primary-red-R400 hover:bg-primary-red-R400 items-center justify-center rounded-full text-white size-5 p-1 text-[10px] font-semibold">
        {arbeitszeit.rechtshinweise.length}
      </Badge>
      <div>{t('domain.arbeitszeiten.sheet.tabs.rechtshinweise')}</div>
    </div>
  );
}
