import { createContext, useContext } from 'react';
import { Arbeitszeit } from '../../entities';
import { useParams } from 'react-router-dom';
import { useArbeitszeiten } from '../../hooks';
import { useStandortContext } from '../../../../store/standortContext';

export type EditSheetContextProps = {
  arbeitszeit: Arbeitszeit;
};

export const EditSheetContext = createContext<
  EditSheetContextProps | undefined
>(undefined);

function EditSheetContextProvider({ children }: { children: React.ReactNode }) {
  const { year, month, day, arbeitszeitId } = useParams();
  const standort = useStandortContext((state) => state.currentStandort);

  const arbeitszeiten = useArbeitszeiten(
    new Date(`${year}-${month}-${day}`),
    standort.id,
  );

  const arbeitszeit =
    arbeitszeiten.abgeschlosseneArbeitszeiten.find(
      (a) => a.id === arbeitszeitId,
    ) ?? arbeitszeiten.offeneArbeitszeiten.find((a) => a.id === arbeitszeitId);

  return (
    <EditSheetContext.Provider value={{ arbeitszeit }}>
      {children}
    </EditSheetContext.Provider>
  );
}

function useEditSheetContext() {
  const editSheetContext = useContext(EditSheetContext);

  if (editSheetContext === undefined) {
    throw new Error(
      'useAuthenticationContext must be used within AuthenticationContextProvider',
    );
  }
  return editSheetContext;
}

// eslint-disable-next-line react-refresh/only-export-components
export { useEditSheetContext, EditSheetContextProvider };
