import { Money } from '@/domain/controlling/entities';
import { formatNumber } from '@/domain/controlling/pages/UmsatzplanungV2/formatNumber';
import { HTMLAttributes } from 'react';

export function MoneyField({
  className,
  value,
  unit,
}: {
  value: Money | undefined;
  unit?: string;
} & HTMLAttributes<HTMLDivElement>) {
  return (
    <div
      className={`flex gap-1 items-baseline text-sm font-semibold whitespace-nowrap text-neutrals-N900 ${className}`}>
      <div>
        {value !== undefined && value !== null
          ? formatNumber(value.amount.toString(), false)
          : '--'}{' '}
      </div>
      <div className="text-neutrals-N700">{unit}</div>
    </div>
  );
}
