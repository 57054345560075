import { Separator } from './Separator';
import { ContentWrapperCell } from './ContentWrapperCell';
import { ZielstundenField } from './GenericFields';
import { useStandort } from '@/hooks';
import { useTagebuchEinstellungen } from '@/domain/controlling/hooks/useTagebuchEinstellungen';

export function ZielstundenCell({
  umsatzZielstunden,
  absatzZielstunden,
}: {
  umsatzZielstunden: number | undefined;
  absatzZielstunden: number | undefined;
}) {
  const { standortId } = useStandort();
  const { umsatzplanung } = useTagebuchEinstellungen(standortId);

  return (
    <ContentWrapperCell>
      {umsatzplanung !== 'ABSATZ' && (
        <ZielstundenField value={umsatzZielstunden} />
      )}
      {umsatzplanung === 'UMSATZ_ABSATZ' && <Separator />}
      {umsatzplanung !== 'UMSATZ' && (
        <ZielstundenField value={absatzZielstunden} />
      )}
    </ContentWrapperCell>
  );
}
