import { Environment } from '../types';

export const isLocalhost = Boolean(
  window.location.hostname === 'localhost' ||
    // [::1] is the IPv6 localhost address.
    window.location.hostname === '[::1]' ||
    // 127.0.0.1/8 is considered localhost for IPv4.
    window.location.hostname.match(
      /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/,
    ),
);

export const isReview = window.location.host.includes('amplifyapp');

export function getEnvironment(): Environment {
  return window.location.hostname === 'app.e2n.de' ||
    window.location.hostname === 'manager.e2n.de'
    ? 'production'
    : window.location.hostname === 'staging.e2n.de' ||
      window.location.hostname === 'manager.staging.e2n.de'
    ? 'staging'
    : window.location.hostname === 'localhost'
    ? 'localhost'
    : 'preview';
}

export const managerV1BaseUrl =
  getEnvironment() === 'production'
    ? import.meta.env.VITE_MANAGER_V1_BASE_URL_PROD
    : getEnvironment() === 'staging'
    ? import.meta.env.VITE_MANAGER_V1_BASE_URL_STAGING
    : import.meta.env.VITE_MANAGER_V1_BASE_URL_DEV;

// export const managerV1BaseUrl =
//   getEnvironment() === 'production'
//     ? import.meta.env.VITE_MANAGER_V1_BASE_URL_PROD
//     : import.meta.env.VITE_MANAGER_V1_BASE_URL_STAGING;

export const managerV2BaseUrl =
  getEnvironment() === 'production'
    ? import.meta.env.VITE_MANAGER_V2_BASE_URL_PROD
    : getEnvironment() === 'staging'
    ? import.meta.env.VITE_MANAGER_V2_BASE_URL_STAGING
    : import.meta.env.VITE_MANAGER_V2_BASE_URL_DEV;

export function getCookieDomain() {
  const env = getEnvironment();
  if (env === 'production') {
    return 'e2n.de';
  }
  if (env === 'staging') {
    return 'staging.e2n.de';
  }
  if (env === 'preview') {
    return window.location.host;
  }
  return 'localhost';
}
