import { Button } from '@/components/ui/button';
import { getMonthNameByDateObject, getWeekdayByDate } from '@/utils';
import {
  faChevronLeft,
  faChevronRight,
} from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import dayjs from 'dayjs';
import { HTMLAttributes } from 'react';
import isoWeek from 'dayjs/plugin/isoWeek';

dayjs.extend(isoWeek);

// eslint-disable-next-line max-lines-per-function
export function Header({
  selectedDate,
  loading,
}: {
  selectedDate: Date;
  loading: boolean;
} & HTMLAttributes<HTMLDivElement>) {
  function getIsPrevDateButtonDisabled() {
    const prevDate = dayjs(selectedDate).subtract(1, 'day');
    return prevDate.get('month') !== dayjs(selectedDate).get('month');
  }

  function getIsNextButtonDisabled() {
    const nextDate = dayjs(selectedDate).add(1, 'day');
    return nextDate.get('month') !== dayjs(selectedDate).get('month');
  }

  return (
    <div className="flex items-center gap-2">
      <div className="flex items-center gap-2">
        <Button
          form="editPlanwerteForm"
          size="icon"
          variant="icon"
          name={`planwertDate#${dayjs(selectedDate)
            .subtract(1, 'day')
            .format('YYYY-MM-DD')}`}
          disabled={getIsPrevDateButtonDisabled() || loading}>
          <FontAwesomeIcon icon={faChevronLeft} />
        </Button>
        <Button
          form="editPlanwerteForm"
          size="icon"
          variant="icon"
          name={`planwertDate#${dayjs(selectedDate)
            .add(1, 'day')
            .format('YYYY-MM-DD')}`}
          disabled={getIsNextButtonDisabled() || loading}>
          <FontAwesomeIcon icon={faChevronRight} />
        </Button>
      </div>
      <div className="font-bold text-neutrals-N900 flex gap-1 items-baseline">
        <span>{getWeekdayByDate(selectedDate).name},</span>
        <span>{dayjs(selectedDate).format('D.')}</span>
        <span>{getMonthNameByDateObject(selectedDate)}</span>
        <span>{dayjs(selectedDate).format('YYYY')}</span>
      </div>
      <div className="text-xs uppercase bg-neutrals-N300 py-[2px] px-1 rounded-full">
        KW {dayjs(selectedDate).isoWeek()}
      </div>
    </div>
  );
}
