import { useTranslation } from 'react-i18next';
import { Separator } from './Separator';
import { ContentWrapperCell } from './ContentWrapperCell';
import { AbsoluteNumberField, MoneyField } from './GenericFields';
import { useStandort } from '@/hooks';
import { useTagebuchEinstellungen } from '@/domain/controlling/hooks/useTagebuchEinstellungen';
import { Money } from '@/domain/controlling/entities';

export function  PrognoseCell({
  absatzPrognose,
  umsatzPrognose,
}: {
  absatzPrognose: number;
  umsatzPrognose: Money;
}) {
  const { t } = useTranslation();
  const { standortId } = useStandort();
  const { umsatzplanung } = useTagebuchEinstellungen(standortId);

  return (
    <ContentWrapperCell>
      {umsatzplanung !== 'ABSATZ' && (
        <MoneyField value={umsatzPrognose} unit={t(`general.currency.EUR`)} />
      )}
      {umsatzplanung === 'UMSATZ_ABSATZ' && <Separator />}
      {umsatzplanung !== 'UMSATZ' && (
        <AbsoluteNumberField
          value={absatzPrognose}
          unit={t('domain.controlling.umsatzplanung.units.stueck')}
        />
      )}
    </ContentWrapperCell>
  );
}
