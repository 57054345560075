import { usePlanwerte } from '@/domain/controlling/hooks';
import { useTagebuchEinstellungen } from '@/domain/controlling/hooks/useTagebuchEinstellungen';
import { useStandort } from '@/hooks';
import { mapPlanwerteToTableRow } from './mapPlanwerteToTableRow';
import { E2Ntable } from '@/components/Table/E2Ntable';
import { E2NTableCell, E2NtableHeader } from '@/components/Table';
import { TableBody, TableHead, TableRow } from '@/components/ui/table';
import {
  AbsatzUmsatzPlanCell,
  DayCell,
  ProduktivitaetCell,
  PrognoseCell,
  ZielstundenCell,
} from './Cells';
import { useTranslation } from 'react-i18next';

// eslint-disable-next-line max-lines-per-function
export function DataTable({ year, month }: { year: number; month: number }) {
  const { standortId } = useStandort();
  const { absatzPlanwerte, umsatzPlanwerte } = usePlanwerte({
    jahrMonat: { jahr: year, monat: month },
    standortId,
  });
  const { tagebuchEinstellungen } = useTagebuchEinstellungen(standortId);
  const { t } = useTranslation();

  const data = mapPlanwerteToTableRow({
    umsatzPlanwerte,
    absatzPlanwerte,
    jahr: year,
    monat: month,
    absatzVorgabe: tagebuchEinstellungen.absatzproduktivitaet.absatzVorgabe,
  });

  return (
    <E2Ntable>
      <E2NtableHeader className="[&_tr]:hover:bg-transparent">
        <TableRow>
          <TableHead className="ps-4 text-xs normal-case font-semibold h-0">
            {t('domain.controlling.umsatzplanung.table.tag')}
          </TableHead>
          <TableHead className="px-0 text-xs normal-case font-semibold h-0">
            {t('domain.controlling.umsatzplanung.table.plan')}
          </TableHead>
          <TableHead className="px-0 text-xs normal-case font-semibold h-0">
            {t('domain.controlling.umsatzplanung.table.prognose2')}
          </TableHead>
          <TableHead className="px-0 text-xs normal-case font-semibold h-0">
            {t('domain.controlling.umsatzplanung.table.produktivitaet')}
          </TableHead>
          <TableHead className="px-0 text-xs normal-case font-semibold h-0">
            {t('domain.controlling.umsatzplanung.table.zielstunden')}
          </TableHead>
        </TableRow>
      </E2NtableHeader>
      <TableBody>
        {data.map((planwert) => {
          return (
            <TableRow
              key={planwert.date.date}
              className="hover:bg-transparent [&_td]:bg-white p-0">
              <E2NTableCell
                className="rounded-l-lg w-4 align-top ps-4 py-0"
                isBorderVisible={false}>
                <DayCell date={planwert.date} />
              </E2NTableCell>
              <E2NTableCell className="w-40 align-top p-0">
                <AbsatzUmsatzPlanCell
                  absatzPlanWert={planwert.absatzWert}
                  umsatzPlanWert={planwert.umsatzWert}
                  absatzPrognoseWert={planwert.absatzPrognose}
                  umsatzPrognoseWert={planwert.umsatzPrognose}
                />
              </E2NTableCell>
              <E2NTableCell className="w-40 p-0">
                <PrognoseCell
                  absatzPrognose={planwert.absatzPrognose}
                  umsatzPrognose={planwert.umsatzPrognose}
                />
              </E2NTableCell>
              <E2NTableCell className="w-40 p-0">
                <ProduktivitaetCell
                  absatzZielproduktivitaet={planwert.absatzZielproduktivitaet}
                  umsatzZielproduktivitaet={planwert.umsatzZielproduktivitaet}
                />
              </E2NTableCell>
              <E2NTableCell className="rounded-r-lg w-40 ps-0 pe-4 py-0">
                <ZielstundenCell
                  absatzZielstunden={planwert.absatzZielstunden}
                  umsatzZielstunden={planwert.umsatzZielstunden}
                />
              </E2NTableCell>
            </TableRow>
          );
        })}
      </TableBody>
    </E2Ntable>
  );
}
