import {
  CreateOrUpdateUmsaatzzielMutation,
  CreateOrUpdateUmsaatzzielMutationVariables,
} from '@/gql/graphql';
import { useMutation } from '@apollo/client';
import { useCallback } from 'react';
import { CREATE_OR_UPDATE_UMSATZZIEL } from './graphql/mutations';

export function useUmsatzzielCreateOrUpdate() {
  const [execute, result] = useMutation<
    CreateOrUpdateUmsaatzzielMutation,
    CreateOrUpdateUmsaatzzielMutationVariables
  >(CREATE_OR_UPDATE_UMSATZZIEL, {
    refetchQueries: ['getUmsatzAbsatzZieleByStandortId'],
  });

  const createOrUpdateUmsatzziel = useCallback(
    (variables: CreateOrUpdateUmsaatzzielMutationVariables) => {
      return execute({ variables });
    },
    [execute],
  );

  return [createOrUpdateUmsatzziel, result] as const;
}
