import { Suspense } from 'react';
import { DataTable, DataTableSkeleton } from './DataTable';

export function TableWrapper({ year, month }: { year: number; month: number }) {
  return (
    <Suspense fallback={<DataTableSkeleton />}>
      <DataTable year={year} month={month} />
    </Suspense>
  );
}
