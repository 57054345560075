import { useSuspenseQuery } from '@apollo/client';
import { GET_MANAGER } from './graphql/queries.ts';
import { Manager, ManagerError, Managerrechte } from '../gql/graphql.ts';
import { handleGraphQLErrors } from '../utils/errors/handleGraphQLErrors.ts';

export class ManagerProfile {
  constructor(
    readonly vorname: string,
    readonly nachname: string,
    readonly email: string,
    readonly rechte: Managerrechte,
  ) {}

  hasAbrechnungsRecht(): boolean {
    const keys = Object.keys(this.rechte.abrechnung);
    let hasRechte = false;
    keys.forEach((key) => (hasRechte = this.rechte.abrechnung[key]));

    return hasRechte;
  }

  hasAuswertungsRecht(): boolean {
    const keys = Object.keys(this.rechte.auswertung);
    let hasRechte = false;
    keys.forEach((key) => (hasRechte = this.rechte.auswertung[key]));

    return hasRechte;
  }

  hasControllingRecht(): boolean {
    return this.hasAuswertungsRecht() || this.rechte.tagebuch;
  }

  hasDienstplanReadOnlyRecht(): boolean {
    return (
      this.rechte.dienstplan.erlaubt &&
      !this.rechte.dienstplan.schichtvorlagen &&
      !this.rechte.dienstplan.bearbeiten &&
      !this.rechte.dienstplan.freigabe &&
      !this.rechte.dienstplan.mitarbeiterEinteilen
    );
  }

  hasUnrestrictedMitarbeiterRechte(): boolean {
    return (
      this.rechte.mitarbeiter.erlaubt && !this.rechte.mitarbeiter.eingeschraenkt
    );
  }

  // Würde ich mal disablen, da ich grad nicht sehe wie wir das vereinfachen können
  // eslint-disable-next-line complexity
  hasEinstellungenRecht(): boolean {
    return (
      this.rechte.einstellungen &&
      (this.rechte.tagebuch ||
        (this.rechte.mitarbeiter.erlaubt &&
          !this.rechte.mitarbeiter.eingeschraenkt) ||
        this.rechte.arbeitszeit.erlaubt ||
        this.rechte.kalender ||
        this.hasAbrechnungsRecht() ||
        (this.rechte.dienstplan.erlaubt && !this.hasDienstplanReadOnlyRecht()))
    );
  }

  isPersonalAuswerter(): boolean {
    return (
      !this.rechte.auswertung.dienstplan && this.rechte.auswertung.personal
    );
  }

  isArbeitszeitAuswerter(): boolean {
    return (
      !this.rechte.auswertung.dienstplan &&
      !this.rechte.auswertung.personal &&
      this.rechte.auswertung.arbeitszeit
    );
  }

  isDienstplanAdmin(): boolean {
    return this.rechte.dienstplan.erlaubt && !this.hasDienstplanReadOnlyRecht();
  }
}

export function useProfile(): { manager?: ManagerProfile; error?: string } {
  const { data } = useSuspenseQuery(GET_MANAGER);

  const errors = handleGraphQLErrors<Pick<Manager, '__typename'>, ManagerError>(
    data.manager,
  );

  if (!errors) {
    const manager = data.manager as Manager;
    return {
      manager: new ManagerProfile(
        manager.vorname,
        manager.nachname,
        manager.email,
        manager.managerrechte,
      ),
    };
  } else {
    return { error: errors.message };
  }
}
