import { Badge } from '@/components/ui/badge';
import { faCheck, faWarning } from '@fortawesome/pro-light-svg-icons';
import { useTranslation } from 'react-i18next';
import { useCallback, useEffect, useState } from 'react';
import { TOTPSetup } from './TOTPSetup';
import { fetchMFAPreference } from 'aws-amplify/auth';
import {
  getSessionTokens,
  isUserLoggedInWithMicrosoft,
} from '../../../services';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as Sentry from '@sentry/react';

// eslint-disable-next-line max-lines-per-function
export function TwoFactorAuthentification() {
  const { t } = useTranslation();
  const [isMFAEnabled, setIsMFAEnabled] = useState<boolean>(false);
  const [isFormOpen, setIsFormOpen] = useState(false);
  const [isSSOUser, setIsSSOUser] = useState(false);

  useEffect(() => {
    getSessionTokens().then((tokens) => {
      const idTokenPayload = tokens.idToken.payload;
      if (
        idTokenPayload.identities &&
        isUserLoggedInWithMicrosoft(idTokenPayload.identities)
      )
        setIsSSOUser(true);
    });
  }, []);

  useEffect(() => {
    fetchMFAPreference()
      .then((value) => {
        if (!value.enabled) {
          setIsMFAEnabled(false);
        } else {
          setIsMFAEnabled(true);
        }
      })
      .catch((error) => Sentry.captureException(error));
  }, [setIsMFAEnabled]);

  const handleOpenFormChange = useCallback(
    (value: boolean) => setIsFormOpen(value),
    [],
  );

  return (
    <div className="grid md:grid-cols-6 grid-cols-1 gap-6 py-12">
      <div className="flex flex-col gap-2 md:col-span-2 col-span-full">
        <div className="flex gap-2">
          <h2 className="text-lg font-semibold leading-7 text-neutrals-N900">
            {t('pages.profile.sections.security.title')}
          </h2>
          {!isMFAEnabled ? (
            <Badge
              className="bg-primary-orange-O600/30 hover:bg-primary-orange-O600/30 text-primary-orange-O800 hover:text-primary-orange-O800 transition-colors duration-300 ease-in-out"
              data-testid="pages-profile-security-preferred-mfa-inactive">
              <FontAwesomeIcon icon={faWarning} className="mr-1" />
              {t('pages.profile.sections.security.preferredMfaInactive')}
            </Badge>
          ) : (
            <Badge
              className="bg-primary-green-G200  text-primary-green-G700 transition-colors duration-300 ease-in-out"
              data-testid="pages-profile-security-preferred-mfa-active">
              <FontAwesomeIcon icon={faCheck} className="mr-1" />
              {t('pages.profile.sections.security.preferredMfaActive')}
            </Badge>
          )}
        </div>
        <p className="text-sm leading-6 text-neutrals-N900">
          {t('pages.profile.sections.security.subtitle')}
        </p>
      </div>
      <div className="md:col-start-3 col-span-full 2xl:col-span-2 xl:col-span-3 content-center flex flex-col gap-16">
        {isSSOUser ? (
          <p className="text-sm leading-6 text-neutrals-N900">
            {t('pages.profile.sections.security.isSSOUserInfoText')}
          </p>
        ) : (
          <TOTPSetup
            isMFAEnabled={isMFAEnabled}
            setIsMFAEnabled={setIsMFAEnabled}
            isFormOpen={isFormOpen}
            handleOpenFormChange={handleOpenFormChange}
          />
        )}
      </div>
    </div>
  );
}
