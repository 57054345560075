import { useSuspenseQuery } from '@apollo/client';
import { GET_ABTEILUNGEN_BY_STANDORTID } from './graphql/queries';
import { handleGraphQLErrors } from '../../../utils/errors/handleGraphQLErrors';
import { AbteilungError, AbteilungByStandortId } from '../../../gql/graphql';
import { Abteilung } from '../entities/Arbeitszeit';
import * as Sentry from '@sentry/react';

export function useAbteilungen(standortId: string): {
  abteilungen?: Abteilung[];
  error?: string;
} {
  const { data } = useSuspenseQuery(GET_ABTEILUNGEN_BY_STANDORTID, {
    variables: { standortId },
    fetchPolicy: 'cache-first',
  });

  const error = handleGraphQLErrors<AbteilungByStandortId, AbteilungError>(
    data.abteilungenByStandortId,
  );
  if (error) {
    Sentry.captureException(error);
    return { error: error.message };
  }

  const currentAbteilungen =
    data.abteilungenByStandortId as AbteilungByStandortId;

  const abteilungen = [...currentAbteilungen.abteilung];

  return {
    abteilungen: abteilungen.map((abteilung) => {
      return new Abteilung(abteilung.name, abteilung.id, abteilung.markierung);
    }),
  };
}
