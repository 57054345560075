import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getSortingIcon } from '../../util';
import { faBarsSort } from '@fortawesome/pro-light-svg-icons';

export const SortableHeader = ({
  title,
  sorted,
}: {
  title: string;
  sorted: 'asc' | 'desc' | false;
}) => (
  <div className="flex flex-row gap-2 items-center cursor-pointer">
    <span>{title ? title : <FontAwesomeIcon icon={faBarsSort} />}</span>
    {getSortingIcon(sorted)}
  </div>
);
