import { BeginnEnde } from './BeginnEnde';
import { getDateTimeFromPuenktlichkeit } from '../../../../../../utils';
import { useArbeitszeitUpdate } from '../../../../hooks';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Pausen } from './Pausen/Pausen';
import { handleOnCompleted } from '../../handleOnCompleted';
import { useManagerCanEditArbeitszeiten } from '../../../../hooks/useManagerCanEditArbeitszeit';
import { Bemerkung } from './Bemerkung/Bemerkung';
import { useNavigate, useParams } from 'react-router-dom';
import { DeleteArbeitszeit } from './DeleteArbeitszeit';
import { useEditSheetContext } from '../../EditSheetContext';
import { toast } from '@/hooks/use-toast';

// eslint-disable-next-line max-lines-per-function, complexity
export function Arbeitszeit() {
  const { arbeitszeit } = useEditSheetContext();
  const [arbeitszeitUpdate] = useArbeitszeitUpdate();

  const [isUpdating, setIsUpdating] = useState(false);
  const canEditArbeitszeit = useManagerCanEditArbeitszeiten();
  const navigate = useNavigate();
  const { year, month, day } = useParams();

  const { t } = useTranslation();

  async function onChangeArbeitszeit(beginn: string, ende: string) {
    if (beginn.length === 0) {
      toast({
        title: t('domain.arbeitszeiten.sheet.arbeitszeit.edit.fehler'),
      });
      return;
    }
    if (
      beginn ===
        `${arbeitszeit.start
          ?.getHours()
          .toString()
          .padStart(2, '0')}:${arbeitszeit.start
          ?.getMinutes()
          .toString()
          .padStart(2, '0')}` &&
      ende ===
        `${arbeitszeit.ende
          ?.getHours()
          .toString()
          .padStart(2, '0')}:${arbeitszeit.ende
          ?.getMinutes()
          .toString()
          .padStart(2, '0')}`
    ) {
      return;
    }
    setIsUpdating(true);
    arbeitszeitUpdate({
      arbeitszeitId: arbeitszeit.id,
      arbeitszeitUpdateInput: {
        beginn: beginn,
        ende: ende === '' ? null : ende,
      },
    })
      .then((result) => {
        handleOnCompleted(result.data, t);
        setIsUpdating(false);
      })
      .catch(() => {
        toast({
          title: t('domain.arbeitszeiten.sheet.arbeitszeit.edit.fehler'),
        });
        setIsUpdating(false);
      });
  }

  const beginnNachPlan =
    (arbeitszeit.puenktlichkeit &&
      arbeitszeit.puenktlichkeit.abweichungBeginn) ||
    arbeitszeit.puenktlichkeit.abweichungBeginn === 0
      ? getDateTimeFromPuenktlichkeit(
          arbeitszeit.start,
          arbeitszeit.puenktlichkeit.abweichungBeginn,
        )
      : null;
  const endeNachPlan =
    (arbeitszeit.puenktlichkeit && arbeitszeit.puenktlichkeit.abweichungEnde) ||
    arbeitszeit.puenktlichkeit.abweichungEnde === 0
      ? getDateTimeFromPuenktlichkeit(
          arbeitszeit.ende,
          arbeitszeit.puenktlichkeit.abweichungEnde,
        )
      : null;

  function handleDelete() {
    navigate(`/arbeitszeiten/uebersicht/${year}/${month}/${day}`);
  }

  return (
    <div className="flex flex-col gap-4 min-h-fit">
      <BeginnEnde
        beginn={arbeitszeit.start}
        ende={arbeitszeit.ende}
        kommen={arbeitszeit.kommen}
        gehen={arbeitszeit.gehen}
        beginnNachPlan={beginnNachPlan}
        endeNachPlan={endeNachPlan}
        onChange={(e) => onChangeArbeitszeit(e.beginn, e.ende)}
        disabled={isUpdating || !canEditArbeitszeit}
      />

      {arbeitszeit.abwesenheit === null && <Pausen arbeitszeit={arbeitszeit} />}

      <Bemerkung arbeitszeit={arbeitszeit} />

      {canEditArbeitszeit && (
        <div className="mt-auto text-end">
          <DeleteArbeitszeit
            arbeitszeit={arbeitszeit}
            onDeleted={handleDelete}
            isDeleting={false}
          />
        </div>
      )}
    </div>
  );
}
