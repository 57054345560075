import { t } from 'i18next';
import { getHoursAndMinutesFromMinutes } from '@/utils';
import { useStandort } from '@/hooks';
import { useUmsatzplanung } from '@/domain/controlling/hooks/useUmsatzplanung';
import { useParams } from 'react-router-dom';
import { LabeledField } from './LabeledField';
import { LabeledTimeField } from './LabeledTimeField';
import { formatNumber } from '../../formatNumber';
import { ZieleError } from './ZieleError';

export function UmsatzZiele() {
  const { year, month } = useParams();
  const jahr = parseInt(year);
  const monat = parseInt(month);

  const standortId = useStandort();
  const { umsatzZiel, error } = useUmsatzplanung(
    standortId.standortId,
    jahr,
    monat,
  );

  return !error ? (
    <div className="flex flex-row px-5 py-4 justify-between">
      <LabeledField
        className="w-1/3"
        value={
          umsatzZiel.wert
            ? formatNumber(umsatzZiel.wert.amount.toString(), false)
            : undefined
        }
        currency={t('general.currency.EUR')}
        label={t('domain.controlling.umsatzplanung.ziele.umsatz')}
      />
      <LabeledField
        className="w-1/3"
        value={
          umsatzZiel.produktivitaet
            ? formatNumber(umsatzZiel.produktivitaet.amount.toString(), false)
            : undefined
        }
        currency={t('domain.controlling.umsatzplanung.units.€/Std.')}
        label={t('domain.controlling.umsatzplanung.ziele.produktivitaet')}
      />
      <LabeledTimeField
        className="w-1/3 border-l-[1px] border-neutrals-N300 pl-4"
        label={t('domain.controlling.umsatzplanung.ziele.zielstunden')}
        hours={getHoursAndMinutesFromMinutes(umsatzZiel.stunden).hours}
        minutes={getHoursAndMinutesFromMinutes(umsatzZiel.stunden).minutes}
      />
    </div>
  ) : (
    <div className="px-5 py-4">
      <ZieleError />
    </div>
  );
}
