import { Button } from '@/components/ui/button';
import {
  AbsatzPlan,
  AbsatzVorgabe,
  UmsatzPlan,
} from '@/domain/controlling/entities';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import dayjs from 'dayjs';
import { faCircle } from '@fortawesome/pro-regular-svg-icons';
import {
  faCircle as faCircleSolid,
  faCheck,
} from '@fortawesome/pro-solid-svg-icons';
import { isPlanwertValid } from '../helpers';

// eslint-disable-next-line max-lines-per-function
export function Day({
  currentDate,
  selectedDate,
  currentAbsatzPlanwert,
  currentUmsatzPlanwert,
  absatzVorgabe,
  loading,
}: {
  currentDate: Date;
  selectedDate: Date;
  currentUmsatzPlanwert: UmsatzPlan;
  currentAbsatzPlanwert: AbsatzPlan;
  absatzVorgabe: AbsatzVorgabe;
  loading: boolean;
}) {
  const isDateEqualToSelectedDate = dayjs(currentDate).isSame(
    selectedDate,
    'date',
  );

  return (
    <div className="flex flex-col gap-1 justify-center items-center">
      <Button
        disabled={loading}
        variant="ghost"
        className={`h-9 w-9 font-normal hover:font-semibold ${
          isDateEqualToSelectedDate
            ? 'bg-primary-blue-B600 text-white hover:bg-primary-blue-B600'
            : ''
        }`}
        name={`planwertDate#${dayjs(currentDate).format('YYYY-MM-DD')}`}
        form="editPlanwerteForm">
        {currentDate.getDate()}
      </Button>
      {isPlanwertValid(
        currentUmsatzPlanwert,
        currentAbsatzPlanwert,
        absatzVorgabe,
      ) ? (
        <div className="fa-layers fa-fw">
          <FontAwesomeIcon
            icon={faCircleSolid}
            className="text-neutrals-N200"
          />
          <FontAwesomeIcon
            icon={faCheck}
            className="text-primary-green-G500"
            transform="shrink-6"
          />
        </div>
      ) : (
        <FontAwesomeIcon icon={faCircle} className="text-neutrals-N300" />
      )}
    </div>
  );
}
