/* eslint-disable max-lines-per-function */
import { E2NtableHeader } from '@/components/Table';
import { Table, TableBody, TableHead, TableRow } from '@/components/ui/table';
import { AbsatzPlan, UmsatzPlan } from '@/domain/controlling/entities';
import { UseFormReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useTagebuchEinstellungen } from '@/domain/controlling/hooks/useTagebuchEinstellungen';
import { useStandort } from '@/hooks';
import { UmsatzPlanWerteTableRow } from './UmsatzPlanWerteTableRow';
import { AbsatzPlanWerteTableRow } from './AbsatzPlanWerteTableRow';

export function EditTable({
  absatzPlanwert,
  umsatzPlanwert,
  form,
}: {
  absatzPlanwert?: AbsatzPlan;
  umsatzPlanwert?: UmsatzPlan;
  form: UseFormReturn<
    {
      absatzPlan?: string;
      absatzProduktivitaet?: string;
      umsatzPlan?: string;
      umsatzProduktivitaet?: string;
    },
    undefined
  >;
}) {
  const { t } = useTranslation();
  const { standortId } = useStandort();
  const { tagebuchEinstellungen } = useTagebuchEinstellungen(standortId);

  return (
    <Table>
      <E2NtableHeader className="[&_tr]:hover:bg-transparent">
        <TableRow>
          <TableHead className="text-xs normal-case font-semibold h-0 py-4 ps-0">
            {t('domain.controlling.umsatzplanung.table.plan')}
          </TableHead>
          <TableHead className="text-xs normal-case font-semibold h-0 py-4 ps-4">
            {t('domain.controlling.umsatzplanung.table.prognose2')}
          </TableHead>
          <TableHead className="text-xs normal-case font-semibold h-0 py-4 ps-4">
            {t('domain.controlling.umsatzplanung.table.produktivitaet')}
          </TableHead>
          <TableHead className="text-xs normal-case font-semibold h-0 py-4 ps-4">
            {t('domain.controlling.umsatzplanung.table.zielstunden')}
          </TableHead>
        </TableRow>
      </E2NtableHeader>
      <TableBody>
        <UmsatzPlanWerteTableRow
          control={form.control}
          umsatzPlanwert={umsatzPlanwert}
        />
        <AbsatzPlanWerteTableRow
          control={form.control}
          absatzPlanwert={absatzPlanwert}
          absatzVorgabe={
            tagebuchEinstellungen.absatzproduktivitaet.absatzVorgabe
          }
        />
      </TableBody>
    </Table>
  );
}
