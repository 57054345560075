/* eslint-disable max-lines-per-function */
/* eslint-disable complexity */
import { faCaretDown, faCaretUp } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  AbsoluteNumberField,
  MoneyField,
  ZielstundenField,
} from './GenericFields';
import { Currency, Money } from '@/domain/controlling/entities';
import { faCheck } from '@fortawesome/pro-regular-svg-icons';
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '@/components/ui/tooltip';

export function Differenz({
  planWert = 0,
  prognoseWert,
  type,
  unit,
  tooltipMessage,
}: {
  planWert?: number;
  prognoseWert: number;
  type: 'absatz' | 'umsatz' | 'stunden';
  unit: string;
  tooltipMessage: string;
}) {
  let abweichung = 0;
  let abweichungNegative = false;
  abweichung = planWert - prognoseWert;
  abweichungNegative = abweichung <= 0;
  // Es kann passieren, dass die Abweichung z.B. 1,77777778 beträgt
  // Um das korrekt anzuzeigen, wird hier die Abweichung auf 2 Nachkommastellen gerundet
  abweichung = Math.round((abweichung + Number.EPSILON) * 100) / 100;
  // Hiermit stellen wir sicher, dass die Abweichung nicht negativ dargestellt wird
  abweichung = Math.abs(abweichung);

  const prozentualeAbweichung = (abweichung * 100) / planWert;

  const colorWithSelektor =
    prozentualeAbweichung <= 5
      ? '[&>div]:text-primary-green-G500'
      : prozentualeAbweichung > 5 && prozentualeAbweichung <= 10
      ? '[&>div]:text-primary-orange-O500'
      : '[&>div]:text-primary-red-R500';

  const color =
    prozentualeAbweichung <= 5
      ? 'text-primary-green-G500'
      : prozentualeAbweichung > 5 && prozentualeAbweichung <= 10
      ? 'text-primary-orange-O500'
      : 'text-primary-red-R500';

  return (
    <TooltipProvider>
      <Tooltip>
        <TooltipContent>{tooltipMessage}</TooltipContent>
        <TooltipTrigger>
          <div className="flex flex-row gap-1 items-center font-semibold">
            <FontAwesomeIcon
              icon={
                abweichung === 0 && prozentualeAbweichung <= 5
                  ? faCheck
                  : abweichungNegative
                  ? faCaretDown
                  : faCaretUp
              }
              className={color}
            />
            {type === 'absatz' ? (
              <AbsoluteNumberField
                value={abweichung}
                unit={unit}
                className={colorWithSelektor}
              />
            ) : type === 'umsatz' ? (
              <MoneyField
                value={new Money(abweichung, Currency.EUR)}
                unit={unit}
                className={colorWithSelektor}
              />
            ) : (
              <ZielstundenField
                value={abweichung}
                className={colorWithSelektor}
              />
            )}
          </div>
        </TooltipTrigger>
      </Tooltip>
    </TooltipProvider>
  );
}
