import { Dropdown2 } from '@e2n/e2n-ui';
import { useDropdownMenuItems } from './useDropdownMenuItems';
import { useProfile } from '../../../hooks';
import { Avatar, AvatarFallback } from '@/components/ui/avatar';

function ProfileAvatar() {
  const { manager } = useProfile();
  const initials =
    manager.vorname[0].toUpperCase() + manager.nachname[0].toUpperCase();

  return (
    <Avatar>
      <AvatarFallback data-testid="navigation-avatar">
        {initials}
      </AvatarFallback>
    </Avatar>
  );
}

export function ProfileItem() {
  const dropdownMenuItems = useDropdownMenuItems();

  return (
    <Dropdown2 trigger={<ProfileAvatar />}>
      <div className="min-w-[200px]">{...dropdownMenuItems}</div>
    </Dropdown2>
  );
}
