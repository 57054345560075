import { Header } from './Header';
import { EditSheetContextProvider } from './EditSheetContext';
import {
  AntraegeTrigger,
  ArbeitszeitTrigger,
  DienstgangTrigger,
  HistorieTrigger,
  RechtshinweiseTrigger,
  ZuschlaegeTrigger,
} from './trigger';
import {
  Navigate,
  Outlet,
  useLocation,
  useNavigate,
  useParams,
} from 'react-router-dom';
import { ErrorBoundary } from '@/components';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs';

function getTabKeyFromPathname(pathname: string) {
  const tabKey = pathname.split('/').pop();
  const availableTabs = [
    'arbeitszeit',
    'hinweise',
    'konflikte',
    'dienstgaenge',
    'zuschlaege',
    'historie',
  ];
  return tabKey && availableTabs.includes(tabKey) ? tabKey : 'arbeitszeit';
}

// eslint-disable-next-line max-lines-per-function
export function EditSheet() {
  const { day, month, year, arbeitszeitId } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const defaultTabValue = getTabKeyFromPathname(location.pathname);

  function handleTabClick(tabKey: string) {
    navigate(
      `/arbeitszeiten/uebersicht/${year}/${month}/${day}/sheet/edit/${arbeitszeitId}/${tabKey}`,
    );
  }

  return (
    <ErrorBoundary
      FallbackComponent={() => (
        <Navigate to={`/arbeitszeiten/uebersicht/${year}/${month}/${day}`} />
      )}>
      <EditSheetContextProvider>
        <Header />
        <Tabs
          defaultValue={defaultTabValue}
          className="p-4 flex flex-col gap-4">
          <TabsList className="flex justify-between overflow-auto">
            <TabsTrigger
              value="arbeitszeit"
              onClick={() => handleTabClick('arbeitszeit')}>
              <ArbeitszeitTrigger />
            </TabsTrigger>
            <TabsTrigger
              onClick={() => handleTabClick('hinweise')}
              value="hinweise">
              <RechtshinweiseTrigger />
            </TabsTrigger>
            <TabsTrigger
              value="antraege"
              onClick={() => handleTabClick('antraege')}>
              <AntraegeTrigger />
            </TabsTrigger>
            <TabsTrigger
              value="dienstgaenge"
              onClick={() => handleTabClick('dienstgaenge')}>
              <DienstgangTrigger />
            </TabsTrigger>
            <TabsTrigger
              value="zuschlaege"
              onClick={() => handleTabClick('zuschlaege')}>
              <ZuschlaegeTrigger />
            </TabsTrigger>
            <TabsTrigger
              value="historie"
              onClick={() => handleTabClick('historie')}>
              <HistorieTrigger />
            </TabsTrigger>
          </TabsList>
          <TabsContent value={defaultTabValue}>
            <Outlet />
          </TabsContent>
        </Tabs>
      </EditSheetContextProvider>
    </ErrorBoundary>
  );
}
