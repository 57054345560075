import { useParams } from 'react-router-dom';
import { useTermine } from '../../../hooks/useTermine';
import { Loading } from './Loading';
import { TermineTooltip } from './TermineTooltip';
import { Suspense } from 'react';
import { useStandortContext } from '../../../../../store/standortContext';

export function Termine() {
  const { year, month, day } = useParams();
  const standort = useStandortContext((state) => state.currentStandort);
  const date = new Date(`${year}-${month}-${day}`);
  const termine = useTermine(standort.id, date);

  return (
    <Suspense fallback={<Loading />}>
      <TermineTooltip termine={termine} />
    </Suspense>
  );
}
