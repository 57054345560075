import { Arbeitszeit, Pause } from '../../../../../../../entities';
import { useArbeitszeitUpdate } from '../../../../../../../hooks';
import { handleOnCompleted } from '../../../../../handleOnCompleted';
import { useTranslation } from 'react-i18next';
import {
  ArbeitszeitenPausenInput,
  ArbeitszeitPauseneinstellung,
  Pausenart,
} from '../../../../../../../../../gql/graphql';
import { mapPauseToInput } from '../../Forms/mapPauseToInputType';
import { useProfile } from '../../../../../../../../../hooks';
import { useEffect, useState } from 'react';
import { Switch } from '@/components/ui/switch';
import { TableCell } from '@/components/ui/table';
import { toast } from '@/hooks/use-toast';
import * as Sentry from '@sentry/react';

// eslint-disable-next-line max-lines-per-function
export function BezahltCell({
  pause,
  arbeitszeit,
}: {
  pause: Pause;
  arbeitszeit: Arbeitszeit;
}) {
  const { id: pauseId, bezahlt, pausenart } = pause;
  const [isBezahlt, setIsBezahlt] = useState(bezahlt);
  const [arbeitszeitUpdate] = useArbeitszeitUpdate();
  const [isUpdating, setIsUpdating] = useState(false);
  const { manager } = useProfile();
  const currentPause = arbeitszeit.pausen.find((pause) => pause.id === pauseId);
  const { t } = useTranslation();

  useEffect(() => {
    setIsBezahlt(pause.bezahlt);
  }, [pause]);

  function handleOnCheckedChange(checked) {
    setIsBezahlt(checked);
    onChangeIsBezahlt(checked);
  }

  async function onChangeIsBezahlt(isBezahlt: boolean) {
    setIsUpdating(true);

    const newPause: ArbeitszeitenPausenInput = {
      ...mapPauseToInput(currentPause),
      bezahlt: isBezahlt,
    };

    const newPausen = arbeitszeit.pausen.map((pause) => {
      if (pause.id === pauseId) {
        return newPause;
      }
      return mapPauseToInput(pause);
    });

    arbeitszeitUpdate({
      arbeitszeitUpdateInput: {
        pausen: newPausen,
      },
      arbeitszeitId: arbeitszeit.id,
    })
      .then((result) => {
        handleOnCompleted(result.data, t);
        setIsUpdating(false);
      })
      .catch((e) => {
        setIsUpdating(false);
        toast({
          title: t(
            'domain.arbeitszeiten.sheet.header.arbeitszeitUpdateUnknownError',
          ),
        });
        Sentry.captureException(e);
      });
  }

  return (
    <TableCell className="font-medium">
      <Switch
        size="sm"
        id="pauseIsBezahlt"
        checked={isBezahlt}
        onCheckedChange={handleOnCheckedChange}
        disabled={
          isUpdating ||
          !manager.rechte.arbeitszeit.bearbeiten ||
          (arbeitszeit.pauseneinstellung ===
            ArbeitszeitPauseneinstellung.Gesetzlich &&
            pausenart === Pausenart.Gesetzlich)
        }
      />
    </TableCell>
  );
}
