import { MoneyInput } from '@/components';
import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
} from '@/components/ui/form';
import { UseFormReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

// eslint-disable-next-line max-lines-per-function
export function UmsatzInputFields({
  form,
}: {
  form: UseFormReturn<
    {
      umsatz?: string;
      umsatzProduktivitaet?: string;
      absatz?: string;
      absatzProduktivitaet?: string;
    },
    unknown,
    undefined
  >;
}) {
  const { t } = useTranslation();
  return (
    <>
      <FormField
        control={form.control}
        name="umsatz"
        render={({ field }) => (
          <FormItem>
            <FormLabel>
              {t('domain.controlling.umsatzplanung.ziele.dialog.umsatz.label')}
            </FormLabel>
            <FormControl>
              <MoneyInput
                defaultValue={field.value}
                isIconVisible={false}
                onBlur={(e) => {
                  field.onChange(e.target.value);
                }}
                onInput={(e) => {
                  field.onChange(e.currentTarget.value);
                }}
                unit="€"
              />
            </FormControl>
          </FormItem>
        )}
      />
      <FormField
        control={form.control}
        name="umsatzProduktivitaet"
        render={({ field }) => (
          <FormItem>
            <FormLabel>
              {t(
                'domain.controlling.umsatzplanung.ziele.dialog.produktivitaet.label',
              )}
            </FormLabel>
            <FormControl>
              <MoneyInput
                defaultValue={field.value}
                isIconVisible={false}
                onBlur={(e) => {
                  field.onChange(e.target.value);
                }}
                onInput={(e) => {
                  field.onChange(e.currentTarget.value);
                }}
                unit={t('domain.controlling.umsatzplanung.ziele.€/Std.')}
              />
            </FormControl>
          </FormItem>
        )}
      />
    </>
  );
}
