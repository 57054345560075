import { CSSProperties } from 'react';
import { faCircle } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

type StyleProps = CSSProperties & {
  '--dot-color': CSSProperties['color'];
};

export function Markierung({ color }: { color: string }) {
  return (
    <FontAwesomeIcon
      icon={faCircle}
      size="xs"
      className={`text-[var(--dot-color)]`}
      style={
        {
          '--dot-color': color,
        } as StyleProps
      }
    />
  );
}
