import { getHoursAndMinutesFromMinutes } from '@/utils';
import { HTMLAttributes } from 'react';
import { useTranslation } from 'react-i18next';

export function ZielstundenField({
  className,
  value,
}: {
  value: number | undefined;
} & HTMLAttributes<HTMLDivElement>) {
  const { t } = useTranslation();
  const timeValue = value
    ? getHoursAndMinutesFromMinutes(value)
    : { minutes: 0, hours: 0 };

  return (
    <div
      className={`flex gap-1 items-baseline text-sm font-semibold whitespace-nowrap ${className}`}>
      <div className="text-neutrals-N900">{timeValue.hours}</div>
      <div className="text-neutrals-N700">{t('general.date.hours')}</div>
      <div className="text-neutrals-N900">{timeValue.minutes}</div>
      <div className="text-neutrals-N700">{t('general.date.minutes')}</div>
    </div>
  );
}
