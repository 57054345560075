import { t } from 'i18next';
import { Dispatch, SetStateAction, useState } from 'react';
import { Arbeitszeit } from '../../../../../entities';
import { useArbeitszeitUpdate } from '../../../../../hooks';
import { useForm } from 'react-hook-form';
import { mapPauseToInput } from '../../Arbeitszeit/Pausen/Forms/mapPauseToInputType';
import { Pausenart } from '../../../../../../../gql/graphql';
import { handleOnCompleted } from '../../../handleOnCompleted';
import { TimeInput } from '../../../../../../../components';
import { Card } from './Card';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { Button } from '@/components/ui/button';
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
} from '@/components/ui/form';
import { toast } from '@/hooks/use-toast';
import * as Sentry from '@sentry/react';

type CreateDienstgangInput = {
  beginn: string;
  ende: string;
};

const FormSchema = z
  .object({
    beginn: z.string().min(1, {
      message: 'Beginn darf nicht leer sein',
    }),
    ende: z.string().min(1, {
      message: 'Ende darf nicht leer sein',
    }),
  })
  .refine(
    (data) =>
      (data.beginn === '' && data.beginn === '') || data.beginn !== data.ende,
    {
      message: 'Beginn und Ende dürfen nicht gleich sein.',
      path: ['beginn'],
    },
  );

// eslint-disable-next-line max-lines-per-function
export function CreateForm({
  setIsCreateFormVisible,
  arbeitszeit,
}: {
  setIsCreateFormVisible: Dispatch<SetStateAction<boolean>>;
  arbeitszeit: Arbeitszeit;
}) {
  const { id: arbeitszeitId, pausen } = arbeitszeit;
  const [arbeitszeitUpdate] = useArbeitszeitUpdate();
  const [isSaving, setIsSaving] = useState(false);

  const form = useForm<z.infer<typeof FormSchema>>({
    resolver: zodResolver(FormSchema),
    defaultValues: {
      beginn: '',
      ende: '',
    },
  });

  async function onSubmit(data: CreateDienstgangInput) {
    const newDienstgaenge = [
      ...pausen.map((pause) => mapPauseToInput(pause)),
      {
        pausenart: Pausenart.Dienstgang,
        beginn: data.beginn,
        ende: data.ende,
        bezahlt: true,
      },
    ];
    setIsSaving(true);
    arbeitszeitUpdate({
      arbeitszeitUpdateInput: {
        pausen: newDienstgaenge,
      },
      arbeitszeitId: arbeitszeitId,
    })
      .then((result) => {
        handleOnCompleted(result.data, t);
        setIsSaving(false);
        setIsCreateFormVisible(false);
      })
      .catch((e) => {
        toast({
          title: t(
            'domain.arbeitszeiten.sheet.header.arbeitszeitUpdateUnknownError',
          ),
        });
        Sentry.captureException(e);
        setIsSaving(false);
      });
  }

  return (
    <Form {...form}>
      <form
        className="flex flex-col gap-4"
        onSubmit={form.handleSubmit(onSubmit)}>
        <div className="flex gap-2 justify-between items-center">
          <Card>
            <FormField
              control={form.control}
              name="beginn"
              render={({ field }) => (
                <FormItem className="w-24">
                  <FormLabel>
                    {t('domain.arbeitszeiten.sheet.arbeitszeit.beginnLabel')}
                  </FormLabel>
                  <FormControl>
                    <TimeInput
                      defaultValue={field.value}
                      placeholder="--:--"
                      {...field}
                      onBlur={(e) => {
                        field.onBlur();
                        field.onChange(e);
                      }}
                    />
                  </FormControl>
                  <FormDescription />
                </FormItem>
              )}
            />
          </Card>
          <div className="h-[1px] bg-neutrals-N500 w-48" />
          <Card>
            <FormField
              control={form.control}
              name="ende"
              render={({ field }) => (
                <FormItem className="w-24">
                  <FormLabel>
                    {t('domain.arbeitszeiten.sheet.arbeitszeit.endeLabel')}
                  </FormLabel>
                  <FormControl>
                    <TimeInput
                      defaultValue={field.value}
                      placeholder="--:--"
                      {...field}
                      onBlur={(e) => {
                        field.onBlur();
                        field.onChange(e);
                      }}
                    />
                  </FormControl>
                  <FormDescription />
                </FormItem>
              )}
            />
          </Card>
        </div>
        {form.formState.errors.beginn && (
          <div className="text-sm text-primary-red-R400 font-semibold bg-primary-red-R100 p-4 rounded-md">
            {form.formState.errors.beginn.message}
          </div>
        )}
        {form.formState.errors.ende && (
          <div className="text-sm text-primary-red-R400 font-semibold bg-primary-red-R100 p-4 rounded-md">
            {form.formState.errors.ende.message}
          </div>
        )}
        <div className="flex justify-end gap-4 items-center">
          <Button
            onClick={() => setIsCreateFormVisible(false)}
            variant="outline">
            {t('actions.abbrechen')}
          </Button>
          <Button type="submit" disabled={isSaving}>
            {!isSaving ? t('actions.speichern') : t('actions.wirdGespeichert')}
          </Button>
        </div>
      </form>
    </Form>
  );
}
