import { AbsoluteNumberInput, MoneyInput } from '../../../../../../components';
import { FocusEvent } from 'react';
import { formatNumber } from '../../formatNumber';

export function TableFormField({
  type,
  value,
  updatePlanumsatzEntry,
}: {
  type: 'number' | 'money';
  value: string;
  updatePlanumsatzEntry: (newPlanumsatzValue: string) => void;
}) {
  const defaultValue = formatNumber(value, type === 'number');

  function handleOnBlur(e: FocusEvent<HTMLInputElement, Element>) {
    if (e.currentTarget.value.length < 1) {
      e.currentTarget.value = '0';
    }
    updatePlanumsatzEntry(e.currentTarget.value);
  }

  return type === 'money' ? (
    <MoneyInput
      className="border-none shadow-none"
      size={8}
      defaultValue={defaultValue}
      onBlur={handleOnBlur}
      value={defaultValue}
    />
  ) : (
    <AbsoluteNumberInput
      unit={'STK.'}
      className="border-none shadow-none"
      size={8}
      onBlur={handleOnBlur}
      defaultValue={defaultValue}
    />
  );
}
