/* eslint-disable max-lines-per-function */
/* eslint-disable max-lines */
/* eslint-disable complexity */
import DatePickerFormField from '../../../../components/DatePickerFormField/DatePickerFormField';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { MitarbeiterSelect } from '../MitarbeiterSelect';
import { Arbeitsbereich, Mitarbeiter } from '../../entities';
import { TimeInput } from '../../../../components';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { useArbeitszeitCreate } from '../../hooks/useArbeitszeitenCreate';
import { useStandort } from '../../../../hooks';
import { useNavigate, useParams } from 'react-router-dom';
import { useEffect, useMemo, useState } from 'react';
import { faSpinner } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { createTimeZoneSafeDateString } from '../../util/date';
import { ArbeitsbereichFilter } from '../Comboboxen/ArbeitsbereichCombobox';
import { Button } from '@/components/ui/button';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form';
import { toast } from '@/hooks/use-toast';
import { Checkbox } from '@/components/ui/checkbox';

type CreateArbeitszeitInput = {
  date: Date;
  mitarbeiter: Mitarbeiter;
  arbeitsbereich: Arbeitsbereich;
  beginn: string;
  ende: string | null;
  weitere: boolean;
};

export function CreateArbeitszeitForm({ defaultDate }: { defaultDate: Date }) {
  const [arbeitszeitCreate] = useArbeitszeitCreate();
  const { year, month, day } = useParams();
  const { standortId } = useStandort();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const FormSchema = useMemo(() => {
    return z
      .object({
        beginn: z.string().min(1, {
          message: t('domain.arbeitszeiten.create.beginnFehlt'),
        }),
        mitarbeiter: z.any().refine((val) => val !== null, {
          message: t('domain.arbeitszeiten.create.mitarbeiterFehlt'),
        }),
        arbeitsbereich: z.any().refine((val) => val !== null, {
          message: t('domain.arbeitszeiten.create.arbeitsbereichFehlt'),
        }),
        date: z.date(),
        ende: z.string().nullable().optional(),
        weitere: z.boolean(),
      })
      .refine(
        (data) =>
          (data.beginn === '' && data.ende === '') || data.beginn !== data.ende,
        {
          message: t('domain.arbeitszeiten.create.beginnEndeGleich'),
          path: ['beginn'],
        },
      );
  }, [t]);

  const form = useForm<CreateArbeitszeitInput>({
    resolver: zodResolver(FormSchema),
    defaultValues: {
      date: defaultDate,
      mitarbeiter: null,
      arbeitsbereich: null,
      beginn: '',
      weitere: false,
    },
    mode: 'onChange',
  });

  const resetForm = () => {
    form.reset();
    form.setValue('weitere', true);
  };

  function handleSubmit(data: CreateArbeitszeitInput) {
    setIsSubmitting(true);
    const year = data.date.getFullYear();
    const month = (data.date.getMonth() + 1).toString().padStart(2, '0');
    const day = data.date.getDate().toString().padStart(2, '0');

    arbeitszeitCreate({
      datum: createTimeZoneSafeDateString(data.date),
      standortId,
      mitarbeiterId: data.mitarbeiter.id,
      arbeitszeitCreateInput: {
        beginn: data.beginn,
        ende: data.ende,
        arbeitsbereichId: data.arbeitsbereich.id,
      },
    }).then((result) => {
      setIsSubmitting(false);
      if (result.data.createArbeitszeit.__typename === 'Arbeitszeiten') {
        const newDate = new Date(result.data.createArbeitszeit.datum);
        const newYear = newDate.getFullYear();
        const newMonth = (newDate.getMonth() + 1).toString().padStart(2, '0');
        const newDay = newDate.getDate().toString().padStart(2, '0');
        if (newYear !== year || newMonth !== month || newDay !== day) {
          if (data.weitere) {
            resetForm();
            toast({
              title: t('domain.arbeitszeiten.create.toast.tageswechsel.title'),
              description: t(
                'domain.arbeitszeiten.create.toast.tageswechsel.description',
                {
                  date: `${newDay}.${newMonth}.${newYear}`,
                },
              ),
            });
            return;
          }
          return navigate(
            `/arbeitszeiten/uebersicht/${newYear}/${newMonth}/${newDay}/sheet/edit/${result.data.createArbeitszeit.id}?tageswechsel=true`,
          );
        }
        if (data.weitere) {
          resetForm();
          toast({
            title: t('domain.arbeitszeiten.create.toast.erfolg.title'),
            description: t(
              'domain.arbeitszeiten.create.toast.erfolg.description',
            ),
          });
          return;
        }
        return navigate(
          `/arbeitszeiten/uebersicht/${year}/${month}/${day}/sheet/edit/${result.data.createArbeitszeit.id}`,
        );
      }
      if (
        result.data.createArbeitszeit.__typename ===
        'ManagerHasRoleArbeitszeitNurHeuteBearbeiten'
      ) {
        toast({
          title: t('domain.arbeitszeiten.create.toast.fehler.title'),
          description: t('domain.arbeitszeiten.create.toast.fehler.nurHeute'),
          variant: 'destructive',
        });
        return;
      }
      return navigate(`/arbeitszeiten/uebersicht/${year}/${month}/${day}`);
    });
  }

  const selectedMitarbeiter = form.watch('mitarbeiter');

  useEffect(() => {
    if (selectedMitarbeiter && selectedMitarbeiter?.arbeitsbereich) {
      form.setValue('arbeitsbereich', selectedMitarbeiter?.arbeitsbereich);
    }
  }, [form, selectedMitarbeiter]);

  return (
    <Form {...form}>
      <form
        id="createArbeitszeit"
        className="grow flex flex-col justify-start items-center gap-8 p-8"
        onSubmit={form.handleSubmit(handleSubmit)}>
        <FormField
          control={form.control}
          name="date"
          render={({ field }) => (
            <FormItem>
              <FormLabel>
                {t('domain.arbeitszeiten.create.datumLabel')}
              </FormLabel>
              <FormControl>
                <DatePickerFormField
                  date={field.value}
                  onSelect={(d) => field.onChange(d)}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="mitarbeiter"
          render={({ field }) => (
            <FormItem className="flex flex-col gap-1">
              <FormLabel>
                {t('domain.arbeitszeiten.create.mitarbeiterLabel')}
              </FormLabel>
              <MitarbeiterSelect
                isUpdating={false}
                onChange={(mitarbeiter) => {
                  field.onChange(mitarbeiter);
                  form.setValue('mitarbeiter', mitarbeiter);
                  form.setValue('arbeitsbereich', mitarbeiter?.arbeitsbereich);
                }}
                selectedMitarbeiter={field.value}
              />
              <FormMessage />
            </FormItem>
          )}
        />

        {form.getValues('mitarbeiter') && form.getValues('date') && (
          <FormField
            control={form.control}
            name="arbeitsbereich"
            render={({ field }) => (
              <FormItem className="flex flex-col gap-1">
                <FormLabel>
                  {t('domain.arbeitszeiten.create.arbeitsbereichLabel')}
                </FormLabel>
                <ArbeitsbereichFilter
                  minimunSelcet={1}
                  multiSelect={false}
                  mitarbeiter={form.getValues('mitarbeiter')}
                  defaultValue={[form.watch('mitarbeiter')?.arbeitsbereich]}
                  onSelect={(arbeitsbereich) => {
                    field.onChange(arbeitsbereich[0]);
                    form.setValue('arbeitsbereich', arbeitsbereich[0]);
                  }}
                />
                <FormMessage />
              </FormItem>
            )}
          />
        )}
        {form.getValues('mitarbeiter') && form.getValues('date') && (
          <>
            <div className="flex justify-between w-[275px] items-start gap-2">
              <FormField
                control={form.control}
                name="beginn"
                render={() => (
                  <FormItem className="flex flex-col gap-1">
                    <FormLabel>
                      {t('domain.arbeitszeiten.sheet.arbeitszeit.beginnLabel')}
                    </FormLabel>
                    <TimeInput
                      placeholder="--:--"
                      onBlur={(e) => {
                        form.setValue('beginn', e.target.value);
                      }}
                    />
                    <FormMessage />
                  </FormItem>
                )}
              />
              <div className="h-[1px] bg-neutrals-N500 w-32 mt-10" />
              <FormField
                control={form.control}
                name="ende"
                render={() => (
                  <FormItem className="flex flex-col gap-1">
                    <FormLabel>
                      {t('domain.arbeitszeiten.sheet.arbeitszeit.endeLabel')}
                    </FormLabel>
                    <TimeInput
                      placeholder="--:--"
                      onBlur={(e) =>
                        form.setValue('ende', e.currentTarget.value || null)
                      }
                    />
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>
          </>
        )}
        <FormField
          control={form.control}
          name="weitere"
          render={({ field }) => (
            <FormItem className="flex items-center gap-2 w-[275px]">
              <FormControl>
                <Checkbox
                  checked={field.value}
                  onCheckedChange={field.onChange}
                />
              </FormControl>
              <FormLabel>{t('domain.arbeitszeiten.create.weitere')}</FormLabel>
            </FormItem>
          )}
        />
        <div className="flex justify-end w-full gap-2 p-4">
          <Button
            variant="outline"
            disabled={isSubmitting}
            onClick={() =>
              navigate(`/arbeitszeiten/uebersicht/${year}/${month}/${day}`, {
                replace: true,
              })
            }>
            {t('actions.abbrechen')}
          </Button>
          <Button
            variant="default"
            form="createArbeitszeit"
            className="flex items-center gap-2"
            disabled={isSubmitting && !form.formState.isValid}>
            <span>{t('actions.speichern')}</span>
            {isSubmitting && <FontAwesomeIcon icon={faSpinner} spin />}
          </Button>
        </div>
      </form>
    </Form>
  );
}
