import {
  getMonthNameByDateObject,
  getWeekdayByDate,
} from '../../utils/dateFunctions/getDatesOfMonth';
import { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChevronLeft,
  faChevronRight,
} from '@fortawesome/pro-solid-svg-icons';
import { useTranslation } from 'react-i18next';
import { Button } from '../ui/button';
import { Calendar } from '../ui/calendar';
import { Popover, PopoverContent, PopoverTrigger } from '../ui/popover';

// eslint-disable-next-line max-lines-per-function
export default function DatePicker({
  date,
  onSelect,
  onForward,
  onBackward,
}: {
  date: Date;
  onSelect: (date: Date) => void;
  onForward: (date: Date) => void;
  onBackward: (date: Date) => void;
}) {
  const [open, setOpen] = useState(false);
  const { t } = useTranslation();
  const handleSelect = (d: Date) => {
    setOpen(false); // Close the popover first
    setTimeout(() => onSelect(d), 0); // Navigate after the popover is closed
  };
  return (
    <Popover open={open} onOpenChange={setOpen}>
      <div className="flex items-center gap-3">
        <FontAwesomeIcon
          icon={faChevronLeft}
          className="text-neutrals-N700 text-xl"
          onClick={() => {
            const previousDay = date;
            date.setDate(date.getDate() - 1);
            onBackward(previousDay);
          }}
        />
        <PopoverTrigger asChild>
          <Button
            variant="ghost"
            className={'justify-start text-left text-2xl font-bold'}>
            {`${
              getWeekdayByDate(date).shortage
            }., ${date.getDate()}. ${getMonthNameByDateObject(date)}`}
          </Button>
        </PopoverTrigger>
        <FontAwesomeIcon
          icon={faChevronRight}
          className="text-neutrals-N700 text-xl"
          onClick={() => {
            const nextDay = date;
            date.setDate(date.getDate() + 1);
            onForward(nextDay);
          }}
        />
      </div>
      <PopoverContent className="w-auto p-0 bg-white" align="start">
        <Calendar
          mode="single"
          selected={date}
          onSelect={(d) => {
            handleSelect(d);
          }}
          defaultMonth={date}
          initialFocus
        />
        <Button
          className="w-full"
          variant="ghost"
          onClick={() => {
            handleSelect(new Date());
          }}>
          {t('general.date.today')}
        </Button>
      </PopoverContent>
    </Popover>
  );
}
