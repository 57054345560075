import { useTranslation } from 'react-i18next';
import { AuthenticationException } from '../services';

export function useAuthErrorMessage(type: AuthenticationException) {
  const { t } = useTranslation();

  if (type === 'UserNotFoundException' || type === 'NotAuthorizedException') {
    return t('pages.login.loginForm.notAuthorizedException');
  } else if (type === 'InvalidParameterException') {
    return t('pages.login.loginForm.invalidParameterException');
  } else if (type === 'UserAlreadyAuthenticatedException') {
    return t('pages.login.loginForm.userAlreadyAuthenticatedException');
  } else {
    return t('pages.login.loginForm.unknownError', {
      error: type,
    });
  }
}
