/* eslint-disable react-refresh/only-export-components */
import { Slot } from '@radix-ui/react-slot';
import { cva } from 'class-variance-authority';

import { cn } from '../../lib/utils';
import { ButtonHTMLAttributes, forwardRef } from 'react';

const buttonVariants = cva<ButtonVariant>(
  'inline-flex items-center justify-center whitespace-nowrap rounded-md text-sm font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-neutrals-N500 focus-visible:ring-offset-2',
  {
    variants: {
      variant: {
        default:
          'bg-primary-blue-B600 text-white hover:bg-primary-blue-B700 disabled:bg-neutrals-N300 disabled:hover:bg-neutrals-N300 disabled:hover:cursor-not-allowed disabled:text-neutrals-N700',
        secondary:
          'bg-[#545e83] text-white hover:bg-[#000e46] disabled:bg-neutrals-N300 disabled:hover:bg-neutrals-N300 disabled:hover:cursor-not-allowed disabled:text-neutrals-N700',
        destructive:
          'bg-primary-red-R400 text-white hover:bg-primary-red-R600 disabled:bg-neutrals-N300 disabled:hover:bg-neutrals-N300 disabled:hover:cursor-not-allowed disabled:text-neutrals-N700',
        outline:
          'border border-primary-blue-B600 bg-inherit hover:bg-primary-blue-B600 hover:text-white text-primary-blue-B600 disabled:border-neutrals-N300 disabled:hover:bg-inherit disabled:hover:cursor-not-allowed disabled:text-neutrals-N700',
        ghost:
          'hover:bg-primary-blue-B100 disabled:hover:bg-inherit disabled:text-neutrals-N700 disabled:hover:cursor-not-allowed',
        link: 'text-black underline-offset-4 hover:underline disabled:text-neutrals-N700 disabled:underline disabled:hover:cursor-not-allowed',
        'outline-secondary':
          'border border-neutrals-N400 bg-inherit hover:bg-neutrals-N300 text-neutrals-N900 disabled:border-neutrals-N400 disabled:hover:bg-inherit disabled:hover:cursor-not-allowed disabled:text-neutrals-N700',
        icon: 'hover:bg-neutrals-N300 disabled:hover:bg-inherit disabled:text-neutrals-N700 disabled:hover:cursor-not-allowed',
      },
      size: {
        default: 'h-10 px-3 py-2',
        sm: 'h-9 rounded-md px-3',
        lg: 'h-11 rounded-md px-8',
        icon: 'h-10 w-10',
      },
    },
    defaultVariants: {
      variant: 'default',
      size: 'default',
    },
  },
);

export type ButtonProps = ButtonHTMLAttributes<HTMLButtonElement> & {
  asChild?: boolean;
  size?: Size;
  variant?: Variant;
};

const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      className,
      variant = 'default',
      size = 'default',
      asChild = false,
      ...props
    },
    ref,
  ) => {
    const Comp = asChild ? Slot : 'button';
    return (
      <Comp
        className={cn(buttonVariants({ variant, size, className }))}
        ref={ref}
        {...props}
      />
    );
  },
);
Button.displayName = 'Button';

export type Size = 'default' | 'sm' | 'lg' | 'icon';

export type Variant =
  | 'default'
  | 'secondary'
  | 'outline'
  | 'outline-secondary'
  | 'destructive'
  | 'ghost'
  | 'link'
  | 'icon';

export type ButtonVariant = {
  variant: Record<Variant, string>;
  size: Record<Size, string>;
};

export { Button, buttonVariants };
