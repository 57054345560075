import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend, { ChainedBackendOptions } from 'i18next-chained-backend';
import LocalStorageBackend from 'i18next-localstorage-backend'; // primary use cache
import HttpApi from 'i18next-http-backend'; // fallback http load

declare module 'i18next' {
  interface CustomTypeOptions {
    returnNull: false;
  }
}

i18next
  .use(Backend)
  .use(initReactI18next)
  .init<ChainedBackendOptions>({
    fallbackLng: 'de',
    debug: true,
    returnNull: false,
    backend: {
      backends: [
        LocalStorageBackend, // primary backend
        HttpApi, // fallback backend
      ],
      backendOptions: [
        {
          /* options for local storage backend */
          prefix: import.meta.env.VITE_I18N_PREFIX,
          versions: {
            en: import.meta.env.VITE_I18N_EN_VERSION,
            de: import.meta.env.VITE_I18N_DE_VERSION,
          },
        },
        {
          /* options for http backend */
          loadPath: import.meta.env.VITE_I18N_LOAD_PATH,
          requestOptions: {
            cache: 'no-store',
          },
        },
      ],
    },
  });

export default i18next;
