/* eslint-disable max-lines-per-function */
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '@/components/ui/tooltip';
import {
  getWiederholungStringFromTerminWiederholung,
  Termin,
} from '../../../entities/Termin';
import { faExclamationCircle } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from '@/components/ui/accordion';
import { Avatar, AvatarFallback, AvatarImage } from '@/components/ui/avatar';
import { Badge } from '@/components/ui/badge';
import { getTimeDisplayFromDate, markierungMapper } from '@/utils';
import { faDash, faCalendarStar } from '@fortawesome/pro-regular-svg-icons';
import { CSSProperties } from 'react';

export function TermineTooltip({
  termine,
}: {
  termine: {
    termine?: Termin[];
    error?: string;
  };
}) {
  const { t } = useTranslation();
  type StyleProps = CSSProperties & {
    '--border-color': CSSProperties['color'];
  };
  return (
    <TooltipProvider>
      <Tooltip delayDuration={0}>
        <TooltipTrigger
          className={`${
            termine.termine.length < 1 && !termine.error
              ? 'cursor-auto'
              : 'cursor-pointer'
          }`}>
          <div className="flex gap-2 items-center p-2 rounded-md bg-neutrals-N200">
            <FontAwesomeIcon
              className={` ${
                termine.termine.length > 0
                  ? 'text-primary-blue-B600'
                  : 'text-neutrals-N200'
              }`}
              icon={faCalendarStar}
            />
            <span className="font-semibold text-sm">
              {t('entities.event_other')}
            </span>
            {termine.error ? (
              <FontAwesomeIcon
                icon={faExclamationCircle}
                size="xs"
                className="text-primary-red-R500"
              />
            ) : (
              <span className="text-neutrals-N700 text-sm">
                {'(' + termine.termine.length + ')'}
              </span>
            )}
          </div>
        </TooltipTrigger>
        {termine.termine.length > 0 && (
          <TooltipContent side="bottom" className="w-[400px]">
            {termine.error ? (
              <div>{termine.error}</div>
            ) : (
              <Accordion type="single" collapsible className="w-full">
                {termine.termine.map((termin) => (
                  <AccordionItem
                    value={termin.id}
                    key={termin.id}
                    className="last:border-0 border-neutrals-N500">
                    <AccordionTrigger className="hover:no-underline">
                      <div className="flex gap-2 items-center justify-between w-full">
                        <div className="flex items-center gap-4">
                          <Avatar
                            className="w-9 h-9 border border-[--border-color] "
                            style={
                              {
                                '--border-color': termin.markierung
                                  ? markierungMapper[termin.markierung].base
                                  : '',
                              } as StyleProps
                            }>
                            <AvatarImage
                              src={termin.bildUrl}
                              alt={termin.bildName}
                            />
                            <AvatarFallback>
                              <FontAwesomeIcon
                                icon={faCalendarStar}
                                size="lg"
                              />
                            </AvatarFallback>
                          </Avatar>
                          <span className="font-semibold">{termin.titel}</span>{' '}
                        </div>
                        <div className="flex items-center gap-1">
                          <div>{getTimeDisplayFromDate(termin.beginn)}</div>
                          <FontAwesomeIcon icon={faDash} />
                          <div>{getTimeDisplayFromDate(termin.ende)}</div>
                        </div>
                      </div>
                    </AccordionTrigger>
                    <AccordionContent>
                      <div className="flex gap-2 flex-col items-start justify-end">
                        <Badge>
                          {getWiederholungStringFromTerminWiederholung(
                            termin.wiederholung,
                          )}
                        </Badge>
                        {termin.beschreibung &&
                        termin.beschreibung.length > 0 ? (
                          <p className="line-clamp-3">{termin.beschreibung}</p>
                        ) : (
                          <p className="text-neutrals-N600 italic">
                            {t(
                              'domain.arbeitszeiten.uebersicht.termine.beschreibungFehlend',
                            )}
                          </p>
                        )}
                      </div>
                    </AccordionContent>
                  </AccordionItem>
                ))}
              </Accordion>
            )}
          </TooltipContent>
        )}
      </Tooltip>
    </TooltipProvider>
  );
}
