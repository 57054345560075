import { toast } from '@/hooks/use-toast';
import { Dienstplanuebernahme } from '../entities';
import {
  ArbeitszeitCreateManyMutation,
  ArbeitszeitCreateManyMutationVariables,
  DienstplanuebernahmeUpdateMutation,
  DienstplanuebernahmeUpdateMutationVariables,
} from '@/gql/graphql';
import {
  ARBEITSZEIT_CREATE_MANY,
  DIENSTPLANUEBERNAHME_UPDATE,
} from './graphql/mutation';
import { useMutation } from '@apollo/client';
import { useCallback } from 'react';
import { createTimeZoneSafeDateString } from '../util/date';
import { useStandort } from '@/hooks';
import { getTimeDisplayFromDate } from '@/utils';
import { useTranslation } from 'react-i18next';

export function useDienstplansuebernahmeIgnorieren() {
  const { t } = useTranslation();
  const [execute, result] = useMutation<
    DienstplanuebernahmeUpdateMutation,
    DienstplanuebernahmeUpdateMutationVariables
  >(DIENSTPLANUEBERNAHME_UPDATE, {
    refetchQueries: ['getArbeitszeitenByDateAndStandortId'],
    awaitRefetchQueries: true,
  });

  const dienstplanIgnorieren = useCallback(
    (dienstplanuebernahmen: Dienstplanuebernahme[]) => {
      return execute({
        variables: {
          input: dienstplanuebernahmen.map((dienstplanuebernahme) => ({
            ignoriert: true,
            mitarbeiterId: dienstplanuebernahme.mitarbeiter.id,
            schichtId: dienstplanuebernahme.schichtId,
          })),
        },
        onCompleted: () => {
          toast({
            title: t('domain.arbeitszeiten.dienstplanuebernahme.toast.title'),
            description:
              dienstplanuebernahmen.length === 1
                ? t(
                    'domain.arbeitszeiten.dienstplanuebernahme.toast.ignorieren_one',
                  )
                : t(
                    'domain.arbeitszeiten.dienstplanuebernahme.toast.ignorieren_many',
                    { count: dienstplanuebernahmen.length },
                  ),
          });
        },
      });
    },
    [execute, t],
  );

  return [dienstplanIgnorieren, result] as const;
}

export function useDienstplanuebernahmeUebernehmen() {
  const { t } = useTranslation();
  const [execute, result] = useMutation<
    ArbeitszeitCreateManyMutation,
    ArbeitszeitCreateManyMutationVariables
  >(ARBEITSZEIT_CREATE_MANY, {
    refetchQueries: ['getArbeitszeitenByDateAndStandortId'],
    awaitRefetchQueries: true,
  });
  const { standortId } = useStandort();

  const dienstplanuebernahmeUebernehmen = useCallback(
    (dienstplanuebernahmen: Dienstplanuebernahme[]) => {
      return execute({
        variables: {
          input: dienstplanuebernahmen.map((dienstplanuebernahme) => ({
            datum: createTimeZoneSafeDateString(dienstplanuebernahme.beginn),
            standortId: standortId,
            beginn: getTimeDisplayFromDate(dienstplanuebernahme.beginn),
            ende: getTimeDisplayFromDate(dienstplanuebernahme.ende),
            mitarbeiterId: dienstplanuebernahme.mitarbeiter.id,
            arbeitsbereichId: dienstplanuebernahme.arbeitsbereich.id,
          })),
        },
        onCompleted: () => {
          toast({
            title: t('domain.arbeitszeiten.dienstplanuebernahme.toast.title'),
            description:
              dienstplanuebernahmen.length === 1
                ? t(
                    'domain.arbeitszeiten.dienstplanuebernahme.toast.uebernehmen_one',
                  )
                : t(
                    'domain.arbeitszeiten.dienstplanuebernahme.toast.uebernehmen_many',
                    { count: dienstplanuebernahmen.length },
                  ),
          });
        },
      });
    },
    [execute, standortId, t],
  );

  return [dienstplanuebernahmeUebernehmen, result] as const;
}
