import { faDash } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getTimeDisplayFromDate } from '../../../../../../../../../utils';
import { TableCell } from '@/components/ui/table';

export function BeginnEndeCell({ beginn, ende }: { beginn: Date; ende: Date }) {
  return (
    <TableCell>
      <div className="flex items-center gap-2">
        {beginn && ende ? (
          <>
            <span className="font-semibold">
              {getTimeDisplayFromDate(beginn)}
            </span>
            <FontAwesomeIcon className="text-neutrals-N700" icon={faDash} />
            <span className="font-semibold">
              {getTimeDisplayFromDate(ende)}
            </span>
          </>
        ) : (
          <FontAwesomeIcon className="text-neutrals-N700" icon={faDash} />
        )}
      </div>
    </TableCell>
  );
}
