import { DropdownMenuItem } from '@e2n/e2n-ui';
import {
  faDesktop,
  faFileContract,
  faQuestionCircle,
  faShieldCheck,
  faSignOut,
  faUserCircle,
  faUserGear,
  faWrench,
} from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ManagerProfile, useProfile } from '../../../hooks';
import { TFunction } from 'i18next';
import { buildLink, managerV1BaseUrl } from '../../../utils';

// eslint-disable-next-line react-refresh/only-export-components
function Separator() {
  return <hr className="border-t my-2 border-solid border-neutrals-N300" />;
}

// eslint-disable-next-line max-lines-per-function
export function useDropdownMenuItems() {
  const { t } = useTranslation();
  const profile = useProfile();
  const manager = profile.manager;
  const menuItems = [];

  if (manager.rechte.admin) {
    menuItems.push(
      <DropdownMenuItem
        className="flex items-center gap-2"
        data-testid="menu-item-administration">
        <FontAwesomeIcon fixedWidth icon={faUserGear} />
        <a className="w-full" href={buildLink('allgemein')}>
          {t('general.navigation.profileDropdown.administration')}
        </a>
      </DropdownMenuItem>,
    );
  }

  menuItems.push(createSettingsLink(manager, t));
  if (manager.rechte.admin) {
    menuItems.push(
      <DropdownMenuItem
        className="flex items-center gap-2"
        data-testid="menu-item-vertraege">
        <FontAwesomeIcon fixedWidth icon={faFileContract} />
        <Link className="w-full" to="/vertraege">
          {t('general.navigation.profileDropdown.contracts')}
        </Link>
      </DropdownMenuItem>,
    );
  }
  menuItems.push(
    <DropdownMenuItem
      className="flex items-center gap-2"
      data-testid="menu-item-profile">
      <FontAwesomeIcon fixedWidth icon={faUserCircle} />
      <Link className="w-full" to="/profile">
        {t('general.navigation.profileDropdown.myProfile')}
      </Link>
    </DropdownMenuItem>,
  );
  if (manager.rechte.admin || manager.hasEinstellungenRecht()) {
    menuItems.push(<Separator />);
  }
  //TODO: Nicht anzeigen bei Schweizer mandant
  menuItems.push(
    <DropdownMenuItem
      className="flex items-center gap-2"
      data-testid="menu-item-privacy">
      <FontAwesomeIcon fixedWidth icon={faShieldCheck} />
      <a className="w-full" href={buildLink('datenschutz')}>
        {t('general.navigation.profileDropdown.privacy')}
      </a>
    </DropdownMenuItem>,
  );

  menuItems.push(
    <DropdownMenuItem
      className="flex items-center gap-2"
      data-testid="menu-item-help">
      <FontAwesomeIcon fixedWidth icon={faQuestionCircle} />
      <a
        className="w-full"
        href="https://hilfe.e2n.de"
        target="_blank"
        rel="noreferrer">
        {t('general.navigation.profileDropdown.help')}
      </a>
    </DropdownMenuItem>,
  );

  menuItems.push(
    <DropdownMenuItem
      className="flex items-center gap-2"
      data-testid="menu-item-remoteMaintenance">
      <FontAwesomeIcon fixedWidth icon={faDesktop} />
      <a
        className="w-full"
        href="https://get.teamviewer.com/e2ngastro"
        target="_blank"
        rel="noreferrer">
        {t('general.navigation.profileDropdown.remoteMaintenance')}
      </a>
    </DropdownMenuItem>,
  );

  menuItems.push(
    <DropdownMenuItem
      className="flex items-center gap-2"
      data-testid="navigation-avatar-dropdown-logout"
      onClick={() => {
        window.location.href = `${managerV1BaseUrl}/logout`;
      }}>
      <FontAwesomeIcon fixedWidth icon={faSignOut} />
      <span>{t('general.navigation.profileDropdown.logout')}</span>
    </DropdownMenuItem>,
  );
  return menuItems;
}

// Hier sehe ich gerade auch keine Verbesserungsmöglichkeit
// eslint-disable-next-line complexity
function createSettingsLink(
  manager: ManagerProfile,
  t: TFunction<'translation' | undefined>,
) {
  let link = '';
  if (manager.hasEinstellungenRecht()) {
    if (manager.rechte.tagebuch) {
      link = buildLink('einstellungenTagebuch');
    }
    if (link === '' && manager.hasUnrestrictedMitarbeiterRechte()) {
      link = buildLink('einstellungenQualifikationen');
    }
    if (link === '' && manager.rechte.arbeitszeit.erlaubt) {
      link = buildLink('einstellungenArbeitszeit');
    }
    if (link === '' && manager.rechte.kalender) {
      link = buildLink('einstellungenAbwesenheit');
    }
    if (
      link === '' &&
      (manager.rechte.abrechnung.export ||
        manager.rechte.abrechnung.monatsabschluss)
    ) {
      link = buildLink('einstellungenZuschlag');
    }
    if (link === '' && manager.isDienstplanAdmin()) {
      link = buildLink('einstellungenDienstplan');
    }
    return (
      <DropdownMenuItem
        className="flex items-center gap-2"
        data-testid="menu-item-settings">
        <FontAwesomeIcon fixedWidth icon={faWrench} />
        <a className="w-full" href={link}>
          {t('general.navigation.profileDropdown.settings')}
        </a>
      </DropdownMenuItem>
    );
  }
  return <></>;
}
