import { ErrorBoundary } from '../../ErrorBoundary';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleExclamation } from '@fortawesome/pro-light-svg-icons';
import { HomeItemContainer } from './HomeItemContainer';
import { Suspense } from 'react';
import { HomeItemLoading } from './HomeItemLoading';
import Skeleton from '../../Skeleton/Skeleton';
import { GraphQLError } from 'graphql';
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '@/components/ui/tooltip';
import * as Sentry from '@sentry/react';

function FallbackComponent({ error }: { error: GraphQLError }) {
  const { t } = useTranslation();

  let message = error.message;
  if (typeof error === 'string') {
    message = error;
  } else {
    message = t('general.navigation.standortNameError');
    Sentry.captureException(error);
  }

  return (
    <TooltipProvider>
      <Tooltip>
        <TooltipTrigger>
          <div className="flex gap-1 items-center text-primary-red-R400">
            <FontAwesomeIcon icon={faCircleExclamation} />
            <Skeleton.Text height="16px" width="80px" />
          </div>
        </TooltipTrigger>
        <TooltipContent side="right">{message}</TooltipContent>
      </Tooltip>
    </TooltipProvider>
  );
}

export function HomeItem() {
  return (
    <ErrorBoundary FallbackComponent={FallbackComponent}>
      <Suspense fallback={<HomeItemLoading />}>
        <HomeItemContainer />
      </Suspense>
    </ErrorBoundary>
  );
}
