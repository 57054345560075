import { useSuspenseQuery } from '@apollo/client';
import { GET_MITARBEITER_BY_STANDORT_ID } from './graphql/queries';
import {
  MitarbeiterByStandortId,
  MitarbeiterError,
} from '../../../gql/graphql';
import { handleGraphQLErrors } from '../../../utils/errors/handleGraphQLErrors';
import { Mitarbeiter } from '../entities';
import * as Sentry from '@sentry/react';

export function useMitarbeiter(standortId: string): {
  mitarbeiter?: Mitarbeiter[];
  error?: string;
} {
  const { data } = useSuspenseQuery(GET_MITARBEITER_BY_STANDORT_ID, {
    variables: { standortId },
    fetchPolicy: 'cache-first',
  });

  const error = handleGraphQLErrors<MitarbeiterByStandortId, MitarbeiterError>(
    data.mitarbeiter,
  );
  if (error) {
    Sentry.captureException(error);
    return { error: error.message };
  }

  const currentMitarbeiter = data.mitarbeiter as MitarbeiterByStandortId;

  const mitarbeiter = [...currentMitarbeiter.mitarbeiter];

  return {
    mitarbeiter: mitarbeiter.map((m) => {
      return new Mitarbeiter(
        m.vorname,
        m.nachname,
        m.id,
        m.extern,
        m.bildUrl,
        m.arbeitsbereich,
      );
    }),
  };
}
