import { faPlusCircle } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Table } from './Table';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { CreateForm } from './Forms/CreateForm';
import { useManagerCanEditArbeitszeiten } from '../../../../hooks/useManagerCanEditArbeitszeit';
import { useEditSheetContext } from '../../EditSheetContext';
import { Button } from '@/components/ui/button';

// eslint-disable-next-line max-lines-per-function
export function Dienstgang() {
  const { arbeitszeit } = useEditSheetContext();
  const { t } = useTranslation();
  const [isCreateFormVisible, setIsCreateFormVisible] = useState(false);
  const managerCanEditArbeitszeit = useManagerCanEditArbeitszeiten();
  const dienstgangDauer = arbeitszeit.dienstgaenge.reduce(
    (sum, currentPausenDauer) => sum + currentPausenDauer.dauer,
    0,
  );

  return arbeitszeit.abwesenheit !== null ? (
    <></>
  ) : (
    <div className="flex flex-col justify-center gap-6 shadow-card bg-white rounded-md p-2">
      <div className="bg-neutrals-N200 rounded-lg py-2 px-4 flex gap-2 justify-between text-sm items-center">
        <div>
          {t('domain.arbeitszeiten.sheet.arbeitszeit.dienstgaengeGesamt')}
        </div>
        <div className="flex gap-2 items-center">
          <div className="flex gap-1 items-baseline">
            <span className="font-semibold">{dienstgangDauer}</span>
            <span className="text-neutrals-N700 text-xs">
              {t('general.date.minutes')}
            </span>
          </div>
        </div>
      </div>
      <Table
        dienstgaenge={arbeitszeit.dienstgaenge}
        arbeitszeit={arbeitszeit}
      />
      {arbeitszeit.dienstgaenge.length === 0 && (
        <div className="flex justify-center text-sm text-neutrals-N700">
          {t('domain.arbeitszeiten.sheet.arbeitszeit.dienstgaengeEmtpyTable')}
        </div>
      )}
      {managerCanEditArbeitszeit &&
        (!isCreateFormVisible ? (
          <div className="flex justify-center">
            <Button
              variant="ghost"
              onClick={() => setIsCreateFormVisible(true)}>
              <div className="flex gap-1 items-center justify-center text-primary-blue-B600">
                <FontAwesomeIcon icon={faPlusCircle} />
                <div>{t('domain.arbeitszeiten.sheet.dienstgang.create')}</div>
              </div>
            </Button>
          </div>
        ) : (
          <CreateForm
            setIsCreateFormVisible={setIsCreateFormVisible}
            arbeitszeit={arbeitszeit}
          />
        ))}
    </div>
  );
}
