import { BackgroundColor } from '../../gql/graphql';

export const backgroundMapper: Record<
  BackgroundColor,
  { light: string; base: string }
> = {
  PRIMARY: { light: '#b3d9eb', base: '#0984bd' },
  WARNING: { light: '#fed8b3', base: '#fd820b' },
  ERROR: { light: '#f8c1c2', base: '#ea383a' },
};
