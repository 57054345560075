import Skeleton from '@/components/Skeleton/Skeleton';
import { t } from 'i18next';

// eslint-disable-next-line max-lines-per-function
export function ZieleSkeleton({
  umsatzplanung,
}: {
  umsatzplanung: 'UMSATZ' | 'ABSATZ' | 'UMSATZ_ABSATZ';
}) {
  return (
    <>
      {umsatzplanung === 'UMSATZ_ABSATZ' && (
        <div className="flex flex-col gap-4 grow justify-between">
          <div className="bg-white rounded-lg px-5 py-4 flex flex-row gap-4">
            <div className="text-xs text-neutrals-N700 font-bold w-1/3">
              {t('domain.controlling.umsatzplanung.ziele.umsatz')}
              <div className="flex flex-row items-baseline pt-1">
                <Skeleton.Field height="28px" width="81px" />
              </div>
            </div>
            <div className="text-xs text-neutrals-N700 font-bold w-1/3">
              {t('domain.controlling.umsatzplanung.ziele.produktivitaet')}
              <div className="flex flex-row items-baseline pt-1">
                <Skeleton.Field height="28px" width="81px" />
              </div>
            </div>
            <div className="text-xs text-neutrals-N700 font-bold w-1/3 border-l-[1px] pl-4">
              {t('domain.controlling.umsatzplanung.ziele.zielstunden')}
              <div className="flex flex-row items-baseline pt-1">
                <Skeleton.Field height="28px" width="81px" />
              </div>
            </div>
          </div>

          <div className="bg-white rounded-lg px-5 py-4 flex flex-row gap-4">
            <div className="text-xs text-neutrals-N700 font-bold w-1/3">
              {t('domain.controlling.umsatzplanung.ziele.absatz')}
              <div className="flex flex-row items-baseline pt-1">
                <Skeleton.Field height="28px" width="81px" />
              </div>
            </div>
            <div className="text-xs text-neutrals-N700 font-bold w-1/3">
              {t('domain.controlling.umsatzplanung.ziele.produktivitaet')}
              <div className="flex flex-row items-baseline pt-1">
                <Skeleton.Field height="28px" width="81px" />
              </div>
            </div>
            <div className="text-xs text-neutrals-N700 font-bold w-1/3 border-l-[1px] pl-4">
              {t('domain.controlling.umsatzplanung.ziele.zielstunden')}
              <div className="flex flex-row items-baseline pt-1">
                <Skeleton.Field height="28px" width="81px" />
              </div>
            </div>
          </div>
        </div>
      )}
      {(umsatzplanung === 'UMSATZ' || umsatzplanung === 'ABSATZ') && (
        <div className="flex flex-col gap-4 grow justify-between">
          <div className="bg-white rounded-lg px-5 py-4 flex flex-row gap-4">
            <div className="text-xs text-neutrals-N700 font-bold w-1/3">
              {umsatzplanung === 'UMSATZ'
                ? t('domain.controlling.umsatzplanung.ziele.umsatz')
                : t('domain.controlling.umsatzplanung.ziele.absatz')}
              <div className="flex flex-row items-baseline pt-1">
                <Skeleton.Field height="28px" width="81px" />
              </div>
            </div>
            <div className="text-xs text-neutrals-N700 font-bold w-1/3">
              {t('domain.controlling.umsatzplanung.ziele.produktivitaet')}
              <div className="flex flex-row items-baseline pt-1">
                <Skeleton.Field height="28px" width="81px" />
              </div>
            </div>
            <div className="text-xs text-neutrals-N700 font-bold w-1/3 border-l-[1px] pl-4">
              {t('domain.controlling.umsatzplanung.ziele.zielstunden')}
              <div className="flex flex-row items-baseline pt-1">
                <Skeleton.Field height="28px" width="81px" />
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
