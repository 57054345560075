import { useMutation } from '@apollo/client';
import { useCallback } from 'react';
import {
  PlanumsatzCreateMutation,
  PlanumsatzCreateMutationVariables,
} from '../../../gql/graphql';
import { PLANUMSATZ_CREATE } from './graphql/mutations';

export function usePlanumsatzCreate() {
  const [execute, result] = useMutation<
    PlanumsatzCreateMutation,
    PlanumsatzCreateMutationVariables
  >(PLANUMSATZ_CREATE, {
    refetchQueries: ['getPlanUmsaetzeByStandortId'],
  });

  const updatePlanumsatz = useCallback(
    (variables: PlanumsatzCreateMutationVariables) => {
      return execute({ variables });
    },
    [execute],
  );

  return [updatePlanumsatz, result] as const;
}
