import { faSpinner } from '@fortawesome/pro-duotone-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Dispatch, SetStateAction, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { updateMFAPreference } from 'aws-amplify/auth';
import { Button } from '@/components/ui/button';
import * as Sentry from '@sentry/react';

export function DisableMFA({
  setIsMFAEnabled,
  handleOpenFormChange,
}: {
  setIsMFAEnabled: Dispatch<SetStateAction<boolean>>;
  handleOpenFormChange: (value: boolean) => void;
}) {
  const { t } = useTranslation();
  const [buttonState, setButtonState] = useState({
    isSubmitting: false,
    inital: true,
  });

  const handleMFADeactivation = async function () {
    setButtonState({ inital: false, isSubmitting: true });
    try {
      await updateMFAPreference({ totp: 'DISABLED' });
      setIsMFAEnabled(false);
      handleOpenFormChange(false);
    } catch (error) {
      Sentry.captureException(error);
      setButtonState({
        inital: true,
        isSubmitting: false,
      });
      // TODO
      // wenn eine Notification Komponenten existiert, soll hier eine Notification getriggert werden
    }
  };

  return buttonState.inital ? (
    <Button
      data-testid="pages-profile-security-disable-mfa-button"
      onClick={handleMFADeactivation}
      size="sm"
      variant="destructive">
      {t('pages.profile.sections.security.headerButtonLabelDisable')}
    </Button>
  ) : (
    <div className="flex gap-1 items-center text-xs leading-6 text-neutrals-N700">
      <FontAwesomeIcon icon={faSpinner} spin />
      {t('pages.profile.sections.security.haderButtonLableDisableIsSubmitting')}
    </div>
  );
}
