import { createContext, ReactNode, useContext, useState } from 'react';
import { useSuspenseQuery } from '@apollo/client';
import { GET_STANDORTE_FOR_MANAGER } from '../hooks/graphql';
import Cookies from 'js-cookie';
import { getCookieDomain } from '../utils';
import { handleGraphQLErrors } from '../utils/errors/handleGraphQLErrors';
import {
  Manager,
  ManagerError,
  Standort as StandortGqlType,
} from '../gql/graphql';

type Standort = {
  id: string;
  name: string;
};

type StandortContextType = {
  standorte: Standort[];
  currentStandort: Standort;
  updateStandort: (standort: Standort) => void;
};

const StandortContext = createContext<StandortContextType | null>(null);

export function StandortProvider({ children }: { children: ReactNode }) {
  const { data } = useSuspenseQuery(GET_STANDORTE_FOR_MANAGER);

  const manager = data.manager as Manager;
  const standorte = manager.standorte.map(({ id, name }: StandortGqlType) => ({
    id,
    name,
  }));
  const standortCookie = Cookies.get('standort');
  const initialStandort = standortCookie
    ? standorte.find(({ id }) => id === standortCookie)
    : standorte.find(({ id }) => id === manager.idStandort);

  const [currentStandort, setCurrentStandort] = useState<Standort>(
    initialStandort!,
  );

  const updateStandort = (standort: Standort) => {
    Cookies.set('standort', standort.id, {
      domain: getCookieDomain(),
      expires: 365,
    });
    setCurrentStandort(standort);
  };

  const errors = handleGraphQLErrors<Pick<Manager, '__typename'>, ManagerError>(
    data.manager,
  );

  if (errors) {
    return null;
  }

  return (
    <StandortContext.Provider
      value={{ standorte, currentStandort, updateStandort }}>
      {children}
    </StandortContext.Provider>
  );
}

// Würde ich hier disablen, damit Context und der zugehörige Hook zusammenstehen
// eslint-disable-next-line react-refresh/only-export-components
export function useStandortContext<T>(
  selector: (state: StandortContextType) => T,
): T {
  const context = useContext(StandortContext);
  if (!context) throw new Error('Missing StandortContext.Provider');
  return selector(context);
}
