import { useTranslation } from 'react-i18next';
import { z } from 'zod';

export const useFormSchema = () => {
  const { t } = useTranslation();

  return z
    .object({
      beginn: z.string().nonempty(''),
      ende: z.string().nullable(),
      kommen: z.string().nullable(),
      gehen: z.string().nullable(),
      planBeginn: z.string().nullable(),
      planEnde: z.string().nullable(),
    })
    .refine(
      (data) => {
        return data.beginn !== '' && data.ende !== '';
      },
      {
        message: t(
          'domain.arbeitszeiten.sheet.arbeitszeit.arbeitszeitError.editEndeOhneBeginn',
        ),
        path: ['dauer'],
      },
    );
};
export type FormValues = z.infer<ReturnType<typeof useFormSchema>>;
