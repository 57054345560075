import { TableCell } from '@/components/ui/table';
import { t } from 'i18next';

export function DauerCell({ dauer }: { dauer: number }) {
  return (
    <TableCell className="font-medium p-4">
      <div className="flex gap-1 items-baseline">
        <span className="font-semibold">{dauer}</span>
        <span className="text-neutrals-N700 text-xs">
          {t('general.date.minutes')}
        </span>
      </div>
    </TableCell>
  );
}
