import { Text } from './Text';
import { Container } from './Container';
import { Avatar } from './Avatar';
import { Badge } from './Badge';
import { TextField } from './TextField';
import { Field } from './Field';

const Skeleton = {
  Text,
  Avatar,
  Badge,
  Container,
  TextField,
  Field,
};

export default Skeleton;
