import { useSuspenseQuery } from '@apollo/client';
import { GET_CURRENT_STANDORT } from './graphql/queries';
import { Manager, ManagerError, Standort } from '../gql/graphql';
import { handleGraphQLErrors } from '../utils/errors/handleGraphQLErrors';
import { useStandortContext } from '../store/standortContext';

export function useStandort(): {
  standortName: string;
  standortId: string;
  abteilungenEnabled: boolean;
  error?: string;
} {
  const { data } = useSuspenseQuery(GET_CURRENT_STANDORT);
  const current = useStandortContext((store) => store.currentStandort);
  const error = handleGraphQLErrors<Pick<Manager, '__typename'>, ManagerError>(
    data.manager,
  );
  if (!error) {
    const manager = data.manager as Manager;
    const currentStandort = manager.standorte.filter(
      (standort: Standort) => standort.id === current.id,
    ) as Standort[];
    return {
      standortId: currentStandort[0].id,
      standortName: currentStandort[0].name,
      abteilungenEnabled: currentStandort[0].settings.abteilungenEnabled,
    };
  } else {
    return {
      standortId: '',
      standortName: '',
      abteilungenEnabled: false,
      error: error.message,
    };
  }
}
