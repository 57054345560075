import { Suspense } from 'react';
import { useParams } from 'react-router-dom';
import { LoadingPage } from '../../../../pages';
import { Page } from './Page';
import { MonthParams } from './types';

export function PageWrapper() {
  const { year, month } = useParams<MonthParams>();
  return (
    <Suspense key={month + '-' + year} fallback={<LoadingPage />}>
      <div className="flex flex-col gap-8 py-10 px-10 w-screen 2xl:max-w-screen-2xl 2xl:mx-auto">
        <Page />
      </div>
    </Suspense>
  );
}
