import { memo } from 'react';
import { E2NTableCell } from '../../../../components/Table';
import { Arbeitsbereich } from '../../entities';
import { Markierung } from '../Markierung';
import { markierungMapper } from '@/utils';

export const ArbeitsbereichCell = memo(function ArbeitsbereichCell({
  arbeitsbereich,
}: {
  arbeitsbereich: Arbeitsbereich;
}) {
  return (
    <E2NTableCell className="font-semibold w-[30%]" isBorderVisible>
      <div className="flex gap-2 items-center">
        <Markierung color={markierungMapper[arbeitsbereich.markierung].base} />
        <span>{arbeitsbereich.name}</span>
      </div>
    </E2NTableCell>
  );
});
