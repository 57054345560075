import { useEffect, useRef } from 'react';

type SingleOTPInputProps = React.InputHTMLAttributes<HTMLInputElement> & {
  focus?: boolean;
};

function usePrevious<T>(value?: T) {
  const ref = useRef<T>();

  useEffect(() => {
    ref.current = value;
  }, [value]);

  return ref.current;
}

function handleFocus(
  inputElement: HTMLInputElement,
  autoFocus: boolean,
  prevFocus: boolean,
  focus: boolean,
) {
  if (focus && autoFocus) {
    inputElement.focus();
  }
  if (focus && autoFocus && focus !== prevFocus) {
    inputElement.focus();
    inputElement.select();
  }
}

export function SingleInput({
  focus,
  autoFocus,
  ...props
}: SingleOTPInputProps) {
  const inputRef = useRef<HTMLInputElement>(null);
  const prevFocus = usePrevious(!!focus);

  useEffect(() => {
    if (inputRef.current) {
      handleFocus(inputRef.current, autoFocus, prevFocus, focus);
    }
  }, [autoFocus, focus, prevFocus]);

  return (
    <input
      ref={inputRef}
      className="border border-solid border-neutrals-N300  hover:cursor-pointer focus:border-neutrals-N700 focus:outline-none transition-colors w-8 xs:w-12 h-12 xs:h-16 text-center text-xl xs:text-3xl rounded-lg"
      {...props}
    />
  );
}
