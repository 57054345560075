import {
  faChartPieSimpleCircleDollar,
  faMinus,
} from '@fortawesome/pro-regular-svg-icons';
import { Zuschlag } from '../../entities';
import { Badge } from '@/components/ui/badge';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export function ZuschlaegeCell({ zuschlaege }: { zuschlaege: Zuschlag[] }) {
  return (
    <Badge
      className="px-2 py-1.5 space-x-2 bg-neutrals-N200 w-[50px]"
      variant="secondary">
      <FontAwesomeIcon
        icon={faChartPieSimpleCircleDollar}
        className={
          zuschlaege.length > 0
            ? 'text-primary-blue-B600'
            : 'text-neutrals-N600'
        }
        fixedWidth
      />
      {zuschlaege.length > 0 ? (
        <span className="font-bold"> {zuschlaege.length}</span>
      ) : (
        <span className="px-0">
          <FontAwesomeIcon
            icon={faMinus}
            size="xs"
            className="text-neutrals-N600"
            fixedWidth
          />
        </span>
      )}
    </Badge>
  );
}
