import { BackgroundColor } from '@/domain/arbeitszeiten/entities/Arbeitszeit';
import { backgroundMapper } from '../../../../../utils/backgroundColor';
import { BackgroundColor as ColorComponent } from '../../BackgroundColor';
import { useEditSheetContext } from '../EditSheetContext';

export function Rechtshinweise() {
  const { arbeitszeit } = useEditSheetContext();
  function getColorByOption(backgroundcolor: BackgroundColor) {
    if (backgroundcolor === BackgroundColor.Info) {
      return backgroundMapper['PRIMARY'].base;
    }
    if (backgroundcolor === BackgroundColor.Fatal) {
      return backgroundMapper['ERROR'].base;
    }
    return backgroundMapper['WARNING'].base;
  }

  return (
    <ul className="divide-y divide-neutrals-N300">
      {arbeitszeit.rechtshinweise.map((rechtshinweis) => (
        <li key={rechtshinweis.name} className="py-4">
          <div className="flex w-full space-x-8 items-center">
            <ColorComponent
              value={rechtshinweis.art}
              color={getColorByOption(rechtshinweis.backgroundcolor)}
            />
            <span className="flex-1 font-medium">{rechtshinweis.name}</span>
          </div>
        </li>
      ))}
    </ul>
  );
}
