import { Link } from '@/components/Link';
import { useTranslation } from 'react-i18next';

export function Footer() {
  const { t } = useTranslation();

  return (
    <div className="w-full grid grid-cols-1 px-8 xs:px-16 py-8">
      <div className="flex flex-col xs:flex-row text-sm gap-1 items-center xs:items-baseline self-center justify-self-center text-neutrals-N900">
        <span>2025 © E2N GmbH</span>
        <span className="hidden xs:block"> | </span>
        <Link target="_blank" href="https://e2n.de/datenschutz/">
          {t('pages.login.footer.privacy')}
        </Link>
        <span className="hidden xs:block"> | </span>
        <Link target="_blank" href="https://e2n.de/impressum/">
          {t('pages.login.footer.terms')}
        </Link>
      </div>
    </div>
  );
}
