/* eslint-disable max-lines-per-function */
/* eslint-disable max-lines */
import { ColumnDef } from '@tanstack/react-table';
import {
  Arbeitsbereich,
  Arbeitszeit,
  Dienstplanuebernahme,
  Mitarbeiter,
} from './entities';
import { useTranslation } from 'react-i18next';
import {
  ArbeitsbereichCell,
  ArbeitszeitCell,
  DauerCell,
  MitarbeiterCell,
} from './components';
import {
  filterArbeitsbereiche,
  filterMitarbeiterName,
} from './filterFunctions';
import { calculateAbweichung } from '../../utils/helperFunctions';
import { EditCell } from './components/Cells/EditCell';
import { AbwesenheitsCell } from './components/Cells/AbwesenheitsCell';
import { mapAbwesenheitToIcon } from './util/mapAbwesenheitToIcon';
import { SortableHeader } from './components/Table';
import { ArbeitsbereichFilter } from './DataTable';
import {
  TerminTooltip,
  BemerkungTooltip,
  RechtshinweiseTooltip,
  ZuschlaegeTooltip,
} from './components/Tooltips';
import { useProfile } from '../../hooks';
import { E2NTableCell } from '../../components/Table';
import { ArbeitszeitAntragCell } from './components/Cells/ArbeitszeitAntragCell';
import { DienstplanuebernahmeArbeitszeit } from './components/Dienstplanuebernahme/DienstplanuebernahmeArbeitszeit';
import { DienstplanuebernahmeEdit } from './components/Dienstplanuebernahme/DienstplanuebernahmeEdit';

export function useColumns(): {
  abgeschlosseneArbeitszeiten: ColumnDef<Arbeitszeit>[];
  offeneArbeitszeiten: ColumnDef<Arbeitszeit>[];
} {
  const { t } = useTranslation();
  const { manager } = useProfile();

  const mitarbeiterColumnDef: ColumnDef<Arbeitszeit> = {
    accessorKey: 'mitarbeiter',
    cell: (props) => {
      const mitarbeiter = props.getValue() as Mitarbeiter;
      return <MitarbeiterCell mitarbeiter={mitarbeiter} />;
    },
    header: ({ column }) => (
      <SortableHeader
        title={t('entities.name_one')}
        sorted={column.getIsSorted()}
      />
    ),
    filterFn: (row, _, filterValue) =>
      filterMitarbeiterName(
        row.original.mitarbeiter.vorname,
        row.original.mitarbeiter.nachname,
        filterValue,
      ),
    sortingFn: (rowA, rowB) => {
      const mitarbeiternameA =
        rowA.original.mitarbeiter.vorname + rowA.original.mitarbeiter.nachname;
      const mitarbeiternameB =
        rowB.original.mitarbeiter.vorname + rowB.original.mitarbeiter.nachname;
      return mitarbeiternameA.localeCompare(mitarbeiternameB);
    },
  };

  const arbeitsbereichColumnDef: ColumnDef<Arbeitszeit> = {
    accessorKey: 'arbeitsbereich',
    cell: (props) => {
      const arbeitsbereich = props.getValue() as Arbeitsbereich;

      return <ArbeitsbereichCell arbeitsbereich={arbeitsbereich} />;
    },

    filterFn: (row, _, filterValue: ArbeitsbereichFilter) => {
      return filterArbeitsbereiche(
        row.original.arbeitsbereich.id,
        row.original.arbeitsbereich.abteilungen,
        filterValue,
        filterValue.arbeitsbereiche,
        filterValue.abteilungen,
      );
    },
    header: ({ column }) => (
      <SortableHeader
        title={t('entities.department_one')}
        sorted={column.getIsSorted()}
      />
    ),
    sortingFn: (rowA, rowB) => {
      const arbeitsbereichA =
        rowA.original.arbeitsbereich.name + rowA.original.arbeitsbereich.id;
      const arbeitsbereichB =
        rowB.original.arbeitsbereich.name + rowB.original.arbeitsbereich.id;
      return arbeitsbereichA.localeCompare(arbeitsbereichB);
    },
  };

  const arbeitszeitColumnDef: ColumnDef<Arbeitszeit> = {
    accessorKey: 'arbeitszeit',
    cell: (props) => {
      const arbeitszeit = props.row.original as Arbeitszeit;
      if (arbeitszeit.antraege.length !== 0) {
        return (
          <ArbeitszeitAntragCell
            beginn={arbeitszeit.start}
            ende={arbeitszeit.ende}
            dauer={arbeitszeit.pausendauer}
            antraege={arbeitszeit.antraege}
            arbeitszeitId={arbeitszeit.id}
          />
        );
      }
      if (arbeitszeit.abwesenheit !== null) {
        return (
          <AbwesenheitsCell
            icon={mapAbwesenheitToIcon(arbeitszeit.abwesenheit)}
            grund={arbeitszeit.abwesenheit.grund.toLocaleUpperCase()}
          />
        );
      }
      return (
        <ArbeitszeitCell
          beginn={arbeitszeit.start}
          ende={arbeitszeit.ende}
          dauer={arbeitszeit.pausendauer}
          puenktlichkeit={arbeitszeit.puenktlichkeit}
          kommen={arbeitszeit.kommen}
          gehen={arbeitszeit.gehen}
        />
      );
    },
    header: ({ column }) => (
      <SortableHeader
        title={t('entities.workingHour_one')}
        sorted={column.getIsSorted()}
      />
    ),
    sortingFn: (rowA, rowB) => {
      const startA = rowA.original.start;
      const startB = rowB.original.start;
      if (startA === null) {
        return 1;
      }
      if (startB === null) {
        return -1;
      }
      if (startA < startB) {
        return -1;
      }
      if (startA > startB) {
        return 1;
      }
      return 0;
    },
  };

  const dauerColumnDef: ColumnDef<Arbeitszeit> = {
    accessorKey: 'dauer',
    header: ({ column }) => (
      <SortableHeader
        title={t('entities.duration')}
        sorted={column.getIsSorted()}
      />
    ),
    sortingFn: (rowA, rowB) => {
      let dauerA = rowA.original.dauer;
      let dauerB = rowB.original.dauer;
      if (rowA.original.abwesenheit !== null) {
        dauerA = rowA.original.abwesenheit.dauer;
      }
      if (rowB.original.abwesenheit !== null) {
        dauerB = rowB.original.abwesenheit.dauer;
      }
      if (dauerA < dauerB) {
        return -1;
      }
      if (dauerA > dauerB) {
        return 1;
      }
      return 0;
    },
    cell: (props) => {
      const arbeitszeit = props.row.original;
      const abwesenheit = arbeitszeit.abwesenheit === null;
      if (arbeitszeit.abwesenheit !== null) {
        return (
          <DauerCell
            dauer={arbeitszeit.abwesenheit.dauer}
            isBorderVisible={abwesenheit}
          />
        );
      }
      let dauerCell = (
        <DauerCell dauer={arbeitszeit.dauer} isBorderVisible={abwesenheit} />
      );
      if (
        arbeitszeit.puenktlichkeit.abweichungBeginn !== undefined &&
        arbeitszeit.puenktlichkeit.abweichungEnde !== undefined
      ) {
        const abweichung = calculateAbweichung(
          arbeitszeit.puenktlichkeit.abweichungBeginn,
          arbeitszeit.puenktlichkeit.abweichungEnde,
        );
        dauerCell = (
          <DauerCell
            dauer={arbeitszeit.dauer}
            abweichung={abweichung}
            isBorderVisible={abwesenheit}
          />
        );
      }
      return dauerCell;
    },
  };

  const tooltipsColumnDef: ColumnDef<Arbeitszeit> = {
    accessorKey: 'tooltips',
    header: '',
    cell: (props) => {
      const arbeitszeit = props.row.original;

      return !arbeitszeit.abwesenheit ? (
        <E2NTableCell className="font-semibold w-40" isBorderVisible>
          <div className="flex gap-2 justify-between">
            <RechtshinweiseTooltip
              rechtshinweise={{
                rechtshinweise: arbeitszeit.rechtshinweise,
              }}
            />
            <ZuschlaegeTooltip zuschlaege={arbeitszeit.zuschlaege} />
            {manager.rechte.termine && (
              <TerminTooltip termin={arbeitszeit.termin} />
            )}
            <BemerkungTooltip bemerkung={arbeitszeit.bemerkung} />
          </div>
        </E2NTableCell>
      ) : (
        <E2NTableCell className="font-semibold w-auto"></E2NTableCell>
      );
    },
  };

  const editButtonColumnDef: ColumnDef<Arbeitszeit> = {
    id: 'editButton',
    header: '',
    cell: (props) => {
      const arbeitszeit = props.row.original as Arbeitszeit;
      if (arbeitszeit.abwesenheit !== null) {
        return <E2NTableCell className="rounded-r-lg w-40 p-4"></E2NTableCell>;
      }
      return <EditCell arbeitszeit={arbeitszeit} />;
    },
  };

  return {
    abgeschlosseneArbeitszeiten: [
      mitarbeiterColumnDef,
      arbeitsbereichColumnDef,
      arbeitszeitColumnDef,
      dauerColumnDef,
      tooltipsColumnDef,
      editButtonColumnDef,
    ],
    offeneArbeitszeiten: [
      mitarbeiterColumnDef,
      arbeitsbereichColumnDef,
      arbeitszeitColumnDef,
      dauerColumnDef,
      tooltipsColumnDef,
      editButtonColumnDef,
    ],
  };
}

export function useDienstplanColumnDefs(): ColumnDef<Dienstplanuebernahme>[] {
  const { t } = useTranslation();
  const { manager } = useProfile();
  const mitarbeiterColumnDef: ColumnDef<Dienstplanuebernahme> = {
    accessorKey: 'mitarbeiter',
    cell: (props) => {
      const mitarbeiter = props.getValue() as Mitarbeiter;
      return <MitarbeiterCell mitarbeiter={mitarbeiter} />;
    },
    header: ({ column }) => (
      <SortableHeader
        title={t('entities.name_one')}
        sorted={column.getIsSorted()}
      />
    ),
    filterFn: (row, _, filterValue) =>
      filterMitarbeiterName(
        row.original.mitarbeiter.vorname,
        row.original.mitarbeiter.nachname,
        filterValue,
      ),
    sortingFn: (rowA, rowB) => {
      const mitarbeiterNameA =
        rowA.original.mitarbeiter.vorname + rowA.original.mitarbeiter.nachname;
      const mitarbeiternameB =
        rowB.original.mitarbeiter.vorname + rowB.original.mitarbeiter.nachname;
      return mitarbeiterNameA.localeCompare(mitarbeiternameB);
    },
  };

  const arbeitsbereichColumnDef: ColumnDef<Dienstplanuebernahme> = {
    accessorKey: 'arbeitsbereich',
    cell: (props) => {
      const arbeitsbereich = props.getValue() as Arbeitsbereich;

      return <ArbeitsbereichCell arbeitsbereich={arbeitsbereich} />;
    },

    filterFn: (row, _, filterValue: ArbeitsbereichFilter) => {
      return filterArbeitsbereiche(
        row.original.arbeitsbereich.id,
        row.original.arbeitsbereich.abteilungen,
        filterValue,
        filterValue.arbeitsbereiche,
        filterValue.abteilungen,
      );
    },
    header: ({ column }) => (
      <SortableHeader
        title={t('entities.department_one')}
        sorted={column.getIsSorted()}
      />
    ),
    sortingFn: (rowA, rowB) => {
      const arbeitsbereichA =
        rowA.original.arbeitsbereich.name + rowA.original.arbeitsbereich.id;
      const arbeitsbereichB =
        rowB.original.arbeitsbereich.name + rowB.original.arbeitsbereich.id;
      return arbeitsbereichA.localeCompare(arbeitsbereichB);
    },
  };

  const arbeitszeitColumnDef: ColumnDef<Dienstplanuebernahme> = {
    accessorKey: 'arbeitszeit',
    cell: (props) => {
      const diensplanuebernahme = props.row.original as Dienstplanuebernahme;
      return (
        <DienstplanuebernahmeArbeitszeit
          beginn={diensplanuebernahme.beginn}
          ende={diensplanuebernahme.ende}
          dauer={diensplanuebernahme.pause}
        />
      );
    },
    header: ({ column }) => (
      <SortableHeader
        title={t('entities.workingHour_one')}
        sorted={column.getIsSorted()}
      />
    ),
    sortingFn: (rowA, rowB) => {
      const beginn = rowA.original.beginn;
      const ende = rowB.original.ende;
      if (ende === null) {
        return -1;
      }
      if (beginn < ende) {
        return -1;
      }
      if (beginn > ende) {
        return 1;
      }
      return 0;
    },
  };

  const dauerColumnDef: ColumnDef<Dienstplanuebernahme> = {
    accessorKey: 'dauer',
    header: ({ column }) => (
      <SortableHeader
        title={t('entities.duration')}
        sorted={column.getIsSorted()}
      />
    ),
    sortingFn: (rowA, rowB) => {
      const dauerA = rowA.original.dauer;
      const dauerB = rowB.original.dauer;
      if (dauerA < dauerB) {
        return -1;
      }
      if (dauerA > dauerB) {
        return 1;
      }
      return 0;
    },
    cell: (props) => {
      const dienstplanuebernahme = props.row.original;
      return (
        <DauerCell dauer={dienstplanuebernahme.dauer} isBorderVisible={true} />
      );
    },
  };

  const tooltipsColumnDef: ColumnDef<Dienstplanuebernahme> = {
    accessorKey: 'tooltips',
    header: '',
    cell: (props) => {
      const dienstplanuebernahme = props.row.original;

      return (
        <E2NTableCell className="font-semibold w-auto" isBorderVisible>
          <div className="flex gap-2 justify-between">
            <RechtshinweiseTooltip
              rechtshinweise={{
                rechtshinweise: dienstplanuebernahme.rechtshinweise,
              }}
            />
            <ZuschlaegeTooltip zuschlaege={dienstplanuebernahme.zuschlaege} />
            {manager.rechte.termine && <TerminTooltip />}
            <BemerkungTooltip bemerkung={dienstplanuebernahme.bemerkung} />
          </div>
        </E2NTableCell>
      );
    },
  };

  const editButtonColumnDef: ColumnDef<Dienstplanuebernahme> = {
    id: 'editButton',
    header: '',
    cell: (props) => {
      const dienstplanuebernahme = props.row.original as Dienstplanuebernahme;

      return (
        <DienstplanuebernahmeEdit dienstplanuebernahme={dienstplanuebernahme} />
      );
    },
  };

  return [
    mitarbeiterColumnDef,
    arbeitsbereichColumnDef,
    arbeitszeitColumnDef,
    dauerColumnDef,
    tooltipsColumnDef,
    editButtonColumnDef,
  ];
}
