import { maskitoTransform } from '@maskito/core';
import { maskitoNumberOptionsGenerator } from '@maskito/kit';

export function formatNumber(value: string, isAbsoluteNumber: boolean) {
  // Mache aus 1234.55 -> 1234,55
  const numberWithCommaSeparator = maskitoTransform(
    value,
    maskitoNumberOptionsGenerator({
      thousandSeparator: '',
      decimalSeparator: ',',
      precision: !isAbsoluteNumber ? 2 : 0,
    }),
  );

  // Mache aus 1234,55 -> 1.234,55
  const numberWithThousandSeparator = maskitoTransform(
    numberWithCommaSeparator,
    maskitoNumberOptionsGenerator({
      thousandSeparator: '.',
      decimalSeparator: ',',
      precision: !isAbsoluteNumber ? 2 : 0,
      decimalZeroPadding: !isAbsoluteNumber ? true : false,
    }),
  );

  return numberWithThousandSeparator;
}
