import { NavigationDropdownButton, NavigationButton } from '@e2n/e2n-ui';
import { useTranslation } from 'react-i18next';
import { buildLink } from '../../utils';
import { ManagerProfile, useProfile } from '../../hooks';
import { Managerrechte } from '../../gql/graphql.ts';
import { TFunction } from 'i18next';

// Hier würde ich die Linter Regeln auch Disabeln, da ich nicht wüsste wie man das Zusammenbauen der Menuitems anders handeln könnte
// eslint-disable-next-line complexity, max-lines-per-function
export function useMenuItems() {
  const { t } = useTranslation();
  const profile = useProfile();
  const manager = profile.manager;
  const menuItems = [];

  if (manager.hasControllingRecht()) {
    menuItems.push(createControllingDropdown(manager, t));
  }

  if (manager.rechte.mitarbeiter.erlaubt) {
    menuItems.push(
      <NavigationButton
        key="employee"
        data-testid="menu-item-employee"
        component="a"
        href={buildLink('mitarbeiterListe')}>
        {t('entities.employee', { count: 2 })}
      </NavigationButton>,
    );
  }

  if (manager.rechte.kalender) {
    menuItems.push(
      <NavigationButton
        component="a"
        href={buildLink('kalender')}
        key="calendar">
        {t('entities.calendar')}
      </NavigationButton>,
    );
  }

  if (manager.rechte.arbeitszeit.erlaubt) {
    menuItems.push(
      <NavigationButton
        key="arbeitszeit"
        component="a"
        href={buildLink('arbeitszeiten')}>
        {t('entities.workingHour', { count: 2 })}
      </NavigationButton>,
    );
  }
  //TODO: Link austauschen bei Schweizer Mandaten
  if (manager.hasAbrechnungsRecht()) {
    menuItems.push(
      <NavigationButton
        key="payoff"
        component="a"
        href={buildLink('abrechnung')}>
        {t('entities.payoff')}
      </NavigationButton>,
    );
  }

  //TODO: Nicht angzeigen bei gesperrtem Mandaten
  if (manager.rechte.dienstplan.erlaubt) {
    menuItems.push(createDienstplanDropdown(manager.rechte, t));
  }

  if (
    manager.rechte.termine ||
    manager.rechte.mitarbeiter.dokumente ||
    manager.rechte.konflikte
  ) {
    menuItems.push(createMoreDropdown(manager.rechte, t));
  }
  return menuItems;
}

function createControllingDropdown(
  manager: ManagerProfile,
  t: TFunction<'translation', undefined>,
) {
  return (
    <NavigationDropdownButton
      trigger={t('entities.controlling')}
      key="controlling">
      {manager.rechte.tagebuch && (
        <a href={buildLink('tagebuch')}>{t('entities.journal')}</a>
      )}
      {manager.rechte.auswertung.dienstplan && (
        //TODO: Anderer Link wenn der Mandant Schweizer ist
        <a href={buildLink('auswertungenDienstplan')}>
          {t('entities.analysis', { count: 2 })}
        </a>
      )}
      {manager.isPersonalAuswerter() && (
        <a href={buildLink('auswertungenPersonal')}>
          {t('entities.analysis', { count: 2 })}
        </a>
      )}
      {manager.isArbeitszeitAuswerter() && (
        <a href={buildLink('auswertungenArbeitszeit')}>
          {t('entities.analysis', { count: 2 })}
        </a>
      )}
      {/* {manager.rechte.tagebuch && (
        <Link to="/controlling/umsatzplanung">
          {t('entities.umsatzplanung', { count: 2 })}
        </Link>
      )} */}
    </NavigationDropdownButton>
  );
}

function createDienstplanDropdown(
  rechte: Managerrechte,
  t: TFunction<'translation', undefined>,
) {
  return (
    <NavigationDropdownButton
      key="employeeRoster"
      trigger={t('entities.roster')}>
      <a href={buildLink('dienstplanPersonal')}>{t('entities.staff')}</a>
      <a href={buildLink('dienstplanSchicht')}>
        {t('entities.shift', { count: 2 })}
      </a>
      {rechte.dienstplan.schichtvorlagen && (
        <a href={buildLink('dienstplanVorlage')}>
          {t('entities.template', { count: 2 })}
        </a>
      )}
    </NavigationDropdownButton>
  );
}

function createMoreDropdown(
  rechte: Managerrechte,
  t: TFunction<'translation' | undefined>,
) {
  return (
    <NavigationDropdownButton
      key="more"
      trigger={t('general.navigation.moreItems')}>
      {rechte.konflikte && (
        <a href={buildLink('konflikte')}>
          {t('entities.conflict', { count: 2 })}
        </a>
      )}
      {rechte.termine && (
        <a href={buildLink('termine')}>{t('entities.event', { count: 2 })}</a>
      )}
      {rechte.mitarbeiter.dokumente && (
        <a href={buildLink('dokumente')}>
          {t('entities.document', { count: 2 })}
        </a>
      )}
    </NavigationDropdownButton>
  );
}
