import {
  CreateOrUpdateAbsatzzielMutation,
  CreateOrUpdateAbsatzzielMutationVariables,
} from '@/gql/graphql';
import { useMutation } from '@apollo/client';
import { useCallback } from 'react';
import { CREATE_OR_UPDATE_ABSATZZIEL } from './graphql/mutations';

export function useAbsatzzielCreateOrUpdate() {
  const [execute, result] = useMutation<
    CreateOrUpdateAbsatzzielMutation,
    CreateOrUpdateAbsatzzielMutationVariables
  >(CREATE_OR_UPDATE_ABSATZZIEL, {
    refetchQueries: ['getUmsatzAbsatzZieleByStandortId'],
  });

  const createOrUpdateAbsatzziel = useCallback(
    (variables: CreateOrUpdateAbsatzzielMutationVariables) => {
      return execute({ variables });
    },
    [execute],
  );

  return [createOrUpdateAbsatzziel, result] as const;
}
