import { backgroundMapper } from '../../../../utils/backgroundColor';
import { BackgroundColor, Rechtshinweis } from '../../entities';
import { BackgroundColor as ColorComponent } from '../BackgroundColor';

export function RechtshinweiseRow({
  rechtshinweise,
}: {
  rechtshinweise: Rechtshinweis[];
}) {
  function getColorByOption(backgroundcolor: BackgroundColor) {
    if (backgroundcolor === BackgroundColor.Info) {
      return backgroundMapper['PRIMARY'].base;
    }
    if (backgroundcolor === BackgroundColor.Fatal) {
      return backgroundMapper['ERROR'].base;
    }
    return backgroundMapper['WARNING'].base;
  }

  return (
    <div className="flex border-b border-neutrals-N300 last:border-none p-5 gap-5 items-center max-w-[400px] tracking-wide">
      {rechtshinweise.map((rechtshinweis) => (
        <div key={rechtshinweis.name} className="flex gap-5 items-center">
          <ColorComponent
            value={rechtshinweis.art}
            color={getColorByOption(rechtshinweis.backgroundcolor)}
          />
          <span className="flex-1 font-medium">{rechtshinweis.name}</span>
        </div>
      ))}
    </div>
  );
}
