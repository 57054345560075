import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Badge } from '@/components/ui/badge';
import { faCalendarStar } from '@fortawesome/pro-regular-svg-icons';
import { Termin } from '../../entities/Termin';

export function TermineCell({ termin }: { termin?: Termin }) {
  return (
    <Badge className="px-1 py-1.5 bg-neutrals-N200" variant="secondary">
      <FontAwesomeIcon
        icon={faCalendarStar}
        size="xl"
        className={
          termin !== null && termin !== undefined
            ? 'text-primary-blue-B600'
            : 'text-neutrals-N600'
        }
        fixedWidth
      />
    </Badge>
  );
}
