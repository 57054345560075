import { AbsoluteNumberInput } from '@/components';
import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
} from '@/components/ui/form';
import { AbsatzVorgabe } from '@/domain/controlling/entities';
import { useTagebuchEinstellungen } from '@/domain/controlling/hooks/useTagebuchEinstellungen';
import { useStandort } from '@/hooks';
import { UseFormReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

// eslint-disable-next-line max-lines-per-function
export function AbsatzInputFields({
  form,
}: {
  form: UseFormReturn<
    {
      umsatz?: string;
      umsatzProduktivitaet?: string;
      absatz?: string;
      absatzProduktivitaet?: string;
    },
    unknown,
    undefined
  >;
}) {
  const { t } = useTranslation();
  const { standortId } = useStandort();
  const { tagebuchEinstellungen } = useTagebuchEinstellungen(standortId);

  return (
    <>
      <FormField
        control={form.control}
        name="absatz"
        render={({ field }) => (
          <FormItem>
            <FormLabel>
              {t('domain.controlling.umsatzplanung.ziele.dialog.absatz.label')}
            </FormLabel>
            <FormControl>
              <AbsoluteNumberInput
                defaultValue={field.value}
                onBlur={(e) => {
                  field.onChange(e.target.value);
                }}
                onInput={(e) => {
                  field.onChange(e.currentTarget.value);
                }}
                unit="Stk."
              />
            </FormControl>
          </FormItem>
        )}
      />
      <FormField
        control={form.control}
        name="absatzProduktivitaet"
        render={({ field }) => (
          <FormItem>
            <FormLabel>
              {t(
                'domain.controlling.umsatzplanung.ziele.dialog.produktivitaet.label',
              )}
            </FormLabel>
            <FormControl>
              <AbsoluteNumberInput
                defaultValue={field.value}
                onBlur={(e) => {
                  field.onChange(e.target.value);
                }}
                onInput={(e) => {
                  field.onChange(e.currentTarget.value);
                }}
                unit={
                  tagebuchEinstellungen.absatzproduktivitaet.absatzVorgabe ===
                  AbsatzVorgabe.Stueck
                    ? t('domain.controlling.umsatzplanung.ziele.Stk./Std.')
                    : t('domain.controlling.umsatzplanung.ziele.mpt')
                }
              />
            </FormControl>
          </FormItem>
        )}
      />
    </>
  );
}
