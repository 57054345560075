import { Bar, BarChart, CartesianGrid, XAxis } from 'recharts';
import { buildChartData } from './buildChartData';
import { TagebuchUpdateInput } from '../../../../../gql/graphql';
import {
  ChartConfig,
  ChartContainer,
  ChartTooltip,
  ChartTooltipContent,
} from '@/components/ui/chart';

export function Chart({
  dates,
  planumsaetze,
}: {
  dates: {
    date: string;
    weekday: string;
  }[];
  planumsaetze: TagebuchUpdateInput[];
}) {
  const chartData = buildChartData(dates, planumsaetze);

  const chartConfig = {
    umsatz: {
      label: 'Umsatz',
      color: '#007AFF',
    },
  } satisfies ChartConfig;

  return (
    <ChartContainer
      config={chartConfig}
      className="min-h-[150px] max-h-[200px] w-full h-screen">
      <BarChart barCategoryGap="35%" accessibilityLayer data={chartData}>
        <CartesianGrid vertical={false} />
        <ChartTooltip
          cursor={{ fillOpacity: 0.3 }}
          content={<ChartTooltipContent />}
        />
        <Bar dataKey="umsatz" fill="var(--color-umsatz)" radius={4} />
        <XAxis
          dataKey="day"
          tickLine={false}
          tickMargin={10}
          axisLine={false}
          tickFormatter={(value) => value.slice(0, 3)}
        />
      </BarChart>
    </ChartContainer>
  );
}
