import { useMaskito } from '@maskito/react';
import { maskitoNumberOptionsGenerator } from '@maskito/kit';
import { ReactNode, useState } from 'react';
import { Input, InputProps } from '@/components/ui/input';

const numberMaskingOptions = maskitoNumberOptionsGenerator({
  thousandSeparator: '.',
  min: 0,
  decimalSeparator: ',',
});

export function AbsoluteNumberInput(props: InputProps & { unit?: ReactNode }) {
  const inputRef = useMaskito({ options: numberMaskingOptions });
  const [focus, setFocus] = useState(false);
  const { onBlur, className, ...otherProps } = props;

  return (
    <div className="flex items-center relative">
      <div className="grow">
        <Input
          onFocus={() => {
            setFocus(true);
          }}
          {...otherProps}
          ref={inputRef}
          className={`min-w-full grow ${className}`}
          onBlur={(e) => {
            setFocus(false);
            onBlur(e);
          }}
        />
      </div>
      {props.unit && (
        <span
          className={`${
            focus ? 'text-primary-blue-B600' : 'text-neutrals-N700'
          } font-extralight mr-2 absolute right-0`}>
          {props.unit}
        </span>
      )}
    </div>
  );
}
