import dayjs from 'dayjs';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChevronLeft,
  faChevronRight,
} from '@fortawesome/pro-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Button } from '@/components/ui/button';
import { getMonthNameByDate } from '@/utils';

export function MonthPicker({ startDate }: { startDate: string }) {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleTodayClick = () => {
    const year = dayjs(new Date()).get('year');
    const month = dayjs(new Date()).get('month') + 1;
    navigate(`/controlling/umsatzplanung/${year}/${month}`, {
      replace: true,
    });
  };

  const handlePrevMonthClick = () => {
    const year = dayjs(startDate).subtract(1, 'M').get('year');
    const month = dayjs(startDate).subtract(1, 'M').get('month') + 1;
    navigate(`/controlling/umsatzplanung/${year}/${month}`, {
      replace: true,
    });
  };

  const handleNextMonthClick = () => {
    const year = dayjs(startDate).add(1, 'M').get('year');
    const month = dayjs(startDate).add(1, 'M').get('month') + 1;
    navigate(`/controlling/umsatzplanung/${year}/${month}`, {
      replace: true,
    });
  };

  return (
    <div className="flex items-center gap-2">
      <div className="flex gap-8 items-center border border-neutrals-N400 rounded-md py-2 px-4">
        <FontAwesomeIcon
          className="text-neutrals-N700 hover:text-neutrals-N500 transition-colors hover:cursor-pointer"
          icon={faChevronLeft}
          onClick={handlePrevMonthClick}
        />
        <div className="w-32 text-center text-sm">
          {getMonthNameByDate(dayjs(startDate).format('YYYY-MM-DD'))}
        </div>
        <FontAwesomeIcon
          className="text-neutrals-N700 hover:text-neutrals-N500 transition-colors hover:cursor-pointer"
          icon={faChevronRight}
          onClick={handleNextMonthClick}
        />
      </div>
      <Button onClick={handleTodayClick} variant="ghost">
        {t('general.date.today')}
      </Button>
    </div>
  );
}
