import { format, eachDayOfInterval } from 'date-fns';
import { de } from 'date-fns/locale/de';
import dayjs from 'dayjs';
import i18n from 'i18next';

export const getAllDatesFromCurrentMonth = (start: string, end: string) => {
  const daysInInterval = eachDayOfInterval({ start, end });

  return daysInInterval.map((date) => ({
    date: format(date, 'yyyy-MM-dd', { locale: de }),
    weekday: format(date, 'EE', { locale: de }),
  }));
};

export const getMonthNameByDate = (date: string) => {
  const month = dayjs(date).get('month');
  const year = dayjs(date).get('year');
  const names = [
    'Januar',
    'Februar',
    'März',
    'April',
    'Mai',
    'Juni',
    'Juli',
    'August',
    'September',
    'Oktober',
    'November',
    'Dezember',
  ];
  return names[month] + ' ' + year;
};

export const getMonthNameByDateObject = (date: Date) => {
  const names = [
    i18n.t('general.date.months.01'),
    i18n.t('general.date.months.02'),
    i18n.t('general.date.months.03'),
    i18n.t('general.date.months.04'),
    i18n.t('general.date.months.05'),
    i18n.t('general.date.months.06'),
    i18n.t('general.date.months.07'),
    i18n.t('general.date.months.08'),
    i18n.t('general.date.months.09'),
    i18n.t('general.date.months.10'),
    i18n.t('general.date.months.11'),
    i18n.t('general.date.months.12'),
  ];
  return names[date.getMonth()];
};

type Weekday = {
  dayOfWeek: number;
  name: string;
  shortage: string;
};

// eslint-disable-next-line complexity
export function getWeekdayByDate(date: Date): Weekday {
  switch (date.getDay()) {
    case 0:
      return {
        name: i18n.t('general.date.days.sunday'),
        shortage: i18n.t('general.date.days.sundayShort'),
        dayOfWeek: 0,
      };
    case 1:
      return {
        name: i18n.t('general.date.days.monday'),
        shortage: i18n.t('general.date.days.mondayShort'),
        dayOfWeek: 1,
      };
    case 2:
      return {
        name: i18n.t('general.date.days.tuesday'),
        shortage: i18n.t('general.date.days.tuesdayShort'),
        dayOfWeek: 2,
      };
    case 3:
      return {
        name: i18n.t('general.date.days.wednesday'),
        shortage: i18n.t('general.date.days.wednesdayShort'),
        dayOfWeek: 3,
      };
    case 4:
      return {
        name: i18n.t('general.date.days.thursday'),
        shortage: i18n.t('general.date.days.thursdayShort'),
        dayOfWeek: 4,
      };
    case 5:
      return {
        name: i18n.t('general.date.days.friday'),
        shortage: i18n.t('general.date.days.fridayShort'),
        dayOfWeek: 5,
      };
    default:
      return {
        name: i18n.t('general.date.days.saturday'),
        shortage: i18n.t('general.date.days.saturdayShort'),
        dayOfWeek: 6,
      };
  }
}
