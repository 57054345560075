import { Vertrag } from '../../../../gql/graphql';
import { VertragsInfo } from './VertragsInfo';
import { Abrechnungsart } from './Abrechnungsart';
import { Vertragsvereinbarung } from './Vertragsvereinbarung';
import { Card, CardContent } from '@/components/ui/card';
import KuendigungButton from './KuendigungButton';

function Separator() {
  return (
    <hr className="border-t my-2 mx-[3.4rem] border-dashed border-neutrals-N300" />
  );
}

function SeparatorWithoutSpace() {
  return <hr className="border-t my-2 border-dashed border-neutrals-N300" />;
}

function VertraegeCard({ vertrag }: { vertrag: Vertrag }) {
  return (
    <Card className="transition duration-300 ease-in-out hover:shadow-lg m-12 px-0 py-3">
      <CardContent className="p-0">
        <div className="flex flex-col">
          <VertragsInfo vertrag={vertrag} />
          <SeparatorWithoutSpace />
          <Abrechnungsart vertrag={vertrag} />
          <Separator />
          <Vertragsvereinbarung vertrag={vertrag} />
          <KuendigungButton />
        </div>
      </CardContent>
    </Card>
  );
}

export default VertraegeCard;
