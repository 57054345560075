import { TableFormField } from './TableFormField';
import { PlanumsatzEntry } from '../../types';
import { maskitoParseNumber } from '@maskito/kit';
import { Dispatch, SetStateAction } from 'react';
import { calculateDurchschnitt } from './calculateDurschnitt';

export function PlanUmsatz({
  currentDatum,
  planumsatzEntries,
  setDurchschnitt,
  setPlanumsatzEntries,
}: {
  currentDatum: string;
  planumsatzEntries: PlanumsatzEntry[];
  setDurchschnitt: Dispatch<SetStateAction<number>>;
  setPlanumsatzEntries: Dispatch<SetStateAction<PlanumsatzEntry[]>>;
}) {
  const planumsatz = planumsatzEntries.find((p) => p.datum === currentDatum);

  function handleOnBlur(newValue: string) {
    if (planumsatzEntries.find((p) => p.datum === currentDatum)) {
      const updatedPlanumsatz: PlanumsatzEntry = {
        ...planumsatz,
        planUmsatz: maskitoParseNumber(newValue, ','),
      };
      const newPlanumsatzEntries = planumsatzEntries.map((p) => {
        if (p.datum === currentDatum) {
          return updatedPlanumsatz;
        } else {
          return p;
        }
      });
      const durchschnitt = calculateDurchschnitt(
        updatedPlanumsatz.planUmsatz,
        updatedPlanumsatz.planAbsatz,
      );
      setDurchschnitt(durchschnitt);
      setPlanumsatzEntries(newPlanumsatzEntries);
    } else {
      const newPlanumsatzEntry: PlanumsatzEntry = {
        datum: currentDatum,
        planAbsatz: 0,
        planUmsatz: maskitoParseNumber(newValue, ','),
        type: 'created',
      };
      setDurchschnitt(0);
      setPlanumsatzEntries([...planumsatzEntries, newPlanumsatzEntry]);
    }
  }

  return (
    <TableFormField
      type="money"
      value={planumsatz ? planumsatz.planUmsatz.toString() : '0'}
      updatePlanumsatzEntry={handleOnBlur}
    />
  );
}
