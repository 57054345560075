import dayjs from 'dayjs';
import 'dayjs/locale/de';
import duration from 'dayjs/plugin/duration';
import { t } from 'i18next';

dayjs.extend(duration);

export function formatDate(value: string): string {
  const date = dayjs(value);
  if (!date.isValid()) {
    return '';
  }
  return date.locale('de-DE').format('DD.MM.YYYY');
}

export function formatDateTime(value: string): string {
  const date = dayjs(value);
  if (!date.isValid()) {
    return '';
  }
  return `${date.locale('de-DE').format('DD.MM.YYYY, HH:mm')} ${t(
    'domain.arbeitszeiten.sheet.historie.uhr',
  )}`;
}

export function formatCurrency(value: string): string {
  return new Intl.NumberFormat('de-DE', {
    style: 'currency',
    currency: 'EUR',
  }).format(parseFloat(value));
}

export function formatDuration(value: string): string {
  const duration = dayjs.duration(parseInt(value, 10), 'minutes');
  const hours = duration.hours();
  const minutes = duration.minutes();
  return `${hours} ${t(
    'domain.arbeitszeiten.sheet.historie.stunde',
  )} ${minutes} ${t('domain.arbeitszeiten.sheet.historie.minute')}`;
}

export function formatBezahlStatus(value: string): string {
  return value === 'true' ? 'Ja' : 'Nein';
}
