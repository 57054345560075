import { Markierung } from '@/gql/graphql';
import i18n from 'i18next';

export class Termin {
  constructor(
    readonly titel?: string,
    readonly wiederholung?: TerminWiederholung,
    readonly id?: string,
    readonly bildUrl?: string,
    readonly bildName?: string,
    readonly beschreibung?: string,
    readonly beginn?: Date,
    readonly ende?: Date,
    readonly markierung?: Markierung,
  ) {}
}

export enum TerminWiederholung {
  JAEHRLICH = 'JAEHRLICH',
  MONATLICH = 'MONATLICH',
  WOECHENTLICH = 'WOECHENTLICH',
  TAEGLICH = 'TAEGLICH',
  OHNE = 'OHNE',
}

export function getWiederholungStringFromTerminWiederholung(
  wiederholung: TerminWiederholung,
) {
  switch (wiederholung) {
    case TerminWiederholung.JAEHRLICH:
      return i18n.t('entities.wiederholung.jaehrlich');
    case TerminWiederholung.MONATLICH:
      return i18n.t('entities.wiederholung.monatlich');
    case TerminWiederholung.WOECHENTLICH:
      return i18n.t('entities.wiederholung.woechentlich');
    case TerminWiederholung.TAEGLICH:
      return i18n.t('entities.wiederholung.taeglich');
    case TerminWiederholung.OHNE:
      return i18n.t('entities.wiederholung.einmalig');
  }
}
