import {
  Table as E2nTable,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@/components/ui/table';
import { Arbeitszeit, Dienstgang } from '../../../../entities';
import { faDash } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { t } from 'i18next';
import { E2NtableHeader } from '../../../../../../components/Table';
import { getTimeDisplayFromDate } from '../../../../../../utils';
import { DeleteAction } from './Forms';
import { useManagerCanEditArbeitszeiten } from '../../../../hooks/useManagerCanEditArbeitszeit';

// eslint-disable-next-line max-lines-per-function
export function Table({
  dienstgaenge,
  arbeitszeit,
}: {
  dienstgaenge: Dienstgang[];
  arbeitszeit: Arbeitszeit;
}) {
  const managerCanEditArbeitszeit = useManagerCanEditArbeitszeiten();

  return (
    <E2nTable>
      <E2NtableHeader>
        <TableRow className="hover:bg-transparent">
          <TableHead className="w-[100px] px-4">
            {t('entities.duration')}
          </TableHead>
          <TableHead className="w-[150px]">
            {t('domain.arbeitszeiten.sheet.dienstgang.tableZeitraumLabel')}
          </TableHead>
        </TableRow>
      </E2NtableHeader>
      <TableBody>
        {dienstgaenge.map((dienstgang, id) => {
          return (
            <TableRow key={id} className="hover:bg-transparent">
              <TableCell className="font-medium p-4">
                <div className="flex gap-1 items-baseline">
                  <span className="font-semibold">{dienstgang.dauer}</span>
                  <span className="text-neutrals-N700 text-xs">
                    {t('general.date.minutes')}
                  </span>
                </div>
              </TableCell>
              <TableCell>
                <div className="flex items-center gap-2">
                  <span className="font-semibold">
                    {getTimeDisplayFromDate(dienstgang.beginn)}
                  </span>
                  <FontAwesomeIcon
                    className="text-neutrals-N700"
                    icon={faDash}
                  />
                  <span className="font-semibold">
                    {getTimeDisplayFromDate(dienstgang.ende)}
                  </span>
                </div>
              </TableCell>
              {managerCanEditArbeitszeit && (
                <TableCell className="flex justify-end">
                  <DeleteAction
                    arbeitszeit={arbeitszeit}
                    dienstgangId={dienstgang.id}
                  />
                </TableCell>
              )}
            </TableRow>
          );
        })}
      </TableBody>
    </E2nTable>
  );
}
