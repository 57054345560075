import { DialogHeader, DialogTitle } from '@/components/ui/dialog';
import { getMonthNameByDate } from '@/utils';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { useEditDialogContext } from './EditDialogContext';
import { useEffect } from 'react';

export function EditDialogHeader() {
  const { jahrMonat, form, isSubmitted } = useEditDialogContext();

  const { t } = useTranslation();

  // function handleSubmit() {
  //   onSubmit(
  //     {
  //       umsatz: form.getValues('umsatz'),
  //       umsatzProduktivitaet: form.getValues('umsatzProduktivitaet'),
  //       absatz: form.getValues('absatz'),
  //       absatzProduktivitaet: form.getValues('absatzProduktivitaet'),
  //     },
  //     false,
  //   );
  // }

  // function handlePrevMonthClick() {
  //   const jahr = dayjs(`${jahrMonat.jahr}-${jahrMonat.monat}-01`)
  //     .subtract(1, 'M')
  //     .get('year');
  //   const monat =
  //     dayjs(`${jahrMonat.jahr}-${jahrMonat.monat}-01`)
  //       .subtract(1, 'M')
  //       .get('month') + 1;
  //   setJahrMonat({ jahr, monat });
  //   if (hasUnsavedChanges) {
  //     handleSubmit();
  //   }
  // }

  // function handleNextMonthClick() {
  //   const jahr = dayjs(`${jahrMonat.jahr}-${jahrMonat.monat}-01`)
  //     .add(1, 'M')
  //     .get('year');
  //   const monat =
  //     dayjs(`${jahrMonat.jahr}-${jahrMonat.monat}-01`)
  //       .add(1, 'M')
  //       .get('month') + 1;
  //   setJahrMonat({ jahr, monat });
  //   if (hasUnsavedChanges) {
  //     handleSubmit();
  //   }
  // }

  // function handleTodayClick() {
  //   const jahr = dayjs().get('year');
  //   const monat = dayjs().get('month') + 1;
  //   setJahrMonat({ jahr, monat });
  //   if (hasUnsavedChanges) {
  //     handleSubmit();
  //   }
  // }

  useEffect(() => {
    form.reset();
  }, [form, isSubmitted]);

  return (
    <DialogHeader>
      <div className="flex flex-col gap-6">
        <DialogTitle className="text-xl font-bold text-neutrals-N900">
          {t('domain.controlling.umsatzplanung.ziele.dialog.title')}
        </DialogTitle>
        <div className="flex items-center gap-4">
          {/* <Button
            onClick={handleTodayClick}
            disabled
            variant="outline-secondary"
            size="sm">
            {t('general.date.today')}
          </Button>
          <div className="flex items-center">
            <Button
              disabled
              size="icon"
              variant="icon"
              onClick={handlePrevMonthClick}>
              <FontAwesomeIcon
                className="text-primary-blue-B300 text-xl"
                icon={faChevronLeft}
              />
            </Button>
            <Button
              disabled
              size="icon"
              variant="icon"
              onClick={handleNextMonthClick}>
              <FontAwesomeIcon
                className="text-primary-blue-B300 text-xl"
                icon={faChevronRight}
              />
            </Button>
          </div> */}
          <div className="font-bold text-neutrals-N900">
            {getMonthNameByDate(
              dayjs(`${jahrMonat.jahr}-${jahrMonat.monat}-01`).format(
                'YYYY-MM-DD',
              ),
            )}
          </div>
        </div>
      </div>
    </DialogHeader>
  );
}
