import { graphql } from '../../gql';

export const GET_CURRENT_STANDORT = graphql(`
  query getCurrentStandort {
    manager {
      ... on Manager {
        id
        idStandort
        standorte {
          ... on Standort {
            id
            name
            settings {
              abteilungenEnabled
            }
          }
          ... on StandortError {
            type
            message
          }
        }
      }
      ... on ManagerError {
        type
        message
      }
    }
  }
`);

export const GET_MANAGER = graphql(`
  query getManager {
    manager {
      ... on Manager {
        id
        email
        vorname
        nachname
        managerrechte {
          tagebuch
          einstellungen
          admin
          termine
          statusmails
          konflikte
          id
          kalender
          auswertung {
            dienstplan
            arbeitszeit
            personal
          }
          dienstplan {
            freigabe
            erlaubt
            bearbeiten
            mitarbeiterEinteilen
            schichtvorlagen
          }
          abrechnung {
            monatsabschluss
            export
          }
          mitarbeiter {
            standortwechsel
            personalfragebogen
            urlaubskonto
            jahreskonto
            fristen
            verfuegbarkeit
            erlaubt
            eingeschraenkt
            dokumente
          }
          arbeitszeit {
            geldbetraegeAnzeigen
            nurHeute
            erlaubt
            alleTage
            bearbeiten
          }
        }
      }
      ... on ManagerError {
        type
        message
      }
    }
  }
`);

export const GET_STANDORTE_FOR_MANAGER = graphql(`
  query getStandorteForManager {
    manager {
      ... on Manager {
        idStandort
        id
        standorte {
          ... on Standort {
            id
            name
          }
          ... on StandortError {
            message
            type
          }
        }
      }
      ... on ManagerError {
        type
        message
      }
    }
  }
`);
