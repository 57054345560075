import { faSpinnerThird } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';

export function DataTableLoading() {
  const { t } = useTranslation();
  return (
    <div className="flex flex-col h-screen gap-4 items-center justify-center text-sm">
      <FontAwesomeIcon
        icon={faSpinnerThird}
        spin
        size="4x"
        className="text-neutrals-N700"
      />
      {t('actions.loading')}
    </div>
  );
}
