import { Vertrag, VertragTarif } from '../../../../gql/graphql';
import { ProgressCircle } from './ProgressCircle';
import { t } from 'i18next';

export function Abrechnungsart({ vertrag }: { vertrag: Vertrag }) {
  return (
    <div className="my-3 px-14">
      <h4 className="pb-5">
        {vertrag.vertragTarif === VertragTarif.Mitarbeiter
          ? t('domain.vertraege.slide.employeePartTitle')
          : t('domain.vertraege.slide.locationPartTitle')}
      </h4>
      <div className="flex gap-3 items-center">
        <ProgressCircle
          vertrag={vertrag}
          percentage={Math.round(
            (vertrag.aktiveAnzahl / vertrag.vereinbarteAnzahl) * 100,
          )}
        />
        <div className="w-2/3">
          <div className="w-full font-medium">
            <span className="text-neutrals-N800 dark:text-white text-xl">
              {vertrag.aktiveAnzahl}
            </span>
            <span className="text-neutrals-N700 dark:text-white"> / </span>
            <span className="text-neutrals-N700 text-xs">
              {vertrag.vereinbarteAnzahl}
            </span>
          </div>
          <div className="flex flex-row gap-2 text-xs">
            {vertrag.vertragTarif === VertragTarif.Mitarbeiter
              ? t('domain.vertraege.slide.employeeTitle')
              : t('domain.vertraege.slide.locationTitle')}
          </div>
        </div>
      </div>
    </div>
  );
}
