export enum Abwesenheitsgrund {
  ARBEITSUNFALL = 'Arbeitsunfall',
  ARZTBESUCH = 'Arztbesuch',
  AUSGLEICHSRUHEZEITENTAG = 'Ausgleichsruhezeitentag',
  BESCHAEFTIGUNGSVERBOT = 'Beschaeftigungsverbot',
  BEZAHLTERARBEITSFREIERTAG = 'BezahlterArbeitsfreierTag',
  BILDUNG = 'Bildung',
  EURENTE = 'EURente',
  ELTERNZEIT = 'Elternzeit',
  FEHLTUNENTSCHULDIGT = 'FehltUnentschuldigt',
  FEIERTAG = 'Feiertag',
  FEIERTAGSAUSGLEICH = 'Feiertagsausgleich',
  FEIERTAGSAUSGLEICHDYNAMISCH = 'FeiertagsausgleichDynamisch',
  FERIENTAG = 'Ferientag',
  FREI = 'Frei',
  FREISTELLUNG = 'Freistellung',
  GUTTAGMINUS = 'GuttagMinus',
  GUTTAGPLUS = 'GuttagPlus',
  KOMPENSATIONSTAG = 'Kompensationstag',
  KRANK = 'Krank',
  KRANKIMFREI = 'KrankImFrei',
  KRANKINKURZARBEIT = 'KrankInKurzarbeit',
  KRANKOHNELOHNFORTZAHLUNG = 'KrankOhneLohnfortzahlung',
  KRANKENGELD = 'Krankengeld',
  KRANKESKIND = 'KrankesKind',
  KUR = 'Kur',
  KURZARBEIT = 'Kurzarbeit',
  LEHRGANG = 'Lehrgang',
  MILITAERZIVILDIENST = 'MilitaerZivildienst',
  MUTTERSCHAFT = 'Mutterschaft',
  MUTTERSCHUTZ = 'Mutterschutz',
  QUARANTAENE = 'Quarantaene',
  QUARANTAENEOHNELFZ = 'QuarantaeneOhneLFZ',
  RUHETAG = 'Ruhetag',
  SCHULE = 'Schule',
  SONDERURLAUB = 'Sonderurlaub',
  UEBERSTUNDENABBAU = 'Ueberstundenabbau',
  UNBEZAHLTERFERIENTAG = 'UnbezahlterFerientag',
  UNBEZAHLTERURLAUB = 'UnbezahlterUrlaub',
  UNFALL = 'Unfall',
  URLAUB = 'Urlaub',
  VATERSCHAFT = 'Vaterschaft',
  WIEDEREINGLIEDERUNG = 'Wiedereingliederung',
  WUNSCHFREI = 'Wunschfrei',
}
