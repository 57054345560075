import { useTranslation } from 'react-i18next';
import { Badge } from '@/components/ui/badge';
import { useEditSheetContext } from '../EditSheetContext';

export function DienstgangTrigger() {
  const { t } = useTranslation();
  const { arbeitszeit } = useEditSheetContext();

  return (
    <div className="flex gap-1 items-center">
      {arbeitszeit.dienstgaenge.length > 0 && (
        <Badge className="flex items-center justify-center rounded-full text-white size-5 p-1 text-[10px] font-semibold">
          {arbeitszeit.dienstgaenge.length}
        </Badge>
      )}
      <div>{t('domain.arbeitszeiten.sheet.tabs.dienstgänge')}</div>
    </div>
  );
}
