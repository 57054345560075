import { LoadingPage } from '../LoadingPage';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { handleSignOut } from '../../services';
import { useAuthenticationContext } from '../../app/AuthenticationContext';

export function Page() {
  const { setUserInfo } = useAuthenticationContext();
  const navigate = useNavigate();

  useEffect(() => {
    handleSignOut({
      callbackAction: () => {
        setUserInfo(undefined);
        navigate('/login');
      },
    });
  }, [navigate, setUserInfo]);

  return <LoadingPage />;
}
