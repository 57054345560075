import { useUmsatzplanung } from '@/domain/controlling/hooks/useUmsatzplanung';
import { useStandort } from '@/hooks';
import { getHoursAndMinutesFromMinutes } from '@/utils';
import { t } from 'i18next';
import { useParams } from 'react-router-dom';
import { LabeledField } from './LabeledField';
import { LabeledTimeField } from './LabeledTimeField';
import { formatNumber } from '../../formatNumber';
import { useTagebuchEinstellungen } from '@/domain/controlling/hooks/useTagebuchEinstellungen';
import { AbsatzVorgabe } from '@/domain/controlling/entities';
import { getAbsatzProduktivitaetByMptOrStueck } from '../../utils';
import { ZieleError } from './ZieleError';

export function AbsatzZiele() {
  const { year, month } = useParams();
  const jahr = parseInt(year);
  const monat = parseInt(month);

  const { standortId } = useStandort();
  const { absatzZiel, error } = useUmsatzplanung(standortId, jahr, monat);
  const { tagebuchEinstellungen } = useTagebuchEinstellungen(standortId);
  const absatzVorgabe =
    tagebuchEinstellungen.absatzproduktivitaet.absatzVorgabe;

  return !error ? (
    <div className="flex flex-row px-5 py-4 justify-between">
      <LabeledField
        className="w-1/3"
        value={
          absatzZiel.wert
            ? formatNumber(absatzZiel.wert.toString(), true)
            : undefined
        }
        label={t('domain.controlling.umsatzplanung.ziele.absatz')}
        currency={t('domain.controlling.umsatzplanung.units.stueck')}
      />
      <LabeledField
        className="w-1/3"
        value={getAbsatzProduktivitaetByMptOrStueck(
          absatzZiel.produktivitaet,
          absatzVorgabe,
        )}
        label={t('domain.controlling.umsatzplanung.ziele.produktivitaet')}
        currency={
          absatzVorgabe === AbsatzVorgabe.Stueck
            ? t('domain.controlling.umsatzplanung.ziele.Stk./Std.')
            : t('domain.controlling.umsatzplanung.ziele.mpt')
        }
      />
      <LabeledTimeField
        className="w-1/3 border-l-[1px] border-neutrals-N300 pl-4"
        label={t('domain.controlling.umsatzplanung.ziele.zielstunden')}
        hours={getHoursAndMinutesFromMinutes(absatzZiel.stunden).hours}
        minutes={getHoursAndMinutesFromMinutes(absatzZiel.stunden).minutes}
      />
    </div>
  ) : (
    <div className="px-5 py-4">
      <ZieleError />
    </div>
  );
}
