import {
  ColumnDef,
  ColumnFiltersState,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable,
} from '@tanstack/react-table';
import { E2Ntable, E2NtableHeader, E2NtableRow } from '../../components/Table';
import { useEffect, useState } from 'react';
import { faSearch } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import { Abteilung, Arbeitsbereich, Arbeitszeit } from './entities';
import { useStandort } from '../../hooks';
import {
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@/components/ui/table';

export type ArbeitsbereichFilter = {
  arbeitsbereiche?: string[];
  abteilungen?: string[];
};

interface DataTableProps<TData, TValue> {
  columns: ColumnDef<TData, TValue>[];
  data: TData[];
  nameFilter: string;
  arbeitsbereichFilter: Arbeitsbereich[];
  abteilungFilter: Abteilung[];
}

// eslint-disable-next-line max-lines-per-function
export function DataTable({
  columns,
  data,
  nameFilter,
  arbeitsbereichFilter,
  abteilungFilter,
}: DataTableProps<Arbeitszeit, Arbeitszeit[]>) {
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([
    { id: 'mitarbeiter', value: nameFilter },
  ]);
  const [sorting, setSorting] = useState<SortingState>([
    { id: 'arbeitszeit', desc: false },
  ]);

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    onColumnFiltersChange: setColumnFilters,
    getFilteredRowModel: getFilteredRowModel(),
    state: { columnFilters, sorting },
    onSortingChange: setSorting,
  });
  const { t } = useTranslation();
  const currentStandort = useStandort();

  useEffect(() => {
    const mitarbeiterColumn = table.getColumn('mitarbeiter');
    const arbeitsbereichColumn = table.getColumn('arbeitsbereich');

    if (mitarbeiterColumn) {
      mitarbeiterColumn.setFilterValue(nameFilter);
    }

    if (arbeitsbereichColumn) {
      const filterValue = {
        arbeitsbereiche: arbeitsbereichFilter.map(
          (arbeitsbereich) => arbeitsbereich.id,
        ),
        abteilungen: currentStandort.abteilungenEnabled
          ? abteilungFilter.map((abteilung) => abteilung.id)
          : [],
      };
      arbeitsbereichColumn.setFilterValue(filterValue);
    }
  }, [
    nameFilter,
    arbeitsbereichFilter,
    abteilungFilter,
    currentStandort.abteilungenEnabled,
    table,
  ]);

  return (
    <E2Ntable>
      <E2NtableHeader>
        {table.getHeaderGroups().map((headerGroup) => (
          <TableRow className="border-b-0" key={headerGroup.id}>
            {headerGroup.headers.map((header) => {
              return (
                <TableHead
                  className={'px-0 text-xs normal-case font-semibold h-0 pt-4'}
                  key={header.id}
                  onClick={header.column.getToggleSortingHandler()}>
                  {header.isPlaceholder
                    ? null
                    : flexRender(
                        header.column.columnDef.header,
                        header.getContext(),
                      )}
                </TableHead>
              );
            })}
          </TableRow>
        ))}
      </E2NtableHeader>
      <TableBody>
        {table.getSortedRowModel().rows?.length ? (
          table.getSortedRowModel().rows.map((row) => {
            return (
              <E2NtableRow
                className={`hover:bg-transparent ${
                  row.original.abwesenheit !== null
                    ? '[&_td]:bg-white/50'
                    : '[&_td]:bg-white'
                }`}
                key={row.id}
                data-state={row.getIsSelected() && 'selected'}>
                {row
                  .getVisibleCells()
                  .map((cell) =>
                    flexRender(cell.column.columnDef.cell, cell.getContext()),
                  )}
              </E2NtableRow>
            );
          })
        ) : (
          <TableRow>
            <TableCell colSpan={columns.length} className="h-24 text-center">
              <div className="flex flex-col gap-4 p-8">
                <FontAwesomeIcon
                  icon={faSearch}
                  size="3x"
                  className="text-neutrals-N800"
                />
                <span className="text-md">
                  {t('domain.arbeitszeiten.uebersicht.noResults.title')}
                </span>
                <span className="text-xs text-neutrals-N600">
                  {t('domain.arbeitszeiten.uebersicht.noResults.subtitle')}
                </span>
              </div>
            </TableCell>
          </TableRow>
        )}
      </TableBody>
    </E2Ntable>
  );
}
