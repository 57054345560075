import { useSuspenseQuery } from '@apollo/client';
import * as Sentry from '@sentry/react';
import { GET_UMSATZ_ABSATZ_ZIELE_BY_STANDORT_ID } from './graphql/queries';
import {
  Absatz,
  AbsatzProduktivitaet,
  Currency,
  Money,
  Umsatz,
} from '../entities';

// eslint-disable-next-line max-lines-per-function
export function useUmsatzplanung(
  idStandort: string,
  jahr: number,
  monat: number,
): {
  umsatzZiel?: Umsatz;
  absatzZiel?: Absatz;
  error?: string;
} {
  const { data, error } = useSuspenseQuery(
    GET_UMSATZ_ABSATZ_ZIELE_BY_STANDORT_ID,
    {
      variables: {
        standortId: idStandort,
        jahr: jahr,
        monat: monat,
      },
      fetchPolicy: 'cache-first',
    },
  );
  if (error) {
    Sentry.captureException(error);
    return { error: error?.message };
  }
  const umsatz = data.controlling[0]?.umsatzZiel?.umsatz;
  const absatz = data.controlling[0]?.absatzZiel?.absatz;

  const newUmsatz = new Umsatz(
    umsatz?.standortId,
    !umsatz?.produktivitaet
      ? null
      : new Money(
          umsatz?.produktivitaet?.amount,
          Currency[umsatz?.produktivitaet?.currency],
        ),
    !umsatz?.wert
      ? null
      : new Money(umsatz?.wert?.amount, Currency[umsatz?.wert?.currency]),
    umsatz?.stunden,
  );

  const newAbsatz = new Absatz(
    absatz?.standortId,
    !absatz.produktivitaet
      ? null
      : new AbsatzProduktivitaet(
          absatz?.produktivitaet?.stueck,
          absatz?.produktivitaet?.mpt,
        ),
    absatz?.wert,
    absatz?.stunden,
  );

  return {
    umsatzZiel: newUmsatz,
    absatzZiel: newAbsatz,
    error: undefined,
  };
}
