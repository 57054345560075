import { cn } from '@/lib/utils';
import { AnchorHTMLAttributes, forwardRef } from 'react';

export type LinkProps = AnchorHTMLAttributes<HTMLAnchorElement>;

export const Link = forwardRef<HTMLAnchorElement, LinkProps>(
  ({ children, className, ...otherProps }, ref) => {
    return (
      <a
        className={cn(
          'font-semibold underline underline-offset-2 transition hover:text-primary-blue-B600 cursor-pointer',
          className,
        )}
        ref={ref}
        {...otherProps}>
        {children}
      </a>
    );
  },
);

Link.displayName = 'Link';
