import { getEnvironment, managerV1BaseUrl } from './index.ts';

const links = {
  allgemein: '/secure/verwaltung/mandant/allgemein.jsf',
  einstellungenTagebuch: '/secure/einstellungen/controlling/tagebuch.jsf',
  einstellungenQualifikationen:
    '/secure/einstellungen/mitarbeiter/qualifikationen/index.jsf',
  einstellungenArbeitszeit: '/secure/einstellungen/arbeitszeit/terminal.jsf',
  einstellungenAbwesenheit: '/secure/einstellungen/kalender/abwesenheiten.jsf',
  einstellungenZuschlag: '/secure/einstellungen/abrechnung/zuschlag/index.jsf',
  einstellungenDienstplan: '/secure/einstellungen/dienstplan/index.jsf',
  changePassword: '/secure/profil/index.jsf',
  datenschutz: '/secure/datenschutz/avv.jsf',
  tagebuch: '/secure/controlling/tagebuch/index.jsf',
  auswertungenDienstplan: '/secure/controlling/auswertung2/vorschau/index.jsf',
  auswertungenPersonal: '/secure/controlling/auswertung2/mitarbeiter/index.jsf',
  auswertungenArbeitszeit:
    '/secure/controlling/auswertung2/arbeitszeiten/index.jsf',
  mitarbeiterListe: '/secure/mitarbeiter/list.jsf',
  kalender: '/secure/kalender/kalender.jsf',
  arbeitszeiten: '/secure/arbeitszeit/tag/index.jsf',
  abrechnung: '/secure/abrechnung/vorschau.jsf',
  dienstplanPersonal: '/secure/dienstplan/personal/index.jsf',
  dienstplanSchicht: '/secure/dienstplan/schicht/index.jsf',
  dienstplanVorlage: '/secure/dienstplan/vorlagen/index.jsf',
  konflikte: '/secure/konflikte/index.jsf',
  termine: '/secure/datenverwaltung/termine/calendar.jsf',
  dokumente: '/secure/dokumente/index.jsf',
};

type Links = keyof typeof links;

export function buildLink(destination: Links): string {
  const env = getEnvironment();
  if (env === 'production') {
    return new URL(links[destination], managerV1BaseUrl).toString();
  }
  return new URL('/app' + links[destination], managerV1BaseUrl).toString();
}
