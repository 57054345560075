import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { faArrowLeft } from '@fortawesome/pro-light-svg-icons';
import React from 'react';
import { ReactRouterLinkWrapper } from '../../../components/ReactRouterLinkWrapper';
import VertraegeCard from '../components/card/VertraegeCard';
import { useVertraege } from '../hooks/graphql/useVertraege';
import { Link } from 'react-router-dom';
import {
  Carousel,
  CarouselApi,
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious,
} from '@/components/ui/carousel';
import { Button } from '@/components/ui/button';

// eslint-disable-next-line max-lines-per-function
export function Home() {
  const { t } = useTranslation();
  const [count, setCount] = React.useState(0);
  const [api, setApi] = React.useState<CarouselApi>();

  const { vertraege } = useVertraege();
  React.useEffect(() => {
    if (!api) {
      return;
    }

    setCount(vertraege.length);
  }, [api, vertraege.length]);

  return (
    <>
      <div className="flex flex-col px-10 py-4 w-screen">
        <Helmet>
          <title>{t('domain.vertraege.navigation')}</title>
        </Helmet>
        <div className="flex flex-row justify-between">
          <ReactRouterLinkWrapper
            className="flex items-center gap-2"
            to="/"
            displayName={t('domain.vertraege.back')}
            icon={faArrowLeft}
          />
        </div>
        <h5 className="mb-[-1rem] text-xl mt-5 font-bold">
          {t('domain.vertraege.header')}
        </h5>
        <div className="flex justify-between items-end ">
          <div>{t('domain.vertraege.subtitle')}</div>
          <div className="text-neutrals-N700">
            {t('domain.vertraege.info.subtitle')}
            <div className="text-neutrals-N900 leading-none dark:text-white">
              {count} {t('domain.vertraege.info.vertraege')}
            </div>
          </div>
        </div>
        <div className="flex sm:w-full sm:justify-center lg:justify-end xl:w-11/12 2xl:w-[85%] my-5">
          {vertraege !== null && vertraege.length > 0 ? (
            <Carousel
              orientation="horizontal"
              setApi={setApi}
              opts={{
                align: 'start',
              }}
              className="mx-1 w-11/12 sm:w-9/12 sm:mx-10 md:w-7/12 md:mx-6 lg:w-11/12 lg:mx-4 xl:w-11/12 2xl:w-10/12 xl2:mx-20 -mt-4">
              {vertraege.length > 1 && (
                <CarouselContent>
                  {vertraege.map((vertrag, index) => (
                    <CarouselItem
                      key={index}
                      className="sm:basis-full md:basis-full lg:basis-[550px] xl:basis-[550px] 2xl:basis-[550px] p-1 ml-8">
                      <VertraegeCard vertrag={vertrag} />
                    </CarouselItem>
                  ))}
                </CarouselContent>
              )}
              {vertraege.length === 1 && (
                <CarouselContent className="justify-center">
                  {vertraege.map((vertrag, index) => (
                    <CarouselItem
                      key={index}
                      className="xs:basis-full lg:basis-1/2 xl:basis-1/2 2xl:basis-[550px] p-1">
                      <VertraegeCard vertrag={vertrag} />
                    </CarouselItem>
                  ))}
                </CarouselContent>
              )}
              {vertraege.length > 1 && (
                <>
                  <CarouselPrevious style={{ left: '-3rem' }} />
                  <CarouselNext style={{ right: '-3rem' }} />
                </>
              )}
            </Carousel>
          ) : (
            <span className="my-4 text-md text-bold">
              {t('domain.vertraege.error')}
            </span>
          )}
        </div>
        <div className="flex justify-center my-4 mx-14">
          <div className="h-[150px] w-10/12 justify-center bg-neutrals-N300 bg-opacity-50 rounded-full my-4 bg-[url('./assets/images/vertraege-header.png')] bg-no-repeat bg-right">
            <div className="absolute  mt-[1.5rem] text-end xs:ms-1 sm:ms-3 md:ms-8 lg:ms-10 xl:ms-[12rem] ">
              <h5 className="font-bold sm:text-sm md:text-md lg:text-xl xs:hidden sm:hidden md:block">
                {t('domain.vertraege.banner.title')}
              </h5>
              <span className="sm:text-xs lg:text-md xs:max-sm:hidden sm:max-md:hidden md:block">
                {t('domain.vertraege.banner.subtitle')}
              </span>
              <div className="xs:max-sm:block xs:max-sm:ms-16 xs:max-sm:mt-20 sm:max-md:block sm:max-md:ms-6 sm:max-md:mt-16 md:mt-4">
                <Link to="https://e2n.de/backoffice/" target="_blank">
                  <Button
                    className={'text-white bottom-0 mb-8 p-2'}
                    variant="secondary">
                    {t('domain.vertraege.banner.buttonText')}
                  </Button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
