import { TagebuchUpdateInput } from '../../../../../gql/graphql';

export function calculateDifferenzZumZiel(
  tagebuch: TagebuchUpdateInput[],
  umsatzBudget: number,
): { value: number; color: string } {
  const planUmsatz = umsatzBudget;
  const istUmsatz = tagebuch.reduce((currentSum, tagebuch) => {
    if (!tagebuch || !tagebuch.planUmsatz) {
      return currentSum;
    } else {
      currentSum += tagebuch.planUmsatz;
      return currentSum;
    }
  }, 0);

  const abweichung = (Math.abs(planUmsatz - istUmsatz) * 100) / planUmsatz;

  const color =
    abweichung <= 5
      ? 'text-primary-green-G500'
      : abweichung > 5 && abweichung <= 10
      ? 'text-orange-500'
      : 'text-primary-red-R500';

  return { value: istUmsatz - planUmsatz, color };
}
