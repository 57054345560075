import { t } from 'i18next';
import { Arbeitszeit } from '../../../../../../entities';
import { useArbeitszeitUpdate } from '../../../../../../hooks';
import { handleOnCompleted } from '../../../../handleOnCompleted';
import { mapPauseToInput } from './mapPauseToInputType';
import { ConfirmDeletePopover } from '../../../../../Popover/ConfirmDeletePopover';
import { toast } from '@/hooks/use-toast';
import * as Sentry from '@sentry/react';

export function DeleteAction({
  arbeitszeit,
  pauseId,
}: {
  arbeitszeit: Arbeitszeit;
  pauseId: string;
}) {
  const [arbeitszeitUpdate] = useArbeitszeitUpdate();

  async function handleDelete() {
    try {
      const newPausen = arbeitszeit.pausen
        .filter((pause) => pause.id !== pauseId)
        .map((pause) => mapPauseToInput(pause));

      const result = await arbeitszeitUpdate({
        arbeitszeitUpdateInput: {
          pausen: newPausen,
        },
        arbeitszeitId: arbeitszeit.id,
      });

      handleOnCompleted(result.data, t);
    } catch (e) {
      toast({
        title: t(
          'domain.arbeitszeiten.sheet.header.arbeitszeitUpdateUnknownError',
        ),
      });
      Sentry.captureException(e);
    }
  }

  return (
    <ConfirmDeletePopover
      confirmText={t(
        'domain.arbeitszeiten.sheet.arbeitszeit.confirmPauseDelete',
      )}
      handleDelete={handleDelete}
      confirmButtonText={t(
        'domain.arbeitszeiten.sheet.arbeitszeit.confirmPauseDeleteButton',
      )}
      processingButtonText={t(
        'domain.arbeitszeiten.sheet.arbeitszeit.confirmPauseIsDeleting',
      )}
    />
  );
}
