import { useMutation } from '@apollo/client';
import { useCallback } from 'react';
import {
  PlanumsatzUpdateMutation,
  PlanumsatzUpdateMutationVariables,
} from '../../../gql/graphql';
import { PLANUMSATZ_UPDATE } from './graphql/mutations';

export function usePlanumsatzUpdate() {
  const [execute, result] = useMutation<
    PlanumsatzUpdateMutation,
    PlanumsatzUpdateMutationVariables
  >(PLANUMSATZ_UPDATE, {
    refetchQueries: ['getPlanUmsaetzeByStandortId'],
  });

  const updatePlanumsatz = useCallback(
    (variables: PlanumsatzUpdateMutationVariables) => {
      return execute({ variables });
    },
    [execute],
  );

  return [updatePlanumsatz, result] as const;
}
