import { DayPicker } from 'react-day-picker';
import { de } from 'date-fns/locale/de';

export type CalendarProps = React.ComponentProps<typeof DayPicker>;

export function Calendar({ classNames, ...props }: CalendarProps) {
  return (
    <DayPicker
      disableNavigation
      locale={de}
      classNames={{
        caption: 'hidden',
        table: 'w-full space-y-1',
        head_row: 'flex',
        head_cell: 'text-neutrals-N600 w-9 font-normal text-xs',
        row: 'flex w-full h-[70px] mt-2 border-b border-neutrals-N300 pb-2 last:border-0 last:pb-0',
        cell: 'h-9 w-9 text-center text-sm p-0 relative text-neutrals-N900',
        ...classNames,
      }}
      {...props}
    />
  );
}
