import { ArbeitszeitenPausenInput } from '../../../../../../../../gql/graphql';
import { getTimeDisplayFromDate } from '../../../../../../../../utils';
import { Pause } from '../../../../../../entities';

export function mapPauseToInput(pause: Pause): ArbeitszeitenPausenInput {
  return {
    dauer: pause.dauer,
    bezahlt: pause.bezahlt,
    pausenart: pause.pausenart,
    beginn: pause.beginn ? getTimeDisplayFromDate(pause.beginn) : null,
    ende: pause.ende ? getTimeDisplayFromDate(pause.ende) : null,
    idPause: pause.id,
  };
}
