import { useForm } from 'react-hook-form';
import { TimeInput } from '../../../../../../components';
import { useTranslation } from 'react-i18next';
import { Card } from '../../../Card';
import { getTimeDisplayFromDate } from '../../../../../../utils';
import {
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  Form,
} from '@/components/ui/form';
import { zodResolver } from '@hookform/resolvers/zod';
import { useFormSchema } from './formSchema';

type BeginnEndeChangeEvent = {
  beginn: string;
  ende: string;
};

// eslint-disable-next-line max-lines-per-function, complexity
export function BeginnEnde({
  beginn,
  ende,
  kommen,
  gehen,
  beginnNachPlan,
  endeNachPlan,
  onChange,
  disabled = false,
}: {
  beginn: Date | null;
  ende: Date | null;
  kommen: Date | null;
  gehen: Date | null;
  beginnNachPlan: Date | null;
  endeNachPlan: Date | null;
  disabled?: boolean;
  onChange?: (event: BeginnEndeChangeEvent) => void;
}) {
  const { t } = useTranslation();

  const form = useForm<{
    beginn: string;
    ende: string;
    kommen: string;
    gehen: string;
    planBeginn: string;
    planEnde: string;
  }>({
    defaultValues: {
      beginn: beginn !== null ? getTimeDisplayFromDate(beginn) : '',
      ende: ende !== null ? getTimeDisplayFromDate(ende) : '',
      kommen: kommen !== null ? getTimeDisplayFromDate(kommen) : '',
      gehen: gehen !== null ? getTimeDisplayFromDate(gehen) : '',
      planBeginn:
        beginnNachPlan !== null ? getTimeDisplayFromDate(beginnNachPlan) : '',
      planEnde:
        endeNachPlan !== null ? getTimeDisplayFromDate(endeNachPlan) : '',
    },
    resolver: zodResolver(useFormSchema()),
  });

  function getTimeStringOrPlaceholder(value: Date | undefined) {
    return value !== null ? getTimeDisplayFromDate(value) : '--:--';
  }

  function onSubmit({ beginn, ende }: { beginn?: string; ende?: string }) {
    const currentBeginn = beginn ?? form.getValues('beginn');
    const currentEnde = ende ?? form.getValues('ende');
    onChange({ beginn: currentBeginn, ende: currentEnde });
  }

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <div className="flex gap-1 justify-between items-center">
          <Card>
            <FormField
              control={form.control}
              name="beginn"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>
                    {t('domain.arbeitszeiten.sheet.arbeitszeit.beginnLabel')}
                  </FormLabel>
                  <FormControl>
                    <TimeInput
                      disabled={disabled}
                      onBlur={(e) => {
                        const value = e.currentTarget.value;
                        field.onChange(value);
                        form.handleSubmit(onSubmit)();
                      }}
                      value={field.value}
                      placeholder="--:--"
                    />
                  </FormControl>
                  <FormDescription />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="kommen"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>
                    {t('domain.arbeitszeiten.sheet.arbeitszeit.kommenLabel')}
                  </FormLabel>
                  <FormControl>
                    <TimeInput
                      disabled
                      applyValue={kommen !== null && kommen !== beginn}
                      placeholder={getTimeStringOrPlaceholder(kommen)}
                      onClick={() => {
                        const value = getTimeDisplayFromDate(kommen);
                        form.setValue('beginn', value);
                        field.onChange(value);
                        form.handleSubmit(onSubmit)();
                      }}
                    />
                  </FormControl>
                  <FormDescription />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="planBeginn"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>
                    {t('domain.arbeitszeiten.sheet.arbeitszeit.planLabel')}
                  </FormLabel>
                  <FormControl>
                    <TimeInput
                      disabled
                      applyValue={
                        beginnNachPlan !== null && beginnNachPlan !== beginn
                      }
                      placeholder={getTimeStringOrPlaceholder(
                        beginnNachPlan
                          ? beginnNachPlan
                          : endeNachPlan
                          ? beginn
                          : null,
                      )}
                      onClick={() => {
                        const value = getTimeDisplayFromDate(beginnNachPlan);
                        form.setValue('beginn', value);
                        field.onChange(value);
                        form.handleSubmit(onSubmit)();
                      }}
                    />
                  </FormControl>
                  <FormDescription />
                </FormItem>
              )}
            />
          </Card>
          <div className="h-[1px] bg-neutrals-N500 w-32" />
          <Card>
            <FormField
              control={form.control}
              name="ende"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>
                    {t('domain.arbeitszeiten.sheet.arbeitszeit.endeLabel')}
                  </FormLabel>
                  <FormControl>
                    <TimeInput
                      disabled={disabled}
                      onBlur={(e) => {
                        const value = e.currentTarget.value;
                        field.onChange(value);
                        form.handleSubmit(onSubmit)();
                      }}
                      value={field.value}
                      placeholder="--:--"
                    />
                  </FormControl>
                  <FormDescription />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="gehen"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>
                    {t('domain.arbeitszeiten.sheet.arbeitszeit.gehenLabel')}
                  </FormLabel>
                  <FormControl>
                    <TimeInput
                      disabled
                      className="text-primary-blue-B600 cursor-pointer"
                      placeholder={getTimeStringOrPlaceholder(gehen)}
                      applyValue={gehen !== null && gehen !== ende}
                      onClick={() => {
                        const value = getTimeDisplayFromDate(gehen);
                        form.setValue('ende', value);
                        field.onChange(value);
                        form.handleSubmit(onSubmit)();
                      }}
                    />
                  </FormControl>
                  <FormDescription />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="planEnde"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>
                    {t('domain.arbeitszeiten.sheet.arbeitszeit.planLabel')}
                  </FormLabel>
                  <FormControl>
                    <TimeInput
                      disabled
                      applyValue={
                        endeNachPlan !== null && endeNachPlan !== ende
                      }
                      onClick={() => {
                        const value = getTimeDisplayFromDate(endeNachPlan);
                        form.setValue('ende', value);
                        field.onChange(value);
                        form.handleSubmit(onSubmit)();
                      }}
                      placeholder={getTimeStringOrPlaceholder(
                        endeNachPlan
                          ? endeNachPlan
                          : beginnNachPlan
                          ? ende
                          : null,
                      )}
                    />
                  </FormControl>
                  <FormDescription />
                </FormItem>
              )}
            />
          </Card>
        </div>
        {Object.keys(form.formState.errors).length > 0 && (
          <div className="mt-4">
            {Object.values(form.formState.errors).map((error, index) => (
              <FormMessage
                key={index}
                className="bg-red-100 rounded-sm text-error-main p-4 text-sm font-semibold">
                {error.message}
              </FormMessage>
            ))}
          </div>
        )}
      </form>
    </Form>
  );
}
