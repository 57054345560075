import { faCircleExclamation } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import * as Sentry from '@sentry/react';

export function ErrorPage({ error }: { error: unknown }) {
  const { t } = useTranslation();

  let message = '';
  if (typeof error === 'string') {
    message = error;
  } else {
    message = t('pages.protectedPageError');
    Sentry.captureException(error);
  }

  return (
    <div className="w-screen h-screen flex justify-center items-center">
      <div className="flex flex-col gap-2 items-center">
        <FontAwesomeIcon
          icon={faCircleExclamation}
          size="4x"
          className="text-primary-red-R400"
        />
        <p className="max-w-xs text-center">{message}</p>
      </div>
    </div>
  );
}
