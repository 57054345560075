/* eslint-disable complexity */

import {
  Bar,
  CartesianGrid,
  ComposedChart,
  Line,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { ChartContainer } from '@/components/ui/chart';
import { usePlanwerte } from '@/domain/controlling/hooks';
import { useStandort } from '@/hooks';
import { useParams } from 'react-router-dom';
import { getHoursAndMinutesFromMinutes, getWeekdayByDate } from '@/utils';
import { useTranslation } from 'react-i18next';
import { formatNumber } from '../../formatNumber';
import { CustomChartTooltip } from './CustomChartTooltip';
import { PayloadEntry } from './types';

// eslint-disable-next-line max-lines-per-function
export default function PlanwerteChart({
  type,
}: {
  type: 'absatz' | 'umsatz';
}) {
  const { t } = useTranslation();
  const { standortId } = useStandort();
  const { year, month } = useParams();
  const { absatzPlanwerte, umsatzPlanwerte } = usePlanwerte({
    jahrMonat: { jahr: parseInt(year), monat: parseInt(month) },
    standortId,
  });

  const datesOfCurrenctMonth = Array.from(
    {
      length: new Date(parseInt(year), parseInt(month), 0).getDate(),
    },
    (_, i) =>
      new Date(parseInt(year), parseInt(month) - 1, i + 2)
        .toISOString()
        .split('T')[0],
  );

  const chartData = [];
  if (type === 'umsatz') {
    datesOfCurrenctMonth.forEach((datum) => {
      const umsatzPlan = umsatzPlanwerte?.find(
        (umsatzPlan) => umsatzPlan.datum === datum,
      );

      const newChartDataEntry = {
        datum,
        planwert:
          umsatzPlan && umsatzPlan.umsatz && umsatzPlan.umsatz.wert
            ? umsatzPlan.umsatz.wert.amount
            : 0,
        prognose:
          umsatzPlan && umsatzPlan.prognose ? umsatzPlan.prognose.amount : 0,
        zielstunden:
          umsatzPlan && umsatzPlan.umsatz && umsatzPlan.umsatz.stunden
            ? umsatzPlan.umsatz.stunden
            : 0,
      };
      chartData.push(newChartDataEntry);
    });
  } else {
    datesOfCurrenctMonth.forEach((datum) => {
      const absatzPlan = absatzPlanwerte?.find(
        (absatzPlan) => absatzPlan.datum === datum,
      );

      const newChartDataEntry = {
        datum,
        planwert:
          absatzPlan && absatzPlan.absatz && absatzPlan.absatz.wert
            ? absatzPlan.absatz.wert
            : 0,
        prognose: absatzPlan && absatzPlan.prognose ? absatzPlan.prognose : 0,
        zielstunden:
          absatzPlan && absatzPlan.absatz && absatzPlan.absatz.stunden
            ? absatzPlan.absatz.stunden
            : 0,
      };
      chartData.push(newChartDataEntry);
    });
  }

  const zielstundenMax = Math.max(...chartData.map((d) => d.zielstunden));
  // Wenn die Zielstunden nicht durch 60 teilbar sind, z.B. 40 Std. 20 Min., dann runden wir auf die nächste volle Stunde
  const adjustedZielstundenMax = Math.ceil(zielstundenMax / 60) * 60;
  // Wir wollen die Y-Achse in 4 gleichmäßige Teile aufteilen
  const tickIntervals = adjustedZielstundenMax / 4;

  // Jeder Tick auf der Y-Achse ist ein Vielfaches des tickIntervals
  const yAxisTicks = Array.from({ length: 5 }, (_, i) =>
    Math.round(i * tickIntervals),
  );

  return (
    <ChartContainer
      config={{
        zielstunden: {
          label: 'zielstunden',
        },
        prognose: {
          label: 'prognose',
        },
        planwert: {
          label: 'planwert',
        },
      }}
      className="h-[300px] w-full p-0">
      <ComposedChart data={chartData} margin={{ top: 20, left: 15, right: 15 }}>
        <CartesianGrid stroke="#E5E5EA" vertical={false} />
        <XAxis
          className="text-xs text-neutrals-N600"
          dataKey="datum"
          scale="point"
          tickLine={false}
          padding={{ left: 20, right: 20 }}
          tickFormatter={(value) => {
            const weekday = getWeekdayByDate(new Date(value));
            if (value.split('-')[2] === '01') {
              return weekday.shortage + '. 01';
            } else if (weekday.dayOfWeek === 1) {
              return weekday.shortage + '. ' + value.split('-')[2];
            } else {
              return '';
            }
          }}
        />
        <YAxis
          tickLine={false}
          yAxisId="left"
          orientation="left"
          stroke="#30B0C7"
          className="font-semibold"
          tickFormatter={(value) => {
            if (type === 'absatz') {
              return (
                formatNumber(value.toString(), true) +
                ' ' +
                t('domain.controlling.umsatzplanung.units.stueck')
              );
            } else {
              return (
                formatNumber(value.toString(), true) +
                ' ' +
                t('general.currency.EUR')
              );
            }
          }}
        />
        <YAxis
          tickLine={false}
          yAxisId="right"
          orientation="right"
          stroke="#007AFF"
          className="font-semibold"
          dataKey="zielstunden"
          ticks={yAxisTicks}
          tickFormatter={(value) => {
            const hoursAndMinutes = getHoursAndMinutesFromMinutes(value);
            // Wir brauchen hier ein non-breaking space, damit die Zeile nicht getrennt wird
            return hoursAndMinutes.hours + '\u00A0' + t('general.date.hours');
          }}
        />
        <Tooltip
          content={(props: {
            active?: boolean;
            payload?: PayloadEntry[];
            label?: string;
          }) => (
            <CustomChartTooltip
              type={type}
              label={props.label}
              payload={props.payload}
              active={props.active}
            />
          )}
        />
        <Bar
          yAxisId="right"
          dataKey="zielstunden"
          fill="#007AFF"
          name="zielstunden"
          barSize={10}
          radius={[3, 3, 0, 0]}
        />
        <Line
          yAxisId="left"
          type="monotone"
          dataKey="planwert"
          stroke="#30B0C7"
          name="planwert"
          strokeWidth={2}
          dot={false}
          activeDot={{ r: 5 }}
        />
        <Line
          yAxisId="left"
          type="monotone"
          dataKey="prognose"
          stroke="#30B0C7"
          name="prognose"
          strokeWidth={2}
          strokeDasharray="5 5"
          dot={false}
          activeDot={{ r: 5 }}
        />
      </ComposedChart>
    </ChartContainer>
  );
}
