import dayjs from 'dayjs';
import { ContentWrapperCell } from './ContentWrapperCell';
import { Link } from 'react-router-dom';

export function DayCell({ date }: { date: { date: string; weekday: string } }) {
  const year = dayjs(date.date).year();
  const month = dayjs(date.date).month() + 1;

  return (
    <ContentWrapperCell>
      <Link
        to={`/controlling/umsatzplanung-v2/${year}/${month}/planwerte/edit?datum=${date.date}`}>
        <span className="text-sm text-primary-blue-B500 hover:text-primary-blue-B400 font-semibold">
          {date.weekday + ' ' + dayjs(date.date).format('D')}
        </span>
      </Link>
    </ContentWrapperCell>
  );
}
