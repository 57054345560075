import { useSuspenseQuery } from '@apollo/client';
import { GET_CONTROLLING_EINSTELLUNGEN_BY_STANDORT_ID } from './graphql/queries';
import {
  AbsatzproduktivitaetEinstellungen,
  AbsatzVorgabe,
  TagebuchEinstellung,
} from '../entities/tagebuchEinstellungen';

export function useTagebuchEinstellungen(standortId: string): {
  tagebuchEinstellungen?: TagebuchEinstellung;
  error?: string;
  umsatzplanung?: 'UMSATZ' | 'ABSATZ' | 'UMSATZ_ABSATZ';
} {
  const { data, error } = useSuspenseQuery(
    GET_CONTROLLING_EINSTELLUNGEN_BY_STANDORT_ID,
    {
      variables: { standortId },
      fetchPolicy: 'network-only',
    },
  );

  if (error) {
    return { error: error.message };
  }

  const controllingEinstellungen = data.controlling;

  if (controllingEinstellungen.length > 1) {
    return {
      error:
        'Es sollten nicht für mehrere Standorte die Controllingeinstellungen zurückgegeben werden',
    };
  }

  const absatzproduktivitaetEinstellungen =
    data.controlling[0].einstellungen.absatzproduktivitaet;

  const absatzVorgabe = !absatzproduktivitaetEinstellungen.absatzVorgabe
    ? AbsatzVorgabe.Stueck
    : AbsatzVorgabe[absatzproduktivitaetEinstellungen.absatzVorgabe];

  return {
    tagebuchEinstellungen: {
      absatzproduktivitaet: new AbsatzproduktivitaetEinstellungen(
        standortId,
        absatzVorgabe,
        absatzproduktivitaetEinstellungen.zielProduktivitaet,
      ),
    },
    umsatzplanung: 'UMSATZ_ABSATZ',
  };
}
