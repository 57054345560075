import { gql } from '@apollo/client';

export const ARBEITSZEIT_UPDATE = gql`
  mutation ArbeitszeitUpdate(
    $arbeitszeitUpdateInput: UpdateArbeitszeitenInput!
    $arbeitszeitId: String!
  ) {
    updateArbeitszeit(
      arbeitszeitUpdateInput: $arbeitszeitUpdateInput
      arbeitszeitId: $arbeitszeitId
    ) {
      ... on Arbeitszeiten {
        datum
        konflikte {
          konflikt
          id
        }
        termin {
          beschreibung
          bildName
          bildUrl
          titel
          wiederholung
          id
        }
        zuschlaege {
          name
          prozent
          betrag {
            ... on ArbeitszeitZuschlaegeBetrag {
              currency
              betrag
            }
            ... on NoAccess {
              message
            }
          }
          dauer
        }
        bemerkung
        rechtshinweise {
          name
          art
          backgroundcolor
        }
        id
        pausen {
          pausenart
          ende
          bezahlt
          dauer
          beginn
        }
        pauseneinstellung
        arbeitsbereich {
          markierung
          id
          name
        }
        mitarbeiter {
          vorname
          nachname
          id
        }
        dienstgaenge {
          beginn
          ende
          dauer
        }
      }
      ... on ArbeitsbereichNotFound {
        type
        message
      }
      ... on CharacterLengthError {
        type
        message
      }
      ... on ArbeitszeitNotFound {
        type
        message
      }
      ... on UnknownError {
        type
        message
      }
      ... on ManagerHasNoAccessToArbeitsbereich {
        type
        message
      }
      ... on ArbeitszeitUpdateIdArbeitsbereichNull {
        type
        message
      }
      ... on ManagerHasRoleArbeitszeitNurHeuteBearbeiten {
        type
        message
      }
    }
  }
`;

export const ARBEITSZEIT_CREATE = gql`
  mutation ArbeitszeitCreate(
    $arbeitszeitCreateInput: CreateArbeitszeitenInput2!
    $mitarbeiterId: String!
    $standortId: String!
    $datum: DateTime!
  ) {
    createArbeitszeit(
      arbeitszeitCreateInput: $arbeitszeitCreateInput
      mitarbeiterId: $mitarbeiterId
      standortId: $standortId
      datum: $datum
    ) {
      ... on Arbeitszeiten {
        id
        datum
      }
      ... on ArbeitszeitCreateEndeVorBeginn {
        type
        message
      }
      ... on UnknownError {
        type
        message
      }
      ... on ArbeitszeitCreateManagerHasNoAccessToArbeitsbereich {
        type
        message
      }
      ... on ArbeitszeitCreateManagerNotFound {
        type
        message
      }
      ... on ArbeitszeitCreateMissingValues {
        type
        message
      }
      ... on ArbeitszeitCreateMonatAbgeschlossen {
        type
        message
      }
      ... on ArbeitszeitCreateOtherValidationError {
        type
        message
      }
      ... on ManagerHasRoleArbeitszeitNurHeuteBearbeiten {
        type
        message
      }
    }
  }
`;

export const ARBEITSZEIT_DELETE = gql`
  mutation ArbeitszeitDelete($arbeitszeitId: String!) {
    deleteArbeitszeit(arbeitszeitId: $arbeitszeitId) {
      ... on Arbeitszeiten {
        id
        datum
        bemerkung
        beginn
        ende
        pausen {
          pausenart
          ende
          bezahlt
          dauer
          beginn
        }
        pauseneinstellung
        arbeitsbereich {
          __typename
          markierung
          id
          name
        }
        mitarbeiter {
          vorname
          nachname
          id
        }
      }
      ... on ArbeitszeitNotFound {
        type
        message
      }
      ... on ArbeitszeitManagerNotFound {
        type
        message
      }
      ... on ArbeitszeitMonatAbgeschlossen {
        type
        message
      }
      ... on ManagerHasRoleArbeitszeitNurHeuteBearbeiten {
        type
        message
      }
      ... on UnknownError {
        type
        message
      }
    }
  }
`;

export const DIENSTPLANUEBERNAHME_UPDATE = gql`
  mutation DienstplanuebernahmeUpdate(
    $input: [UpdateDienstplanuebernahmeInput!]!
  ) {
    updateDienstplanuebernahme(updateDienstplanuebernahmeInput: $input) {
      id
    }
  }
`;

export const ARBEITSZEIT_CREATE_MANY = gql`
  mutation ArbeitszeitCreateMany($input: [CreateArbeitszeitenBulkInput!]!) {
    createManyArbeitszeiten(input: $input) {
      input {
        arbeitsbereichId
        beginn
        datum
        ende
        mitarbeiterId
        standortId
      }
      result {
        ... on Arbeitszeiten {
          id
          datum
        }
        ... on ArbeitszeitCreateEndeVorBeginn {
          type
          message
        }
        ... on UnknownError {
          type
          message
        }
        ... on ArbeitszeitCreateManagerHasNoAccessToArbeitsbereich {
          type
          message
        }
        ... on ArbeitszeitCreateManagerNotFound {
          type
          message
        }
        ... on ArbeitszeitCreateMissingValues {
          type
          message
        }
        ... on ArbeitszeitCreateMonatAbgeschlossen {
          type
          message
        }
        ... on ArbeitszeitCreateOtherValidationError {
          type
          message
        }
        ... on ManagerHasRoleArbeitszeitNurHeuteBearbeiten {
          type
          message
        }
      }
    }
  }
`;
