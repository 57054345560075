import { graphql } from '../../../../gql';

export const PLANUMSATZ_CREATE = graphql(`
  mutation PlanumsatzCreate(
    $planumsatzCreateInput: PlanumsatzCreateInput!
    $standortId: String!
  ) {
    createPlanumsatz(
      planumsatzCreateInput: $planumsatzCreateInput
      standortId: $standortId
    ) {
      ... on PlanumsatzCreate {
        __typename
        tagebuch {
          idStandort
          istAbsatz
          istUmsatz
          planAbsatz
          planUmsatz
          datum
        }
        umsatzBudget
      }
      ... on PlanumsatzError {
        __typename
        message
        type
      }
    }
  }
`);

export const PLANUMSATZ_UPDATE = graphql(`
  mutation PlanumsatzUpdate(
    $planumsatzUpdateInput: PlanumsatzUpdateInput!
    $standortId: String!
  ) {
    updatePlanumsatz(
      planumsatzUpdateInput: $planumsatzUpdateInput
      standortId: $standortId
    ) {
      ... on PlanumsatzUpdate {
        __typename
        tagebuch {
          datum
          idStandort
          istAbsatz
          istUmsatz
          planAbsatz
          planUmsatz
        }
        umsatzBudget
      }
      ... on PlanumsatzError {
        __typename
        message
        type
      }
    }
  }
`);

export const CREATE_OR_UPDATE_UMSATZZIEL = graphql(`
  mutation CreateOrUpdateUmsaatzziel(
    $createOrUpdateUmsatzzielInput: CreateOrUpdateUmsatzziel!
  ) {
    createOrUpdateZiel: createOrUpdateUmsatzZiel(
      createOrUpdateUmsatzzielInput: $createOrUpdateUmsatzzielInput
    ) {
      __typename
      ... on Umsatz {
        __typename
        produktivitaet {
          amount
          currency
        }
        standortId
        stunden
        wert {
          amount
          currency
        }
      }
      ... on UmsatzplanungUnkownError {
        __typename
        message
        type
      }
      ... on UmsatzUpdateResultStandortNotFound {
        __typename
        message
        type
      }
      ... on UmsatzUpdateResultUpdateError {
        __typename
        message
        type
      }
    }
  }
`);

export const CREATE_OR_UPDATE_ABSATZZIEL = graphql(`
  mutation CreateOrUpdateAbsatzziel(
    $createOrUpdateAbsatzzielInput: CreateOrUpdateAbsatzziel!
  ) {
    createOrUpdateZiel: createOrUpdateAbsatzziel(
      createOrUpdateAbsatzzielInput: $createOrUpdateAbsatzzielInput
    ) {
      ... on Absatz {
        __typename
        produktivitaet {
          mpt
          stueck
        }
        standortId
        stunden
        wert
      }
      ... on UmsatzplanungUnkownError {
        __typename
        message
        type
      }
      ... on AbsatzUpdateResultStandortNotFound {
        __typename
        message
        type
      }
      ... on AbsatzUpdateResultUpdateError {
        __typename
        message
        type
      }
    }
  }
`);

export const CREATE_OR_UPDATE_UMSATZ_PLANWERT = graphql(`
  mutation CreateOrUpdateUmsatzPlanWert(
    $createOrUpdateUmsatzPlanInput: CreateOrUpdateUmsatzPlan!
  ) {
    createOrUpdateUmsatzPlan(
      createOrUpdateUmsatzPlanInput: $createOrUpdateUmsatzPlanInput
    ) {
      __typename
      ... on Umsatz {
        __typename
        produktivitaet {
          amount
          currency
        }
        standortId
        stunden
        wert {
          amount
          currency
        }
      }
      ... on UmsatzplanungUnkownError {
        __typename
        message
        type
      }
      ... on UmsatzUpdateResultStandortNotFound {
        __typename
        message
        type
      }
      ... on UmsatzUpdateResultUpdateError {
        __typename
        message
        type
      }
    }
  }
`);

export const CREATE_OR_UPDATE_ABSATZ_PLANWERT = graphql(`
  mutation CreateOrUpdateAbsatzPlanWert(
    $createOrUpdateAbsatzPlanInput: CreateOrUpdateAbsatzPlan!
  ) {
    createOrUpdateAbsatzPlan(
      createOrUpdateAbsatzPlanInput: $createOrUpdateAbsatzPlanInput
    ) {
      __typename
      ... on Absatz {
        __typename
        produktivitaet {
          mpt
          stueck
        }
        standortId
        stunden
        wert
      }
      ... on UmsatzplanungUnkownError {
        __typename
        message
        type
      }
      ... on AbsatzUpdateResultStandortNotFound {
        __typename
        message
        type
      }
      ... on AbsatzUpdateResultUpdateError {
        __typename
        message
        type
      }
    }
  }
`);
