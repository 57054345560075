import { useSuspenseQuery } from '@apollo/client';
import { GET_UMSATZPLANUNG_SOLLSTUNDEN_BY_STANDORT_ID } from './graphql/queries';
import { Sollstunden } from '../entities/sollstunden';
import { JahrMonat } from '../entities/jahrMonat';

export function useUmsatzplanungSollstunden(
  standortId: string,
  jahrMonat: JahrMonat,
): {
  sollstunden?: Sollstunden;
  error?: string;
} {
  const { data, error } = useSuspenseQuery(
    GET_UMSATZPLANUNG_SOLLSTUNDEN_BY_STANDORT_ID,
    {
      variables: { standortId, jahr: jahrMonat.jahr, monat: jahrMonat.monat },
      fetchPolicy: 'network-only',
    },
  );

  if (error) {
    return { error: error.message };
  }

  const sollstunden = data.controlling;

  if (sollstunden.length > 1) {
    return {
      error:
        'Es sollten nicht für mehrere Standorte die Sollstunden zurückgegeben werden',
    };
  }

  return {
    sollstunden: new Sollstunden(
      sollstunden[0].sollstunden.aushilfen,
      sollstunden[0].sollstunden.festangestellt,
    ),
  };
}
