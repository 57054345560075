import dayjs from 'dayjs';

export function getStartDate(
  isValidYear: boolean,
  isValidMonth: boolean,
  year: string,
  month: string,
) {
  if (!isValidYear && !isValidMonth) {
    return dayjs().startOf('month').format('YYYY-MM-DD');
  }
  return dayjs(`${year}-${month}-01`).startOf('month').format('YYYY-MM-DD');
}

export function getEndDate(
  isValidYear: boolean,
  isValidMonth: boolean,
  year: string,
  month: string,
) {
  if (!isValidYear && !isValidMonth) {
    return dayjs().endOf('month').format('YYYY-MM-DD');
  }
  return dayjs(`${year}-${month}-01`).endOf('month').format('YYYY-MM-DD');
}

export function getIsValidYear(year: string) {
  return year && !Number.isNaN(year) && year.length === 4;
}

export function getIsValidMonth(month: string) {
  return month && !Number.isNaN(month) && /^[1-9]$|^1[0-2]$/.test(month);
}
