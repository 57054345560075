import { useSuspenseQuery } from '@apollo/client';
import { GET_PLANUMSAETZE_BY_STANDORTID } from './graphql/queries';
import {
  Planumsatz,
  PlanumsatzError,
  Tagebuch,
  ZeitraumInput,
} from '../../../gql/graphql';
import { handleGraphQLErrors } from '../../../utils/errors/handleGraphQLErrors';
import { Prognoseumsatz } from '../entities/prognoseumsatz';

export function usePlanumsatz(
  zeitraum: ZeitraumInput,
  standortId: string,
): {
  tagebuch?: Tagebuch[];
  error?: string;
  umsatzBudget?: number;
  prognoseumsaetze?: Prognoseumsatz[];
} {
  const filter = { zeitraum, standortId };

  const { data } = useSuspenseQuery(GET_PLANUMSAETZE_BY_STANDORTID, {
    variables: {
      filter,
    },
    fetchPolicy: 'cache-first',
  });

  const error = handleGraphQLErrors<Planumsatz, PlanumsatzError>(
    data.planUmsaetzeByStandortId,
  );

  if (error) {
    return { error: error.message };
  }

  const planumsatz = data.planUmsaetzeByStandortId as Planumsatz;

  return {
    tagebuch: planumsatz.tagebuch ?? [],
    umsatzBudget: planumsatz.umsatzBudget,
    prognoseumsaetze: planumsatz.prognoseumsaetze,
    error: error ? error.message : undefined,
  };
}
