import { useSuspenseQuery } from '@apollo/client';
import {
  Audit,
  ArbeitszeitAuditManager,
  ArbeitszeitAuditAenderung,
} from '../entities/Arbeitszeit';
import { GET_AUDIT_BY_ARBEITSZEIT_ID } from './graphql/queries';
import { ArbeitszeitAuditAenderungsTyp } from '@/gql/graphql';
import { formatAttribut } from '../components/EditSheet/content/Historie/formatAttribut';

export function useAudit(idArbeitszeit: string): {
  audit?: Audit[];
  error?: string;
} {
  const { data, error } = useSuspenseQuery(GET_AUDIT_BY_ARBEITSZEIT_ID, {
    variables: { idArbeitszeit },
    fetchPolicy: 'network-only',
  });

  if (error) {
    return { error: error.message, audit: [] };
  }

  const auditData = data?.arbeitszeitById?.audit ?? [];

  return {
    audit: auditData
      .map((audit) => {
        if (audit && audit.__typename === 'Audit') {
          return new Audit(
            audit.id,
            audit.typ,
            ArbeitszeitAuditAenderungsTyp[audit.aenderungsTyp],
            new Date(audit.aenderungsdatum),
            audit.geaendertVon,
            audit.geaendertVonEmail,
            audit.manager
              ? new ArbeitszeitAuditManager(
                  audit.manager.email,
                  audit.manager.vorname,
                  audit.manager.nachname,
                )
              : null,
            audit.aenderungen.map(
              (aenderung) =>
                new ArbeitszeitAuditAenderung(
                  aenderung.attribut.charAt(0).toUpperCase() +
                    aenderung.attribut.slice(1).toLocaleLowerCase(),
                  formatAttribut(aenderung.attribut, aenderung.nachher),
                  formatAttribut(aenderung.attribut, aenderung.vorher),
                ),
            ),
          );
        }
        return null;
      })
      .filter((audit) => audit !== null),
  };
}
