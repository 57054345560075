import { Suspense } from 'react';
import {
  DataTableHeader,
  DataTableLoading,
  DatePickerSkeleton,
} from '../../components/Skeleton';
import { Uebersicht } from './Uebersicht';
import { useParams } from 'react-router-dom';

export function UebersichtWrapper() {
  const { year, month, day } = useParams();
  return (
    <Suspense
      fallback={
        <div>
          <div className="flex p-8 pt-4 bg-white justify-between">
            <DatePickerSkeleton year={year} month={month} day={day} />
            <DataTableHeader />
          </div>
          <DataTableLoading />
        </div>
      }>
      <Uebersicht />
    </Suspense>
  );
}
