import { TFunction } from 'i18next';
import {
  CreateOrUpdateUmsaatzzielMutation,
  CreateOrUpdateAbsatzzielMutation,
  UmsatzZielUpdateResult,
  AbsatzZielUpdateResult,
} from '../../../../gql/graphql';

function getTranslationKey(
  updateErrorResult: UmsatzZielUpdateResult | AbsatzZielUpdateResult,
) {
  let message = '';
  if (
    updateErrorResult.__typename === 'UmsatzUpdateResultStandortNotFound' ||
    updateErrorResult.__typename === 'AbsatzUpdateResultStandortNotFound'
  ) {
    message =
      'domain.controlling.umsatzplanung.ziele.errors.updateResultStandortNotFound';
  } else if (
    updateErrorResult.__typename === 'UmsatzUpdateResultUpdateError' ||
    updateErrorResult.__typename === 'AbsatzUpdateResultUpdateError'
  ) {
    message =
      'domain.controlling.umsatzplanung.ziele.errors.updateResultUpdateError';
  } else {
    message = 'domain.controlling.umsatzplanung.ziele.errors.unknownError';
  }
  return message;
}

export function getMessage(
  updateResult:
    | CreateOrUpdateUmsaatzzielMutation
    | CreateOrUpdateAbsatzzielMutation,
  t: TFunction,
) {
  let updateError = undefined;
  if (
    updateResult.createOrUpdateZiel.__typename !== 'Umsatz' &&
    updateResult.createOrUpdateZiel.__typename !== 'Absatz'
  ) {
    updateError = {
      type: updateResult.createOrUpdateZiel.type,
      message: updateResult.createOrUpdateZiel.message,
    };
  }

  if (updateError) {
    return t(getTranslationKey(updateResult.createOrUpdateZiel));
  } else {
    return t('domain.controlling.umsatzplanung.ziele.success');
  }
}
