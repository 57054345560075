import { t } from 'i18next';
import { Arbeitszeit } from '../../../../../entities';
import { useArbeitszeitUpdate } from '../../../../../hooks/useArbeitszeitenUpdate';
import { ConfirmDeletePopover } from '../../../../Popover/ConfirmDeletePopover';
import { handleOnCompleted } from '../../../handleOnCompleted';

export function DeleteAction({
  arbeitszeit,
  onDeleted,
}: {
  arbeitszeit: Arbeitszeit;
  onDeleted?: () => void;
}) {
  const [arbeitszeitUpdate] = useArbeitszeitUpdate();

  async function handleDelete() {
    const result = await arbeitszeitUpdate({
      arbeitszeitUpdateInput: {
        bemerkung: '',
      },
      arbeitszeitId: arbeitszeit.id,
    });
    handleOnCompleted(result.data, t);
  }

  return (
    <ConfirmDeletePopover
      confirmText={t(
        'domain.arbeitszeiten.sheet.arbeitszeit.bemerkung.confirmDelete',
      )}
      handleDelete={handleDelete}
      onDeleted={onDeleted}
      confirmButtonText={t(
        'domain.arbeitszeiten.sheet.arbeitszeit.confirmButton',
      )}
      processingButtonText={t(
        'domain.arbeitszeiten.sheet.arbeitszeit.confirmIsDeleting',
      )}
    />
  );
}
