import { Sollstunden } from '@/domain/controlling/entities/sollstunden';
import { getHoursAndMinutesFromMinutes } from '@/utils';
import { faCircle } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';

// eslint-disable-next-line max-lines-per-function
export function ChartTooltip({ sollstunden }: { sollstunden: Sollstunden }) {
  const { t } = useTranslation();

  return (
    <div className="py-2 px-3 flex flex-col gap-2 w-[280px]">
      {sollstunden.aushilfen + sollstunden.festangestellte === 0 ? (
        <div>
          {t('domain.controlling.umsatzplanung.sollstunden.keineSollstunden')}
        </div>
      ) : (
        <>
          <div className="font-semibold">
            {t('domain.controlling.umsatzplanung.sollstunden.label')}
          </div>
          <div className="flex justify-between items-center">
            <div className="flex gap-2 items-center">
              <FontAwesomeIcon
                icon={faCircle}
                className="w-3 h-3 text-primary-blue-B500"
              />
              <div>
                {t(
                  'domain.controlling.umsatzplanung.sollstunden.festangestelltLabel',
                )}
              </div>
            </div>
            <div className="flex items-baseline gap-1 font-semibold">
              <div className="flex items-baseline gap-[2px] font-semibold">
                <div>
                  {
                    getHoursAndMinutesFromMinutes(sollstunden.festangestellte)
                      .hours
                  }
                </div>
                <div>{t('general.date.hours')}</div>
              </div>
              <div className="flex items-baseline gap-[2px] font-semibold">
                <div>
                  {
                    getHoursAndMinutesFromMinutes(sollstunden.festangestellte)
                      .minutes
                  }
                </div>
                <div>{t('general.date.minutes')}</div>
              </div>
            </div>
          </div>
          <div className="flex items-center justify-between">
            <div className="flex gap-2 items-center">
              <FontAwesomeIcon
                icon={faCircle}
                className="w-3 h-3 text-primary-blue-B200"
              />
              <div>
                {t(
                  'domain.controlling.umsatzplanung.sollstunden.aushilfenLabel',
                )}
              </div>
            </div>
            <div className="flex items-baseline gap-1 font-semibold">
              <div className="flex items-baseline gap-[2px] font-semibold">
                <div>
                  {getHoursAndMinutesFromMinutes(sollstunden.aushilfen).hours}
                </div>
                <div>{t('general.date.hours')}</div>
              </div>
              <div className="flex items-baseline gap-[2px] font-semibold">
                <div>
                  {getHoursAndMinutesFromMinutes(sollstunden.aushilfen).minutes}
                </div>
                <div>{t('general.date.minutes')}</div>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
}
