/* eslint-disable max-lines-per-function */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Prognoseumsatz } from '../../../../entities/prognoseumsatz';
import { useTranslation } from 'react-i18next';
import { Dispatch, SetStateAction } from 'react';
import { PlanumsatzEntry } from '../../types';
import { calculateDurchschnitt } from './calculateDurschnitt';
import { maskitoParseNumber } from '@maskito/kit';
import { Button } from '@/components/ui/button';
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '@/components/ui/tooltip';
import { formatNumber } from '../../formatNumber';
import { faArrowLeftFromArc } from '@fortawesome/pro-regular-svg-icons';

export function PrognoseumsatzCell({
  currentDatum,
  prognoseumsaetze,
  planumsatzEntries,
  setPlanumsatzEntries,
  setDurchschnitt,
}: {
  currentDatum: string;
  prognoseumsaetze: Prognoseumsatz[];
  planumsatzEntries: PlanumsatzEntry[];
  setPlanumsatzEntries: Dispatch<SetStateAction<PlanumsatzEntry[]>>;
  setDurchschnitt: Dispatch<SetStateAction<number>>;
}) {
  const { t } = useTranslation();
  const planumsatz = planumsatzEntries.find((p) => p.datum === currentDatum);
  const currentPrognose = prognoseumsaetze.find(
    (prognose) => prognose.datum === currentDatum,
  );

  function handleOnBlur(newValue: string) {
    if (planumsatzEntries.find((p) => p.datum === currentDatum)) {
      const updatedPlanumsatz: PlanumsatzEntry = {
        ...planumsatz,
        planUmsatz: maskitoParseNumber(newValue, ','),
      };
      const newPlanumsatzEntries = planumsatzEntries.map((p) => {
        if (p.datum === currentDatum) {
          return updatedPlanumsatz;
        } else {
          return p;
        }
      });
      const durchschnitt = calculateDurchschnitt(
        updatedPlanumsatz.planUmsatz,
        updatedPlanumsatz.planAbsatz,
      );
      setDurchschnitt(durchschnitt);
      setPlanumsatzEntries(newPlanumsatzEntries);
    } else {
      const newPlanumsatzEntry: PlanumsatzEntry = {
        datum: currentDatum,
        planAbsatz: 0,
        planUmsatz: maskitoParseNumber(newValue, ','),
        type: 'created',
      };
      setDurchschnitt(0);
      setPlanumsatzEntries([...planumsatzEntries, newPlanumsatzEntry]);
    }
  }

  return (
    <div className="flex gap-1 items-center">
      <TooltipProvider>
        <Tooltip>
          <TooltipTrigger asChild>
            <Button
              size="sm"
              variant="ghost"
              onClick={() =>
                handleOnBlur(
                  currentPrognose
                    ? formatNumber(currentPrognose.prognose.toString(), false)
                    : '0',
                )
              }>
              <FontAwesomeIcon
                icon={faArrowLeftFromArc}
                className="text-primary-blue-B600"
              />
            </Button>
          </TooltipTrigger>
          <TooltipContent>
            <span>
              {t(
                'domain.controlling.umsatzplanung.actions.prognoseUebernehmen',
              )}
            </span>
          </TooltipContent>
        </Tooltip>
      </TooltipProvider>
      <span>
        {currentPrognose
          ? formatNumber(currentPrognose.prognose.toString(), false)
          : '0'}
      </span>
    </div>
  );
}
