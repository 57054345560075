import { usePlanwerte } from '@/domain/controlling/hooks';
import { useStandort } from '@/hooks';
import { MonthParams } from '../../types';
import { useParams } from 'react-router-dom';
import { ZielstundenField } from '../DataTable/Cells/GenericFields';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Differenz } from '../DataTable/Cells/Differenz';
import { faCircle } from '@fortawesome/pro-solid-svg-icons';
import { useUmsatzplanungSollstunden } from '@/domain/controlling/hooks/useUmsatzplanungSollstunden';

// eslint-disable-next-line max-lines-per-function
export function SollstundenAbweichungKPI({
  type,
}: {
  type: 'absatz' | 'umsatz';
}) {
  const { standortId } = useStandort();
  const { year, month } = useParams<MonthParams>();
  const { t } = useTranslation();
  const { sollstunden: zielSollstunden } = useUmsatzplanungSollstunden(
    standortId,
    {
      jahr: parseInt(year),
      monat: parseInt(month),
    },
  );

  const { umsatzPlanwerte, absatzPlanwerte } = usePlanwerte({
    standortId,
    jahrMonat: { jahr: parseInt(year), monat: parseInt(month) },
  });

  let sollstundenSum = 0;
  if (type == 'umsatz') {
    umsatzPlanwerte.forEach((umsatzPlanwert) => {
      if (umsatzPlanwert.umsatz) {
        sollstundenSum = umsatzPlanwert.umsatz.stunden + sollstundenSum;
      }
    });
  } else {
    absatzPlanwerte.forEach((absatzPlanwert) => {
      if (absatzPlanwert.absatz) {
        sollstundenSum = absatzPlanwert.absatz.stunden + sollstundenSum;
      }
    });
  }

  return (
    <div className="flex flex-col gap-2 w-1/3">
      <div className="flex gap-2 items-center">
        <FontAwesomeIcon
          icon={faCircle}
          className="text-primary-blue-B600 h-3 w-3"
        />
        <div className="text-xs font-semibold text-neutrals-N700">
          {t('domain.controlling.umsatzplanung.table.zielstunden')}
        </div>
      </div>
      <div className="flex flex-col gap-1">
        <ZielstundenField
          value={sollstundenSum}
          className="[&>div:nth-child(odd)]:text-2xl [&>div:nth-child(even)]:text-base [&>div:nth-child(odd)]:font-extrabold [&>div:nth-child(even)]:font-normal"
        />
        <Differenz
          tooltipMessage={t(
            'domain.controlling.umsatzplanung.planung.tooltip.abweichungZuSollstunden',
          )}
          planWert={sollstundenSum}
          prognoseWert={
            zielSollstunden.aushilfen + zielSollstunden.festangestellte
          }
          type="stunden"
          unit={t('general.currency.EUR')}
        />
      </div>
    </div>
  );
}
