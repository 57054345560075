import { graphql } from '../../../../gql';

export const GET_VERTRAEGE_BY_STANDORTID = graphql(`
  query getVertraege($idStandorte: [String!]!) {
    vertraegeByIdStandort(idStandorte: $idStandorte) {
      vertrag {
        id
        kundennummer
        unternehmensname
        aktiveAnzahl
        vereinbarteAnzahl
        vertragTarif
        vertragIntervall
        faelligkeitsdatum
        summeNetto {
          waehrung
          wert
        }
        vertragZahlungsmethode
      }
    }
  }
`);
