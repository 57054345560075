import { t } from 'i18next';
import { Arbeitszeit } from '../../../../../entities';
import { useArbeitszeitUpdate } from '../../../../../hooks';
import { mapPauseToInput } from '../../Arbeitszeit/Pausen/Forms/mapPauseToInputType';
import { handleOnCompleted } from '../../../handleOnCompleted';
import { ConfirmDeletePopover } from '../../../../Popover/ConfirmDeletePopover';
import { toast } from '@/hooks/use-toast';
import * as Sentry from '@sentry/react';

export function DeleteAction({
  arbeitszeit,
  dienstgangId,
}: {
  arbeitszeit: Arbeitszeit;
  dienstgangId: string;
}) {
  const [arbeitszeitUpdate] = useArbeitszeitUpdate();

  async function handleDelete() {
    try {
      const newDienstgaenge = arbeitszeit.pausen
        .filter((pause) => pause.id !== dienstgangId)
        .map((pause) => mapPauseToInput(pause));
      const result = await arbeitszeitUpdate({
        arbeitszeitUpdateInput: {
          pausen: newDienstgaenge,
        },
        arbeitszeitId: arbeitszeit.id,
      });

      handleOnCompleted(result.data, t);
    } catch (e) {
      toast({
        title: t(
          'domain.arbeitszeiten.sheet.header.arbeitszeitUpdateUnknownError',
        ),
      });
      Sentry.captureException(e);
    }
  }

  return (
    <ConfirmDeletePopover
      confirmText={t(
        'domain.arbeitszeiten.sheet.arbeitszeit.confirmDienstgangDelete',
      )}
      handleDelete={handleDelete}
      confirmButtonText={t(
        'domain.arbeitszeiten.sheet.arbeitszeit.confirmPauseDeleteButton',
      )}
      processingButtonText={t(
        'domain.arbeitszeiten.sheet.arbeitszeit.confirmPauseIsDeleting',
      )}
    />
  );
}
