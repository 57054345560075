import {
  faCheck,
  faCircleExclamation,
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';

export type DialogFooterMessageType = {
  type?: 'error' | 'success';
  message?: string;
};

export function DialogFooterMessage({
  isUpdating,
  dialogFooterMessage,
}: {
  isUpdating: boolean;
  dialogFooterMessage?: DialogFooterMessageType;
}) {
  const { t } = useTranslation();
  return (
    <div className="text-neutrals-N700 text-sm">
      {isUpdating && t('actions.wirdGespeichert')}
      {dialogFooterMessage && (
        <div className="flex items-center gap-1">
          {dialogFooterMessage.type === 'error' ? (
            <FontAwesomeIcon
              icon={faCircleExclamation}
              className="text-primary-red-R400"
            />
          ) : (
            <FontAwesomeIcon
              icon={faCheck}
              className="text-primary-green-G500"
            />
          )}
          {dialogFooterMessage.message}
        </div>
      )}
    </div>
  );
}
