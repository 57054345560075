/* eslint-disable @typescript-eslint/no-explicit-any */
// Der Code der ErrorBoundary ist an die Library react-error-boundary angelehnt
// Siehe auch https://github.com/bvaughn/react-error-boundary

import {
  Component,
  ComponentType,
  ErrorInfo,
  ReactNode,
  createElement,
} from 'react';
import { ErrorBoundaryContext } from './ErrorBoundaryContext';
import { BffError } from './types';
import * as Sentry from '@sentry/react';

type ErrorBoundaryState =
  | {
      error: any;
      didCatch: true;
    }
  | { error: any; didCatch: false };

type ErrorBoundaryProps = {
  FallbackComponent: ComponentType<{ error: any }>;
  children: ReactNode;
  onError?: (error: Error, info: ErrorInfo) => void;
};

const initalErrorState: ErrorBoundaryState = {
  error: null,
  didCatch: false,
};

export class ErrorBoundary extends Component<
  ErrorBoundaryProps,
  ErrorBoundaryState
> {
  constructor(props) {
    super(props);
    this.state = initalErrorState;
  }

  static getDerivedStateFromError(error: Error) {
    return { didCatch: true, error };
  }

  componentDidCatch(error: Error, info: ErrorInfo) {
    this.props.onError?.(error, info);
    Sentry.captureException(error);
  }

  render() {
    const { children, FallbackComponent } = this.props;
    const { didCatch, error } = this.state;

    let childToRender = children;

    if (didCatch) {
      if (error instanceof BffError) {
        const props = { error: error.displayMessage };
        childToRender = createElement(FallbackComponent, props);
      } else {
        const props = { error };
        childToRender = createElement(FallbackComponent, props);
      }
    }

    return createElement(
      ErrorBoundaryContext.Provider,
      { value: { didCatch, error } },
      childToRender,
    );
  }
}
