import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

export function PageWrapper({ children }: { children: ReactNode }) {
  const { t } = useTranslation();

  return (
    <div className="p-10">
      <h2
        data-testid="pages-profile-header"
        className="mb-4 text-2xl font-bold">
        {t('pages.profile.header')}
      </h2>
      <div>{children}</div>
    </div>
  );
}
