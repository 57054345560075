import { useTranslation } from 'react-i18next';
import { E2NTableCell } from '../../../../components/Table';
import { IconDefinition } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { memo } from 'react';

export const AbwesenheitsCell = memo(function AbwesenheitsCell({
  grund,
  icon,
}: {
  grund: string;
  icon: IconDefinition;
}) {
  const { t } = useTranslation();
  return (
    <E2NTableCell className="rounded-l-lg w-[30%]" isBorderVisible>
      <div className="flex items-center justify-center gap-4">
        <FontAwesomeIcon icon={icon} />
        <div className="flex gap-4 items-center font-semibold justify-center">
          {t(`entities.abwesenheiten.${grund}`)}
        </div>
      </div>
    </E2NTableCell>
  );
});
