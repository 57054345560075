import { Button } from '@/components/ui/button';
import { useTranslation } from 'react-i18next';
import { Label } from '@/components/ui/label';
import { Field } from '@/components/Skeleton/Field';

export function SkeletonForm() {
  const { t } = useTranslation();
  return (
    <div className="space-y-6">
      <div className="grid grid-cols-2 gap-6">
        <div className="space-y-1">
          <Label>
            {t('domain.controlling.umsatzplanung.ziele.dialog.umsatz.label')}
          </Label>
          <Field width="295px" height="42px" />
        </div>
        <div className="space-y-1">
          <Label>
            {t(
              'domain.controlling.umsatzplanung.ziele.dialog.produktivitaet.label',
            )}
          </Label>
          <Field width="295px" height="42px" />
        </div>
        <hr className="h-px bg-neutrals-N300 border-0 col-span-2"></hr>
        <div className="space-y-1">
          <Label>
            {t('domain.controlling.umsatzplanung.ziele.dialog.absatz.label')}
          </Label>
          <Field width="295px" height="42px" />
        </div>
        <div className="space-y-1">
          <Label>
            {t(
              'domain.controlling.umsatzplanung.ziele.dialog.produktivitaet.label',
            )}
          </Label>
          <Field width="295px" height="42px" />
        </div>
      </div>
      <hr className="h-px bg-neutrals-N300 border-0 -mx-8"></hr>
      <div className="flex items-center justify-end gap-4">
        <Button disabled>
          {t('domain.controlling.umsatzplanung.ziele.fertig')}
        </Button>
      </div>
    </div>
  );
}
