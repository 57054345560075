import { useTranslation } from 'react-i18next';
import { E2NtableHeader } from '../../../../../components/Table';
import { TableHead, TableRow } from '@/components/ui/table';
import { useTagebuchEinstellungen } from '@/domain/controlling/hooks/useTagebuchEinstellungen';
import { useStandort } from '@/hooks/useStandort';
import { AbsatzVorgabe } from '@/domain/controlling/entities';

export function Header() {
  const { t } = useTranslation();
  const { standortId } = useStandort();
  const absatzvorgabe =
    useTagebuchEinstellungen(standortId).tagebuchEinstellungen
      ?.absatzproduktivitaet?.absatzVorgabe;

  return (
    <E2NtableHeader className="[&_tr]:border-b-0">
      <TableRow>
        <TableHead>{t('domain.controlling.umsatzplanung.table.tag')}</TableHead>
        <TableHead>
          {t('domain.controlling.umsatzplanung.table.umsatz')}
        </TableHead>
        <TableHead>
          {t('domain.controlling.umsatzplanung.table.prognose')}
        </TableHead>
        <TableHead>
          {absatzvorgabe === AbsatzVorgabe.Stueck
            ? t('domain.controlling.umsatzplanung.table.absatz')
            : t('domain.controlling.umsatzplanung.table.absatzMpt')}
        </TableHead>
        <TableHead>
          {t('domain.controlling.umsatzplanung.table.durchschnitt')}
        </TableHead>
        <TableHead className="text-center"></TableHead>
      </TableRow>
    </E2NtableHeader>
  );
}
