import { useUmsatzplanungSollstunden } from '@/domain/controlling/hooks/useUmsatzplanungSollstunden';
import { useStandort } from '@/hooks';
import { useTranslation } from 'react-i18next';
import { PieChart } from './PieChart';
import { useTagebuchEinstellungen } from '@/domain/controlling/hooks/useTagebuchEinstellungen';
import { useParams } from 'react-router-dom';
import { MonthParams } from '../../types';
import { getHoursAndMinutesFromMinutes } from '@/utils';

export function SollstundenWidget() {
  const { year, month } = useParams<MonthParams>();
  const { standortId } = useStandort();
  const { sollstunden } = useUmsatzplanungSollstunden(standortId, {
    jahr: parseInt(year),
    monat: parseInt(month),
  });
  const { umsatzplanung } = useTagebuchEinstellungen(standortId);
  const { t } = useTranslation();

  return (
    <div className="bg-white py-4 px-5 rounded-lg flex justify-between items-center h-full">
      <div className="flex flex-col gap-1">
        <div className="text-neutrals-N700 font-semibold text-xs">
          {t('domain.controlling.umsatzplanung.sollstunden.label')}
        </div>
        <div className="flex gap-1 items-baseline">
          <div className="text-xl font-extrabold leading-6">
            {
              getHoursAndMinutesFromMinutes(
                sollstunden.aushilfen + sollstunden.festangestellte,
              ).hours
            }
          </div>
          <div className="text-neutrals-N700">{t('general.date.hours')}</div>
          <div className="text-xl font-extrabold leading-6">
            {
              getHoursAndMinutesFromMinutes(
                sollstunden.aushilfen + sollstunden.festangestellte,
              ).minutes
            }
          </div>
          <div className="text-neutrals-N700">{t('general.date.minutes')}</div>
        </div>
      </div>
      <PieChart sollstunden={sollstunden} umsatzplanung={umsatzplanung} />
    </div>
  );
}
