import { faEnvelope, faLock } from '@fortawesome/pro-light-svg-icons';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { useTranslation } from 'react-i18next';
import { Dispatch, SetStateAction, useMemo, useState } from 'react';
import { signIn } from 'aws-amplify/auth';
import { useNavigate } from 'react-router-dom';
import { managerV1BaseUrl } from '../../../utils';
import { useAuthErrorMessage } from '../../../hooks';
import { AuthenticationException, MFAMethod } from '../../../services';
import { z } from 'zod';
import { Alert, AlertDescription } from '@/components/ui/alert';
import { Link } from '@/components';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form';
import * as Sentry from '@sentry/react';

function ErrorMessage({
  serverError,
}: {
  serverError: Partial<{
    type: string | number;
    message: string;
  }>;
}) {
  const { t } = useTranslation();
  const message = useAuthErrorMessage(
    serverError.type as AuthenticationException,
  );

  return (
    <Alert data-testid="login-error-callout" variant="destructive">
      <AlertDescription>
        {message}{' '}
        <Link
          className="hover:text-primary-red-R600"
          href="https://hilfe.e2n.de"
          target="_blank">
          {t('pages.login.loginForm.linkTitle')}
        </Link>
        {'.'}
      </AlertDescription>
    </Alert>
  );
}

// eslint-disable-next-line max-lines-per-function
export function LoginForm({
  setMfaChallengeType,
}: {
  setMfaChallengeType: Dispatch<SetStateAction<MFAMethod>>;
}) {
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const { t } = useTranslation();

  const schema = useMemo(
    () =>
      z.object({
        username: z
          .string()
          .min(1, { message: t('pages.login.loginForm.emailIsRequired') })
          .transform((value) => value.trim()),
        password: z
          .string()
          .min(1, { message: t('pages.login.loginForm.passwordIsRequired') }),
      }),
    [t],
  );

  type LoginFormInput = z.infer<typeof schema>;

  const form = useForm<LoginFormInput>({
    resolver: zodResolver(schema),
    defaultValues: { username: '', password: '' },
  });
  const {
    handleSubmit,
    setError,
    formState: { errors },
  } = form;

  async function onSubmit(data: LoginFormInput) {
    setIsLoading(true);

    try {
      const signInOutput = await signIn({
        username: data.username,
        password: data.password,
      });
      if (signInOutput.nextStep.signInStep === 'DONE') {
        navigate({ pathname: '/' }, { replace: true });
      } else if (
        signInOutput.nextStep.signInStep === 'CONFIRM_SIGN_IN_WITH_TOTP_CODE'
      ) {
        setMfaChallengeType('TOTP');
      }
    } catch (error) {
      setError('root.serverError', {
        type: error.name,
        message: error.message,
      });
      Sentry.captureException(error);
    }

    setIsLoading(false);
  }

  /* async function handleSignInWithRedirect() {
    try {
      await signInWithRedirect({ provider: { custom: AZURE_IDP_NAME } });
    } catch (error) {
      setError('root.serverError', {
        type: error.name,
        message: error.message,
      });
      awsRum.recordError(error);
    }
  }
    */

  return (
    <Form {...form}>
      <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col gap-4">
        {!isLoading && errors.root && errors.root.serverError && (
          <ErrorMessage serverError={errors.root.serverError} />
        )}
        <FormField
          control={form.control}
          name="username"
          render={({ field }) => (
            <FormItem>
              <FormLabel>{t('pages.login.loginForm.eMailLabel')}</FormLabel>
              <FormControl>
                <Input
                  startIcon={faEnvelope}
                  placeholder={
                    t('pages.login.loginForm.eMailPlaceholder') as string
                  }
                  data-testid="login-username-input-field"
                  {...field}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="password"
          render={({ field }) => (
            <FormItem>
              <FormLabel>{t('pages.login.loginForm.passwordLabel')}</FormLabel>
              <FormControl>
                <Input
                  startIcon={faLock}
                  showPassword={true}
                  type="password"
                  autoComplete="password"
                  placeholder={t('pages.login.loginForm.passwordPlaceholder')}
                  data-testid="login-password-input-field"
                  {...field}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <div className="flex justify-end">
          <Link
            className="text-sm"
            data-testid="login-forgot-password-link"
            href={`${managerV1BaseUrl}/forgot.jsf`}>
            {t('pages.login.forgotPassword.linkTitle')}
          </Link>
        </div>
        <Button
          data-testid="login-submit-button"
          onSubmit={handleSubmit(onSubmit)}>
          {!isLoading
            ? t('pages.login.loginForm.buttonLabel')
            : t('pages.login.loginForm.isSubmitting')}
        </Button>
      </form>
    </Form>
  );
}
