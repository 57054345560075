import { faSpinner } from '@fortawesome/pro-regular-svg-icons';
import { faCircle } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export function ArbeitsbereichSelectLoading({ name }: { name: string }) {
  return (
    <div className="flex items-center justify-between gap-2 w-full h-full">
      <FontAwesomeIcon
        icon={faCircle}
        size="xs"
        className="text-neutrals-N700 text"
      />
      <span className="truncate grow">{name}</span>
      <FontAwesomeIcon fixedWidth size="sm" icon={faSpinner} spin />
    </div>
  );
}
