import { Button } from '@/components/ui/button';
import { Dialog, DialogContent, DialogTitle } from '@/components/ui/dialog';
import { useEditDialogContext } from './EditDialogContext';
import { MonthParams } from '../../types';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export function UnsavedChangesDialog({
  open,
  onOpenChange,
  onEditDialogOpenChange,
}: {
  open: boolean;
  onOpenChange: (open: boolean) => void;
  onEditDialogOpenChange: (open: boolean) => void;
}) {
  const { setJahrMonat } = useEditDialogContext();
  const { year, month } = useParams<MonthParams>();
  const { t } = useTranslation();

  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent className="sm:max-w-[444px] p-8 flex flex-col gap-6">
        <DialogTitle>
          {t(
            'domain.controlling.umsatzplanung.ziele.alertUnsavedChanges.header',
          )}
        </DialogTitle>
        <div className="text-sm text-neutrals-N900">
          {t(
            'domain.controlling.umsatzplanung.ziele.alertUnsavedChanges.subHeader',
          )}
        </div>
        <div className="flex items-center justify-end gap-4">
          <Button
            onClick={() => {
              onOpenChange(false);
              onEditDialogOpenChange(false);
              setJahrMonat({ jahr: parseInt(year), monat: parseInt(month) });
            }}
            variant="outline">
            {t('actions.verlassen')}
          </Button>
          <Button onClick={() => onOpenChange(false)}>
            {t('actions.weiterBearbeiten')}
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
}
