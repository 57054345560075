import { Money } from './money';

export class Umsatz {
  constructor(
    readonly standortId: string,
    readonly produktivitaet: Money,
    readonly wert: Money,
    readonly stunden: number,
  ) {}
}

export class UmsatzPlan {
  constructor(
    readonly datum: string,
    readonly umsatz: Umsatz,
    readonly prognose: Money,
  ) {}
}
