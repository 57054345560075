import { Badge } from '@/components/ui/badge';
import { CSSProperties } from 'react';

type StyleProps = CSSProperties & {
  '--dot-color': CSSProperties['color'];
};

export function BackgroundColor({
  color,
  value,
}: {
  color: string;
  value: string;
}) {
  return (
    <>
      <Badge
        className={`flex items-center justify-center w-24 h-8 text-xs font-semibold rounded-md bg-[var(--dot-color)] hover:bg-[var(--dot-color)] text-white`}
        style={
          {
            '--dot-color': color,
          } as StyleProps
        }>
        {value}
      </Badge>
    </>
  );
}
