/* eslint-disable react-refresh/only-export-components */
/* eslint-disable max-lines */
/* eslint-disable max-lines-per-function */
import {
  createContext,
  Dispatch,
  SetStateAction,
  useContext,
  useState,
} from 'react';
import { useParams } from 'react-router-dom';
import { JahrMonat } from '@/domain/controlling/entities';
import { MonthParams } from '../../types';
import { useForm, UseFormReturn } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import {
  UmsatzplanungZieleFormValues,
  umsatzplanungZieleSchema,
} from './schema';
import { useUmsatzzielCreateOrUpdate } from '@/domain/controlling/hooks/useUmsatzzielCreateOrUpdate';
import { useStandort } from '@/hooks';
import { maskitoParseNumber } from '@maskito/kit';
import { t } from 'i18next';
import { toast } from '@/hooks/use-toast';
import { getMessage } from '../../getMessage';
import { useTagebuchEinstellungen } from '@/domain/controlling/hooks/useTagebuchEinstellungen';
import { useAbsatzzielCreateOrUpdate } from '@/domain/controlling/hooks/useAbsatzzielCreateOrUpdate';
import * as Sentry from '@sentry/react';
import { useUmsatzplanung } from '@/domain/controlling/hooks/useUmsatzplanung';
import { formatNumber } from '../../formatNumber';
import { Absatz, Currency, Umsatz } from '@/gql/graphql';
import { getAbsatzProduktivitaetByMptOrStueck } from '../../utils';

type DialogFooterMessage = {
  type?: 'error' | 'success';
  message?: string;
};

type EditDialogContextProps = {
  jahrMonat: JahrMonat;
  setJahrMonat: Dispatch<SetStateAction<JahrMonat>>;
  isUpdating: boolean;
  setIsUpdating: Dispatch<SetStateAction<boolean>>;
  isSubmitted: boolean;
  setIsSubmitted: Dispatch<SetStateAction<boolean>>;
  hasUnsavedChanges: boolean;
  setHasUnsavedChanges: Dispatch<SetStateAction<boolean>>;
  dialogFooterMessage: DialogFooterMessage | undefined;
  setDialogFooterMessage: Dispatch<
    SetStateAction<DialogFooterMessage | undefined>
  >;
  form: UseFormReturn<UmsatzplanungZieleFormValues, unknown, undefined>;
  onSubmit(values: UmsatzplanungZieleFormValues, closeDialog: boolean): void;
  umsatzplanungEinstellung: 'UMSATZ' | 'ABSATZ' | 'UMSATZ_ABSATZ';
};

export const EditDialogContext = createContext<
  EditDialogContextProps | undefined
>(undefined);

function EditDialogContextProvider({
  children,
  onOpenChange,
}: {
  children: React.ReactNode;
  onOpenChange: (open: boolean) => void;
}) {
  const { year, month } = useParams<MonthParams>();
  const [jahrMonat, setJahrMonat] = useState<JahrMonat>({
    jahr: parseInt(year),
    monat: parseInt(month),
  });
  const { standortId } = useStandort();
  const { absatzZiel, umsatzZiel } = useUmsatzplanung(
    standortId,
    jahrMonat.jahr,
    jahrMonat.monat,
  );
  const { tagebuchEinstellungen } = useTagebuchEinstellungen(standortId);
  const [createOrUpdateUmsatzziel] = useUmsatzzielCreateOrUpdate();
  const [createOrUpdateAbsatzziel] = useAbsatzzielCreateOrUpdate();
  const [isUpdating, setIsUpdating] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const [dialogFooterMessage, setDialogFooterMessage] =
    useState<DialogFooterMessage>(undefined);
  const { umsatzplanung } = useTagebuchEinstellungen(standortId);

  const form = useForm<UmsatzplanungZieleFormValues>({
    resolver: zodResolver(umsatzplanungZieleSchema),
    defaultValues: {
      absatz: absatzZiel.wert
        ? formatNumber(absatzZiel.wert.toString(), true)
        : '',
      absatzProduktivitaet: getAbsatzProduktivitaetByMptOrStueck(
        absatzZiel.produktivitaet,
        tagebuchEinstellungen.absatzproduktivitaet.absatzVorgabe,
      ),
      umsatz: umsatzZiel.wert
        ? formatNumber(umsatzZiel.wert.amount.toString(), false)
        : '',
      umsatzProduktivitaet: umsatzZiel.produktivitaet
        ? formatNumber(umsatzZiel.produktivitaet.amount.toString(), false)
        : '',
    },
  });

  function handleOnStart() {
    setIsUpdating(true);
    setDialogFooterMessage(undefined);
  }

  function handleOnError(translationKey: string) {
    setIsUpdating(false);
    setIsSubmitted(true);
    setDialogFooterMessage({
      type: 'error',
      message: t(translationKey),
    });
  }

  function handleOnSuccess(message: string, closeDialog: boolean) {
    setIsUpdating(false);
    setIsSubmitted(true);
    if (closeDialog) {
      onOpenChange(false);
      toast({
        title: t(message),
      });
    } else {
      setDialogFooterMessage({ type: 'success', message: t(message) });
    }
  }

  function handleOnFinish() {
    setIsSubmitted(false);
    setHasUnsavedChanges(false);
  }

  function resetUmsatzFormFields(umsatzValues: Umsatz) {
    form.reset({
      umsatz: umsatzValues.wert
        ? formatNumber(umsatzValues.wert.amount.toString(), false)
        : '',
      umsatzProduktivitaet: umsatzValues.produktivitaet
        ? formatNumber(umsatzValues.produktivitaet.amount.toString(), false)
        : '',
      absatz: form.getValues('absatz'),
      absatzProduktivitaet: form.getValues('absatzProduktivitaet'),
    });
  }

  function resetAbsatzFormFields(absatzValues: Absatz) {
    form.reset({
      absatz: absatzValues.wert
        ? formatNumber(absatzValues.wert.toString(), true)
        : '',
      absatzProduktivitaet: getAbsatzProduktivitaetByMptOrStueck(
        absatzValues.produktivitaet,
        tagebuchEinstellungen.absatzproduktivitaet.absatzVorgabe,
      ),
      umsatz: form.getValues('umsatz'),
      umsatzProduktivitaet: form.getValues('umsatzProduktivitaet'),
    });
  }

  // eslint-disable-next-line complexity
  function onSubmit(
    values: UmsatzplanungZieleFormValues,
    closeDialog: boolean,
  ) {
    handleOnStart();

    // Je nach dem ob Umsatz oder Absatz oder beides angezeigt wird, werden unterschiedliche Mutations aufgerufen
    if (umsatzplanung === 'UMSATZ') {
      createOrUpdateUmsatzziel({
        createOrUpdateUmsatzzielInput: {
          standortId,
          jahr: jahrMonat.jahr,
          monat: jahrMonat.monat,
          umsatzziel: values.umsatz
            ? {
                amount: maskitoParseNumber(values.umsatz, ','),
                currency: Currency.Eur,
              }
            : null,
          umsatzzielProduktivitaet: values.umsatzProduktivitaet
            ? {
                amount: maskitoParseNumber(values.umsatzProduktivitaet, ','),
                currency: Currency.Eur,
              }
            : null,
        },
      })
        .then((result) => {
          if (result.data.createOrUpdateZiel.__typename === 'Umsatz') {
            resetUmsatzFormFields(result.data.createOrUpdateZiel);
          }
          const message = getMessage(result.data, t);
          handleOnSuccess(message, closeDialog);
        })
        .catch((e) => {
          Sentry.captureException(e);
          handleOnError(
            'domain.controlling.umsatzplanung.ziele.errors.unknownError',
          );
        });
    } else if (umsatzplanung === 'ABSATZ') {
      createOrUpdateAbsatzziel({
        createOrUpdateAbsatzzielInput: {
          standortId,
          jahr: jahrMonat.jahr,
          monat: jahrMonat.monat,
          absatzziel: maskitoParseNumber(values.absatz ?? '', ','),
          absatzzielProduktivitaet: maskitoParseNumber(
            values.absatzProduktivitaet ?? '',
            ',',
          ),
        },
      })
        .then((result) => {
          if (result.data.createOrUpdateZiel.__typename === 'Absatz') {
            resetAbsatzFormFields(result.data.createOrUpdateZiel);
          }
          const message = getMessage(result.data, t);
          handleOnSuccess(message, closeDialog);
        })
        .catch((e) => {
          Sentry.captureException(e);
          handleOnError(
            'domain.controlling.umsatzplanung.ziele.errors.unknownError',
          );
        });
    } else if (umsatzplanung === 'UMSATZ_ABSATZ') {
      handleOnStart();
      Promise.all([
        createOrUpdateUmsatzziel({
          createOrUpdateUmsatzzielInput: {
            standortId,
            jahr: jahrMonat.jahr,
            monat: jahrMonat.monat,
            umsatzziel: values.umsatz
              ? {
                  amount: maskitoParseNumber(values.umsatz, ','),
                  currency: Currency.Eur,
                }
              : null,
            umsatzzielProduktivitaet: values.umsatzProduktivitaet
              ? {
                  amount: maskitoParseNumber(values.umsatzProduktivitaet, ','),
                  currency: Currency.Eur,
                }
              : null,
          },
        }),
        createOrUpdateAbsatzziel({
          createOrUpdateAbsatzzielInput: {
            standortId,
            jahr: jahrMonat.jahr,
            monat: jahrMonat.monat,
            absatzziel: maskitoParseNumber(values.absatz ?? '', ','),
            absatzzielProduktivitaet: maskitoParseNumber(
              values.absatzProduktivitaet ?? '',
              ',',
            ),
          },
        }),
      ])
        .then((values) => {
          const umsatzValues = values[0].data.createOrUpdateZiel;
          const absatzValues = values[1].data.createOrUpdateZiel;

          if (absatzValues.__typename === 'Absatz') {
            resetAbsatzFormFields(absatzValues);
          }
          if (umsatzValues.__typename === 'Umsatz') {
            resetUmsatzFormFields(umsatzValues);
          }

          const message = getMessage(values[0].data, t);
          handleOnSuccess(message, closeDialog);
        })
        .catch((e) => {
          Sentry.captureException(e);
          handleOnError(
            'domain.controlling.umsatzplanung.ziele.errors.unknownError',
          );
        });
    }

    handleOnFinish();
  }

  const value: EditDialogContextProps = {
    jahrMonat,
    setJahrMonat,
    dialogFooterMessage,
    setDialogFooterMessage,
    isUpdating,
    setIsUpdating,
    isSubmitted,
    setIsSubmitted,
    hasUnsavedChanges,
    setHasUnsavedChanges,
    form,
    onSubmit,
    umsatzplanungEinstellung: umsatzplanung,
  };

  return (
    <EditDialogContext.Provider value={value}>
      {children}
    </EditDialogContext.Provider>
  );
}

function useEditDialogContext() {
  const editDialogContext = useContext(EditDialogContext);

  if (editDialogContext === undefined) {
    throw new Error(
      'useEditDialogContext must be used within EditDialogContextProvider',
    );
  }
  return editDialogContext;
}

export { useEditDialogContext, EditDialogContextProvider };
