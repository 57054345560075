import { faCircleExclamation } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export function ZieleError() {
  return (
    <div className="flex flex-row gap-2">
      <FontAwesomeIcon
        icon={faCircleExclamation}
        className="text-error-main py-1"
      />{' '}
      <div className="text-red-500">
        Die Ziele konnten nicht geladen werden. Bitte probiere es später erneut.
      </div>
    </div>
  );
}
