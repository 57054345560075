import { AbsoluteNumberInput } from '@/components';
import { FormControl, FormField, FormItem } from '@/components/ui/form';
import { TableCell, TableRow } from '@/components/ui/table';
import { AbsatzPlan, AbsatzVorgabe } from '@/domain/controlling/entities';
import { useTranslation } from 'react-i18next';
import {
  AbsoluteNumberField,
  ZielstundenField,
} from '../../DataTable/Cells/GenericFields';
import { Control } from 'react-hook-form';

// eslint-disable-next-line max-lines-per-function
export function AbsatzPlanWerteTableRow({
  absatzPlanwert,
  control,
  absatzVorgabe,
}: {
  control: Control<{
    absatzPlan?: string;
    absatzProduktivitaet?: string;
    umsatzPlan?: string;
    umsatzProduktivitaet?: string;
  }>;
  absatzPlanwert: AbsatzPlan;
  absatzVorgabe: AbsatzVorgabe;
}) {
  const { t } = useTranslation();

  return (
    <TableRow className="hover:bg-transparent">
      <TableCell className="font-medium py-4 ps-0 w-1/4">
        <FormField
          control={control}
          name="absatzPlan"
          render={({ field }) => (
            <FormItem>
              <FormControl>
                <AbsoluteNumberInput
                  value={field.value}
                  onBlur={(e) => {
                    field.onChange(e.target.value);
                  }}
                  onInput={(e) => {
                    field.onChange(e.currentTarget.value);
                  }}
                  unit="Stk."
                />
              </FormControl>
            </FormItem>
          )}
        />
      </TableCell>
      <TableCell className="font-medium p-4 w-1/4">
        <AbsoluteNumberField
          value={absatzPlanwert ? absatzPlanwert.prognose : undefined}
          unit={t('domain.controlling.umsatzplanung.units.stueck')}
        />
      </TableCell>
      <TableCell className="font-medium p-4 w-1/4">
        <FormField
          control={control}
          name="absatzProduktivitaet"
          render={({ field }) => (
            <FormItem>
              <FormControl>
                <AbsoluteNumberInput
                  value={field.value}
                  onBlur={(e) => {
                    field.onChange(e.target.value);
                  }}
                  onInput={(e) => {
                    field.onChange(e.currentTarget.value);
                  }}
                  unit={
                    absatzVorgabe === AbsatzVorgabe.Stueck
                      ? t('domain.controlling.umsatzplanung.ziele.Stk./Std.')
                      : t('domain.controlling.umsatzplanung.ziele.mpt')
                  }
                />
              </FormControl>
            </FormItem>
          )}
        />
      </TableCell>
      <TableCell className="font-medium p-4 w-1/4">
        <ZielstundenField
          value={
            absatzPlanwert && absatzPlanwert.absatz
              ? absatzPlanwert.absatz.stunden
              : undefined
          }
        />
      </TableCell>
    </TableRow>
  );
}
