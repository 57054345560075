/* eslint-disable max-lines-per-function */
import { buildLink, checkTimeChange } from '../../../../utils';
import { useArbeitszeiten } from '../../hooks/useArbeitszeiten';
import {
  Link,
  Outlet,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom';
import * as z from 'zod';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ErrorPage } from '../../../../app/Routes/AuthenticatedRoutes/ErrorPage';
import DatePicker from '../../../../components/DatePicker/DatePicker';
import { DataTable } from '../../DataTable';
import { useColumns } from '../../columns';
import { Termine } from './Termine';
import { useProfile, useStandort } from '../../../../hooks';
import { DebouncedTextField } from '../../../../components/DebouncedTextField/DebouncedTextField';
import { Abteilung, Arbeitsbereich } from '../../entities';
import {
  AbteilungsFilter,
  ArbeitsbereichFilter,
} from '../../components/Comboboxen';
import { useManagerCanEditArbeitszeiten } from '../../hooks/useManagerCanEditArbeitszeit.ts';
import { faPlus } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useStandortContext } from '../../../../store/standortContext.tsx';
import { Button } from '@/components/ui/button.tsx';
import { Badge } from '@/components/ui/badge.tsx';
import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from '@/components/ui/tabs.tsx';
import { toast } from '@/hooks/use-toast';
import { ToggleFeedback } from '@/components/Feadbacktoggle/index.ts';
import { DienstplanuebernahmeTable } from '../../components/index.ts';

const dateSchema = z.object({
  year: z.string().length(4).regex(/^\d+$/),
  month: z.string().length(2).regex(/^\d+$/),
  day: z.string().length(2).regex(/^\d+$/),
});

export function Uebersicht() {
  const { year, month, day } = useParams();
  const { manager } = useProfile();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const currentStandort = useStandort();
  const [name, setName] = useState('');
  const [activeTab, setActiveTab] = useState('vollständig');
  const canEditArbeitszeit = useManagerCanEditArbeitszeiten();
  const [searchParams] = useSearchParams();
  const [arbeitsbereichFilter, setArbeitsbereiche] = useState<Arbeitsbereich[]>(
    [],
  );
  const [abteilungFilter, setAbteilungen] = useState<Abteilung[]>([]);

  const redirectToDate = useCallback(
    (date: Date) => {
      const currentMonth = date.getMonth() + 1;
      const month = currentMonth.toString().padStart(2, '0');
      const year = date.getFullYear();
      const day = date.getDate().toString().padStart(2, '0');

      navigate(`/arbeitszeiten/uebersicht/${year}/${month}/${day}`);
    },
    [navigate],
  );
  const columns = useColumns();
  useEffect(() => {
    if (searchParams.get('tageswechsel')) {
      toast({
        title: t('domain.arbeitszeiten.create.toast.tageswechsel.title'),
        description: t(
          'domain.arbeitszeiten.create.toast.tageswechsel.description',
          {
            date: `${day}.${month}.${year}`,
          },
        ),
      });
    }
  }, [searchParams, day, month, year, t]);
  useEffect(() => {
    try {
      dateSchema.parse({
        year: year,
        month: month,
        day: day,
      });
    } catch {
      const currentDate = new Date();
      redirectToDate(currentDate);
    }
  }, [year, month, day, navigate, redirectToDate]);
  const umstellung = checkTimeChange(new Date(`${year}-${month}-${day}`));

  const standort = useStandortContext((state) => state.currentStandort);
  const date = new Date(`${year}-${month}-${day}`);
  const arbeitszeiten = useArbeitszeiten(date, standort.id);

  if (arbeitszeiten.error) {
    return (
      <ErrorPage error={t('domain.arbeitszeiten.uebersicht.error')}></ErrorPage>
    );
  }

  return (
    <div className="pt-8 bg-white mb-16">
      <ToggleFeedback />
      <div className="w-full flex justify-between px-8 pb-8">
        <div className="flex items-center gap-8">
          <DatePicker
            date={date}
            onSelect={(d) => {
              redirectToDate(d);
            }}
            onBackward={(d) => {
              redirectToDate(d);
            }}
            onForward={(d) => {
              redirectToDate(d);
            }}
          />
          {/* Platzhalter für Feiertags Komponente */}
          {/* <div className="text-sm font-semibold">Feiertag</div> */}
          {manager.rechte.termine && <Termine />}
          {umstellung && (
            <Badge className="cursor-default">
              {t(`domain.arbeitszeiten.uebersicht.zeitUmstellung`, {
                zeit: umstellung,
              })}
            </Badge>
          )}
        </div>
        <div className="flex justify-end gap-5">
          <DebouncedTextField
            placeholder="Namen suchen"
            value={name}
            onChange={(event) => {
              if (typeof event === 'string') {
                setName(event);
                return;
              }
              setName(event.toString());
            }}
            className="max-w-sm"
          />

          {currentStandort.abteilungenEnabled ? (
            <AbteilungsFilter
              onSelect={(abteilung) => {
                setAbteilungen(abteilung);
              }}
            />
          ) : (
            <ArbeitsbereichFilter
              onSelect={(arbeitsbereich) => {
                setArbeitsbereiche(arbeitsbereich);
              }}
            />
          )}
          {canEditArbeitszeit && (
            <Link
              to={`/arbeitszeiten/uebersicht/${year}/${month}/${day}/sheet/create`}>
              <Button variant="default" size="icon">
                <FontAwesomeIcon icon={faPlus} />
              </Button>
            </Link>
          )}
          <a
            href={buildLink('arbeitszeiten')}
            className="bg-[#545e83] py-3 px-2 rounded-lg text-white w-auto text-sm h-10 inline-flex items-center justify-center">
            Classic Design
          </a>
        </div>
      </div>
      <Outlet />
      <Tabs
        defaultValue="vollständig"
        onValueChange={(value) => setActiveTab(value)}
        value={activeTab}
        className=" flex flex-col">
        <div className="flex flex-row justify-between px-8">
          <TabsList className="flex justify-start gap-4 w-fit">
            <TabsTrigger key={'vollständig'} value="vollständig">
              {t('domain.arbeitszeiten.uebersicht.tabellenTabs.vollstaendig')}
            </TabsTrigger>
            <TabsTrigger
              className="flex items-center gap-2"
              key={'todo'}
              value="todo">
              {t('domain.arbeitszeiten.uebersicht.tabellenTabs.todo')}
              <Badge className="rounded-full px-2 font-bold">
                {arbeitszeiten.offeneArbeitszeiten.length}
              </Badge>
            </TabsTrigger>
            <TabsTrigger
              className="flex items-center gap-2"
              key={'dienstplanuebernahme'}
              value="dienstplanuebernahme">
              {t(
                'domain.arbeitszeiten.uebersicht.tabellenTabs.dienstplanuebernahmen',
              )}
              <Badge className="rounded-full px-2 font-bold">
                {arbeitszeiten.dienstplanuebernahmen.length}
              </Badge>
            </TabsTrigger>
          </TabsList>
        </div>

        <TabsContent
          key={'vollständig'}
          value="vollständig"
          className="bg-neutrals-N200 px-8">
          <DataTable
            columns={columns.abgeschlosseneArbeitszeiten}
            data={arbeitszeiten.abgeschlosseneArbeitszeiten}
            nameFilter={name}
            arbeitsbereichFilter={arbeitsbereichFilter}
            abteilungFilter={abteilungFilter}
          />
        </TabsContent>
        <TabsContent
          key={'todo'}
          value="todo"
          className="bg-neutrals-N200 px-8">
          <DataTable
            columns={columns.offeneArbeitszeiten}
            data={arbeitszeiten.offeneArbeitszeiten}
            nameFilter={name}
            arbeitsbereichFilter={arbeitsbereichFilter}
            abteilungFilter={abteilungFilter}
          />
        </TabsContent>
        <TabsContent
          key={'dienstplanuebernahme'}
          value="dienstplanuebernahme"
          className="bg-neutrals-N200 px-8">
          <DienstplanuebernahmeTable
            data={arbeitszeiten.dienstplanuebernahmen}
            nameFilter={name}
            arbeitsbereichFilter={arbeitsbereichFilter}
            abteilungFilter={abteilungFilter}
          />
        </TabsContent>
      </Tabs>
    </div>
  );
}
