import {
  AbsatzPlan,
  AbsatzVorgabe,
  Currency,
  UmsatzPlan,
} from '@/domain/controlling/entities';
import { TableRow } from './types';
import dayjs from 'dayjs';
import { getAllDatesFromCurrentMonth } from '@/utils';

export function mapPlanwerteToTableRow({
  umsatzPlanwerte,
  absatzPlanwerte,
  jahr,
  monat,
  absatzVorgabe,
}: {
  umsatzPlanwerte: UmsatzPlan[];
  absatzPlanwerte: AbsatzPlan[];
  jahr: number;
  monat: number;
  absatzVorgabe: AbsatzVorgabe;
}): TableRow[] {
  const planwerte = [];
  const startDate = dayjs(`${jahr}-${monat}-01`).format('YYYY-MM-DD');
  const endDate = dayjs(startDate).endOf('month').format('YYYY-MM-DD');
  const allDaysOfCurrentMonth = getAllDatesFromCurrentMonth(startDate, endDate);

  for (let i = 0; i < allDaysOfCurrentMonth.length; i++) {
    const umsatzByCurrentDay = umsatzPlanwerte.find(
      (umsatz) => umsatz.datum === allDaysOfCurrentMonth[i].date,
    );
    const absatzByCurrentDay = absatzPlanwerte.find(
      (absatz) => absatz.datum === allDaysOfCurrentMonth[i].date,
    );

    const umsatz: Partial<TableRow> = mapUmsatz(umsatzByCurrentDay);
    const absatz: Partial<TableRow> = mapAbsatz(
      absatzByCurrentDay,
      absatzVorgabe,
    );

    const newPlanwert: TableRow = {
      date: {
        date: allDaysOfCurrentMonth[i].date,
        weekday: allDaysOfCurrentMonth[i].weekday,
      },
      ...umsatz,
      ...absatz,
    };
    planwerte.push(newPlanwert);
  }

  return planwerte;
}

function mapUmsatz(umsatzByCurrentDay: UmsatzPlan): Partial<TableRow> {
  return umsatzByCurrentDay
    ? {
        umsatzWert: umsatzByCurrentDay.umsatz.wert
          ? {
              amount: umsatzByCurrentDay.umsatz.wert.amount,
              currency: Currency[umsatzByCurrentDay.umsatz.wert.currency],
            }
          : undefined,
        umsatzZielproduktivitaet: umsatzByCurrentDay.umsatz.produktivitaet
          ? {
              amount: umsatzByCurrentDay.umsatz.produktivitaet.amount,
              currency:
                Currency[umsatzByCurrentDay.umsatz.produktivitaet.currency],
            }
          : undefined,
        umsatzZielstunden: umsatzByCurrentDay.umsatz.stunden,
        umsatzPrognose: umsatzByCurrentDay.prognose
          ? {
              amount: umsatzByCurrentDay.prognose.amount,
              currency: Currency[umsatzByCurrentDay.prognose.currency],
            }
          : undefined,
      }
    : {};
}

function mapAbsatz(
  absatzByCurrentDay: AbsatzPlan,
  absatzVorgabe: AbsatzVorgabe,
): Partial<TableRow> {
  return absatzByCurrentDay
    ? {
        absatzWert: absatzByCurrentDay.absatz.wert,
        absatzZielproduktivitaet:
          absatzByCurrentDay.absatz.produktivitaet.mpt &&
          absatzByCurrentDay.absatz.produktivitaet.stueck
            ? absatzVorgabe === AbsatzVorgabe.Stueck
              ? absatzByCurrentDay.absatz.produktivitaet.stueck
              : absatzByCurrentDay.absatz.produktivitaet.mpt
            : undefined,
        absatzZielstunden: absatzByCurrentDay.absatz.stunden,
        absatzPrognose: absatzByCurrentDay.prognose ?? undefined,
      }
    : {};
}
