import {
  Table,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
} from '@/components/ui/table';
import { E2NtableHeader } from '../../../../../../components/Table';
import { Zuschlag } from '../../../../entities';
import { useTranslation } from 'react-i18next';
import { truncatNumberToTwoDigits } from '../../../../util';

// eslint-disable-next-line max-lines-per-function
export function ZuschlagsTabelle({ zuschlaege }: { zuschlaege?: Zuschlag[] }) {
  const showBetrag = zuschlaege?.some((zuschlag) => zuschlag.betrag !== null);
  const { t } = useTranslation();

  return (
    <Table>
      <E2NtableHeader>
        <TableRow className="hover:bg-transparent">
          <TableHead className="w-[200px] px-4">
            {t('domain.arbeitszeiten.sheet.zuschlaege.art')}
          </TableHead>
          <TableHead className="w-[150px]">
            {t('domain.arbeitszeiten.sheet.zuschlaege.dauer')}
          </TableHead>
          {showBetrag && (
            <TableHead>
              {t('domain.arbeitszeiten.sheet.zuschlaege.betrag')}
            </TableHead>
          )}
        </TableRow>
      </E2NtableHeader>

      <TableBody>
        {zuschlaege?.map((zuschlag, id) => {
          return (
            <TableRow key={id} className="hover:bg-transparent">
              <TableCell className="font-medium p-4">
                <div className="flex gap-1 items-baseline">
                  <span className="font-semibold">{zuschlag.name}</span>
                </div>
              </TableCell>
              <TableCell>
                <div className="flex gap-1 items-baseline">
                  <span className="font-semibold">{zuschlag.dauer}</span>
                  <span className="text-neutrals-N700 text-xs">
                    {t('general.date.minutes')}
                  </span>
                </div>
              </TableCell>
              {showBetrag && (
                <TableCell>
                  <div className="flex gap-1 items-baseline">
                    <span className="font-semibold">
                      {truncatNumberToTwoDigits(zuschlag.betrag)}
                    </span>
                    <span className="text-neutrals-N700 text-xs">
                      {t('general.currency.EUR')}
                    </span>
                  </div>
                </TableCell>
              )}
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
  );
}
