import { Pausenart } from '../../../../../../../gql/graphql';
import {
  faBookSection,
  faCalendar,
  faHand,
  faRobot,
  faSmoking,
  faStamp,
} from '@fortawesome/pro-regular-svg-icons';

export function getPausenartIndicator(pausenart: Pausenart) {
  if (pausenart === Pausenart.Automatisch) {
    return faRobot;
  }
  if (pausenart === Pausenart.Gesetzlich) {
    return faBookSection;
  }
  if (pausenart === Pausenart.Gestempelt) {
    return faStamp;
  }
  if (pausenart === Pausenart.Manuell) {
    return faHand;
  }
  if (pausenart === Pausenart.Rauchen) {
    return faSmoking;
  }
  // Pausenart Schicht
  return faCalendar;
}
