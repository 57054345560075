import { useSuspenseQuery } from '@apollo/client';
import { GET_TERMINE_BY_DATE_AND_STANDORT_ID } from './graphql/queries';
import { TermineByStandortId, TermineError } from '../../../gql/graphql';
import { handleGraphQLErrors } from '../../../utils/errors/handleGraphQLErrors';
import { Termin, TerminWiederholung } from '../entities/Termin';
import { getDateOrNull } from '../../../utils/helperFunctions';
import * as Sentry from '@sentry/react';

export function useTermine(
  standortId: string,
  datum: Date,
): {
  termine?: Termin[];
  error?: string;
} {
  const parsedDate = datum.toISOString().slice(0, 10);
  const { data } = useSuspenseQuery(GET_TERMINE_BY_DATE_AND_STANDORT_ID, {
    variables: { standortId, datum: parsedDate },
    fetchPolicy: 'cache-first',
  });

  const error = handleGraphQLErrors<TermineByStandortId, TermineError>(
    data.termine,
  );
  if (error) {
    Sentry.captureException(error);
    return { error: error.message };
  }

  const currentTermine = data.termine as TermineByStandortId;

  const termine = [...currentTermine.termine];

  return {
    termine: termine.map((termin) => {
      return new Termin(
        termin.titel,
        TerminWiederholung[termin.wiederholung],
        termin.id,
        termin.bildUrl,
        termin.bildName,
        termin.beschreibung,
        getDateOrNull(termin.beginn),
        getDateOrNull(termin.ende),
        termin.markierung,
      );
    }),
  };
}
