import {
  Sheet,
  SheetClose,
  SheetContent,
  SheetHeader,
  SheetTitle,
} from '@/components/ui/sheet';
import { faArrowLeft, faSpinnerThird } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { t } from 'i18next';
import { Suspense } from 'react';
import { Outlet, useNavigate, useParams } from 'react-router-dom';

export function SheetWrapper() {
  const { year, month, day } = useParams();

  const navigate = useNavigate();

  function handleClose() {
    navigate(`/arbeitszeiten/uebersicht/${year}/${month}/${day}`, {
      replace: true,
    });
  }

  return (
    <Sheet open onOpenChange={handleClose}>
      <SheetContent
        className="p-0 sm:max-w-[720px] overflow-y-auto"
        removeCloseIcon>
        <Suspense
          fallback={
            <>
              <SheetHeader className="bg-neutrals-N200">
                <SheetTitle className="flex items-center justify-between p-4">
                  <SheetClose className="flex items-center gap-2">
                    <FontAwesomeIcon
                      className="text-blue-500"
                      icon={faArrowLeft}
                      fixedWidth
                    />
                    <span className="text-sm text-neutrals-N700">
                      Neue Arbeitszeit erstellen
                    </span>
                  </SheetClose>
                </SheetTitle>
              </SheetHeader>
              <div className="flex flex-col h-screen gap-4 items-center justify-center text-sm">
                <FontAwesomeIcon
                  icon={faSpinnerThird}
                  className="text-primary-blue-B200"
                  spin
                  size="4x"
                />
                {t('actions.loading')}
              </div>
            </>
          }>
          <Outlet />
        </Suspense>
      </SheetContent>
    </Sheet>
  );
}
