import { Form } from '@/components/ui/form';
import { useEffect } from 'react';
import { useEditDialogContext } from '../EditDialogContext';
import { AbsatzInputFields } from './AbsatzInputFields';
import { UmsatzInputFields } from './UmsatzInputFields';

// eslint-disable-next-line max-lines-per-function
export function EditForm() {
  const {
    form,
    onSubmit,
    setDialogFooterMessage,
    isSubmitted,
    umsatzplanungEinstellung,
    setHasUnsavedChanges,
  } = useEditDialogContext();

  useEffect(() => {
    const subscription = form.watch((watchedValues) => {
      if (
        watchedValues.absatz !== form.formState.defaultValues.absatz ||
        watchedValues.absatzProduktivitaet !==
          form.formState.defaultValues.absatzProduktivitaet ||
        watchedValues.umsatz !== form.formState.defaultValues.umsatz ||
        watchedValues.umsatzProduktivitaet !==
          form.formState.defaultValues.umsatzProduktivitaet
      ) {
        setHasUnsavedChanges(true);
        setDialogFooterMessage(undefined);
      }
    });
    return () => subscription.unsubscribe();
  }, [form, setDialogFooterMessage, setHasUnsavedChanges]);

  useEffect(() => {
    setHasUnsavedChanges(false);
  }, [form, isSubmitted, setHasUnsavedChanges]);

  return (
    <Form {...form}>
      <form
        id="editUmsatzzieleForm"
        onSubmit={form.handleSubmit((formValues) => {
          if (setHasUnsavedChanges) {
            onSubmit(formValues, true);
          }
        })}
        className="space-y-6">
        <div className="grid grid-cols-2 gap-6">
          {umsatzplanungEinstellung !== 'ABSATZ' && (
            <UmsatzInputFields form={form} />
          )}
          {umsatzplanungEinstellung === 'UMSATZ_ABSATZ' && (
            <hr className="h-px bg-neutrals-N300 border-0 col-span-2"></hr>
          )}
          {umsatzplanungEinstellung !== 'UMSATZ' && (
            <AbsatzInputFields form={form} />
          )}
        </div>
      </form>
    </Form>
  );
}
