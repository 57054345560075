/* eslint-disable max-lines */
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from '@/components/ui/dialog';
import { MonthParams } from '../../types';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Button } from '@/components/ui/button';
import {
  useAbsatzPlanWertCreateOrUpdate,
  usePlanwerte,
  useUmsatzPlanWertCreateOrUpdate,
} from '@/domain/controlling/hooks';
import { useStandort } from '@/hooks';
import { Suspense, useEffect, useState } from 'react';
import { DialogFooterMessage, DialogFooterMessageType } from '../../components';
import { Calendar } from './Calendar/Calendar';
import { getMonthNameByDateObject } from '@/utils';
import dayjs from 'dayjs';
import { DayProps } from 'react-day-picker';
import { useTagebuchEinstellungen } from '@/domain/controlling/hooks/useTagebuchEinstellungen';
import { Day } from './Calendar/Day';
import {
  getDefaultAbsatzPlanwert,
  getDefaultAbsatzProduktivitaet,
  getDefaultUmsatzPlanwert,
  getDefaultUmsatzProduktivitaet,
  isPlanwertValid,
} from './helpers';
import { Header } from './Header';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import { maskitoParseNumber } from '@maskito/kit';
import { Currency } from '@/gql/graphql';
import * as Sentry from '@sentry/react';
import { Form } from '@/components/ui/form';
import { EditTable } from './EditTable/EditTable';

export const editPlanWerteSchema = z.object({
  absatzPlan: z.string().nullable().optional(),
  absatzProduktivitaet: z.string().nullable().optional(),
  umsatzPlan: z.string().nullable().optional(),
  umsatzProduktivitaet: z.string().nullable().optional(),
});

export type EditPlanWerteFormValues = z.infer<typeof editPlanWerteSchema>;

// eslint-disable-next-line max-lines-per-function
export function EditPlanwerteDialogForm() {
  const { year, month } = useParams<MonthParams>();
  const [searchParams] = useSearchParams();
  const { standortId } = useStandort();
  const datum = searchParams.get('datum') || '';

  const { absatzPlanwerte, umsatzPlanwerte } = usePlanwerte({
    jahrMonat: { jahr: parseInt(year), monat: parseInt(month) },
    standortId,
  });
  const { tagebuchEinstellungen } = useTagebuchEinstellungen(standortId);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [selectedDate, setSelectedDate] = useState(new Date(datum));
  const [dialogFooterMessage, setDialogFooterMessage] =
    useState<DialogFooterMessageType>();

  const [createOrUpdateAbsatzPlanWert] = useAbsatzPlanWertCreateOrUpdate();
  const [createOrUpdateUmsatzPlanWert] = useUmsatzPlanWertCreateOrUpdate();

  const currentAbsatzPlanwert = absatzPlanwerte.find(
    (planwert) => planwert.datum === dayjs(selectedDate).format('YYYY-MM-DD'),
  );
  const currentUmsatzPlanwert = umsatzPlanwerte.find(
    (planwert) => planwert.datum === dayjs(selectedDate).format('YYYY-MM-DD'),
  );

  const form = useForm<EditPlanWerteFormValues>({
    resetOptions: { keepDefaultValues: false, keepValues: false },
    resolver: zodResolver(editPlanWerteSchema),
    values: {
      absatzPlan: getDefaultAbsatzPlanwert(currentAbsatzPlanwert),
      absatzProduktivitaet: getDefaultAbsatzProduktivitaet(
        tagebuchEinstellungen.absatzproduktivitaet.absatzVorgabe,
        currentAbsatzPlanwert,
      ),
      umsatzPlan: getDefaultUmsatzPlanwert(currentUmsatzPlanwert),
      umsatzProduktivitaet: getDefaultUmsatzProduktivitaet(
        currentUmsatzPlanwert,
      ),
    },
  });

  const daysOfMonth = Array.from(
    { length: dayjs(selectedDate).daysInMonth() },
    (_, i) =>
      dayjs(new Date(parseInt(year), parseInt(month) - 1, i + 1)).format(
        'YYYY-MM-DD',
      ),
  );

  const validPlanwerteCount = daysOfMonth.reduce((acc, day) => {
    const currentAbsatzPlanwert = absatzPlanwerte.find(
      (planwert) => planwert.datum === day,
    );
    const currentUmsatzPlanwert = umsatzPlanwerte.find(
      (planwert) => planwert.datum === day,
    );
    if (
      isPlanwertValid(
        currentUmsatzPlanwert,
        currentAbsatzPlanwert,
        tagebuchEinstellungen.absatzproduktivitaet.absatzVorgabe,
      )
    ) {
      return acc + 1;
    }
    return acc;
  }, 0);

  async function onSubmit(
    data: EditPlanWerteFormValues,
    closeDialog: boolean,
    redirectDate?: string,
  ) {
    // Wenn wir tageweise blättern, zeigen wir an dass das Speichern erfolgreich war oder ein Fehler vorlag
    // Damit es dann beim nächsten Speichern nicht komisch aussieht, resetten wir hier einmal die Nachricht
    setDialogFooterMessage(null);
    setLoading(true);
    Promise.all([
      createOrUpdateAbsatzPlanWert({
        createOrUpdateAbsatzPlanInput: {
          datum,
          standortId,
          plan: maskitoParseNumber(data.absatzPlan ?? '', ','),
          produktivitaet: maskitoParseNumber(
            data.absatzProduktivitaet ?? '',
            ',',
          ),
        },
      }),
      createOrUpdateUmsatzPlanWert({
        createOrUpdateUmsatzPlanInput: {
          datum,
          standortId,
          plan: data.umsatzPlan
            ? {
                amount: maskitoParseNumber(data.umsatzPlan, ','),
                currency: Currency.Eur,
              }
            : null,
          produktivitaet: data.umsatzProduktivitaet
            ? {
                amount: maskitoParseNumber(data.umsatzProduktivitaet, ','),
                currency: Currency.Eur,
              }
            : null,
        },
      }),
    ])
      .then((result) => {
        const updateAbsatzPlanwerteResult = result[0];
        const updateUmsatzPlanwerteResult = result[1];
        if (
          updateAbsatzPlanwerteResult.data.createOrUpdateAbsatzPlan
            .__typename === 'Absatz' &&
          updateUmsatzPlanwerteResult.data.createOrUpdateUmsatzPlan
            .__typename === 'Umsatz'
        ) {
          const year = dayjs(datum).year();
          const month = dayjs(datum).month() + 1;
          setLoading(false);
          if (closeDialog) {
            navigate(
              `/controlling/umsatzplanung-v2/${year}/${month}?success=true`,
            );
          } else {
            form.reset();
            setDialogFooterMessage({
              message: t('domain.controlling.umsatzplanung.planung.success'),
            });
            navigate(
              `/controlling/umsatzplanung-v2/${year}/${month}/planwerte/edit?datum=${redirectDate}`,
            );
          }
        } else {
          setLoading(false);
          setDialogFooterMessage({
            type: 'error',
            message: t(
              'domain.controlling.umsatzplanung.planung.errors.unknownError',
            ),
          });
          Sentry.captureException(
            t('domain.controlling.umsatzplanung.planung.errors.unknownError'),
          );
        }
      })
      .catch((e) => {
        setLoading(false);
        setDialogFooterMessage({
          type: 'error',
          message: t(
            'domain.controlling.umsatzplanung.planung.errors.unkownError',
          ),
        });
        Sentry.captureException(e);
      });
  }

  function handleClose() {
    navigate(`/controlling/umsatzplanung-v2/${year}/${month}`, {
      replace: true,
    });
  }

  useEffect(() => {
    const datum = searchParams.get('datum');
    setSelectedDate(new Date(datum));
    form.reset();
  }, [form, searchParams]);

  return (
    <Dialog open onOpenChange={handleClose}>
      <DialogContent className="sm:max-w-[1200px] px-8 py-0 gap-0">
        <div className="flex flex-col gap-6 pt-8">
          <DialogHeader>
            <DialogTitle className="text-xl font-bold text-neutrals-N900">
              {t('domain.controlling.umsatzplanung.planung.dialog.title')}
            </DialogTitle>
          </DialogHeader>
          <Form {...form} key={datum}>
            <form
              onSubmit={(e) => {
                e.preventDefault();
                const submitter = (e.nativeEvent as SubmitEvent)
                  .submitter as HTMLButtonElement;

                if (submitter.name === 'submitAndCloseEditPlanwerteDialog') {
                  onSubmit(form.getValues(), true);
                } else if (submitter.name.includes('planwertDate#')) {
                  const redirectDate = submitter.name.split('planwertDate#')[1];
                  onSubmit(form.getValues(), false, redirectDate);
                }
              }}
              id="editPlanwerteForm">
              <div className="flex gap-8 h-full">
                <div className="flex mt-[9px] pb-8 flex-col gap-8">
                  <div className="flex flex-col gap-2">
                    <div className="text-xs font-bold">
                      {getMonthNameByDateObject(selectedDate)} {year}
                    </div>
                    <div className="text-xs text-neutrals-N700">
                      {t(
                        'domain.controlling.umsatzplanung.planung.dialog.validPlanwerteSubtitle',
                        {
                          validPlanwerteCount,
                          daysInMonth: dayjs(selectedDate).daysInMonth(),
                        },
                      )}
                    </div>
                  </div>
                  <Calendar
                    selected={selectedDate}
                    month={selectedDate}
                    mode="single"
                    components={{
                      Day: ({ date }: DayProps) => {
                        const isDateInCurrentMonth =
                          date.getMonth() === parseInt(month) - 1;

                        const umsatzPlanwert = umsatzPlanwerte.find(
                          (planwert) =>
                            planwert.datum === dayjs(date).format('YYYY-MM-DD'),
                        );
                        const absatzPlanwert = absatzPlanwerte.find(
                          (planwert) =>
                            planwert.datum === dayjs(date).format('YYYY-MM-DD'),
                        );

                        return isDateInCurrentMonth ? (
                          <Day
                            loading={loading}
                            currentAbsatzPlanwert={absatzPlanwert}
                            currentUmsatzPlanwert={umsatzPlanwert}
                            currentDate={date}
                            selectedDate={selectedDate}
                            absatzVorgabe={
                              tagebuchEinstellungen.absatzproduktivitaet
                                .absatzVorgabe
                            }
                          />
                        ) : null;
                      },
                    }}
                  />
                </div>
                <div className="h-full w-[1px] bg-neutrals-N300" />
                <div className="grow flex flex-col gap-2">
                  <Header selectedDate={selectedDate} loading={loading} />
                  <Suspense fallback={<div>Loading…</div>}>
                    <EditTable
                      absatzPlanwert={currentAbsatzPlanwert}
                      umsatzPlanwert={currentUmsatzPlanwert}
                      form={form}
                    />
                  </Suspense>
                </div>
              </div>
            </form>
          </Form>
        </div>
        <hr className="h-px bg-neutrals-N300 border-0 -mx-8"></hr>
        <div className="flex items-center justify-between py-4">
          <DialogFooterMessage
            dialogFooterMessage={dialogFooterMessage}
            isUpdating={loading}
          />
          <Button
            form="editPlanwerteForm"
            name="submitAndCloseEditPlanwerteDialog"
            disabled={loading}>
            {t('domain.controlling.umsatzplanung.ziele.fertig')}
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
}
