import { createContext } from 'react';

export type ErrorBoundaryContextType = {
  didCatch: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  error: any;
};

export const ErrorBoundaryContext =
  createContext<ErrorBoundaryContextType | null>(null);
