import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { HTMLAttributes } from 'react';
import { Link as ReactRouterLink, To } from 'react-router-dom';
import { IconProp, SizeProp } from '@fortawesome/fontawesome-svg-core';

type CustomProps = {
  to: To;
  target?: string;
  displayName: string;
  icon?: IconProp;
  iconSize?: SizeProp;
};

export type ReactRouterLinkWrapperProps = CustomProps &
  HTMLAttributes<HTMLDivElement>;

export function ReactRouterLinkWrapper({
  to,
  target,
  displayName,
  icon,
  iconSize,
}: ReactRouterLinkWrapperProps) {
  return (
    <span className="group-hover:text-primary-blue-B600">
      <ReactRouterLink className="no-underline" to={to} target={target}>
        {icon !== undefined ? (
          <FontAwesomeIcon
            className="group-hover:text-primary-blue-B600"
            fixedWidth
            icon={icon}
            size={iconSize}
          />
        ) : (
          ''
        )}
        {displayName}
      </ReactRouterLink>
    </span>
  );
}
