// Component inspired by https://fastnguyen.medium.com/build-otp-input-with-reactjs-hooks-5699eb58b427

import { useCallback, useEffect, useState } from 'react';
import { SingleInput } from './SingleInput';

export type OTPInputProps = {
  length: number;
  onChangeOTP: (otp: string) => void;
  autoFocus?: boolean;
  disabled?: boolean;
  defaultValue: string | undefined;
};

// Bei Function Components soll diese Regel nicht gelten
// eslint-disable-next-line max-lines-per-function
export function OTPInput({
  autoFocus,
  disabled,
  onChangeOTP,
  length,
  defaultValue,
  ...rest
}: OTPInputProps) {
  const [activeInput, setActiveInput] = useState(0);
  const [otpValues, setOTPValues] = useState(Array<string>(length).fill(''));

  const focusInput = useCallback(
    (inputIndex: number) => {
      const selectedIndex = Math.max(Math.min(length - 1, inputIndex), 0);
      setActiveInput(selectedIndex);
    },
    [length],
  );

  const handleOnFocus = useCallback(
    (index: number) => () => {
      focusInput(index);
    },
    [focusInput],
  );

  const focusNextInput = useCallback(() => {
    focusInput(activeInput + 1);
  }, [activeInput, focusInput]);

  const focusPrevInput = useCallback(() => {
    focusInput(activeInput - 1);
  }, [activeInput, focusInput]);

  const handleOnBlur = useCallback(() => {
    setActiveInput(-1);
  }, []);

  const handleOTPChange = useCallback(
    (otp: string[]) => {
      onChangeOTP(otp.join(''));
    },
    [onChangeOTP],
  );

  const updateOTPValue = useCallback(
    (value: string) => {
      const updatedOTPValues = [...otpValues];
      updatedOTPValues[activeInput] = value[0] || '';
      setOTPValues(updatedOTPValues);
      handleOTPChange(updatedOTPValues);
    },
    [activeInput, handleOTPChange, otpValues],
  );

  const handleOnChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const value = event.target.value;
      if (!value) {
        event.preventDefault();
        return;
      }
      updateOTPValue(value);
      focusNextInput();
    },
    [focusNextInput, updateOTPValue],
  );

  const handleOnKeyDown = useCallback(
    // Da die Switch Cases auf diese Fälle beschränkt sind, ist es ok die Komplexität nicht weiter anzupassen
    // eslint-disable-next-line complexity
    (event: React.KeyboardEvent<HTMLInputElement>) => {
      const key = event.key;
      switch (key) {
        case 'Backspace':
        case 'Delete': {
          event.preventDefault();
          if (otpValues[activeInput]) {
            updateOTPValue('');
          } else {
            focusPrevInput();
          }
          break;
        }

        case 'ArrowLeft': {
          event.preventDefault();
          focusPrevInput();
          break;
        }

        case 'ArrowRight': {
          event.preventDefault();
          focusNextInput();
          break;
        }

        default: {
          if (key.match(/^[^A-Za-z0-9]$/)) {
            event.preventDefault();
          }
          break;
        }
      }
    },
    [activeInput, focusNextInput, focusPrevInput, otpValues, updateOTPValue],
  );

  const handleOnPaste = useCallback(
    (event: React.ClipboardEvent<HTMLInputElement>) => {
      event.preventDefault();
      const pastedData = event.clipboardData
        .getData('text')
        .trim()
        .slice(0, length - activeInput)
        .split('');
      if (pastedData) {
        let nextFocusIndex = 0;
        const updatedOTPValues = [...otpValues];
        updatedOTPValues.forEach((value, index) => {
          if (index >= activeInput) {
            const changedValue = pastedData.shift() || value;
            if (changedValue) {
              updatedOTPValues[index] = changedValue;
              nextFocusIndex = index;
            }
          }
        });
        setOTPValues(updatedOTPValues);
        handleOTPChange(updatedOTPValues);
        setActiveInput(Math.min(nextFocusIndex + 1, length - 1));
      }
    },
    [activeInput, handleOTPChange, length, otpValues],
  );

  /*
   * Wenn ein Password-Manager verwendet wird und dieser das OTP-Token in das entsprechende Input-Feld
   * schreibt, landet der Wert als defaultValue hier in dieser Komponente.
   * Der useEffect wird aufgerufen, sobald OTPInput gerendert wurde. Gibt es dann einen defaultValue, so
   * wird per Aufruf von handleOTPChange das Befüllen der Komponente getriggert. Gibt es keinen defaultValue so ändert
   * sich nichts.
   */
  useEffect(() => {
    if (defaultValue !== '') {
      setOTPValues(defaultValue.split(''));
      handleOTPChange(defaultValue.split(''));
    }
  }, [defaultValue, handleOTPChange]);

  return (
    <div className="flex justify-between" {...rest}>
      {Array(length)
        .fill('')
        .map((_, index) => (
          <SingleInput
            data-testid={`login-totp-input-${index}`}
            key={`singleInput-${index}`}
            focus={activeInput === index}
            value={otpValues && otpValues[index]}
            autoFocus={autoFocus}
            onFocus={handleOnFocus(index)}
            onChange={handleOnChange}
            onKeyDown={handleOnKeyDown}
            onBlur={handleOnBlur}
            onPaste={handleOnPaste}
            disabled={disabled}
          />
        ))}
    </div>
  );
}
