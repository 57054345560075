import createCache from '@emotion/cache';
import { serializeStyles } from '@emotion/serialize';
import { cache } from '@emotion/css';
import { useEffect } from 'react';

/** Emotion can not update global styles */
/** Followed this blogpost for a workarround: https://ntsim.uk/posts/how-to-update-or-remove-global-styles-in-emotion */
const globalThemeCache = createCache({ key: 'global-theme' });

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function injectThemedGlobal(...args: any) {
  const serialized = serializeStyles(args, cache.registered);

  if (!globalThemeCache.inserted[serialized.name]) {
    globalThemeCache.insert('', serialized, globalThemeCache.sheet, true);
  }
}

function flushThemedGlobals() {
  globalThemeCache.sheet.flush();
  globalThemeCache.inserted = {};
  globalThemeCache.registered = {};
}

export function GlobalStyles() {
  useEffect(() => {
    injectThemedGlobal({
      body: {
        fontFamily: 'PublicSans',
        margin: 0,
        fontSize: 16,
        backgroundColor: '#FFFFFF',
        color: '#1D2939',
      },
    });

    return () => flushThemedGlobals();
  }, []);

  return null;
}
