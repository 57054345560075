import { faCheck, faCopy } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react';

export function CopyToClipboard({ value }: { value: string }) {
  const [isCopyied, setIsCopyied] = useState(false);
  return (
    <div className="group flex gap-4 items-center justify-between border border-solid px-4 py-2 leading-6 rounded-lg border-neutrals-N300 dark:border-neutrals-N700">
      <div className="text-xs">{value}</div>
      <FontAwesomeIcon
        className="text-neutrals-N700 dark:text-neutrals-N300 opacity-0 group-hover:opacity-100 group-hover:bg-neutrals-N200 p-2 rounded-md hover:cursor-pointer"
        icon={!isCopyied ? faCopy : faCheck}
        onClick={() => {
          setIsCopyied(true);
          navigator.clipboard.writeText(value);
          setTimeout(() => {
            setIsCopyied(false);
          }, 2000);
        }}
      />
    </div>
  );
}
