import { TwoFactorAuthentification } from './TwoFactorAuthentification';
import { PageWrapper } from './PageWrapper';
import { UserInfo } from './UserInfo';
import { PasswordReset } from './PasswordReset/PasswordReset';

export function Page() {
  return (
    <PageWrapper>
      <UserInfo />
      <PasswordReset />
      <TwoFactorAuthentification />
    </PageWrapper>
  );
}
