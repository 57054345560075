import { maskitoUpdateElement } from '@maskito/core';
import { maskitoEventHandler, maskitoTimeOptionsGenerator } from '@maskito/kit';
import { useMaskito } from '@maskito/react';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeftToArc } from '@fortawesome/pro-regular-svg-icons';
import { Input, InputProps } from '@/components/ui/input';

interface TimeInputProps extends InputProps {
  icon?: IconDefinition;
  className?: string;
  onClick?: () => void;
  applyValue?: boolean;
}
const timeOptions = maskitoTimeOptionsGenerator({
  mode: 'HH:MM',
  timeSegmentMaxValues: { hours: 23 },
});

const options = {
  ...timeOptions,
  plugins: [
    ...timeOptions.plugins,
    maskitoEventHandler('blur', (element) => {
      const [hours = '', minutes = ''] = element.value.split(':');

      if (hours === '' && minutes === '') {
        return;
      }

      maskitoUpdateElement(
        element,
        [hours, minutes].map((segment) => segment.padEnd(2, '0')).join(':'),
      );
    }),
  ],
};

export function TimeInput({ applyValue, ...props }: TimeInputProps) {
  const maskedInputRef = useMaskito({ options });

  return (
    <div className="relative">
      <Input
        {...props}
        ref={maskedInputRef}
        className={`${applyValue ? 'ps-7' : 'text-center'}`}
      />
      {applyValue && (
        <FontAwesomeIcon
          icon={faArrowLeftToArc}
          onClick={props.onClick}
          className="text-primary-blue-B600 hover:text-primary-blue-B700 absolute left-2 top-1/2 -translate-y-1/2 cursor-pointer"
        />
      )}
    </div>
  );
}
