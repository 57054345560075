/* eslint-disable max-lines-per-function */
import { useSuspenseQuery } from '@apollo/client';
import {
  Absatz,
  AbsatzPlan,
  AbsatzProduktivitaet,
  Currency,
  JahrMonat,
  Money,
  Umsatz,
  UmsatzPlan,
} from '../entities';
import { GET_PLANWERTE_BY_STANDORT_ID } from './graphql/queries';
import * as Sentry from '@sentry/react';

export function usePlanwerte({
  jahrMonat,
  standortId,
}: {
  jahrMonat: JahrMonat;
  standortId: string;
}): {
  umsatzPlanwerte?: UmsatzPlan[];
  absatzPlanwerte?: AbsatzPlan[];
  error?: string;
} {
  const { data, error } = useSuspenseQuery(GET_PLANWERTE_BY_STANDORT_ID, {
    variables: {
      jahr: jahrMonat.jahr,
      monat: jahrMonat.monat,
      standortId,
    },
    fetchPolicy: 'network-only',
  });

  if (error) {
    Sentry.captureException(error);
    return { error: error?.message };
  }

  const absatzPlanwerte = data.controlling[0].absatzPlanWerte.map(
    (currentAbsatzPlanWertFromBFF) => {
      const mappedAbsatz = new Absatz(
        currentAbsatzPlanWertFromBFF.standortId,
        currentAbsatzPlanWertFromBFF.plan.produktivitaet
          ? new AbsatzProduktivitaet(
              currentAbsatzPlanWertFromBFF.plan.produktivitaet.stueck,
              currentAbsatzPlanWertFromBFF.plan.produktivitaet.mpt,
            )
          : null,
        currentAbsatzPlanWertFromBFF.plan.wert,
        currentAbsatzPlanWertFromBFF.plan.stunden,
      );
      const mappedAbsatzPlanwert = new AbsatzPlan(
        currentAbsatzPlanWertFromBFF.datum,
        mappedAbsatz,
        currentAbsatzPlanWertFromBFF.prognose,
      );
      return mappedAbsatzPlanwert;
    },
  );

  const umsatzPlanwerte = data.controlling[0].umsatzPlanWerte.map(
    (currentUmsatzPlanWertFromBFF) => {
      const mappedUmsatz = new Umsatz(
        currentUmsatzPlanWertFromBFF.standortId,
        currentUmsatzPlanWertFromBFF.plan.produktivitaet
          ? new Money(
              currentUmsatzPlanWertFromBFF.plan.produktivitaet.amount,
              Currency[
                currentUmsatzPlanWertFromBFF.plan.produktivitaet.currency
              ],
            )
          : null,
        currentUmsatzPlanWertFromBFF.plan.wert
          ? new Money(
              currentUmsatzPlanWertFromBFF.plan.wert.amount,
              Currency[currentUmsatzPlanWertFromBFF.plan.wert.currency],
            )
          : null,
        currentUmsatzPlanWertFromBFF.plan.stunden,
      );
      const mappedUmsatzPlanWerte = new UmsatzPlan(
        currentUmsatzPlanWertFromBFF.datum,
        mappedUmsatz,
        currentUmsatzPlanWertFromBFF.prognose
          ? new Money(
              currentUmsatzPlanWertFromBFF.prognose.amount,
              Currency[currentUmsatzPlanWertFromBFF.prognose.currency],
            )
          : null,
      );
      return mappedUmsatzPlanWerte;
    },
  );

  return { absatzPlanwerte, umsatzPlanwerte };
}
