import {
  faClock,
  faFemale,
  faTruckMoving,
  IconDefinition,
} from '@fortawesome/pro-light-svg-icons';
import { Abwesenheit, Abwesenheitsgrund } from '../entities';
import {
  faArrowDownWideShort,
  faCalendar,
  faGift,
  faHeartPulse,
  faHome,
  faHospital,
  faHospitalUser,
  faHouseChimneyMedical,
  faMicroscope,
  faPersonPregnant,
  faSuitcase,
  faSuitcaseMedical,
} from '@fortawesome/pro-light-svg-icons';
import { faLeanpub } from '@fortawesome/free-brands-svg-icons';
import {
  faCalendarTime,
  faDoorOpen,
  faFamily,
  faFilesMedical,
  faGaugeCircleMinus,
  faGaugeCirclePlus,
  faGraduationCap,
  faHandshakeAngle,
  faHouseDay,
  faMale,
  faMedkit,
  faPersonCircleQuestion,
  faUserDoctor,
} from '@fortawesome/pro-solid-svg-icons';
import { faAmbulance } from '@fortawesome/free-solid-svg-icons';
import { faBan } from '@fortawesome/pro-regular-svg-icons';

const iconMap = new Map<Abwesenheitsgrund, IconDefinition>();
iconMap.set(Abwesenheitsgrund.KRANK, faSuitcaseMedical);
iconMap.set(Abwesenheitsgrund.SCHULE, faLeanpub);
iconMap.set(Abwesenheitsgrund.KURZARBEIT, faArrowDownWideShort);
iconMap.set(Abwesenheitsgrund.QUARANTAENE, faMicroscope);
iconMap.set(Abwesenheitsgrund.QUARANTAENEOHNELFZ, faMicroscope);
iconMap.set(Abwesenheitsgrund.URLAUB, faSuitcase);
iconMap.set(Abwesenheitsgrund.UNBEZAHLTERURLAUB, faSuitcase);
iconMap.set(Abwesenheitsgrund.KRANKESKIND, faPersonPregnant);
iconMap.set(Abwesenheitsgrund.KRANKIMFREI, faHouseChimneyMedical);
iconMap.set(Abwesenheitsgrund.KRANKENGELD, faHeartPulse);
iconMap.set(Abwesenheitsgrund.KRANKINKURZARBEIT, faArrowDownWideShort);
iconMap.set(Abwesenheitsgrund.EURENTE, faHospitalUser);
iconMap.set(Abwesenheitsgrund.FREI, faHome);
iconMap.set(Abwesenheitsgrund.WUNSCHFREI, faHouseDay);
iconMap.set(Abwesenheitsgrund.KRANKOHNELOHNFORTZAHLUNG, faFilesMedical);
iconMap.set(Abwesenheitsgrund.KUR, faHospital);
iconMap.set(Abwesenheitsgrund.FEIERTAGSAUSGLEICHDYNAMISCH, faCalendar);
iconMap.set(Abwesenheitsgrund.FEIERTAGSAUSGLEICH, faCalendar);
iconMap.set(Abwesenheitsgrund.SONDERURLAUB, faGift);
iconMap.set(Abwesenheitsgrund.UEBERSTUNDENABBAU, faCalendarTime);
iconMap.set(Abwesenheitsgrund.ELTERNZEIT, faFamily);
iconMap.set(Abwesenheitsgrund.MUTTERSCHUTZ, faFemale);
iconMap.set(Abwesenheitsgrund.GUTTAGPLUS, faGaugeCirclePlus);
iconMap.set(Abwesenheitsgrund.GUTTAGMINUS, faGaugeCircleMinus);
iconMap.set(Abwesenheitsgrund.FREISTELLUNG, faDoorOpen);
iconMap.set(Abwesenheitsgrund.WIEDEREINGLIEDERUNG, faHandshakeAngle);
iconMap.set(Abwesenheitsgrund.ARBEITSUNFALL, faAmbulance);
iconMap.set(Abwesenheitsgrund.BESCHAEFTIGUNGSVERBOT, faBan);
iconMap.set(Abwesenheitsgrund.LEHRGANG, faGraduationCap);
iconMap.set(Abwesenheitsgrund.FEIERTAG, faSuitcase);
iconMap.set(Abwesenheitsgrund.UNBEZAHLTERFERIENTAG, faSuitcase);
iconMap.set(Abwesenheitsgrund.FEIERTAG, faCalendar);
iconMap.set(Abwesenheitsgrund.RUHETAG, faHome);
iconMap.set(Abwesenheitsgrund.UNFALL, faMedkit);
iconMap.set(Abwesenheitsgrund.MILITAERZIVILDIENST, faTruckMoving);
iconMap.set(Abwesenheitsgrund.MUTTERSCHAFT, faFemale);
iconMap.set(Abwesenheitsgrund.VATERSCHAFT, faMale);
iconMap.set(Abwesenheitsgrund.BILDUNG, faLeanpub);
iconMap.set(Abwesenheitsgrund.BEZAHLTERARBEITSFREIERTAG, faCalendar);
iconMap.set(Abwesenheitsgrund.KOMPENSATIONSTAG, faCalendar);
iconMap.set(Abwesenheitsgrund.AUSGLEICHSRUHEZEITENTAG, faClock);
iconMap.set(Abwesenheitsgrund.ARZTBESUCH, faUserDoctor);
iconMap.set(Abwesenheitsgrund.FEHLTUNENTSCHULDIGT, faPersonCircleQuestion);

export function mapAbwesenheitToIcon(abwesenheit: Abwesenheit) {
  return iconMap.get(abwesenheit.grund);
}
