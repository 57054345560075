import { ChartConfig, ChartContainer } from '@/components/ui/chart';
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '@/components/ui/tooltip';
import { useTranslation } from 'react-i18next';
import { Pie, PieChart as RechartPieChart } from 'recharts';
import { ChartTooltip } from './ChartTooltip';
import { Sollstunden } from '@/domain/controlling/entities/sollstunden';

// eslint-disable-next-line max-lines-per-function
export function PieChart({
  sollstunden,
  umsatzplanung,
}: {
  sollstunden: Sollstunden;
  umsatzplanung: 'UMSATZ' | 'ABSATZ' | 'UMSATZ_ABSATZ';
}) {
  const { t } = useTranslation();

  const chartConfig = {
    count: {
      label: t('domain.controlling.umsatzplanung.sollstunden.countLabel'),
    },
    aushilfen: {
      label: t('domain.controlling.umsatzplanung.sollstunden.aushilfenLabel'),
      color: '#BDCBFB',
    },
    festangestellte: {
      label: t(
        'domain.controlling.umsatzplanung.sollstunden.festangestelltLabel',
      ),
      color: '#3394FF',
    },
  } satisfies ChartConfig;

  const chartData = [
    {
      type: 'festangestellte',
      count: sollstunden.festangestellte,
      fill: '#3394FF',
    },
    {
      type: 'aushilfen',
      count: sollstunden.aushilfen,
      fill: '#BDCBFB',
    },
  ];

  return (
    <TooltipProvider delayDuration={0}>
      <Tooltip>
        <TooltipTrigger asChild>
          {sollstunden.aushilfen + sollstunden.festangestellte === 0 ? (
            // Hier hätte ich eigentlich gerne PieChartSkeleton verwendet, als hier alles nochmal zu duplizieren.
            // Aber wegen dem Tooltip brauchen wir eine Referenz und irgendwie hat die Skeleton-Komponente das nicht akzeptiert
            <ChartContainer
              config={
                {
                  festangestellte: {
                    color: '#E5E5EA',
                  },
                } satisfies ChartConfig
              }
              className={`aspect-square ${
                umsatzplanung === 'UMSATZ_ABSATZ'
                  ? 'w-[100px] h-[100px]'
                  : 'w-[50px] h-[50px]'
              }`}>
              <RechartPieChart
                margin={{ top: 0, right: 0, bottom: 0, left: 0 }}>
                <Pie
                  isAnimationActive={false}
                  data={[
                    {
                      type: 'festangestellte',
                      count: 1,
                      fill: '#E5E5EA',
                    },
                  ]}
                  dataKey="count"
                  nameKey="type"
                  innerRadius={umsatzplanung === 'UMSATZ_ABSATZ' ? 30 : 13}
                />
              </RechartPieChart>
            </ChartContainer>
          ) : (
            <ChartContainer
              config={chartConfig}
              className={`aspect-square ${
                umsatzplanung === 'UMSATZ_ABSATZ'
                  ? 'w-[100px] h-[100px]'
                  : 'w-[50px] h-[50px]'
              }`}>
              <RechartPieChart
                margin={{ top: 0, right: 0, bottom: 0, left: 0 }}>
                <Pie
                  animationBegin={0}
                  startAngle={90}
                  endAngle={-270}
                  data={chartData}
                  dataKey="count"
                  nameKey="type"
                  innerRadius={umsatzplanung === 'UMSATZ_ABSATZ' ? 30 : 13}
                />
              </RechartPieChart>
            </ChartContainer>
          )}
        </TooltipTrigger>
        <TooltipContent side="bottom" className="p-0">
          <ChartTooltip sollstunden={sollstunden} />
        </TooltipContent>
      </Tooltip>
    </TooltipProvider>
  );
}
