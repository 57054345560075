import {
  Markierung,
  Pausenart,
  ArbeitszeitPauseneinstellung,
  ArbeitszeitantragStatus,
} from '../../../gql/graphql';
import { Abwesenheitsgrund } from './Abwesenheitsgrund';
import { ArbeitszeitAuditAenderungsTyp } from './ArbeitszeitAuditAenderungsTyp';
import { Termin } from './Termin';

export class Arbeitszeit {
  constructor(
    readonly id: string,
    readonly mitarbeiter: Mitarbeiter,
    readonly arbeitsbereich: Arbeitsbereich,
    readonly abteilung: Abteilung,
    readonly dauer: number,
    readonly pausendauer: number,
    readonly puenktlichkeit: Puenktlichkeit,
    readonly pausen: Pause[],
    readonly pauseneinstellung: ArbeitszeitPauseneinstellung,
    readonly dienstgaenge: Dienstgang[],
    readonly konflikte: Konflikt[],
    readonly rechtshinweise: Rechtshinweis[],
    readonly standortId: string,
    readonly start?: Date,
    readonly ende?: Date,
    readonly kommen?: Date,
    readonly gehen?: Date,
    readonly abwesenheit?: Abwesenheit,
    readonly bemerkung?: string,
    readonly zuschlaege?: Zuschlag[],
    readonly antraege?: Antrag[],
    readonly audit: Audit[] = [],
    readonly termin?: Termin,
    readonly geaendertVon?: string,
    readonly geaendertAm?: Date,
    readonly datum?: Date,
  ) {}
}

export class Dienstplanuebernahme {
  constructor(
    readonly id: string,
    readonly schichtId: string,
    readonly beginn: Date,
    readonly pause: number,
    readonly dauer: number,
    readonly mitarbeiter: Mitarbeiter,
    readonly arbeitsbereich: Arbeitsbereich,
    readonly ende?: Date,
    readonly zuschlaege: Zuschlag[] = [],
    readonly rechtshinweise: Rechtshinweis[] = [],
    readonly bemerkung?: string,
  ) {}
}

export class Mitarbeiter {
  constructor(
    readonly vorname: string,
    readonly nachname: string,
    readonly id: string,
    readonly extern?: boolean,
    readonly bildUrl?: string,
    readonly arbeitsbereich?: Arbeitsbereich,
  ) {}
}

export class Arbeitsbereich {
  constructor(
    readonly name: string,
    readonly id: string,
    readonly markierung: Markierung,
    readonly abteilungen?: Abteilung[],
  ) {}
}

export class Abteilung {
  constructor(
    readonly name: string,
    readonly id: string,
    readonly markierung: Markierung,
  ) {}
}

export class Puenktlichkeit {
  constructor(
    readonly abweichungBeginn?: number,
    readonly abweichungEnde?: number,
  ) {}
}

export class Dienstgang {
  constructor(
    readonly id: string,
    readonly beginn: Date,
    readonly ende: Date,
    readonly dauer: number,
  ) {}
}

export class Pause {
  constructor(
    readonly dauer: number,
    readonly pausenart: Pausenart,
    readonly id?: string,
    readonly bezahlt?: boolean,
    readonly beginn?: Date,
    readonly ende?: Date,
  ) {}
}

export class Konflikt {
  constructor(readonly id: string, readonly konflikt: string) {}
}
export class Rechtshinweis {
  constructor(
    readonly name: string,
    readonly art: string,
    readonly backgroundcolor: BackgroundColor,
  ) {}
}

export enum BackgroundColor {
  Fatal = 'Fatal',
  Info = 'Info',
  Warnung = 'Warnung',
}

export class Abwesenheit {
  constructor(
    readonly id: string,
    readonly grund: Abwesenheitsgrund,
    readonly dauer: number,
  ) {}
}

export class Zuschlag {
  constructor(
    readonly dauer: number,
    readonly name: string,
    readonly prozent: number,
    readonly betrag?: number,
  ) {}
}

export class ArbeitszeitAuditManager {
  constructor(
    readonly email: string,
    readonly vorname: string,
    readonly nachname: string,
  ) {}
}

export class ArbeitszeitAuditAenderung {
  constructor(
    readonly attribut: string,
    readonly nachher: string,
    readonly vorher: string,
  ) {}
}

export class Audit {
  constructor(
    readonly id: string,
    readonly typ: string,
    readonly aenderungsTyp: ArbeitszeitAuditAenderungsTyp,
    readonly aenderungsdatum: Date,
    readonly geaendertVon: string,
    readonly geaendertVonEmail: string,
    readonly manager: ArbeitszeitAuditManager,
    readonly aenderungen: ArbeitszeitAuditAenderung[],
  ) {}
}

export class Antrag {
  constructor(
    readonly id: string,
    readonly status: ArbeitszeitantragStatus,
    readonly arbeitszeitId: string,
    readonly offen: boolean,
    readonly mitarbeiterId: string,
    readonly standortId: string,
    readonly bemerkungAntragsteller: string,
    readonly bemerkungVorgesetzter: string,
    readonly datum: Date,
    readonly aenderungsdatum: Date,
    readonly erstellungsdatum: Date,
    readonly beginn?: Date,
    readonly ende?: Date,
    readonly arbeitsbereichId?: string,
  ) {}
}
