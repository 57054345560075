/* eslint-disable complexity */
import { Dispatch, SetStateAction, useState } from 'react';
import {
  AbsoluteNumberInput,
  TimeInput,
} from '../../../../../../../../components';
import { useForm } from 'react-hook-form';
import { useArbeitszeitUpdate } from '../../../../../../hooks';
import { handleOnCompleted } from '../../../../handleOnCompleted';
import { Arbeitszeit } from '../../../../../../entities';
import { Card } from './Card';
import { Separator } from './Separator';
import { DeleteAction } from './DeleteAction';
import { mapPauseToInput } from './mapPauseToInputType';
import { ArbeitszeitenPausenInput } from '../../../../../../../../gql/graphql';
import { maskitoParseNumber } from '@maskito/kit';
import { getTimeDisplayFromDate } from '../../../../../../../../utils';
import { formSchema, FormValues } from './formSchema';
import { Button } from '@/components/ui/button';
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form';
import { toast } from '@/hooks/use-toast';
import { zodResolver } from '@hookform/resolvers/zod';
import { useTranslation } from 'react-i18next';
import * as Sentry from '@sentry/react';

// eslint-disable-next-line max-lines-per-function
export function EditForm({
  setIsCreateFormVisible,
  arbeitszeit,
  pauseId,
}: {
  setIsCreateFormVisible: Dispatch<SetStateAction<boolean>>;
  arbeitszeit: Arbeitszeit;
  pauseId: string;
}) {
  const { id: arbeitszeitId, pausen } = arbeitszeit;
  const currentPause = arbeitszeit.pausen.find((pause) => pause.id === pauseId);
  const [arbeitszeitUpdate] = useArbeitszeitUpdate();
  const [isSaving, setIsSaving] = useState(false);
  const { t } = useTranslation();

  const form = useForm<FormValues>({
    resolver: zodResolver(formSchema),
    mode: 'onBlur',
    defaultValues: {
      beginn: currentPause.beginn
        ? getTimeDisplayFromDate(currentPause.beginn)
        : '',
      ende: currentPause.ende ? getTimeDisplayFromDate(currentPause.ende) : '',
      dauer: currentPause.dauer ? currentPause.dauer.toString() : '0',
      arbeitszeitStart: arbeitszeit.start
        ? getTimeDisplayFromDate(arbeitszeit.start)
        : '',
      arbeitszeitEnde: arbeitszeit.ende
        ? getTimeDisplayFromDate(arbeitszeit.ende)
        : '',
    },
  });

  async function onSubmit(data: FormValues) {
    const newPause: ArbeitszeitenPausenInput = {
      pausenart: currentPause.pausenart,
      idPause: currentPause.id,
      bezahlt: currentPause.bezahlt,
      dauer: maskitoParseNumber(data.dauer, '.'),
      beginn: data.beginn.length > 0 ? data.beginn : undefined,
      ende: data.ende.length > 0 ? data.ende : undefined,
    };
    const newPausen = pausen.map((pause) => {
      if (pause.id === pauseId) {
        return newPause;
      }
      return mapPauseToInput(pause);
    });
    setIsSaving(true);
    arbeitszeitUpdate({
      arbeitszeitUpdateInput: {
        pausen: newPausen,
      },
      arbeitszeitId: arbeitszeitId,
    })
      .then((result) => {
        handleOnCompleted(result.data, t);
        setIsSaving(false);
        setIsCreateFormVisible(false);
      })
      // Hier werden die Fehler abgefangen, die nicht von den Union Type gehandelt werden konnten
      .catch((e) => {
        toast({
          title: t(
            'domain.arbeitszeiten.sheet.header.arbeitszeitUpdateUnknownError',
          ),
        });
        Sentry.captureException(e);
        setIsSaving(false);
      });
  }

  return (
    <Form {...form}>
      <form
        className="flex flex-col gap-4 w-[635px]"
        onSubmit={form.handleSubmit(onSubmit)}>
        <div className="flex gap-2 justify-between items-center">
          <Card>
            <FormField
              control={form.control}
              name="dauer"
              render={({ field }) => (
                <FormItem className="w-24">
                  <FormLabel>
                    {t(
                      'domain.arbeitszeiten.sheet.arbeitszeit.pausenDauerLabel',
                    )}
                  </FormLabel>
                  <FormControl>
                    <AbsoluteNumberInput
                      defaultValue={field.value}
                      placeholder="--"
                      unit={t('general.date.minutes')}
                      {...field}
                      onBlur={(e) => {
                        field.onBlur();
                        field.onChange(e);
                        form.setValue('beginn', '');
                        form.setValue('ende', '');
                      }}
                    />
                  </FormControl>
                  <FormDescription />
                  <FormMessage />
                </FormItem>
              )}
            />
          </Card>
          <Separator />
          <Card>
            <FormField
              control={form.control}
              name="beginn"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>
                    {t('domain.arbeitszeiten.sheet.arbeitszeit.beginnLabel')}
                  </FormLabel>
                  <FormControl>
                    <TimeInput
                      defaultValue={field.value}
                      placeholder="--:--"
                      {...field}
                      onBlur={(e) => {
                        field.onBlur();
                        field.onChange(e);
                        form.setValue('dauer', '');
                      }}
                    />
                  </FormControl>
                  <FormDescription />
                  <FormMessage />
                </FormItem>
              )}
            />
            <div className="h-[1px] bg-neutrals-N500 w-32 mt-6" />
            <FormField
              control={form.control}
              name="ende"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>
                    {t('domain.arbeitszeiten.sheet.arbeitszeit.endeLabel')}
                  </FormLabel>
                  <FormControl>
                    <TimeInput
                      defaultValue={field.value}
                      placeholder="--:--"
                      {...field}
                      onBlur={(e) => {
                        field.onBlur();
                        field.onChange(e);
                        form.setValue('dauer', '');
                      }}
                    />
                  </FormControl>
                  <FormDescription />
                  <FormMessage />
                </FormItem>
              )}
            />
          </Card>
        </div>
        <div className="flex justify-end gap-6 items-center">
          <div className="flex gap-2">
            <Button
              onClick={() => setIsCreateFormVisible(false)}
              variant="outline">
              {t('actions.abbrechen')}
            </Button>
            <Button disabled={isSaving || !form.formState.isValid}>
              {!isSaving
                ? t('actions.speichern')
                : t('actions.wirdGespeichert')}
            </Button>
          </div>
          <DeleteAction arbeitszeit={arbeitszeit} pauseId={pauseId} />
        </div>
      </form>
    </Form>
  );
}
