import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Arbeitszeit } from '../../../../../entities';
import { faEdit } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useMemo, useState } from 'react';
import { useArbeitszeitUpdate } from '../../../../../hooks';
import { handleOnCompleted } from '../../../handleOnCompleted';
import { DeleteAction } from './DeleteAction';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { Button } from '@/components/ui/button';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
} from '@/components/ui/form';
import { toast } from '@/hooks/use-toast';
import * as Sentry from '@sentry/react';

type EditBemerkungInput = {
  bemerkung: string;
};

// eslint-disable-next-line max-lines-per-function, complexity
export function Bemerkung({ arbeitszeit }: { arbeitszeit: Arbeitszeit }) {
  const { t } = useTranslation();
  const [arbeitszeitUpdate] = useArbeitszeitUpdate();
  const { id: arbeitszeitId, bemerkung } = arbeitszeit;
  const [isEditable, setIsEditable] = useState(bemerkung.length > 0);
  const [isSaving, setIsSaving] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  const schema = useMemo(() => {
    return z.object({
      bemerkung: z
        .string()
        .max(250, t('domain.arbeitszeiten.sheet.arbeitszeit.bemerkung.laenge'))
        .refine((value) => value.trim().length > 0, {
          message: t(
            'domain.arbeitszeiten.sheet.arbeitszeit.bemerkung.whiteSpace',
          ),
        }),
    });
  }, [t]);

  const form = useForm<z.infer<typeof schema>>({
    defaultValues: {
      bemerkung: bemerkung,
    },
    resolver: zodResolver(schema),
  });

  async function onSubmit(data: EditBemerkungInput) {
    const newBemerkung = data.bemerkung;

    if (isEditable || bemerkung !== newBemerkung) {
      setIsSaving(true);
      arbeitszeitUpdate({
        arbeitszeitUpdateInput: {
          bemerkung: newBemerkung,
        },
        arbeitszeitId: arbeitszeitId,
      })
        .then((result) => {
          handleOnCompleted(result.data, t);
          setIsSaving(false);
          setIsEditable(!isEditable);
        })
        .catch((e) => {
          toast({
            title: t(
              'domain.arbeitszeiten.sheet.header.arbeitszeitUpdateUnknownError',
            ),
          });
          Sentry.captureException(e);
          setIsSaving(false);
        });
    }
  }
  const textFieldValue = form.watch('bemerkung', '');

  useEffect(() => {
    form.setValue('bemerkung', bemerkung);
  }, [bemerkung, form]);

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <div className="flex gap-1 justify-between items-center">
          <FormField
            control={form.control}
            name="bemerkung"
            // eslint-disable-next-line complexity
            render={() => (
              <FormItem>
                <FormControl>
                  <div className="relative w-[350px]">
                    <textarea
                      {...form.register('bemerkung')}
                      maxLength={250}
                      className={`${
                        (bemerkung.length && isEditable) ||
                        (form.getValues('bemerkung') && isEditable)
                          ? 'bg-neutrals-N300'
                          : ''
                      } placeholder-neutrals-N700 border border-neutrals-N500 rounded-md p-1.5 placeholder:font-normal placeholder:text-sm w-[355px] max-h-[200px] h-[106px] text-left pr-6 overflow-y-auto text-sm`}
                      placeholder={t(
                        'domain.arbeitszeiten.sheet.arbeitszeit.bemerkung.placeholder',
                      )}
                      disabled={
                        (isEditable && bemerkung.length > 0) ||
                        (isSaving &&
                          form.getValues('bemerkung').length === 0) ||
                        isDeleting
                      }
                    />
                    <div
                      className={`${
                        isEditable ? 'bg-neutrals-N300' : 'bg-white'
                      } absolute bottom-2.5 right-1 text-neutrals-N700 text-sm pointer-events-none px-0.5`}>
                      {textFieldValue.length} / 250
                    </div>

                    <div className="flex space-x-4">
                      <button
                        className="absolute top-0.5 right-0.5 text-primary-blue-B500 hover:text-primary-blue-B600"
                        onClick={() => setIsEditable(!isEditable)}>
                        {isEditable && (
                          <FontAwesomeIcon
                            icon={faEdit}
                            className="text-primary-blue-B600"
                          />
                        )}
                      </button>
                    </div>
                  </div>
                </FormControl>
                <FormMessage className="pt-0 pb-2" />
              </FormItem>
            )}
          />
        </div>
        {!isEditable && (
          <div className="flex space-x-4">
            <Button
              disabled={
                isSaving ||
                isDeleting ||
                form.getValues('bemerkung').length === 0 ||
                (form.getFieldState('bemerkung').error &&
                  form.getFieldState('bemerkung').error.message.length !== 0) ||
                bemerkung === textFieldValue
              }
              className={`${
                form.watch('bemerkung').length === 0 &&
                'pointer-events-none bg-neutrals-N300 text-neutrals-N500'
              } font-bold`}>
              {!isSaving
                ? t(
                    'domain.arbeitszeiten.sheet.arbeitszeit.bemerkung.speichern',
                  )
                : t('actions.wirdGespeichert')}
            </Button>
            {bemerkung.length > 0 && (
              <DeleteAction
                arbeitszeit={arbeitszeit}
                onDeleted={() => {
                  form.setValue('bemerkung', '');
                  setIsDeleting(false);
                }}
              />
            )}
          </div>
        )}
      </form>
    </Form>
  );
}
