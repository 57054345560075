import { updateMFAPreference, verifyTOTPSetup } from 'aws-amplify/auth';
import { QRCodeCanvas } from 'qrcode.react';
import { Dispatch, SetStateAction, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Button } from '@/components/ui/button';
import { CopyToClipboard } from '@/components';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
} from '@/components/ui/form';
import { Input } from '@/components/ui/input';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { useTranslation } from 'react-i18next';
import * as Sentry from '@sentry/react';

// eslint-disable-next-line max-lines-per-function
export function TOTPSetupForm({
  setIsMFAEnabled,
  totpSetup,
}: {
  setIsMFAEnabled: Dispatch<SetStateAction<boolean>>;
  totpSetup: {
    sharedSecret: string;
    setUpURI: URL;
  };
}) {
  const [isQRCodeVisible, setIsQRCodeVisible] = useState(false);
  const { t } = useTranslation();

  const schema = useMemo(
    () =>
      z.object({
        challengeAnswer: z
          .string()
          .min(1, {
            message: t(
              'pages.profile.sections.security.setupTOTPForm.codeIsRequired',
            ),
          })
          .refine(
            (value) => /^[0-9]+$/.test(value),
            t(
              'pages.profile.sections.security.setupTOTPForm.codePatternMismatch',
            ),
          ),
      }),
    [t],
  );

  type VerifyTOTPTokenInput = z.infer<typeof schema>;

  const form = useForm<VerifyTOTPTokenInput>({
    resolver: zodResolver(schema),
    defaultValues: { challengeAnswer: '' },
  });
  const {
    handleSubmit,
    setError,
    reset,
    formState: {
      isLoading,
      isSubmitting,
      isSubmitSuccessful,
      errors: validationErrors,
    },
  } = form;

  function handleButtonLoadingState() {
    return isSubmitting || isLoading
      ? t(
          'pages.profile.sections.security.setupTOTPForm.verifyButtonLabelIsSubmitting',
        )
      : isSubmitSuccessful && !validationErrors.challengeAnswer
      ? t(
          'pages.profile.sections.security.setupTOTPForm.verifyButtonLabelIsSubmitted',
        )
      : t('pages.profile.sections.security.setupTOTPForm.verifyButtonLabel');
  }

  const onSubmit = async function (data: VerifyTOTPTokenInput) {
    try {
      await verifyTOTPSetup({ code: data.challengeAnswer });
      await updateMFAPreference({ totp: 'PREFERRED' });
      setIsMFAEnabled(true);
      reset();
    } catch (error) {
      Sentry.captureException(error);
      setError('challengeAnswer', {
        message: t(
          'pages.profile.sections.security.setupTOTPForm.softwareTokenException',
        ),
      });
    }
  };

  return (
    <>
      <p className="text-sm leading-6 text-neutrals-N900">
        {t('pages.profile.sections.security.setupTOTPForm.detailsDescription')}
      </p>
      <h2 className="text-base font-semibold leading-7 text-neutrals-N900">
        {t('pages.profile.sections.security.setupTOTPForm.scanQRCodeTitle')}
      </h2>
      <p className="text-sm leading-6 text-neutrals-N900">
        {t('pages.profile.sections.security.setupTOTPForm.qrCodeDescription')}
      </p>
      <div className="border border-solid border-neutrals-N500 dark:border-neutrals-N900 rounded-md p-2 w-fit">
        <input
          className="hidden"
          defaultValue={totpSetup.sharedSecret}
          data-testid="pages-profile-security-totp-secret"
        />
        <QRCodeCanvas value={totpSetup.setUpURI.href} />
      </div>
      <div className="flex gap-2 items-center">
        <p className="text-sm leading-6 text-neutrals-N900">
          {t('pages.profile.sections.security.setupTOTPForm.unableToScan')}
        </p>
        <Button
          size="sm"
          variant="ghost"
          onClick={() => setIsQRCodeVisible(!isQRCodeVisible)}>
          {isQRCodeVisible ? 'Ausblenden' : 'Anzeigen'}
        </Button>
      </div>
      {isQRCodeVisible && <CopyToClipboard value={totpSetup.sharedSecret} />}
      <h2 className="text-base font-semibold leading-7 text-neutrals-N900">
        {t('pages.profile.sections.security.setupTOTPForm.verifyCodeTitle')}
      </h2>
      <Form {...form}>
        <form
          className="flex flex-col gap-4"
          id="verifyTOTP"
          onSubmit={handleSubmit(onSubmit)}>
          <FormField
            control={form.control}
            name="challengeAnswer"
            render={({ field }) => (
              <FormItem>
                <FormControl>
                  <Input
                    className="w-full sm:w-1/2"
                    data-testid="pages-profile-security-totp-input"
                    placeholder="XXXXXX"
                    maxLength={6}
                    {...field}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <Button
            data-testid="pages-profile-security-totp-verify"
            className="w-fit"
            form="verifyTOTP"
            type="submit"
            variant={'default'}
            disabled={isSubmitting || isLoading}>
            {handleButtonLoadingState()}
          </Button>
        </form>
      </Form>
    </>
  );
}
