import { TdHTMLAttributes } from 'react';
import { TableCell } from '../ui/table';

export function E2NTableCell({
  children,
  isBorderDashed = false,
  isBorderVisible = false,
  ...other
}: TdHTMLAttributes<HTMLTableCellElement> & {
  isBorderVisible?: boolean;
  isBorderDashed?: boolean;
}) {
  const className = 'p-0 py-1 ps-4 ' + other.className;
  return (
    <TableCell {...other} className={className}>
      <div className="flex justify-between items-center">
        <div className="grow">{children}</div>
        {isBorderVisible && (
          <div
            className={`ms-4 self-stretch h-10 border-r border-neutrals-N300 ${
              isBorderDashed ? 'border-dashed' : 'border-solid'
            }`}
          />
        )}
      </div>
    </TableCell>
  );
}
