import cover from '../../assets/images/cover.jpg';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';

import { Helmet } from 'react-helmet-async';
import { MFAForm, LoginForm } from './forms';
import { Footer } from './Footer';
import { Header } from './Header';
import { Transition } from '@headlessui/react';
import { MFAMethod } from '../../services';
import { useAuthenticationContext } from '../../app/AuthenticationContext';
import { useNavigate } from 'react-router-dom';
import { Link } from '@/components';

// eslint-disable-next-line max-lines-per-function
export function Page() {
  const { t } = useTranslation();
  const [mfaChallengeType, setMfaChallengeType] = useState<MFAMethod>('NONE');
  const [autoFocusOfOTPInput, setAutoFocusOfOTPInput] = useState(false);
  const { userInfo } = useAuthenticationContext();
  const navigate = useNavigate();

  useEffect(() => {
    navigate('/');
  }, [navigate, userInfo]);

  return (
    <>
      <Helmet>
        <title>Login</title>
      </Helmet>
      <div className="grid min-h-screen grid-cols-1 grid-rows-1 lg:grid-cols-2 lg:grid-rows-1 bg-white">
        <img
          alt="cover"
          className="hidden lg:block lg:object-cover lg:overflow-hidden lg:h-0 lg:w-0 lg:min-h-full lg:min-w-full"
          src={cover}
        />
        <div className="flex flex-col justify-between items-center">
          <Header />
          <div className="flex flex-col gap-4 w-full p-8 sm:p-16 md:w-1/2 md:p-0 lg:w-full lg:p-16 xl:w-1/2 xl:p-0 overflow-hidden">
            <Transition
              show={mfaChallengeType === 'NONE'}
              unmount={mfaChallengeType === 'TOTP'}
              leave="transition ease-in-out duration-1000 transform opacity"
              leaveFrom="translate-x-0 opacity-100"
              leaveTo="-translate-x-full opacity-0">
              <div className="flex flex-col gap-4 2xl:gap-8">
                <div className="flex flex-col gap-2">
                  <h2 className="text-2xl font-bold">
                    {t('pages.login.loginForm.header')}
                  </h2>
                  <div className="text-sm">
                    {t('pages.login.loginForm.noAccount.description')}{' '}
                    <Link
                      data-testid="login-register-link"
                      target="_blank"
                      href="https://e2n.de/kontakt/#register">
                      {t('pages.login.loginForm.noAccount.linkTitle')}
                    </Link>
                  </div>
                </div>
                <LoginForm setMfaChallengeType={setMfaChallengeType} />
              </div>
            </Transition>
            <Transition
              show={mfaChallengeType === 'TOTP'}
              enter="transition ease-in-out duration-300 transform opacity"
              enterFrom="translate-x-full opacity-0"
              enterTo="translate-x-0 opacity-100"
              afterEnter={() => {
                setAutoFocusOfOTPInput(true);
              }}>
              <div className="flex flex-col gap-4 2xl:gap-8">
                <div className="flex flex-col gap-2">
                  <h2 className="text-2xl font-bold">
                    {t('pages.login.mfaForm.header')}
                  </h2>
                  <div className="text-sm">
                    {t('pages.login.mfaForm.descriptionTOTP')}
                  </div>
                </div>
                <MFAForm autofocus={autoFocusOfOTPInput} />
              </div>
            </Transition>
          </div>
          <Footer />
        </div>
      </div>
    </>
  );
}
