import { ReactNode } from 'react';
import { Navigation } from '../components';
import { Toaster } from '@/components/ui/toaster';

export function DefaultPage({ children }: { children: ReactNode }) {
  return (
    <>
      <div className="flex flex-col h-screen bg-neutrals-N200 divide-y divide-neutrals-N200">
        <div className="shrink-0">
          <Navigation />
        </div>
        <div className="overflow-auto">{children}</div>
      </div>
      <Toaster />
    </>
  );
}
