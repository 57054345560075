import { DialogContent, DialogTitle } from '@/components/ui/dialog';
import { faExclamationCircle } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';

export function ErrorFallbackDialog() {
  const { t } = useTranslation();

  return (
    <DialogContent className="sm:max-w-[680px] px-8 py-0">
      <div className="flex flex-col gap-4 py-8 w-full">
        <DialogTitle className="text-xl font-bold text-neutrals-N900">
          {t('domain.controlling.umsatzplanung.ziele.dialog.title')}
        </DialogTitle>
        <div className="flex flex-col justify-center items-center w-full gap-4">
          <FontAwesomeIcon
            icon={faExclamationCircle}
            className="text-red-R400 h-16 w-16"
          />
          <div className="flex flex-col gap-2 justify-center items-center">
            <div className="font-bold">
              {t('domain.controlling.umsatzplanung.ziele.dialog.errorHeader')}
            </div>
            <div className="text-[15px]">
              {t(
                'domain.controlling.umsatzplanung.ziele.dialog.errorSubHeader',
              )}
            </div>
          </div>
        </div>
      </div>
    </DialogContent>
  );
}
