import { faCalendar } from '@fortawesome/pro-duotone-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Vertrag, VertragZahlungsmethode } from '../../../../gql/graphql';
import i18next, { t } from 'i18next';

// eslint-disable-next-line max-lines-per-function
export function Vertragsvereinbarung({ vertrag }: { vertrag: Vertrag }) {
  const defaultZahlungsmethode =
    vertrag.vertragZahlungsmethode === VertragZahlungsmethode.Sepa
      ? 'Sepa-Lastschrift'
      : vertrag.vertragZahlungsmethode === VertragZahlungsmethode.Ueberweisung
      ? 'Überweisung'
      : 'Unbekannt';

  const formatSummeNetto = vertrag.summeNetto.wert
    .toLocaleString('de-DE', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    })
    .replace('.', '');

  const formatFaelligkeitsdatum = Intl.DateTimeFormat(i18next.language).format(
    new Date(vertrag.faelligkeitsdatum),
  );

  return (
    <div className="flex flex-col mt-3 px-14">
      <h4 className="pb-5">{t('domain.vertraege.slide.contractPartTitle')}</h4>
      <div className="flex gap-4">
        <div className="h-full w-auto rounded-[14px] p-3 bg-neutrals-N400 bg-opacity-30">
          <FontAwesomeIcon
            fixedWidth
            size={'2x'}
            className="text-neutrals-N800 dark:text-neutrals-N400"
            icon={faCalendar}
          />
        </div>
        <div className="flex flex-wrap">
          <h5 className="pb-1 text-md font-bold">
            {vertrag.vertragIntervall === 'JAHR'
              ? t('domain.vertraege.slide.yearly')
              : t('domain.vertraege.slide.monthly')}
          </h5>
          <div className="w-full text-xs">
            {t('domain.vertraege.slide.nextInvoice')}
            {formatFaelligkeitsdatum}
          </div>
          <div className="py-5 text-end w-full">
            <div className="w-full text-regular">
              {t('domain.vertraege.slide.taxInfo')}
            </div>
            <span className="text-sm">{t('domain.vertraege.slide.tax')}</span>
            <div className="flex pb-2 justify-end font-bold gap-2">
              <span className="flex top-0 mt-[-0.25rem] text-xl align-top font-semibold">
                {t('general.currency.EUR')}
              </span>
              <span className="flex text-4xl">{formatSummeNetto}</span>
            </div>
            <div className="font-semibold text-xs text-neutrals-N700 uppercase">
              {defaultZahlungsmethode}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
