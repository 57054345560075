import { useTranslation } from 'react-i18next';
import { Arbeitszeit } from '../../../../../entities';
import { Table } from './Table';
import { faPlusCircle } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react';
import { CreateForm } from './Forms';
import { PausenEinstellungen } from './PausenEinstellungen';
import { useManagerCanEditArbeitszeiten } from '../../../../../hooks/useManagerCanEditArbeitszeit';
import { Button } from '@/components/ui/button';

// eslint-disable-next-line max-lines-per-function
export function Pausen({ arbeitszeit }: { arbeitszeit: Arbeitszeit }) {
  const { pausen } = arbeitszeit;
  const managerCanEditArbeitszeit = useManagerCanEditArbeitszeiten();
  const { t } = useTranslation();
  const [isCreateFormVisible, setIsCreateFormVisible] = useState(false);

  const bezahltePausendauer = pausen
    .filter((pause) => pause.bezahlt)
    .reduce((sum, currentPausenDauer) => sum + currentPausenDauer.dauer, 0);

  const unbezahltePausendauer = pausen
    .filter((pause) => !pause.bezahlt)
    .reduce((sum, currentPausenDauer) => sum + currentPausenDauer.dauer, 0);

  return (
    <div className="flex flex-col justify-center gap-6 shadow-card bg-white rounded-md p-2">
      <div>
        <div className="bg-neutrals-N200 rounded-lg py-2 px-4 flex gap-2 justify-between text-sm items-center">
          <div>{t('domain.arbeitszeiten.sheet.arbeitszeit.pausenGesamt')}</div>
          <div className="flex gap-2 items-center">
            <div className="flex gap-4 ">
              <div>
                {t('domain.arbeitszeiten.sheet.arbeitszeit.pausenBezahlt')}
              </div>
              <div className="flex gap-1 items-baseline">
                <span className="font-semibold">{bezahltePausendauer}</span>
                <span className="text-neutrals-N700 text-xs">
                  {t('general.date.minutes')}
                </span>
              </div>
            </div>
            <div className="h-6 text-neutrals-N700 w-[1px]" />
            <div className="flex gap-4 ">
              <div>
                {t('domain.arbeitszeiten.sheet.arbeitszeit.pausenUnbezahlt')}
              </div>
              <div className="flex gap-1 items-baseline">
                <span className="font-semibold">{unbezahltePausendauer}</span>
                <span className="text-neutrals-N700 text-xs">
                  {t('general.date.minutes')}
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="rounded-lg px-4 py-2 flex gap-2 justify-between text-sm">
          <div>Einstellungen</div>
          <PausenEinstellungen arbeitszeit={arbeitszeit} />
        </div>
      </div>

      <Table arbeitszeit={arbeitszeit} />
      {arbeitszeit.pausen.length === 0 && (
        <div className="flex justify-center text-sm text-neutrals-N700">
          {t('domain.arbeitszeiten.sheet.arbeitszeit.pausenEmtpyTable')}
        </div>
      )}
      {managerCanEditArbeitszeit &&
        (!isCreateFormVisible ? (
          <div className="flex justify-center">
            <Button
              variant="ghost"
              onClick={() => setIsCreateFormVisible(true)}>
              <div className="flex gap-1 items-center justify-center text-primary-blue-B600">
                <FontAwesomeIcon icon={faPlusCircle} />
                <div>Pause erstellen</div>
              </div>
            </Button>
          </div>
        ) : (
          <CreateForm
            setIsCreateFormVisible={setIsCreateFormVisible}
            arbeitszeit={arbeitszeit}
          />
        ))}
    </div>
  );
}
