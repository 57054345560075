import { ReactNode, Suspense } from 'react';
import { ErrorBoundary } from '../../../components';
import { LoadingPage } from '../../../pages';
import { ErrorPage } from './ErrorPage';
import { StandortProvider } from '../../../store/standortContext';

export function UnauthorizedPage({ children }: { children: ReactNode }) {
  return (
    <ErrorBoundary FallbackComponent={ErrorPage}>
      <Suspense fallback={<LoadingPage />}>
        <StandortProvider>{children}</StandortProvider>
      </Suspense>
    </ErrorBoundary>
  );
}
