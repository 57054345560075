import { TableCell } from '@/components/ui/table';
import { faEdit } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Dispatch, SetStateAction } from 'react';

export function EditCell({
  setEditablePause,
  pauseId,
}: {
  setEditablePause: Dispatch<SetStateAction<string>>;
  pauseId: string;
}) {
  return (
    <TableCell className="font-medium p-4">
      <FontAwesomeIcon
        onClick={() => {
          setEditablePause(pauseId);
        }}
        icon={faEdit}
        className="text-primary-blue-B600"
        fixedWidth
      />
    </TableCell>
  );
}
