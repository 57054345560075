import { Suspense } from 'react';
import { Historie } from './Historie';
import { LoadingPage } from '@/pages/LoadingPage';

export function HistorieWrapper() {
  return (
    <Suspense fallback={<LoadingPage />}>
      <Historie />
    </Suspense>
  );
}
