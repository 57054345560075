import { HTMLAttributes } from 'react';

export function LabeledField({
  label,
  value,
  currency,
  className,
}: {
  label: string;
  value: string | number | undefined;
  currency: string;
} & HTMLAttributes<HTMLDivElement>) {
  return (
    <div className={`flex flex-col justify-center gap-1 ${className}`}>
      <div className="text-xs text-neutrals-N700 font-bold">{label}</div>
      <div className="flex flex-row gap-1 items-baseline">
        {!value ? (
          <div className="text-neutrals-N400">--</div>
        ) : (
          <div className="text-xl font-extrabold">{value}</div>
        )}
        <div className="font-semibold text-sm text-neutrals-N700">
          {currency}
        </div>
      </div>
    </div>
  );
}
