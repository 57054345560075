import { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashCan, faXmark } from '@fortawesome/pro-regular-svg-icons';
import { t } from 'i18next';
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@/components/ui/popover';
import { toast } from '@/hooks/use-toast';
import { Button } from '@/components/ui/button';
import * as Sentry from '@sentry/react';

interface ConfirmDeletePopoverProps {
  confirmText?: string;
  handleDelete: () => Promise<void>;
  confirmButtonText?: string;
  processingButtonText?: string;
  onDeleted?: () => void;
}

// eslint-disable-next-line max-lines-per-function
export function ConfirmDeletePopover({
  confirmText = t('domain.arbeitszeiten.sheet.arbeitszeit.confirmDelete'),
  handleDelete,
  confirmButtonText = t('domain.arbeitszeiten.sheet.arbeitszeit.confirmButton'),
  processingButtonText = t(
    'domain.arbeitszeiten.sheet.arbeitszeit.confirmIsDeleting',
  ),
  onDeleted,
}: ConfirmDeletePopoverProps) {
  const [isConfirmOpen, setIsConfirmOpen] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  const handleDeleteClick = async () => {
    setIsDeleting(true);
    try {
      await handleDelete();
    } catch (e) {
      toast({
        title: t(
          'domain.arbeitszeiten.sheet.header.arbeitszeitUpdateUnknownError',
        ),
      });
      Sentry.captureException(e);
    } finally {
      setIsConfirmOpen(false);
      setIsDeleting(false);
    }
  };

  return (
    <Popover
      open={isConfirmOpen}
      onOpenChange={(open) => {
        setIsConfirmOpen(open);
        if (!open) {
          onDeleted?.();
        }
      }}>
      <PopoverTrigger asChild>
        <Button
          className="bg-primary-red-R100 text-primary-red-R400 hover:bg-primary-red-R200 transition-colors w-[45px]"
          onClick={() => setIsConfirmOpen(true)}>
          <FontAwesomeIcon icon={faTrashCan} />
        </Button>
      </PopoverTrigger>
      <PopoverContent
        side="bottom"
        className="text-sm flex flex-col gap-2 items-start w-[230px] text-start bg-white">
        <div className="font-semibold">{confirmText}</div>
        <div className="flex gap-2">
          <Button
            className="right-2 transition-colors h-8"
            variant="destructive"
            disabled={isDeleting}
            onClick={handleDeleteClick}>
            {isDeleting ? processingButtonText : confirmButtonText}
          </Button>
          <Button
            className="right-2 transition-colors px-2.5 h-8"
            variant="outline"
            onClick={() => setIsConfirmOpen(false)}>
            <FontAwesomeIcon icon={faXmark} className="text-lg" />
          </Button>
        </div>
      </PopoverContent>
    </Popover>
  );
}
