import { usePlanwerte } from '@/domain/controlling/hooks';
import { useStandort } from '@/hooks';
import { MonthParams } from '../../types';
import { useParams } from 'react-router-dom';
import {
  AbsoluteNumberField,
  MoneyField,
} from '../DataTable/Cells/GenericFields';
import { useTranslation } from 'react-i18next';
import { Currency, Money } from '@/domain/controlling/entities';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Differenz } from '../DataTable/Cells/Differenz';
import { useUmsatzplanung } from '@/domain/controlling/hooks/useUmsatzplanung';
import { faCircle } from '@fortawesome/pro-solid-svg-icons';

// eslint-disable-next-line max-lines-per-function
export function ZielAbweichungKPI({ type }: { type: 'absatz' | 'umsatz' }) {
  const { standortId } = useStandort();
  const { year, month } = useParams<MonthParams>();
  const { t } = useTranslation();

  const { umsatzPlanwerte, absatzPlanwerte } = usePlanwerte({
    standortId,
    jahrMonat: { jahr: parseInt(year), monat: parseInt(month) },
  });
  const { absatzZiel, umsatzZiel } = useUmsatzplanung(
    standortId,
    parseInt(year),
    parseInt(month),
  );

  let sum = 0;
  if (type == 'umsatz') {
    umsatzPlanwerte.forEach((umsatzPlanwert) => {
      if (umsatzPlanwert.umsatz && umsatzPlanwert.umsatz.wert) {
        sum = umsatzPlanwert.umsatz.wert.amount + sum;
      }
    });
  } else {
    absatzPlanwerte.forEach((absatzPlanwert) => {
      if (absatzPlanwert.absatz && absatzPlanwert.absatz.wert) {
        sum = absatzPlanwert.absatz.wert + sum;
      }
    });
  }

  return (
    <div className="flex flex-col gap-2 w-1/3">
      <div className="flex gap-2 items-center">
        <FontAwesomeIcon
          icon={faCircle}
          className="text-secondary-teal-T500 h-3 w-3"
        />
        <div className="text-xs font-semibold text-neutrals-N700">
          {type === 'umsatz'
            ? t('domain.controlling.umsatzplanung.kpi.planumsatz.label')
            : t('domain.controlling.umsatzplanung.kpi.planabsatz.label')}
        </div>
      </div>
      {type === 'umsatz' ? (
        <div className="flex flex-col gap-1">
          <MoneyField
            value={new Money(sum, Currency.EUR)}
            unit={t('general.currency.EUR')}
            className="[&>div:first-child]:text-2xl [&>div:last-child]:text-base [&>div:first-child]:font-extrabold [&>div:last-child]:font-normal"
          />
          <Differenz
            tooltipMessage={t(
              'domain.controlling.umsatzplanung.planung.tooltip.abweichungZumZielumsatz',
            )}
            planWert={sum}
            prognoseWert={umsatzZiel?.wert?.amount || 0}
            type="umsatz"
            unit={t('general.currency.EUR')}
          />
        </div>
      ) : (
        <div className="flex flex-col gap-1">
          <AbsoluteNumberField
            className="[&>div:first-child]:text-2xl [&>div:last-child]:text-base [&>div:first-child]:font-extrabold [&>div:last-child]:font-normal"
            value={sum}
            unit={t('domain.controlling.umsatzplanung.units.stueck')}
          />
          <Differenz
            tooltipMessage={t(
              'domain.controlling.umsatzplanung.planung.tooltip.abweichungZumZielabsatz',
            )}
            planWert={sum}
            prognoseWert={absatzZiel?.wert || 0}
            type="absatz"
            unit={t('domain.controlling.umsatzplanung.units.stueck')}
          />
        </div>
      )}
    </div>
  );
}
