/* eslint-disable max-lines */
import { useSuspenseQuery } from '@apollo/client';
import { GET_ARBEITSZEITEN_BY_DATE_AND_STANDORT_ID } from './graphql/queries';
import {
  Abteilung,
  Abwesenheit,
  Abwesenheitsgrund,
  Antrag,
  Arbeitsbereich,
  Arbeitszeit,
  Dienstgang,
  Mitarbeiter,
  Pause,
  Puenktlichkeit,
  Rechtshinweis,
  Zuschlag,
  Audit,
  ArbeitszeitAuditAenderung,
  ArbeitszeitAuditManager,
  Dienstplanuebernahme,
  BackgroundColor,
} from '../entities';
import { handleGraphQLErrors } from '../../../utils/errors/handleGraphQLErrors';
import {
  ArbeitszeitAuditAenderungsTyp,
  Arbeitszeiten,
  ArbeitszeitenByDatum,
  ArbeitszeitError,
  Markierung,
} from '../../../gql/graphql';
import { getDateOrNull } from '../../../utils/helperFunctions';
import { Termin, TerminWiederholung } from '../entities/Termin';

function calculatePausendauer(arbeitszeit: Arbeitszeiten) {
  let pausendauer = 0;
  for (let i = 0; i < arbeitszeit.pausen.length; i++) {
    if (arbeitszeit.pausen[i].dauer !== null) {
      pausendauer += arbeitszeit.pausen[i].dauer;
    }
  }
  return pausendauer;
}

function calculatePuenktlichkeit(arbeitszeit: Arbeitszeiten) {
  let puenktlichkeit = new Puenktlichkeit();

  if (arbeitszeit.puenktlichkeit !== null) {
    puenktlichkeit = new Puenktlichkeit(
      arbeitszeit.puenktlichkeit.abweichungBeginn === null
        ? null
        : arbeitszeit.puenktlichkeit.abweichungBeginn,
      arbeitszeit.puenktlichkeit.abweichungEnde === null
        ? null
        : arbeitszeit.puenktlichkeit.abweichungEnde,
    );
  }
  return puenktlichkeit;
}

function mapDienstgaenge(arbeitszeit: Arbeitszeiten) {
  return arbeitszeit.dienstgaenge.map((dienstgang) => {
    return {
      id: dienstgang.id,
      beginn: getDateOrNull(dienstgang.beginn),
      ende: getDateOrNull(dienstgang.ende),
      dauer: dienstgang.dauer,
    };
  });
}

function mapPausen(arbeitszeit: Arbeitszeiten): Pause[] {
  return arbeitszeit.pausen.map((pause) => {
    return {
      id: pause.id,
      beginn: getDateOrNull(pause.beginn),
      ende: getDateOrNull(pause.ende),
      dauer: pause.dauer,
      pausenart: pause.pausenart,
      bezahlt: pause.bezahlt,
    };
  });
}

function mapRechtshinweise(arbeitszeit: Arbeitszeiten) {
  return arbeitszeit.rechtshinweise.map((rechtshinweis) => {
    return {
      name: rechtshinweis.name,
      art: rechtshinweis.art,
      backgroundcolor: BackgroundColor[rechtshinweis.backgroundcolor],
    };
  });
}

function mapAntrage(arbeitszeit: Arbeitszeiten): Antrag[] {
  return arbeitszeit.antraege.map((antrag) => {
    return {
      id: antrag.id,
      beginn: getDateOrNull(antrag.beginn),
      ende: getDateOrNull(antrag.ende),
      aenderungsdatum: getDateOrNull(antrag.aenderungsdatum),
      arbeitszeitId: antrag.arbeitszeitId,
      bemerkungAntragsteller: antrag.bemerkungAntragsteller,
      bemerkungVorgesetzter: antrag.bemerkungVorgesetzter,
      datum: getDateOrNull(antrag.datum),
      erstellungsdatum: getDateOrNull(antrag.erstellungsdatum),
      mitarbeiterId: antrag.mitarbeiterId,
      offen: antrag.offen,
      standortId: antrag.standortId,
      status: antrag.status,
      arbeitsbereichId: antrag.arbeitsbereichId,
    };
  });
}

function mapAudit(arbeitszeit: Arbeitszeiten): Audit[] {
  return arbeitszeit.audit.map((audit) => {
    if (audit.__typename === 'Audit') {
      return new Audit(
        audit.id,
        audit.typ,
        ArbeitszeitAuditAenderungsTyp[audit.aenderungsTyp],
        new Date(audit.aenderungsdatum),
        audit.geaendertVon,
        audit.geaendertVonEmail,
        new ArbeitszeitAuditManager(
          audit.manager.email,
          audit.manager.vorname,
          audit.manager.nachname,
        ),
        audit.aenderungen.map(
          (aenderung) =>
            new ArbeitszeitAuditAenderung(
              aenderung.attribut,
              aenderung.nachher,
              aenderung.vorher,
            ),
        ),
      );
    }
  });
}

function isOffen(arbeitszeit: Arbeitszeit): boolean {
  return (
    (arbeitszeit.antraege.some((antrag) => antrag.offen) ||
      arbeitszeit.start === null ||
      arbeitszeit.ende === null) &&
    arbeitszeit.abwesenheit === null
  );
}

// eslint-disable-next-line max-lines-per-function
export function useArbeitszeiten(
  datum: Date,
  standortId: string,
): {
  abgeschlosseneArbeitszeiten: Arbeitszeit[];
  offeneArbeitszeiten: Arbeitszeit[];
  dienstplanuebernahmen: Dienstplanuebernahme[];
  error?: string;
} {
  const parsedDate = datum.toISOString().slice(0, 10);
  const { data } = useSuspenseQuery(GET_ARBEITSZEITEN_BY_DATE_AND_STANDORT_ID, {
    variables: {
      datum: parsedDate,
      standortId: standortId,
    },
    fetchPolicy: 'no-cache',
  });
  const error = handleGraphQLErrors<ArbeitszeitenByDatum, ArbeitszeitError>(
    data.arbeitszeiten,
  );

  if (error) {
    return {
      error: error.message,
      abgeschlosseneArbeitszeiten: [],
      offeneArbeitszeiten: [],
      dienstplanuebernahmen: [],
    };
  }

  const zeiten = data.arbeitszeiten as ArbeitszeitenByDatum;

  const arbeitszeiten = zeiten.arbeitszeiten.map((arbeitszeit) => {
    return createArbeitszeit(arbeitszeit);
  });

  const offeneArbeitszeiten = arbeitszeiten.filter((arbeitszeit) => {
    return isOffen(arbeitszeit);
  });

  const abgeschlosseneArbeitszeiten = arbeitszeiten.filter((arbeitszeit) => {
    return !isOffen(arbeitszeit);
  });

  const dienstplanuebernahmen = data.dienstplanuebernahmen.map(
    (dienstplanuebernahme) =>
      new Dienstplanuebernahme(
        dienstplanuebernahme.id,
        dienstplanuebernahme.schichtId,
        getDateOrNull(dienstplanuebernahme.beginn),
        dienstplanuebernahme.pause,
        dienstplanuebernahme.dauer,
        new Mitarbeiter(
          dienstplanuebernahme.mitarbeiter.vorname,
          dienstplanuebernahme.mitarbeiter.nachname,
          dienstplanuebernahme.mitarbeiter.id,
          false,
          dienstplanuebernahme.mitarbeiter.bildUrl,
        ),
        new Arbeitsbereich(
          dienstplanuebernahme.arbeitsbereich.name,
          dienstplanuebernahme.arbeitsbereich.id,
          dienstplanuebernahme.arbeitsbereich
            .markierung as unknown as Markierung,
          [],
        ),
        getDateOrNull(dienstplanuebernahme.ende),
      ),
  );

  return {
    offeneArbeitszeiten,
    abgeschlosseneArbeitszeiten,
    dienstplanuebernahmen,
  };
}

// eslint-disable-next-line max-lines-per-function
function createArbeitszeit(arbeitszeit: Arbeitszeiten) {
  const pausendauer = calculatePausendauer(arbeitszeit);
  const puenktlichkeit = calculatePuenktlichkeit(arbeitszeit);
  const dienstgaenge: Dienstgang[] = mapDienstgaenge(arbeitszeit);
  const bemerkung = arbeitszeit.bemerkung ? arbeitszeit.bemerkung : '';
  const pausen: Pause[] = mapPausen(arbeitszeit);
  const pauseneinstellung = arbeitszeit.pauseneinstellung;
  const zuschlaege: Zuschlag[] = [];
  const antraege = mapAntrage(arbeitszeit);
  const rechtshinweise: Rechtshinweis[] = mapRechtshinweise(arbeitszeit);
  const audits: Audit[] = arbeitszeit.audit ? mapAudit(arbeitszeit) : [];
  let abwesenheit: Abwesenheit = null;

  if (arbeitszeit.abwesenheit !== null) {
    abwesenheit = new Abwesenheit(
      arbeitszeit.abwesenheit.id,
      Abwesenheitsgrund[arbeitszeit.abwesenheit.grund],
      arbeitszeit.abwesenheit.dauer,
    );
  }
  if (arbeitszeit.zuschlaege !== null) {
    const tmp = arbeitszeit.zuschlaege.map((zuschlag) => {
      let betrag = null;
      if (zuschlag.betrag.__typename === 'ArbeitszeitZuschlaegeBetrag') {
        betrag = zuschlag.betrag.betrag;
      }
      return new Zuschlag(
        zuschlag.dauer,
        zuschlag.name,
        zuschlag.prozent,
        betrag,
      );
    });
    zuschlaege.push(...tmp);
  }

  let termin: Termin | null = null;

  if (arbeitszeit.termin !== null) {
    termin = new Termin(
      arbeitszeit.termin.titel,
      TerminWiederholung[arbeitszeit.termin.wiederholung],
      arbeitszeit.termin.id,
      arbeitszeit.termin.bildUrl,
      arbeitszeit.termin.bildName,
      arbeitszeit.termin.beschreibung,
      null,
      null,
      arbeitszeit.termin.markierung,
    );
  }

  return new Arbeitszeit(
    arbeitszeit.id,
    new Mitarbeiter(
      arbeitszeit.mitarbeiter.vorname,
      arbeitszeit.mitarbeiter.nachname,
      arbeitszeit.mitarbeiter.id,
      false,
      arbeitszeit.mitarbeiter.bildUrl,
    ),
    new Arbeitsbereich(
      arbeitszeit.arbeitsbereich.name,
      arbeitszeit.arbeitsbereich.id,
      arbeitszeit.arbeitsbereich.markierung,
      arbeitszeit.arbeitsbereich.abteilungen,
    ),
    new Abteilung(
      arbeitszeit.arbeitsbereich.name,
      arbeitszeit.arbeitsbereich.id,
      arbeitszeit.arbeitsbereich.markierung,
    ),
    arbeitszeit.dauer,
    pausendauer,
    puenktlichkeit,
    pausen,
    pauseneinstellung,
    dienstgaenge,
    [],
    rechtshinweise,
    '',
    getDateOrNull(arbeitszeit.beginn),
    getDateOrNull(arbeitszeit.ende),
    getDateOrNull(arbeitszeit.kommen),
    getDateOrNull(arbeitszeit.gehen),
    abwesenheit,
    bemerkung,
    zuschlaege,
    antraege,
    audits,
    termin,
    arbeitszeit.geaendertVon,
    getDateOrNull(arbeitszeit.aenderungsdatum),
    getDateOrNull(arbeitszeit.datum),
  );
}
