import { HTMLAttributes } from 'react';
import { Table } from '../ui/table';

export function E2Ntable({
  children,
  ...other
}: HTMLAttributes<HTMLTableElement>) {
  const className =
    'border-separate border-spacing-y-3 table-auto ' + other.className;
  return (
    <Table {...other} className={className}>
      {children}
    </Table>
  );
}
