import dayjs from 'dayjs';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChevronLeft,
  faChevronRight,
} from '@fortawesome/pro-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Button } from '@/components/ui/button';

export function MonthPicker({ startDate }: { startDate: string }) {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleTodayClick = () => {
    const year = dayjs(new Date()).get('year');
    const month = dayjs(new Date()).get('month') + 1;
    navigate(`/controlling/umsatzplanung-v2/${year}/${month}`, {
      replace: true,
    });
  };

  const handlePrevMonthClick = () => {
    const year = dayjs(startDate).subtract(1, 'M').get('year');
    const month = dayjs(startDate).subtract(1, 'M').get('month') + 1;
    navigate(`/controlling/umsatzplanung-v2/${year}/${month}`, {
      replace: true,
    });
  };

  const handleNextMonthClick = () => {
    const year = dayjs(startDate).add(1, 'M').get('year');
    const month = dayjs(startDate).add(1, 'M').get('month') + 1;
    navigate(`/controlling/umsatzplanung-v2/${year}/${month}`, {
      replace: true,
    });
  };

  return (
    <div className="flex items-center gap-4">
      <Button onClick={handleTodayClick} variant="outline-secondary">
        {t('general.date.today')}
      </Button>
      <div className="flex items-center">
        <Button size="icon" variant="icon" onClick={handlePrevMonthClick}>
          <FontAwesomeIcon
            className="text-neutrals-N700 text-xl"
            icon={faChevronLeft}
          />
        </Button>
        <Button size="icon" variant="icon" onClick={handleNextMonthClick}>
          <FontAwesomeIcon
            className="text-neutrals-N700 text-xl"
            icon={faChevronRight}
          />
        </Button>
      </div>
    </div>
  );
}
