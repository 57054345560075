import {
  faChevronLeft,
  faChevronRight,
} from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getWeekdayByDate, getMonthNameByDateObject } from '../../../../utils';
import { Button } from '@/components/ui/button';

interface DatePickerProps {
  year: string;
  month: string;
  day: string;
}

export function DatePickerSkeleton({ year, month, day }: DatePickerProps) {
  const date = new Date(`${year}-${month}-${day}`);
  return (
    <div className="flex items-center gap-3">
      <FontAwesomeIcon
        icon={faChevronLeft}
        className="text-neutrals-N700 cursor-pointer text-xl"
        size="lg"
      />
      <Button
        variant="ghost"
        className={'justify-start text-left text-2xl font-bold'}>
        {`${
          getWeekdayByDate(date).shortage
        }., ${date.getDate()}. ${getMonthNameByDateObject(date)}`}
      </Button>
      <FontAwesomeIcon
        icon={faChevronRight}
        className="text-neutrals-N700 cursor-pointer text-xl"
        size="lg"
      />
    </div>
  );
}
