import { useTranslation } from 'react-i18next';
import { E2NTableCell } from '../../../../components/Table';
import { Abweichung } from '../Abweichung';
import { getHoursAndMinutesFromMinutes } from '../../../../utils';
import { memo } from 'react';

export const DauerCell = memo(function DauerCell({
  dauer,
  abweichung,
  isBorderVisible,
}: {
  dauer: number;
  abweichung?: number;
  isBorderVisible: boolean;
}) {
  const { t } = useTranslation();
  const { hours, minutes } = getHoursAndMinutesFromMinutes(dauer);

  let abweichungKomponent = null;
  if (abweichung !== 0 && abweichung !== undefined) {
    abweichungKomponent = <Abweichung abweichung={abweichung} />;
  }

  return (
    <E2NTableCell
      className="font-semibold w-[30%]"
      isBorderVisible={isBorderVisible}>
      <div
        className={`flex justify-center items-center ${
          isBorderVisible && 'ps-5'
        }`}>
        <div className="flex flex-col">
          <div className="flex gap-1 items-baseline">
            {hours}
            <span className="text-neutrals-N700 text-xs">
              {t('general.date.hours')}
            </span>
            {minutes}
            <span className="text-neutrals-N700 text-xs">
              {t('general.date.minutes')}
            </span>
          </div>
          {abweichungKomponent}
        </div>
      </div>
    </E2NTableCell>
  );
});
