/* eslint-disable max-lines-per-function */
import {
  getWiederholungStringFromTerminWiederholung,
  Termin,
} from '../../entities/Termin';
import { TermineCell } from '..';
import { Avatar, AvatarFallback, AvatarImage } from '@/components/ui/avatar';
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '@/components/ui/tooltip';
import { Badge } from '@/components/ui/badge';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarStar } from '@fortawesome/pro-regular-svg-icons';
import { CSSProperties } from 'react';
import { markierungMapper } from '@/utils';

export function TerminTooltip({ termin }: { termin?: Termin }) {
  const { t } = useTranslation();
  type StyleProps = CSSProperties & {
    '--border-color': CSSProperties['color'];
  };
  if (termin === undefined || termin === null) {
    return <TermineCell />;
  }

  let beschreibung = (
    <Badge className="bg-gray-200 hover:bg-gray-200 text-gray-500 mt-2">
      {t('domain.arbeitszeiten.uebersicht.termine.beschreibungFehlend')}{' '}
    </Badge>
  );

  if (termin.beschreibung && termin.beschreibung.length > 0) {
    beschreibung = (
      <p className="font-normal line-clamp-2">{termin.beschreibung}</p>
    );
  }

  return (
    <TooltipProvider>
      <Tooltip delayDuration={0}>
        <TooltipTrigger>
          <TermineCell termin={termin} />
        </TooltipTrigger>
        <TooltipContent className="w-96 h-24 p-4 mr-4">
          <div className="flex gap-4">
            <Avatar
              className="w-16 h-16 border border-[--border-color]"
              style={
                {
                  '--border-color': termin.markierung
                    ? markierungMapper[termin.markierung].base
                    : '',
                } as StyleProps
              }>
              <AvatarImage src={termin.bildUrl} alt={termin.bildName} />
              <AvatarFallback>
                <FontAwesomeIcon icon={faCalendarStar} size="lg" />
              </AvatarFallback>
            </Avatar>
            <div>
              <span>
                <span className="font-bold">{termin.titel}</span> -{' '}
                {getWiederholungStringFromTerminWiederholung(
                  termin.wiederholung,
                )}
              </span>
              {beschreibung}
            </div>
          </div>
        </TooltipContent>
      </Tooltip>
    </TooltipProvider>
  );
}
