import { Tabs, TabsList, TabsTrigger, TabsContent } from '@/components/ui/tabs';
import {
  PrognoseAbweichungKPI,
  SollstundenAbweichungKPI,
  ZielAbweichungKPI,
} from './KPI';
import Chart from './Chart/Chart';
import { Suspense } from 'react';

export function PlanungWrapper() {
  return (
    <div className="flex flex-col gap-4">
      <h4 className="text-xl font-bold">Planung</h4>
      <Suspense fallback={<div>Loading…</div>}>
        <Tabs
          className="flex flex-col gap-10 w-full bg-white rounded-md p-5"
          defaultValue="umsatz">
          <div className="flex justify-between items-start">
            <TabsContent
              value="umsatz"
              className="w-2/3 flex items-start justify-between data-[state=inactive]:hidden m-0">
              <ZielAbweichungKPI type="umsatz" />
              <PrognoseAbweichungKPI type="umsatz" />
              <SollstundenAbweichungKPI type="umsatz" />
            </TabsContent>
            <TabsContent
              value="absatz"
              className="w-2/3 flex items-start justify-between data-[state=inactive]:hidden m-0">
              <ZielAbweichungKPI type="absatz" />
              <PrognoseAbweichungKPI type="absatz" />
              <SollstundenAbweichungKPI type="absatz" />
            </TabsContent>
            <TabsList>
              <TabsTrigger value="umsatz">Umsatz</TabsTrigger>
              <TabsTrigger value="absatz">Absatz</TabsTrigger>
            </TabsList>
          </div>
          <TabsContent value="umsatz">
            <Chart type="umsatz" />
          </TabsContent>
          <TabsContent value="absatz">
            <Chart type="absatz" />
          </TabsContent>
        </Tabs>
      </Suspense>
    </div>
  );
}
