import { Badge } from '@/components/ui/badge';
import { faMinus } from '@fortawesome/pro-light-svg-icons';
import { faTriangleExclamation } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Rechtshinweis } from '../../entities';

export function RechtshinweiseCell({
  rechtshinweise,
}: {
  rechtshinweise: Rechtshinweis[];
}) {
  return (
    <Badge
      className={`px-2 py-1.5 space-x-2 w-[50px] ${
        rechtshinweise.length < 1
          ? 'bg-neutrals-N200'
          : 'bg-primary-red-R100 hover:bg-primary-red-R200'
      }`}
      variant="secondary">
      <FontAwesomeIcon
        icon={faTriangleExclamation}
        size="xl"
        className={
          rechtshinweise.length > 0
            ? 'text-primary-red-R400'
            : 'text-neutrals-N600'
        }
        fixedWidth
      />
      {rechtshinweise.length > 0 ? (
        <span className="text-black text-bold">{rechtshinweise.length}</span>
      ) : (
        <FontAwesomeIcon
          icon={faMinus}
          size="sm"
          className="text-black text-bold"
          fixedWidth
        />
      )}
    </Badge>
  );
}
