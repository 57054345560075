import {
  Dispatch,
  ReactNode,
  SetStateAction,
  createContext,
  useContext,
  useState,
} from 'react';
import { UserInfo } from '../../services';

export type AuthenticationContextProps = {
  hasAccess: boolean;
  setHasAccess: (value: boolean) => void;
  userInfo: UserInfo | undefined;
  setUserInfo: Dispatch<SetStateAction<UserInfo>>;
  isUserLoading: boolean;
  setIsUserLoading: (value: boolean) => void;
};

const AuthenticationContext = createContext<
  AuthenticationContextProps | undefined
>(undefined);

function AuthenticationProvider({ children }: { children: ReactNode }) {
  const [hasAccess, setHasAccess] = useState(false);
  const [isUserLoading, setIsUserLoading] = useState(true);
  const [userInfo, setUserInfo] = useState<UserInfo | undefined>(undefined);

  const value = {
    hasAccess,
    setHasAccess,
    userInfo,
    setUserInfo,
    isUserLoading,
    setIsUserLoading,
  };

  return (
    <AuthenticationContext.Provider value={value}>
      {children}
    </AuthenticationContext.Provider>
  );
}

function useAuthenticationContext() {
  const authenticationContext = useContext(AuthenticationContext);

  if (authenticationContext === undefined) {
    throw new Error(
      'useAuthenticationContext must be used within AuthenticationContextProvider',
    );
  }
  return authenticationContext;
}

// eslint-disable-next-line react-refresh/only-export-components
export { AuthenticationProvider, useAuthenticationContext };
