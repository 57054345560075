import { getHoursAndMinutesFromMinutes } from './getHoursAndMinutesFromMinutes';

export function getDateTimeFromPuenktlichkeit(
  date: Date,
  puenktlichkeit: number,
): Date | null {
  if (puenktlichkeit === 0) {
    return date;
  }

  const dateInMinutes = date.getHours() * 60 + date.getMinutes();
  let newDate: { hours: number; minutes: number };
  if (puenktlichkeit < 0) {
    newDate = getHoursAndMinutesFromMinutes(
      dateInMinutes + Math.abs(puenktlichkeit),
    );
  } else {
    newDate = getHoursAndMinutesFromMinutes(
      dateInMinutes - Math.abs(puenktlichkeit),
    );
  }
  return new Date(
    date.getFullYear(),
    date.getMonth(),
    date.getDate(),
    newDate.hours,
    newDate.minutes,
  );
}
