import { faArrowLeft } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useParams } from 'react-router-dom';
import { CreateArbeitszeitForm } from './CreateArbeitszeitForm';
import { useTranslation } from 'react-i18next';
import { SheetClose, SheetHeader, SheetTitle } from '@/components/ui/sheet';

export function CreateSheet() {
  const { year, month, day } = useParams();
  const date = new Date(`${year}-${month}-${day}`);
  const { t } = useTranslation();

  return (
    <>
      <SheetHeader className="bg-neutrals-N200">
        <SheetTitle className="flex items-center justify-between p-4">
          <SheetClose className="flex items-center gap-2">
            <FontAwesomeIcon
              className="text-primary-blue-B500"
              icon={faArrowLeft}
              fixedWidth
            />
            <span className="text-sm text-neutrals-N800">
              {t('domain.arbeitszeiten.create.erstellen')}
            </span>
          </SheetClose>
        </SheetTitle>
      </SheetHeader>
      <CreateArbeitszeitForm defaultDate={date} />
    </>
  );
}
