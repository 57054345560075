import { useTranslation } from 'react-i18next';
import { ZuschlaegeRow } from './ZuschlaegeRow';
import { ZuschlaegeCell } from '../Cells';
import { Zuschlag } from '../../entities';
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '@/components/ui/tooltip';

export function ZuschlaegeTooltip({ zuschlaege }: { zuschlaege: Zuschlag[] }) {
  const { t } = useTranslation();
  return (
    <TooltipProvider>
      <Tooltip delayDuration={0}>
        <TooltipTrigger
          className={`${zuschlaege ? 'cursor-auto' : 'cursor-pointer'}`}>
          <div className="flex">
            <ZuschlaegeCell zuschlaege={zuschlaege} />
          </div>
        </TooltipTrigger>
        {zuschlaege.length > 0 && (
          <TooltipContent
            side="bottom"
            className="border-neutrals-N300 bg-white text-neutrals-N900 text-sm w-[400px] p-0">
            <div className="font-bold uppercase tracking-wide text-sm text-neutrals-N700 space-y-2 px-3 pt-2">
              {t('domain.arbeitszeiten.uebersicht.tooltips.zuschlaege')}
            </div>
            {!zuschlaege ? (
              <div>Error</div>
            ) : (
              zuschlaege.map((zuschlag, id) => (
                <ZuschlaegeRow key={id} zuschlag={zuschlag} />
              ))
            )}
          </TooltipContent>
        )}
      </Tooltip>
    </TooltipProvider>
  );
}
