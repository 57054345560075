import { faCircle } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export function UncheckedIcon() {
  return (
    <span className="fa-stack fa-xs text-neutrals-N700">
      <FontAwesomeIcon icon={faCircle} fixedWidth className="fa-stack-2x" />
    </span>
  );
}
