// eslint-disable-next-line complexity
export function handleGraphQLErrors<
  Base extends { __typename?: string },
  Error extends { __typename?: string; type: string; message: string },
>(data: Base | Error): null | { type: string; message: string } {
  if (
    data.__typename.includes('ArbeitsbereichNotFound') ||
    data.__typename.includes('ArbeitszeitNotFound') ||
    data.__typename.includes('ArbeitszeitUpdateIdArbeitsbereichNull') ||
    data.__typename.includes('ManagerHasNoAccessToArbeitsbereich') ||
    data.__typename.includes('Error')
  ) {
    const error = data as Error;
    return {
      type: error.type,
      message: error.message,
    };
  }
  const keys = Object.keys(data);
  for (let i = 0; i < keys.length; i++) {
    if (data[keys[i]] instanceof Array) {
      for (let j = 0; j < data[keys[i]].length; j++) {
        return handleGraphQLErrors(data[keys[i]][j]);
      }
    } else if (data[keys[i]] instanceof Object) {
      return handleGraphQLErrors(data[keys[i]]);
    }
  }
}
