import {
  AbsatzPlan,
  AbsatzVorgabe,
  UmsatzPlan,
} from '@/domain/controlling/entities';
import { formatNumber } from '../../formatNumber';
import { getAbsatzProduktivitaetByMptOrStueck } from '../../utils';

// eslint-disable-next-line complexity
export function isPlanwertValid(
  umsatzPlanwert: UmsatzPlan,
  absatzPlanwert: AbsatzPlan,
  absatzVorgabe: AbsatzVorgabe,
) {
  if (!umsatzPlanwert || !umsatzPlanwert.umsatz) {
    return false;
  }
  if (!umsatzPlanwert.umsatz.wert || umsatzPlanwert.umsatz.wert.amount <= 0) {
    return false;
  }
  if (
    !umsatzPlanwert.umsatz.produktivitaet ||
    umsatzPlanwert.umsatz.produktivitaet.amount <= 0
  ) {
    return false;
  }
  if (!absatzPlanwert || !absatzPlanwert.absatz) {
    return false;
  }
  if (!absatzPlanwert.absatz.wert || absatzPlanwert.absatz.wert <= 0) {
    return false;
  }
  if (!absatzPlanwert.absatz.produktivitaet) {
    return false;
  }
  if (
    (absatzVorgabe === AbsatzVorgabe.Mpt &&
      absatzPlanwert.absatz.produktivitaet.mpt <= 0) ||
    (absatzVorgabe === AbsatzVorgabe.Stueck &&
      absatzPlanwert.absatz.produktivitaet.stueck <= 0)
  ) {
    return false;
  }
  return true;
}

export function getDefaultAbsatzPlanwert(absatzPlanwert?: AbsatzPlan) {
  return absatzPlanwert && absatzPlanwert.absatz && absatzPlanwert.absatz.wert
    ? formatNumber(absatzPlanwert.absatz.wert.toString(), true)
    : '';
}

export function getDefaultAbsatzProduktivitaet(
  absatzVorgabe: AbsatzVorgabe,
  absatzPlanwert?: AbsatzPlan,
) {
  return absatzPlanwert &&
    absatzPlanwert.absatz &&
    absatzPlanwert.absatz.produktivitaet
    ? getAbsatzProduktivitaetByMptOrStueck(
        absatzPlanwert.absatz.produktivitaet,
        absatzVorgabe,
      )
    : '';
}

export function getDefaultUmsatzPlanwert(umsatzPlanwert?: UmsatzPlan) {
  return umsatzPlanwert && umsatzPlanwert.umsatz && umsatzPlanwert.umsatz.wert
    ? formatNumber(umsatzPlanwert.umsatz.wert.amount.toString(), false)
    : '';
}

export function getDefaultUmsatzProduktivitaet(umsatzPlanwert?: UmsatzPlan) {
  return umsatzPlanwert &&
    umsatzPlanwert.umsatz &&
    umsatzPlanwert.umsatz.produktivitaet
    ? formatNumber(
        umsatzPlanwert.umsatz.produktivitaet.amount.toString(),
        false,
      )
    : '';
}
