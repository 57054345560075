import { t } from 'i18next';
import { E2NtableHeader } from '../../../../../../../../components/Table';
import { TableHead, TableRow } from '@/components/ui/table';

export function TableHeader() {
  return (
    <E2NtableHeader>
      <TableRow className="hover:bg-transparent">
        <TableHead className="w-[100px] px-4">
          {t('domain.arbeitszeiten.sheet.arbeitszeit.pausenArtLabel')}
        </TableHead>
        <TableHead>
          {t('domain.arbeitszeiten.sheet.arbeitszeit.pausenZeitraumLabel')}
        </TableHead>
        <TableHead className="px-4">
          {t('domain.arbeitszeiten.sheet.arbeitszeit.pausenDauerLabel')}
        </TableHead>
        <TableHead>
          {t('domain.arbeitszeiten.sheet.arbeitszeit.pausenBezahltLabel')}
        </TableHead>
        <TableHead></TableHead>
      </TableRow>
    </E2NtableHeader>
  );
}
