import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { Link, OTPInput } from '../../../components';
import { useTranslation } from 'react-i18next';
import { confirmSignIn } from 'aws-amplify/auth';
import { Button } from '@/components/ui/button';
import { Alert, AlertDescription } from '@/components/ui/alert';
import * as Sentry from '@sentry/react';

type MFAFormInput = {
  code: string;
};

type AuthErrorMessage =
  | {
      event: string;
      data?: unknown;
      message?: string;
    }
  | undefined;

function ErrorMessage({
  authErrorMessage,
}: {
  authErrorMessage: AuthErrorMessage;
}) {
  const { t } = useTranslation();
  const message =
    authErrorMessage.message ?? 'Du konntest nicht eingeloggt werden.';

  return (
    <Alert data-testid="login-error-callout" variant="destructive">
      <AlertDescription>
        {message}{' '}
        <Link
          className="hover:text-primary-red-R600"
          href="https://hilfe.e2n.de"
          target="_blank">
          {t('pages.login.loginForm.linkTitle')}
        </Link>
        {'.'}
      </AlertDescription>
    </Alert>
  );
}

// eslint-disable-next-line max-lines-per-function
export function MFAForm({ autofocus }: { autofocus: boolean }) {
  const [autocompleteValue, setAutocompleteValue] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [authErrorMessages, setAuthErrorMessages] =
    useState<AuthErrorMessage>(null);
  const navigate = useNavigate();
  const { handleSubmit, setValue } = useForm<MFAFormInput>();
  const { t } = useTranslation();

  const onSubmitCode = async (data: MFAFormInput) => {
    setIsLoading(true);

    try {
      await confirmSignIn({ challengeResponse: data.code });
      navigate({ pathname: '/' }, { replace: true });
    } catch (e) {
      Sentry.captureException(e);
      setAuthErrorMessages({
        event: 'totp_failure',
        message: t('pages.login.mfaForm.totpCodeNotValid'),
      });
    }

    setIsLoading(false);
  };

  return (
    <form className="flex flex-col gap-4" onSubmit={handleSubmit(onSubmitCode)}>
      {!isLoading && authErrorMessages && (
        <ErrorMessage authErrorMessage={authErrorMessages} />
      )}
      {/* 
        Wenn ein Password-Manager zum automatischen Befüllen des Login-Formulars mit MFA benutzt wird,
        greift dieses unsichtbare input-Feld den Wert für das OTP-Token ab. Der Password-Manager schreibt
        den Wert für das OTP-Token in dieses input-Field, was per CSS unsichtbar gemacht wird.
        Das Befüllen des inputs mit dem Wert aus dem Password-Manager triggert das onChange-Event. 
        Das onChange-Event speichert den Wert des OTP-Tokens in der State-Variablen "autocompleteValue". 
        autocompleteValue wird dann als defaultValue an OTPInput weitergereicht. OTPInput kümmert sich dann
        um das Rendering des defaultValues.
      */}
      <input
        autoFocus={autofocus}
        autoComplete="one-time-code"
        className="opacity-0 w-0 h-0 block"
        onChange={(event) => {
          setAutocompleteValue(event.target.value ?? '');
        }}
      />
      <div className="flex flex-col gap-8">
        <OTPInput
          autoFocus={autofocus}
          defaultValue={autocompleteValue}
          length={6}
          onChangeOTP={(value) => {
            setValue('code', value);
          }}
        />
        <Button
          data-testid="login-mfa-submit-button"
          style={{
            width: '100%',
            justifyContent: 'center',
            gap: '1rem',
          }}
          type="submit">
          {isLoading
            ? t('pages.login.mfaForm.isSubmitting')
            : t('pages.login.mfaForm.buttonLabel')}
        </Button>
      </div>
    </form>
  );
}
