import { faCircle } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import { MoneyInput } from '../../../../../components';
import {
  Dispatch,
  SetStateAction,
  useEffect,
  useState,
  FocusEvent,
} from 'react';
import { maskitoParseNumber } from '@maskito/kit';
import { PlanumsatzEntry } from '../types';
import { calculatePlanumsatz } from './calculatePlanumsatz';
import { calculateDifferenzZumZiel } from './calculateDifferenzZumZiel';
import { formatNumber } from '../formatNumber';

// eslint-disable-next-line max-lines-per-function
export function KPI({
  planumsatzEntries,
  zielumsatz,
  setUmsatzBudget,
}: {
  planumsatzEntries: PlanumsatzEntry[];
  zielumsatz: number;
  setUmsatzBudget: Dispatch<SetStateAction<number>>;
}) {
  const { t } = useTranslation();
  const defaultDifferenzZumZiel = calculateDifferenzZumZiel(
    planumsatzEntries,
    zielumsatz,
  );
  const [differenzZumZiel, setDifferenzZumZiel] = useState(
    defaultDifferenzZumZiel,
  );
  const defaultPlanumsatz = calculatePlanumsatz(planumsatzEntries);
  const [planumsatz, setPlanumsatz] = useState(defaultPlanumsatz);

  useEffect(() => {
    const newDifferenzZumZiel = calculateDifferenzZumZiel(
      planumsatzEntries,
      zielumsatz,
    );
    setDifferenzZumZiel(newDifferenzZumZiel);
    const newPlanumsatz = calculatePlanumsatz(planumsatzEntries);
    setPlanumsatz(newPlanumsatz);
  }, [planumsatzEntries, zielumsatz]);

  function handleOnBlur(event: FocusEvent<HTMLInputElement, Element>) {
    const newZielumsatz =
      event.currentTarget.value.length > 0
        ? maskitoParseNumber(event.currentTarget.value, ',')
        : 0;

    if (event.currentTarget.value.length < 1) {
      event.currentTarget.value = '0';
    }

    setUmsatzBudget(newZielumsatz);
    const newDifferenzZumZiel = calculateDifferenzZumZiel(
      planumsatzEntries,
      newZielumsatz,
    );
    setDifferenzZumZiel({
      ...differenzZumZiel,
      value: newDifferenzZumZiel.value,
    });
  }

  return (
    <div className="flex gap-8 items-center">
      <div className="flex flex-col justify-start gap-2">
        <div className="font-semibold text-neutrals-N700 text-sm">
          {t('domain.controlling.umsatzplanung.kpi.umsatzplanung.label')}
        </div>
        <MoneyInput
          key={zielumsatz}
          defaultValue={formatNumber(zielumsatz.toString(), false)}
          size={8}
          className="font-extrabold text-3xl"
          onBlur={handleOnBlur}
        />
      </div>
      <div className="h-24 w-[1px] bg-neutrals-N400" />
      <div className="flex flex-col justify-start gap-2">
        <div className="font-semibold text-neutrals-N700 text-sm">
          {t('domain.controlling.umsatzplanung.kpi.planumsatz.label')}
        </div>
        <div className="flex gap-2 items-baseline">
          <span className="text-3xl font-extrabold">
            {formatNumber(planumsatz.toString(), false)}
          </span>
          <span className="font-semibold text-neutrals-N700">
            {t('general.currency.EUR')}
          </span>
        </div>
      </div>
      <div className="flex flex-col justify-start gap-2">
        <div className="font-semibold text-neutrals-N700 text-sm">
          {t('domain.controlling.umsatzplanung.kpi.differenz.label')}
        </div>
        <div className="flex gap-2 items-baseline">
          <FontAwesomeIcon
            icon={faCircle}
            className={`${differenzZumZiel.color} text-sm`}
          />
          <span className="text-3xl font-extrabold">
            {formatNumber(differenzZumZiel.value.toString(), false)}
          </span>
          <span className="font-semibold text-neutrals-N700">
            {t('general.currency.EUR')}
          </span>
        </div>
      </div>
    </div>
  );
}
