import { z } from 'zod';

export const umsatzplanungZieleSchema = z.object({
  absatz: z.string().nullable().optional(),
  absatzProduktivitaet: z.string().nullable().optional(),
  umsatz: z.string().nullable().optional(),
  umsatzProduktivitaet: z.string().nullable().optional(),
});

export type UmsatzplanungZieleFormValues = z.infer<
  typeof umsatzplanungZieleSchema
>;
