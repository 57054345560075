import {
  getMonthNameByDateObject,
  getWeekdayByDate,
} from '../../utils/dateFunctions/getDatesOfMonth';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Popover, PopoverContent, PopoverTrigger } from '../ui/popover';
import { Calendar } from '../ui/calendar';
import { Button } from '../ui/button';
import { Input } from '../ui/input';

export default function DatePickerFormField({
  date,
  onSelect,
}: {
  date: Date;
  onSelect: (date: Date) => void;
}) {
  const [open, setOpen] = useState(false);
  const { t } = useTranslation();
  const handleSelect = (d: Date) => {
    setOpen(false);
    onSelect(d);
  };
  const weekday = getWeekdayByDate(date);
  const day = date.getDate();
  const month = getMonthNameByDateObject(date);
  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger asChild>
        <Input
          className="w-[275px]"
          value={`${weekday.shortage}., ${day}. ${month}`}
        />
      </PopoverTrigger>
      <PopoverContent
        className="w-[275px] p-0 bg-white flex flex-col justify-center"
        align="start">
        <Calendar
          mode="single"
          selected={date}
          onSelect={(d) => {
            handleSelect(d);
          }}
          defaultMonth={date}
          initialFocus
        />
        <Button
          className="w-full"
          variant="ghost"
          onClick={() => {
            handleSelect(new Date());
          }}>
          {t('general.date.today')}
        </Button>
      </PopoverContent>
    </Popover>
  );
}
