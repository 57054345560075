import { Arbeitszeit } from '../../../../entities';
import { t } from 'i18next';
import { useArbeitszeitDelete } from '../../../../hooks/useArbeitszeitenDelete';
import { handleOnCompletedDelete } from '../../handleOnCompletedDelete';
import { ConfirmDeletePopover } from '../../../Popover/ConfirmDeletePopover';
import { toast } from '@/hooks/use-toast';
import * as Sentry from '@sentry/react';

export function DeleteArbeitszeit({
  arbeitszeit,
  onDeleted,
}: {
  arbeitszeit: Arbeitszeit;
  isDeleting: boolean;
  onDeleted?: () => void;
}) {
  const [arbeitszeitDelete] = useArbeitszeitDelete();

  async function handleDelete() {
    try {
      const result = await arbeitszeitDelete({
        arbeitszeitId: arbeitszeit.id,
      });
      handleOnCompletedDelete(result.data, t);
      toast({
        title: t('domain.arbeitszeiten.sheet.arbeitszeit.delete.erfolgreich'),
      });
    } catch (e) {
      toast({
        title: t(
          'domain.arbeitszeiten.sheet.header.arbeitszeitDeleteUnknownError',
        ),
      });
      Sentry.captureException(e);
    }
  }

  return (
    <ConfirmDeletePopover
      confirmText={t('domain.arbeitszeiten.sheet.arbeitszeit.confirmDelete')}
      handleDelete={handleDelete}
      onDeleted={onDeleted}
      confirmButtonText={t(
        'domain.arbeitszeiten.sheet.arbeitszeit.confirmButton',
      )}
      processingButtonText={t(
        'domain.arbeitszeiten.sheet.arbeitszeit.confirmIsDeleting',
      )}
    />
  );
}
