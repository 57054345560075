import { Markierung } from '../../gql/graphql';

export const markierungMapper: Record<
  Markierung,
  { light: string; base: string }
> = {
  YELLOW: { light: '#ffeec3', base: '#ffc73c' },
  ORANGE: { light: '#fed8b3', base: '#fd820b' },
  RED: { light: '#f8c1c2', base: '#ea383a' },
  DARKRED: { light: '#e5b6c1', base: '#ab1438' },
  ROSE: { light: '#fdd1e6', base: '#f76caf' },
  LILA: { light: '#dacefd', base: '#8962f8' },
  VIOLETT: { light: '#cdc9e4', base: '#5f50a9' },
  MAGENTA: { light: '#ecd8fd', base: '#c282fa' },
  PINK: { light: '#fdc4dd', base: '#f74090' },
  DARKBLUE: { light: '#b2d0e3', base: '#0567a6' },
  BLUE: { light: '#b3d9eb', base: '#0984bd' },
  LIGHTBLUE: { light: '#b0e3fa', base: '#00a4ef' },
  CYAN: { light: '#b6e5eb', base: '#5fc7d3' },
  DARKGREEN: { light: '#cddeda', base: '#5e9387' },
  GREEN: { light: '#e5ecc1', base: '#abc337' },
  LIGHTGREEN: { light: '#d9eed7', base: '#83c87d' },
  BROWN: { light: '#e7d8ca', base: '#b08053' },
  GOLD: { light: '#f8e9d4', base: '#eab875' },
  GRAY: { light: '#c6ccd4', base: '#475b74' },
  LIGHTGRAY: { light: '#dce3e7', base: '#72848e' },
  BLACK: { light: '#b9bbc1', base: '#1d2538' },
};
