import { Suspense } from 'react';
import { useParams } from 'react-router-dom';
import { LoadingPage } from '../../../../pages';
import { Page } from './Page';
import { MonthParams } from './types';

export function PageWrapper() {
  const { year, month } = useParams<MonthParams>();
  return (
    <Suspense key={month + '-' + year} fallback={<LoadingPage />}>
      <Page />
    </Suspense>
  );
}
