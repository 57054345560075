/* eslint-disable max-lines-per-function */
import {
  ColumnDef,
  ColumnFiltersState,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable,
} from '@tanstack/react-table';
import { useEffect, useState } from 'react';
import {
  Abteilung,
  Arbeitsbereich,
  Dienstplanuebernahme,
} from '../../entities';
import { useStandort } from '@/hooks';
import { useTranslation } from 'react-i18next';
import {
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@/components/ui/table';
import { E2Ntable, E2NtableHeader, E2NtableRow } from '@/components/Table';
import { faSearch } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useDienstplanColumnDefs } from '../../columns';
import { AlleUebernehmenDialog } from './AlleUebernehmenDialog';
import { AlleIgnorierenDialog } from './AlleIgnorierenDialog';

export type ArbeitsbereichFilter = {
  arbeitsbereiche?: string[];
  abteilungen?: string[];
};

interface DataTableProps<TData> {
  data: TData[];
  nameFilter: string;
  arbeitsbereichFilter: Arbeitsbereich[];
  abteilungFilter: Abteilung[];
}

export function DienstplanuebernahmeTable({
  data,
  nameFilter,
  arbeitsbereichFilter,
  abteilungFilter,
}: DataTableProps<Dienstplanuebernahme>) {
  const columns: ColumnDef<Dienstplanuebernahme>[] = useDienstplanColumnDefs();
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([
    { id: 'mitarbeiter', value: nameFilter },
  ]);
  const [sorting, setSorting] = useState<SortingState>([
    { id: 'arbeitszeit', desc: false },
  ]);
  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    onColumnFiltersChange: setColumnFilters,
    getFilteredRowModel: getFilteredRowModel(),
    state: { columnFilters, sorting },
    onSortingChange: setSorting,
  });

  const { t } = useTranslation();
  const currentStandort = useStandort();
  useEffect(() => {
    const mitarbeiterColumn = table.getColumn('mitarbeiter');
    const arbeitsbereichColumn = table.getColumn('arbeitsbereich');

    if (mitarbeiterColumn) {
      mitarbeiterColumn.setFilterValue(nameFilter);
    }

    if (arbeitsbereichColumn) {
      const filterValue = {
        arbeitsbereiche: arbeitsbereichFilter.map(
          (arbeitsbereich) => arbeitsbereich.id,
        ),
        abteilungen: currentStandort.abteilungenEnabled
          ? abteilungFilter.map((abteilung) => abteilung.id)
          : [],
      };
      arbeitsbereichColumn.setFilterValue(filterValue);
    }
  }, [
    nameFilter,
    arbeitsbereichFilter,
    abteilungFilter,
    currentStandort.abteilungenEnabled,
    table,
  ]);
  return (
    <div className="relative">
      <div className="absolute top-0 right-0 p-4 z-50">
        <div className="flex flex-row gap-1">
          <AlleUebernehmenDialog
            dienstplanuebernahmen={table
              .getFilteredRowModel()
              .rows.map((row) => row.original)}
          />
          <AlleIgnorierenDialog
            dienstplanuebernahmen={table
              .getFilteredRowModel()
              .rows.map((row) => row.original)}
          />
        </div>
      </div>
      <E2Ntable>
        <E2NtableHeader>
          {table.getHeaderGroups().map((headerGroup) => (
            <TableRow className="border-b-0" key={headerGroup.id}>
              {headerGroup.headers.map((header) => {
                return (
                  <TableHead
                    className={
                      'px-0 text-xs normal-case font-semibold h-0 pt-4'
                    }
                    key={header.id}
                    onClick={header.column.getToggleSortingHandler()}>
                    {header.isPlaceholder
                      ? null
                      : flexRender(
                          header.column.columnDef.header,
                          header.getContext(),
                        )}
                  </TableHead>
                );
              })}
            </TableRow>
          ))}
        </E2NtableHeader>
        <TableBody className="p-0">
          {table.getSortedRowModel().rows?.length ? (
            table.getSortedRowModel().rows.map((row) => {
              return (
                <E2NtableRow
                  className={`hover:bg-transparent ${'[&_td]:bg-white'}`}
                  key={row.id}
                  data-state={row.getIsSelected() && 'selected'}>
                  {row
                    .getVisibleCells()
                    .map((cell) =>
                      flexRender(cell.column.columnDef.cell, cell.getContext()),
                    )}
                </E2NtableRow>
              );
            })
          ) : (
            <TableRow>
              <TableCell colSpan={columns.length} className="h-24 text-center">
                <div className="flex flex-col gap-4 p-8">
                  <FontAwesomeIcon
                    icon={faSearch}
                    size="3x"
                    className="text-secondary-main"
                  />
                  <span className="text-md">
                    {t('domain.arbeitszeiten.uebersicht.noResults.title')}
                  </span>
                  <span className="text-xs text-neutral-400">
                    {t('domain.arbeitszeiten.uebersicht.noResults.subtitle')}
                  </span>
                </div>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </E2Ntable>
    </div>
  );
}
